import React, { useLayoutEffect, useRef } from "react";

export const AddPaymentHTMLTemplate = ({ data }) => {
  const buttonRef = useRef();
  useLayoutEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.click()
    }
  }, []);
  return <form action={`${data.url}`} method="post" id="form1" name="form1">
    <input type="hidden" name="ACTION" value={`${data.action}`} />
    <input type="hidden" name="TERMINALID" value={`${data.terminalId}`} />
    <input type="hidden" name="MERCHANTREF" value={`${data.merchantRef}`} />
    <input type="hidden" name="DATETIME" value={`${data.dateTime}`} />
    <input type="hidden" name="HASH" value={`${data.hash}`} />
    <input type="hidden" name="STOREDCREDENTIALUSE" value={`${data.storedCredentialUse}`} />
    <button ref={buttonRef} type="submit" style={{ display: 'hidden' }}></button>
  </form>
};