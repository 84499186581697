import { takeLatest, all, put, select } from 'redux-saga/effects';
import Actions from '../action';
import { doContionalGet, doContionalPost } from '../../service/index'
import { PIM_PRODUCTS, BISTRO_PRODUCT_LIST, PIM_PRODUCT_DETAILS, BISTRO_PRODUCT_DETAILS } from '../../config/api_endpoint'


export function* pimProducts({ params }) {
  try {
    if (params.page === 0) {
      yield put(Actions.pimProductsSuccess({ isLoading: true, response: { products: [] } }))
    }
    const response = yield doContionalGet(PIM_PRODUCTS, params);
    const currentData = yield select(Actions.getPimProducts);
    if (currentData?.pimProducts?.products) {
      const data = {
        response: {
          hasLoadMore: (response?.response?.products?.length >= params?.size),
          products: [
            ...currentData.pimProducts.products,
            ...response?.response?.products
          ]
        }
      }
      yield put(Actions.pimProductsSuccess(data))
    } else {
      response.hasLoadMore = (response?.response?.products?.length >= params?.size);
      yield put(Actions.pimProductsSuccess(response))
    }
  }
  catch (error) {
    yield put(Actions.pimProductsFailure({ error }))
  }
}

export function* pimBistroProducts({ params }) {
  try {
    if (params.page === 0) {
      yield put(Actions.bistroPimProductsSuccess({ isLoading: true, response: { response: [] } }))
    }
    const response = yield doContionalGet(BISTRO_PRODUCT_LIST, params);
    const currentData = yield select(Actions.getPimProducts);
    if (currentData?.pimProducts?.response) {
      const data = {
        hasLoadMore: (response?.response?.response?.length >= params?.size),
        response: {
          response: [
            ...currentData.pimProducts?.response,
            ...response?.response?.response
          ]
        }
      }
      yield put(Actions.bistroPimProductsSuccess(data))
    } else {
      response.hasLoadMore = (response?.response?.response?.length >= params.size);
      yield put(Actions.bistroPimProductsSuccess(response))
    }
  }
  catch (error) {
    yield put(Actions.bistroPimProductsFailure({ error }))
  }
}

export function* pimProductsDetails({ params }) {
  try {
    const response = yield doContionalPost(PIM_PRODUCT_DETAILS, params);
    yield put(Actions.pimProductsDetailsSuccess(response))
  }
  catch (error) {
    yield put(Actions.pimProductsDetailsFailure({ error }))
  }
}

export function* bistroProductsDetails({ params }) {
  try {
    const response = yield doContionalPost(BISTRO_PRODUCT_DETAILS, params);
    yield put(Actions.bistroProductsDetailsSuccess(response))
  }
  catch (error) {
    yield put(Actions.bistroProductsDetailsFailure({ error }))
  }
}

export default function* pimProductsWatcher() {
  yield all([
    takeLatest(Actions.PIM_PRODUCTS, pimProducts),
    takeLatest(Actions.BISTRO_PIM_PRODUCTS, pimBistroProducts),
    takeLatest(Actions.PIM_PRODUCTS_DETAILS, pimProductsDetails),
    takeLatest(Actions.BISTRO_PRODUCTS_DETAILS, bistroProductsDetails),

  ]);
}
