import Actions from '../../action'
const initialState = {
    isLoading: false,
    userDetails: {},
    error: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_USER_DETAIL: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_USER_DETAIL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                userDetails: action.data?.response || {},
                error: false
            };
        }
        case Actions.GET_USER_DETAIL_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        } case Actions.UPDATE_PROFILE_NAME: {
            let { userDetails } = state
            return {
                ...state,
                userDetails: {
                    ...userDetails,
                    firstName: action.data?.firstName,
                    lastName: action.data?.lastName,
                }
            };
        }
        default:
            return state;
    }
};
