import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { createPortal } from 'react-dom'
import routes from '../../config/routes';
import { toast } from 'react-toastify';
import Constants from '../../Utility/Constants';
import { AddPaymentHTMLTemplate } from './AddPaymentTemplate';


export const IFrame = ({
    children,
    ...props
}) => {
    const [contentRef, setContentRef] = useState(null);
    const history = useHistory()
    const mountNode =
        contentRef?.contentWindow?.document?.body

    const loadedScreen = () => {
        try {
            const currentPath = document.getElementById("payment_iframe_id").contentWindow.location.href;
            if (currentPath.includes('/subscription')) {
                history.replace(routes.SUBSCRIPTION);
                toast.success(`${Constants.PAYMENT.subscriptionSuccess}`);
            } else {
                history.replace(routes.SUBSCRIPTION);
                toast.error(Constants.PAYMENT.failureMessage);
            }
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <iframe
                title="payment_iframe_title"
                id="payment_iframe_id"
                ref={setContentRef}
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                onLoad={loadedScreen}
                {...props}
            >
                {mountNode && createPortal(children, mountNode)}
            </iframe>
        </div>
    )
}

export class SubscriptionPaymentScreen extends React.Component {

    render() {
        const { htmlTemplate } = this.props.location.state;
        return htmlTemplate ? <IFrame>
            <AddPaymentHTMLTemplate data={htmlTemplate} />
        </IFrame> : null
    }
}

export default withRouter(SubscriptionPaymentScreen)