import React, { Component } from 'react';
import Close from '../../asset/img/modal-close.svg'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import Constants, { RefundOptions } from "../../Utility/Constants";
import moment from "moment";
import { getRandomNumber } from '../../Utility/Utils';
import OrderStatus from '../../container/orderSummary/OrderStatus';

class ViewRefundReceipt extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        let { invoiceId } = this.props.match.params;
        let params = { invoiceId: invoiceId };
        this.props.getRefundDetails(params);
    }

    _getPriceOfProduct = item => {
        let { unitPrice, invoiceProductAddOnMappingList, quantity, acceptedQuantity } = item;
        let sellingPrice = parseFloat(unitPrice);
        if (invoiceProductAddOnMappingList && invoiceProductAddOnMappingList.length !== 0) {
            sellingPrice = sellingPrice +
                invoiceProductAddOnMappingList.reduce((accumulator, i) => {
                    return accumulator + parseFloat(i.unitPrice);
                }, 0);
        }
        return ((acceptedQuantity !== 0 ? acceptedQuantity : quantity) * sellingPrice)?.toFixed(2);
    };

    render() {
        const { onClose, openRefundPreview } = this.props;
        const { refundDetailsData } = this.props.refundDetails || {};
        const { refundDetails } = refundDetailsData || {};
        const { invoiceProductDetailsList, action } = refundDetails || {};
        return (
            <React.Fragment>
                <div className="modal d-block" id="order-details-modal" tabIndex="-1" aria-labelledby="login" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-0">

                            <img src={Close} alt="X"
                                data-bs-dismiss="modal" aria-label="Close" id='closeButton' className="modal-close" onClick={() => onClose()} />

                            <div className="modal-header rounded-0 align-items-center py-4">
                                <div className="col">
                                    <h5 className="modal-title"> Refund details </h5>
                                </div>

                            </div>
                            <div className="modal-body font13 p-40">

                                <div className="row mb-1">
                                    <div className="col"> <h3 className="font16 fw-bold">Refund Items</h3></div>
                                    <div className="col-auto text-end">
                                        <OrderStatus paymentStatus={action} />
                                    </div>
                                </div>

                                <div className="pt-1 mb-3">
                                    {invoiceProductDetailsList?.map((o) => {
                                        let qtydiff = o?.refundQuantity - o?.refundApprovedQuantity;
                                        return (
                                            <div className="row mb-2">
                                                <div className={`col title-case `}>
                                                    <div className={`${(o?.refundApprovedQuantity === 0
                                                        && refundDetails?.action !== 11) ? 'strike' : ''}`}>
                                                        {refundDetails.action === 11 || o?.refundApprovedQuantity === 0 ? o?.refundQuantity : o?.refundApprovedQuantity} x {o?.name?.toLowerCase()} (SKU : {o?.upc})
                                                        {o?.ageRestricted && <span style={{ color: 'red' }}>18+</span>}
                                                        {o.invoiceProductAddOnMappingList?.length > 0 && (<>
                                                            <p style={{ fontSize: '1rem', paddingLeft: '10px' }}
                                                                className={`mt-1 ${(o?.acceptedQuantity === 0) ? 'strike' : ''}`}>
                                                                <b>{`Your Customization`}</b>:</p>
                                                            <p style={{ paddingLeft: '10px', fontSize: '1rem' }}
                                                                className={`title-case mt-1 ${(o?.acceptedQuantity === 0) ? 'strike' : ''}`}
                                                            >
                                                                {o.invoiceProductAddOnMappingList?.map((addons) => addons?.name?.toLowerCase()).join(',')}
                                                            </p>
                                                        </>)}

                                                    </div>
                                                    {o && o.refundQuantity > 0 && (
                                                        <>
                                                            <p style={{ fontSize: '1rem', paddingLeft: '10px' }} className="mt-1">Refund Reason: <b>{RefundOptions(o?.refundType)}</b></p>
                                                            {o?.refundReason && <p style={{ fontSize: '1rem', paddingLeft: '10px' }} className="mt-1"><b>Comments :</b>{o?.refundReason}</p>}
                                                        </>
                                                    )
                                                    }
                                                    {qtydiff && (action === 13 || action === 12) && (
                                                        o?.refundApprovedQuantity !== 0) ? <p style={{ color: 'red', paddingLeft: '10px' }} className="mt-1"> Unfulfilled Refund Quantity: {qtydiff}</p> : null}
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>

                                {refundDetails ?
                                    <>
                                        <div className="dotted-divider pt-2 mt-2 mb-3">
                                            <div className="row mb-2">
                                                <div
                                                    className="col">Receipt number</div>
                                                <div
                                                    className="col-auto text-end"><b>{refundDetails?.invoiceId}</b> </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div
                                                    className="col">Refund number</div>
                                                <div
                                                    className="col-auto text-end"><b>{refundDetails?.refundDetailsId}</b></div>
                                            </div>

                                            <div className="row mb-2">
                                                <div
                                                    className="col">Requested date</div>
                                                <div
                                                    className="col-auto text-end"><b>{moment(refundDetails?.createdDate).format('DD - MMM | h:mm a')}</b></div>
                                            </div>


                                            {
                                                refundDetails?.partialRefundComment && <div className="row mb-2">
                                                    <div className="col">Refund comment:</div>
                                                    <div className="col-auto"> {refundDetails?.partialRefundComment}</div>
                                                </div>
                                            }
                                            {
                                                refundDetails?.actionComment && <div className="row mb-2">
                                                    <div className="col">Declined reason</div>
                                                    <div className="col-auto text-end">{refundDetails?.actionComment} </div>
                                                </div>
                                            }

                                        </div>
                                        <div className="row mb-2">
                                            <div
                                                className="col"><b>Requested amount</b></div>
                                            <div
                                                className="col-auto text-end"><b>${refundDetails?.totalAmount?.toFixed(2)} </b></div>
                                        </div>
                                        {(action === 12 || action === 13) && <div className="row mb-2">
                                            <div className="col"><b>Processed amount:</b></div>
                                            <div className="col-auto text-end"><b>${refundDetails?.acceptedAmount?.toFixed(2)}</b></div>
                                        </div>}
                                        {
                                            action === 12 && <span style={{ color: 'blue', fontWeight: '500' }}>Note : {Constants.ORDER_SUMMARY.paymentType.refundApprocedAfter}</span>
                                        }
                                        {
                                            action === 11 && <span style={{ color: 'red', fontWeight: '500' }}>{Constants.ORDER_SUMMARY.refundText}</span>
                                        }

                                        {
                                            refundDetails?.refundImageUrl?.length > 0 && <div className="mt-2">
                                                {
                                                    refundDetails?.refundImageUrl?.map((o) => {
                                                        return (
                                                            <span
                                                                id="openRefundPreview"
                                                                onClick={() => openRefundPreview(o?.imageUrl)}
                                                                style={{ marginLeft: '20px' }}
                                                                className="cursor-pointer">
                                                                <img
                                                                    src={o?.imageUrl + '?$' + getRandomNumber()}
                                                                    alt="img"
                                                                    style={{ width: '100px' }}
                                                                />
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }

                                    </>
                                    : null
                                }

                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </React.Fragment >
        );
    }
}


export const mapStateToProps = (state) => {
    return {
        refundDetails: Actions.refundDetails(state),
    };
};

export default withRouter(connect(mapStateToProps, {
    getRefundDetails: Actions.getRefundDetails,
})(ViewRefundReceipt));
