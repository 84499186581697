import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doDelete } from '../../service/index'
import { ACCOUNT_DELETE } from '../../config/api_endpoint';

export function* accountDelete({ params, callback }) {
    try {
        const response = yield doDelete(ACCOUNT_DELETE, params);
        yield put(Actions.accountDeleteSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.accountDeleteFailure(error));
    }
}


export default function* menuWatcher() {
    yield all([
        takeLatest(Actions.ACCOUNT_DELETE, accountDelete),
    ]);
}
