import Actions from '../action'
const initialState = {
    isLoading: false,
    rewards: null,
    invoiceCount: null,
    error: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_REWARDS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_REWARDS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                rewards: action?.data,
                invoiceCount: action.data.count,
                error: false
            };
        }
        case Actions.GET_REWARDS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
