import React from 'react'
import { withRouter } from 'react-router-dom'
class SavedCards extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    renderCardType(cartType) {
        if (cartType === 'VISA') {
            return require('../asset/img/card_type/visa_credit.png');
        }
        if (cartType === 'VISA DEBIT') {
            return require('../asset/img/card_type/visa_debit.png');
        }
        if (cartType === 'ELECTRON') {
            return require('../asset/img/card_type/visa_electron.png');
        }
        if (cartType === 'MASTERCARD') {
            return require('../asset/img/card_type/master_card.png');
        }
        if (cartType === 'DEBIT MASTERCARD') {
            return require('../asset/img/card_type/master_card.png');
        }
        if (cartType === 'MAESTRO') {
            return require('../asset/img/card_type/maestro.png');
        } if (cartType === 'AMEX') {
            return require('../asset/img/card_type/amex.png');
        }if (cartType === 'DINERS') {
            return require('../asset/img/card_type/diners.png');
        }
        if (cartType === 'JCB') {
            return require('../asset/img/card_type/jcb.png');
        }
        if (cartType === 'DISCOVER') {
            return require('../asset/img/card_type/discover.png');
        }
        return require('../asset/img/card_type/general.png');
    }
    render() {
        const { makeDefaultAlert, cardDetails, index, deletePopup, isCart, chooseCheckoutCard } = this.props || {}
        const { paymentResponseJson, defaultPaymentMethod, customerTokenDetailsId,
            maskedCardNumber } = cardDetails || {}
        let parsedJSON = ''
        if (paymentResponseJson) {
            parsedJSON = JSON.parse(paymentResponseJson)
        }
        return (
            <React.Fragment>
                <div className="col" key={index}>
                    <div className="card rounded-0 border-dark ">
                        <div className="card-body pb-4">
                            {
                                isCart ? <input
                                    type="radio"
                                    name="addr-type"
                                    id="addr1"
                                    className="mb-3 cursor-pointer"
                                    checked={this.props.selectedCartId === customerTokenDetailsId ? 'checked' : ''}
                                    onClick={() => chooseCheckoutCard(customerTokenDetailsId)}
                                /> :

                                    <input
                                        type="radio"
                                        name="addr-type"
                                        id="addr1"
                                        className="mb-3 cursor-pointer"
                                        onChange={() => makeDefaultAlert(customerTokenDetailsId, defaultPaymentMethod)}
                                        checked={defaultPaymentMethod ? "checked" : ""} />
                            }

                            <div className="row">
                                <div className="col-sm-3">
                                    <img src={this.renderCardType(parsedJSON?.CARDTYPE)} alt="mastercard_cc_logo "
                                        className="img-fluid" />
                                </div>

                                <div className="col-sm-9">
                                    <label htmlFor="addr2">
                                        <h5 className="card-title fw-bold">{maskedCardNumber}</h5>
                                        <p className="card-text">Expires <b>{parsedJSON?.CARDEXPIRY?.slice(0, 2)}/
                                            {parsedJSON?.CARDEXPIRY?.slice(2)}</b></p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {
                            !isCart && <div className="card-footer text-end">
                                <div className="row">
                                    <div className="col text-start">
                                    </div>
                                    <div className="col text-end">
                                        <small className="text-muted">
                                            <span
                                                id="RemoveAccount"
                                                onClick={() => deletePopup(customerTokenDetailsId, defaultPaymentMethod)}
                                                className="text-success cursor-pointer">Remove Card</span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(SavedCards)