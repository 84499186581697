import cloneDeep from 'lodash.clonedeep';
import Actions from '../action'
const initialState = {
    chatMessages: {},
    isLoading: false,
    error: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_CHAT_MESSAGES_LIST: {
            return {
                ...state,
                isLoading: true,
                chatMessages: {}
            };
        }
        case Actions.GET_CHAT_MESSAGES_LIST_SUCCESS: {
            const chatdata = action.data;
            return {
                ...state,
                isLoading: false,
                chatMessages: chatdata,
                error: false
            };
        }
        case Actions.GET_CHAT_MESSAGES_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};


export const updateChatObjectSelector = (state) => {
    return state?.CHAT_SUPPORT?.chatMessages || {}
};

export const getChatObjectSelector = (state) => {
    const data = state?.CHAT_SUPPORT?.chatMessages;
    if (data?.chatMessages) {
        const newRefData = cloneDeep(data);
        newRefData.chatMessages.reverse();
        return newRefData;
    }
    return {}
};


export const isChatListLoadingSelector = (state) => state?.CHAT_SUPPORT?.isLoading