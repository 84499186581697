export const UPDATE_ACCOUNT_PASSWORD = `UPDATE_ACCOUNT_PASSWORD`;
export const UPDATE_ACCOUNT_PASSWORD_SUCCESS = `UPDATE_ACCOUNT_PASSWORD_SUCCESS`;
export const UPDATE_ACCOUNT_PASSWORD_FAILURE = `UPDATE_ACCOUNT_PASSWORD_FAILURE`;

export const updateAccountPassword = (params, callback) => {
    return {
        type: UPDATE_ACCOUNT_PASSWORD,
        params,
        callback
    }
}
export const updateAccountPasswordSuccess = (data) => {
    return {
        type: UPDATE_ACCOUNT_PASSWORD_SUCCESS,
        data
    }
}

export const updateAccountPasswordFailure = (error) => {
    return {
        type: UPDATE_ACCOUNT_PASSWORD_FAILURE,
        error
    }
}





