import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Actions from "../../redux/action";
import { startSocketConnect, stopSocketConnect } from '../../socket'
import { updateChatObjectSelector, isChatListLoadingSelector } from '../../redux/reducer/chatsReducer';
import cloneDeep from 'lodash.clonedeep';
import { faComment, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SocketMessage extends React.Component {
    componentDidMount() {
        this._initiateSocket()
    }

    _initiateSocket() {
        const { token } = this.props.user
        if (token) {
            startSocketConnect((socket) => {
                this.showToastMessage(socket);
                const pathArr = this.props.location.pathname.split('/');
                const invoiceId = pathArr[pathArr.length - 1];
                if (socket?.type === 'newChatMessage' &&
                    (this.props.history.location.pathname.includes('ordersummary') || this.props.history.location.pathname.includes('myaccounts/order/summaryDetails'))
                    && invoiceId && parseInt(invoiceId, 10) === socket?.invoiceId) {
                    const { messageContent } = socket || {}
                    const tempChatObj = this.props.chatObject
                    const { chatMessages } = tempChatObj || {}
                    chatMessages && chatMessages.unshift(messageContent)
                    this.props.getChatMessagesListSuccess(tempChatObj)
                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.user?.token?.access_token !== this.props.user?.token?.access_token) {
            stopSocketConnect();
            this._initiateSocket();
        }
    }
    renderToastIcons(socketObj) {
        if (socketObj?.type === 'newChatMessage') {
            return <FontAwesomeIcon icon={faComment} size="lg" color='rgb(0,128,0)' />;
        } else {
            return <FontAwesomeIcon icon={faInfoCircle} size="lg" color='rgb(0,128,0)' />;
        }
    }
    showToastMessage(socketObj) {
        if (socketObj?.message) {
            const toastId = Date.now()
            toast(<><span style={{ marginRight: '10px' }}>{this.renderToastIcons(socketObj)}</span>{socketObj?.message}</>, {
                position: toast.POSITION.TOP_RIGHT,
                closeOnClick: () => null,
                onClick: () => this.navToaster(socketObj, toastId),
                toastId,
                progressStyle: {
                    background: 'rgb(0,128,0)',
                }
            });
            // if user is in the summary page refresh the page
            if ((socketObj.invoiceDetailsId && this.props.history.location.pathname.includes('ordersummary')) ||
                (socketObj.invoiceDetailsId && this.props.history.location.pathname.includes('myaccounts/order/summaryDetails'))) {
                const currentScreenId = this.props.history.location.pathname.split('/').reverse().shift();
                if (currentScreenId && parseInt(currentScreenId) === parseInt(socketObj.invoiceDetailsId)) {
                    window.location.reload();
                }
            }
            // if (socketObj?.type === 'manualRefund') {
            //     window.location.reload();
            // }
        }

    }

    navToaster(socketObj, toastId) {
        toast.dismiss(toastId);
        // socket message scenario

        if (socketObj?.type === 'manualRefund') {
            this.navManualRefund(socketObj)
        } else {
            if (socketObj?.type === 'newChatMessage' &&
                !this.props.history.location.pathname.includes('ordersummary')) {
                if (this.props.history.location.pathname.includes('myaccounts/orders')) {
                    this.props.history.push(`/myaccounts/order/summaryDetails/${socketObj.invoiceId}?openChat=true`);
                    return;
                }
                this.props.history.push('myaccounts/orders');
                this.props.history.push(`/myaccounts/order/summaryDetails/${socketObj.invoiceId}?openChat=true`);
            } else if (socketObj?.type === 'newChatMessage' &&
                this.props.history.location.pathname.includes('ordersummary')) {
                const [, , , invoiceId] = this.props.location.pathname.split('/');
                if (parseInt(invoiceId, 10) !== socketObj?.invoiceId) {
                    this.props.history.replace(`/ordersummary/${socketObj.invoiceId}?openChat=true`);
                }
                // normal scenario 
            } else {
                this.props.history.push(`/ordersummary/${socketObj.invoiceDetailsId}`);
            }
        }


    }


    navManualRefund(socketObj) {
        if (socketObj?.type === 'manualRefund' &&
            !this.props.history.location.pathname.includes('ordersummary')) {
            if (this.props.history.location.pathname.includes('myaccounts/orders')) {
                this.props.history.push(`/myaccounts/order/summaryDetails/${socketObj?.invoiceId}`);
                return;
            }
            this.props.history.push('myaccounts/orders');
            this.props.history.push(`/myaccounts/order/summaryDetails/${socketObj?.invoiceId}`);
        } else if (socketObj?.type === 'manualRefund' &&
            this.props.history.location.pathname.includes('ordersummary')) {
            const [, , , invoiceId] = this.props.location.pathname.split('/');
            if (parseInt(invoiceId, 10) !== socketObj?.invoiceId) {
                this.props.history.replace(`/ordersummary/${socketObj?.invoiceId}`);
            }
            // normal scenario 
        } else {
            this.props.history.push(`/ordersummary/${socketObj?.invoiceId}`);
        }
    }

    render() {
        return <ToastContainer />
    }
}

export const mapStateToProps = (state) => {
    const messages = updateChatObjectSelector(state);
    return {
        user: Actions.getUserDetail(state),
        chatObject: cloneDeep(messages),
        isChatsLoading: isChatListLoadingSelector(state),
        orderSummaryInfo: Actions.getOrderDetails(state),

    };
};
export default connect(mapStateToProps, {
    getChatMessages: Actions.getChatMessagesList,
    getChatMessagesListSuccess: Actions.getChatMessagesListSuccess,
})(withRouter(SocketMessage));
