import React from 'react'
import { withRouter } from 'react-router-dom'
import ModalPopup, { ModalHeader } from "../../component/ModalPopup";

class RepeatCustomization extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { closeCustomModal, isModalOpen, repeatCustom, chooseNew } = this.props
        return (
            <>
                {isModalOpen && <ModalPopup id='ModalPopup' onCloseModal={() =>
                    closeCustomModal()}>
                    <ModalHeader
                        onCloseModal={() => closeCustomModal()} />
                    <form>
                        <div className="row mb-4">
                            <div className="col">
                                <h3 className="font18 fw-bold mb-3">Repeat customization ? </h3>
                                <p>Would you like to repeat the last used customization for this?</p>
                            </div>
                        </div>

                        <div className="mb-2">
                            <button
                                type="button"
                                className="btn btn-dark big-btn px-5 mb-4 me-2 text-uppercase" id="chooseNew" onClick={() => chooseNew()}>I'll choose new  </button>
                            <button
                                type="button"
                                className="btn btn-outline-dark big-btn mb-4 px-5 text-uppercase" id="repeatCustom" onClick={() => repeatCustom()}>Repeat last</button>
                        </div>

                    </form>
                </ModalPopup>}
            </>
        )
    }
}

export default withRouter(RepeatCustomization)