
const NAME = "USER";
export const LOGIN = `${NAME}/LOGIN`;
export const LOGIN_SUCCESS = `${NAME}/LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${NAME}/LOGIN_FAILURE`;

export const LOGOUT = `${NAME}/LOGOUT`;
export const LOGOUT_SUCCESS = `${NAME}/LOGOUT_SUCCESS`;
export const LOGOUT_FAILURE = `${NAME}/LOGOUT_FAILURE`;

export const REFRESH_TOKEN_ACTION = 'REFRESH_TOKEN_ACTION';


export const getUserDetail = store => store[NAME]
export const login = (params, callback) => {
    return {
        type: LOGIN,
        params,
        callback
    }
}

export const loginSuccess = (data) => {
    return {
        type: LOGIN_SUCCESS,
        data
    }
}

export const loginFailure = (error) => {
    return {
        type: LOGIN_FAILURE,
        error
    }
}


export const logout = () => {
    return {
        type: LOGOUT,
    }
}

export const logoutSuccess = () => {
    return {
        type: LOGOUT_SUCCESS,
    }
}

export const logoutFailure = () => {
    return {
        type: LOGOUT_FAILURE,
    }
}



export const refreshTokenAction = (data, callback) => ({
    type: REFRESH_TOKEN_ACTION,
    data,
    callback,
})

