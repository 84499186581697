import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Actions from "../redux/action";
import eventBus, { EVENT_SIGN_IN_OPEN } from "../Utility/event";

class CustomerCare extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        const { token } = this.props.userDeatils;

        localStorage.setItem("isGuestCustomer", true)
        if (!token) {
            eventBus.dispatch(EVENT_SIGN_IN_OPEN, { isAddToCart: true });
        } else {
            this.props.history.push('/')
        }
    }
    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        userDeatils: Actions.getUserDetail(state),
    };
};

export default withRouter(connect(mapStateToProps, {
    createGuestCustomer: Actions.createGuestCustomer,
    getPimCartList: Actions.getPimCartList
})(CustomerCare))