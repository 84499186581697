import React from 'react'
import { withRouter } from 'react-router-dom'
import NoResult from '../asset/img/empty.png'
class EmptyPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { content } = this.props
        return (
            <React.Fragment>
                <div className="text-center">
                    <img src={NoResult} alt="Nol Results" />
                    <h2 className="font16 mb-2 mt-4 fw-bold">
                        {content}
                    </h2>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(EmptyPage)