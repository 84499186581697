const NAME = "MANAGE_PAYMENT";
export const GET_PAYMENT_OPTIONS_LIST = `${NAME}/GET_PAYMENT_OPTIONS_LIST`;
export const GET_PAYMENT_OPTIONS_LIST_SUCCESS = `${NAME}/GET_PAYMENT_OPTIONS_LIST_SUCCESS`;
export const GET_PAYMENT_OPTIONS_LIST_FAILURE = `${NAME}/GET_PAYMENT_OPTIONS_LIST_FAILURE`;

export const CREATE_PAYMENT_CARD = `${NAME}/CREATE_PAYMENT_CARD`;
export const CREATE_PAYMENT_CARD_SUCCESS = `${NAME}/CREATE_PAYMENT_CARD_SUCCESS`;
export const CREATE_PAYMENT_CARD_FAILURE = `${NAME}/CREATE_PAYMENT_CARD_FAILURE`;

export const CHANGE_CUSTOMER_PAYMENT_STATUS = 'CHANGE_CUSTOMER_PAYMENT_STATUS'

export const getPaymentCardDetails = store => store[NAME]
export const getPaymentCardList = (params) => {
    return {
        type: GET_PAYMENT_OPTIONS_LIST,
        params
    }
}

export const getPaymentCardListSuccess = (data) => {
    return {
        type: GET_PAYMENT_OPTIONS_LIST_SUCCESS,
        data
    }
}

export const getPaymentCardListFailure = (error) => {
    return {
        type: GET_PAYMENT_OPTIONS_LIST_FAILURE,
        error
    }
}


export const createPaymentCard = (params, callback) => {
    return {
        type: CREATE_PAYMENT_CARD,
        params,
        callback
    }
}

export const createPaymentCardSuccess = (data) => {
    return {
        type: CREATE_PAYMENT_CARD_SUCCESS,
        data
    }
}

export const createPaymentCardFailure = (error) => {
    return {
        type: CREATE_PAYMENT_CARD_FAILURE,
        error
    }
}

export const changeCustomerPaymentStatus = (data) => {
    return {
        type: CHANGE_CUSTOMER_PAYMENT_STATUS,
        data
    }
}