import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthGet } from '../../service/index'
import { ADDRESS_TYPE } from '../../config/api_endpoint';

export function* addressType() {
    try {
        const response = yield doAuthGet(ADDRESS_TYPE);
        yield put(Actions.addressTypeSuccess(response));
    } catch (error) {
        yield put(Actions.addressTypeFailure(error));
    }
}


export default function* cartWatcher() {
    yield all([
        takeLatest(Actions.ADDRESS_TYPE, addressType),
    ]);
}
