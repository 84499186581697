import React from 'react';


class OrderSummaryOfferDetails extends React.Component {
    _renderComboOffer = () => {
        return this.props.cartOffer?.comboOffer?.length > 0 ? <>
            {this.props.cartOffer.comboOffer.map(comboOfferObj => {

                const comboProducts = comboOfferObj.skus?.map(sku => this.props.cartItems?.find(c => c.upc === sku))
                const itemStr = comboProducts && comboProducts?.map(c => c?.name).join(', ')
                return (<div className="row mb-3" key={JSON.stringify(comboOfferObj) + itemStr}>
                    <div className="col title-case">
                        <strong>Combo Offer:</strong> <br />
                        {comboOfferObj?.comboAppliedTimes}
                        {'x '}{itemStr?.toLowerCase()} <strong className="text-success">(${comboOfferObj?.totalComboPrice?.toFixed(2)})</strong>
                    </div>
                    <div className="col text-end">
                        <strong></strong> <br />
                        <strong className="text-success">- ${comboOfferObj?.discount?.toFixed(2)}</strong>
                    </div>
                </div>
                )
            })}
        </> : null;
    };

    _renderFreeGive = () => {
        return this.props.cartOffer?.freeGive?.length > 0 ? <>
            {this.props.cartOffer?.freeGive?.map(freeGiveObj => {
                const itemStr = freeGiveObj?.freeItemIdsWithQuantities?.reduce((accumulator, currentObj) => {
                    const cartObject = this.props.cartItems?.find(c => c.upc === currentObj?.sku)
                    accumulator = accumulator ? accumulator + ', ' : '';
                    return accumulator + currentObj?.quantity + 'x ' + cartObject?.name?.toLowerCase();
                }, '');
                const cartFreeItemObject = this.props.cartItems?.find(c => c.upc === freeGiveObj?.sku)
                return <React.Fragment key={JSON.stringify(freeGiveObj)}>
                    <div className="row mb-3">
                        <div className="col">

                            {
                                itemStr && <>  <strong>Free Give:</strong><div className="col title-case">
                                    <strong>Paid Item:</strong> <br />
                                    {cartFreeItemObject?.name?.toLowerCase()}
                                </div>

                                    <div className="col title-case">
                                        <strong>Free:</strong> <br />
                                        {itemStr?.trim().length > 0 && <div>{itemStr?.toLowerCase()}</div>}
                                    </div> </>
                            }

                        </div>
                        {itemStr && <div className="col text-end">
                            <strong></strong> <br />
                            <strong className="text-success">- ${freeGiveObj?.subTotalDiscount?.toFixed(2)}</strong>
                        </div>}
                    </div>
                </React.Fragment>
            })}
        </> : null;
    };


    _renderMultiBuy = () => {
        return this.props.cartOffer?.multiBuy?.length > 0 ? <>
            {this.props.cartOffer.multiBuy?.map(multiBuyObj => {
                const cartObject = this.props.cartItems?.find(c => c.upc === multiBuyObj?.sku)
                return (
                    <div className="row mb-3" key={JSON.stringify(multiBuyObj)}>
                        <div className="col title-case">
                            <strong>Multi Buy:</strong> <br />
                            {multiBuyObj?.quantity}
                            {'x '}{cartObject?.name?.toLowerCase()}
                        </div>
                        <div className="col text-end">
                            <strong></strong> <br />
                            <strong className="text-success">- ${multiBuyObj?.discount?.toFixed(2)}</strong>
                        </div>
                    </div>
                )
            })}
        </> : null;
    };

    _renderHappyHours = () => {
        return this.props.cartOffer?.happyHours?.length > 0 ? <>
            {this.props.cartOffer.happyHours?.map(happyHoursObj => {
                const cartObject = this.props.cartItems?.find(c => c.upc === happyHoursObj?.sku)
                return (
                    <div className="row mb-3" key={JSON.stringify(happyHoursObj)}>
                        <div className="col title-case">
                            <strong>Happy Hours:</strong> <br />
                            {cartObject?.name?.toLowerCase()}
                        </div>
                        <div className="col text-end">
                            <strong></strong> <br />
                            <strong className="text-success">- ${happyHoursObj?.discount?.toFixed(2)}</strong>
                        </div>
                    </div>
                )
            })}
        </> : null;
    };


    _renderSubscription = () => {
        return this.props.cartOffer?.subscriptions?.length > 0 ? <>
            {this.props.cartOffer.subscriptions?.map(subscriptionObj => {
                const subscriptionProducts = subscriptionObj?.products?.map(o => this.props.cartItems.find(c => c.upc === o.sku))
                const itemStr = subscriptionProducts?.map(c => c?.name).join(', ')
                return (<div className="row mb-3">
                    <div className="col title-case">
                        <strong>Subscriptions: </strong> <br />
                        {itemStr?.toLowerCase()}
                    </div>
                    <div className="col text-end">
                        <strong></strong> <br />
                        <strong className="text-success">- ${subscriptionObj?.discount?.toFixed(2)}</strong>
                    </div>
                </div>
                )
            })}
        </> : null;

    }

    _renderOffers = () => {
        if (
            this.props.cartOffer &&
            ((this.props.cartOffer.comboOffer &&
                this.props.cartOffer?.comboOffer?.length > 0) ||
                (this.props.cartOffer.freeGive &&
                    this.props.cartOffer?.freeGive?.length > 0) ||
                (this.props.cartOffer.multiBuy &&
                    this.props.cartOffer?.multiBuy?.length > 0) ||
                (this.props.cartOffer.happyHours &&
                    this.props.cartOffer?.happyHours?.length > 0) ||
                (this.props.cartOffer.subscriptions &&
                    this.props.cartOffer?.subscriptions?.length > 0))
        ) {
            return (
                <div className="order-dividers pt-3 mb-3 title-case">
                    <h3 className="font16 fw-bold mb-3">OFFERS</h3>
                    {this._renderSubscription()}
                    {this._renderComboOffer()}
                    {this._renderFreeGive()}
                    {this._renderHappyHours()}
                    {this._renderMultiBuy()}
                </div>
            );
        }
    };

    render() {
        return (<>
            {this._renderOffers()}
        </>)
    }
}


export default OrderSummaryOfferDetails;