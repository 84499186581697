import Actions from '../../action'
const initialState = {
    isLoading: false,
    addressDetails: [],
    error: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_ADDRESS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_ADDRESS_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                addressDetails: action.data.response,
                error: false
            };
        }
        case Actions.GET_ADDRESS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                addressDetails: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};
