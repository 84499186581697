import React, { Component } from 'react';
import Constants from '../../Utility/Constants';
import { toast } from 'react-toastify'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import ProfileAddressPopUp from '../profileAddressPopUp';
import EmptyPage from '../emptyPage'
import Alert from '../alert';

class ManageAddress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addAddressModal: false,
            editAddressModal: false,
            fields: {
                active: false
            },
            errors: {},
            alertModal: false
        }
    }

    componentDidMount() {
        this.props.getAddress()
    }

    onEditAddress(obj) {
        let { fields } = this.state
        fields["doorNo"] = obj.street;
        fields["landmark"] = obj.landmark;
        fields["addressId"] = obj.id;
        fields["mobileNumber"] = obj.mobileNumber;
        fields["lat"] = obj.latitude;
        fields["lng"] = obj.longitude;
        fields["active"] = obj?.active;
        fields['formattedAddress'] = obj?.formattedAddress;
        fields['addressType'] = obj?.addresstype?.id
        this.setState({ editAddressModal: true, fields })
    }


    onOpenAlertModal(addressId, index) {
        this.setState({ alertModal: !this.state.alertModal, addressId, index })
    }

    onDeleteAddress = () => {
        const { addressId } = this.state
        const callback = (response) => {
            if (response && response.response_code === 0) {
                toast.success('address deleted successfully')
                this.setState({ alertModal: false, errorMessage: null })
            } else {
                toast.error(`${response?.response_message}`)
            }
        }

        let params = {
            addressId
        }
        this.props.deleteAddress(params, callback)
    }

    addressDetails = () => {
        this.setState({ addAddressModal: true })
    }

    handleSubmitValidation(fields) {
        let isFormValid = true;
        fields.forEach((field) => {
            if (!this.handleValidation(field)) {
                isFormValid = false
            }
        })
        return isFormValid;
    }

    handleValidation(field) {
        let { fields, errors } = this.state;
        if (field === "mobileNumber") {
            if (!(fields[field]) || fields[field].length !== 10) {
                if (fields[field] && fields[field].length !== 10) {
                    errors[field] = Constants.CHECKOUT.errorMessages.mobileNumberLength;
                } else
                    errors[field] = Constants.CHECKOUT.errorMessages.mobileNumber;
                this.setState({ errors })
                return false
            }

        }
        errors[field] = ''
        this.setState({ errors })
        return true;
    }

    onUpdateAddressCloseModal() {
        this.setState({ editAddressModal: false, fields: { active: false }, errors: {}, errorMessage: '' })
    }

    onCreateAddressCloseModal() {
        this.setState({ addAddressModal: false, fields: { active: false }, errors: {}, errorMessage: '' })
    }


    createAddress(isCreate) {
        if (isCreate) {
            const { fields } = this.state
            const { formattedAddress, mobileNumber, landmark, lat, lng, doorNo, active, addressType } = fields
            const callback = (response) => {
                if (response.response_code === 0) {
                    this.onCreateAddressCloseModal()
                    toast.success('Address created successfully')
                } else {
                    this.setState({ errorMessage: response?.response_message || 'Something went wrong. Please try again' })
                }
            }

            let error = null;
            if (!formattedAddress) {
                error = 'Please choose a location';
            } else if (!mobileNumber) {
                error = Constants.CHECKOUT.errorMessages.mobileNumber;
            } else if (mobileNumber?.length !== 10) {
                error = Constants.CHECKOUT.errorMessages.mobileNumberLength;
            } else if (!addressType) {
                error = 'Please enter residence type';
            } else {
                error = ''
            }
            this.setState({ errorMessage: error });
            if (error) {
                return;
            }
            let params = {
                formattedAddress,
                mobileNumber,
                latitude: lat,
                longitude: lng,
                landmark,
                street: doorNo,
                active: active,
                addressType: addressType
            }
            this.props.addAddress(params, callback)
        } else { this.onCreateAddressCloseModal() }
    }

    updateAddress(isUpdate) {
        if (isUpdate) {
            let { fields } = this.state
            let { formattedAddress, doorNo, landmark, addressId, mobileNumber, lat, lng, active, addressType } = fields
            const callback = (response) => {
                if (response.response_code === 0) {
                    this.onUpdateAddressCloseModal()
                    toast.success('Address updated successfully')
                } else {
                    this.setState({
                        errorMessage: response?.response_message || 'Something went wrong. Please try again'
                    })
                }
            }
            let error = null;
            if (!formattedAddress) { error = 'Please choose a location' }
            else if (!mobileNumber) { error = Constants.CHECKOUT.errorMessages.mobileNumber }
            else if (mobileNumber?.length !== 10) { error = Constants.CHECKOUT.errorMessages.mobileNumberLength }
            else if (!addressType) { error = 'Please enter residence type' }
            else { error = '' }

            this.setState({ errorMessage: error });
            if (error) { return; }
            let params = {
                formattedAddress,
                mobileNumber,
                latitude: lat,
                longitude: lng,
                landmark,
                street: doorNo,
                active: active,
                addressId,
                addressType: addressType
            }
            this.props.updateAddress(params, callback)
        } else { this.onUpdateAddressCloseModal() }

    }

    handleChange(value, name) {
        let { fields } = this.state
        fields[name] = value
        this.setState({ fields }, () => {
            this.handleValidation(name);
        })
    }

    handleDefaultAddress = (e) => {
        const { checked, name } = e?.target || {}
        let { fields } = this.state
        fields[name] = checked
        this.setState({ fields })
    }

    render() {
        const { editAddressModal, addAddressModal, fields, errors, errorMessage, alertModal } = this.state
        const { addressDetails, isLoading } = this.props.getAddressDetails
        return (
            <div className="col ps-md-5">

                <div className="row mb-5 align-items-center">
                    <div className="col-12 col-md">
                        <h1 className="font18 fw-bold pb-3 pb-md-0">Manage addresses</h1>
                    </div>
                    <div className="col-12 col-md text-md-end">
                        <button className="btn btn-dark big-btn p-2 px-5 text-uppercase" id="address" onClick={() => this.addressDetails()}>+ Add New Address</button>
                    </div>
                </div>

                <div id="manage-addr-grid" className="row row-cols-1 row-cols-md-2 g-4 mb-4">
                    {addressDetails && addressDetails.map((obj, index) => {
                        return (
                            <div className="col" key={index}>
                                <div className={`${obj?.active ? 'card rounded-0 border-dark' : ''}`}>
                                    <div className="card rounded-0">
                                        <div className="card-body pb-4">
                                            <div className="row mb-2">
                                                <div
                                                    className="col">
                                                </div>
                                                <div
                                                    className="col-auto text-end">
                                                    <b>{obj.addresstype?.type ? obj.addresstype?.type : ''}</b>
                                                </div>
                                            </div>
                                            <label for="addr1">
                                                <p className="card-text mb-2">{(obj.street ? obj.street + ',' : '') + (obj.landmark ? obj.landmark + ',' : '') + obj.formattedAddress}</p>
                                                <p className="card-text">{obj.mobileNumber}</p>
                                            </label>
                                        </div>
                                        <div className="card-footer text-end">
                                            <div className="row">
                                                {
                                                    obj?.active && <div className="col text-start"> <small className="text-dark fw-bold">Default Address</small> </div>
                                                }

                                                <div className="col text-end">
                                                    <small className="text-muted">
                                                        <snap data-bs-toggle="modal"
                                                            data-bs-target="#edit-addr-modal"
                                                            className="text-dark text-link" id='edit'
                                                            onClick={() => this.onEditAddress(obj)} >Edit</snap> | <snap
                                                                className="text-dark text-link" id='delete'
                                                                onClick={(i) => this.onOpenAlertModal(obj.id, i)}>Delete</snap>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {addAddressModal && <ProfileAddressPopUp
                    id="add-address-modal"
                    isModalOpen={addAddressModal}
                    label='Add Address'
                    button='Create Address'
                    closeAddressModal={(value) => this.createAddress(value)}
                    handleChange={(value, name) => this.handleChange(value, name)}
                    handleDefaultAddress={this.handleDefaultAddress}
                    fields={fields}
                    errors={errors}
                    errorMessage={errorMessage}
                />}
                {editAddressModal && <ProfileAddressPopUp
                    id="edit-addr-modal"
                    isModalOpen={editAddressModal}
                    label='Update Address'
                    button='Update'
                    closeAddressModal={(value) => this.updateAddress(value)}
                    handleChange={(value, name) => this.handleChange(value, name)}
                    handleDefaultAddress={this.handleDefaultAddress}
                    fields={fields}
                    errors={errors}
                    errorMessage={errorMessage}
                />}
                {
                    (addressDetails && addressDetails.length === 0 && !isLoading) && (
                        <EmptyPage
                            content={Constants.NOT_FOUND.address}
                        />
                    )
                }

                {alertModal && <Alert
                    id="AlertInfo"
                    title={"Info"}
                    description={'Do you want to delete the record?'}
                    proceed={'Delete'}
                    cancel={'Cancel'}
                    onSuccess={() => this.onDeleteAddress()}
                    onFailure={() => this.onOpenAlertModal()}
                />}

            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getAddressDetails: Actions.getAddressDetails(state),
        getUserDetail: Actions.getUserDetail(state),
    };
};


export default withRouter(connect(mapStateToProps, {
    getAddress: Actions.getAddress,
    deleteAddress: Actions.deleteAddress,
    updateAddress: Actions.updateAddress,
    addAddress: Actions.addAddress,
})(ManageAddress));