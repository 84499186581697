
const NAME = "PRODUCT_GROUP_INFORMATION";
export const PRODUCT_GROUP_INFORMATION = `${NAME}/PRODUCT_GROUP_INFORMATION`;
export const PRODUCT_GROUP_INFORMATION_SUCCESS = `${NAME}/PRODUCT_GROUP_INFORMATION_SUCCESS`;
export const PRODUCT_GROUP_INFORMATION_FAILURE = `${NAME}/PRODUCT_GROUP_INFORMATION_FAILURE`;


export const getProductGroupInformation = store => store[NAME]
export const productGroupInformation = (params) => {
    return {
        type: PRODUCT_GROUP_INFORMATION,
        params
    }
}

export const productGroupInformationSuccess = (data) => {
    return {
        type: PRODUCT_GROUP_INFORMATION_SUCCESS,
        data
    }
}

export const productGroupInformationFailure = (error) => {
    return {
        type: PRODUCT_GROUP_INFORMATION_FAILURE,
        error
    }
}

