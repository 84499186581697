const NAME = "CUSTOMER_CARE";
export const CREATE_GUEST_CUSTOMER = `${NAME}/CREATE_GUEST_CUSTOMER`;
export const CREATE_GUEST_CUSTOMER_SUCCESS = `${NAME}/CREATE_GUEST_CUSTOMER_SUCCESS`;
export const CREATE_GUEST_CUSTOMER_FAILURE = `${NAME}/ CREATE_GUEST_CUSTOMER_FAILURE`;


export const GET_GUEST_TRANSACTION_DETAILS = `${NAME}/GET_GUEST_TRANSACTION_DETAILS`;
export const GET_GUEST_TRANSACTION_DETAILS_SUCCESS = `${NAME}/GET_GUEST_TRANSACTION_DETAILS_SUCCESS`;
export const GET_GUEST_TRANSACTION_DETAILS_FAILURE = `${NAME}/ GET_GUEST_TRANSACTION_DETAILS_FAILURE`;

export const CARD_SWAP_DETAILS = `${NAME}/CARD_SWAP_DETAILS`;
export const createGuestCustomer = (params, callback) => {
    return {
        type: CREATE_GUEST_CUSTOMER,
        params,
        callback
    }
}
export const createGuestCustomerSuccess = (data) => {
    return {
        type: CREATE_GUEST_CUSTOMER_SUCCESS,
        data
    }
}

export const createGuestCustomerFailure = (error) => {
    return {
        type: CREATE_GUEST_CUSTOMER_FAILURE,
        error
    }
}


export const getGuestTransactions = (params, callback) => {
    return {
        type: GET_GUEST_TRANSACTION_DETAILS,
        params,
        callback
    }
}
export const getGuestTransactionsSuccess = (data) => {
    return {
        type: GET_GUEST_TRANSACTION_DETAILS_SUCCESS,
        data
    }
}

export const getGuestTransactionsFailure = (error) => {
    return {
        type: GET_GUEST_TRANSACTION_DETAILS_FAILURE,
        error
    }
}


export const cardSwap = (params, callback) => {
    return {
        type: CARD_SWAP_DETAILS,
        params,
        callback
    }
}




