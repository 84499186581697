import React from 'react'
import { withRouter } from 'react-router-dom'
import { DEV_URL } from '../../config/base';

class LegalInformation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    openTermsOfUse() {
        window.open(`${DEV_URL}policy/tos.html`, '_blank');
    }
    openPrivacy() {
        window.open(`${DEV_URL}policy/privacy_policy.html`, '_blank');
    }
    openDisclaimer() {
        window.open(`${DEV_URL}policy/disclaimer.html`, '_blank');
    }
    render() {
        return (
            <React.Fragment>
                <div className="col ps-md-5">
                    <div className="row mb-5 align-items-center">
                        <div className="col-12 col">
                            <h1 className="font18 fw-bold">Legal information</h1>
                        </div>
                    </div>
                    <div className="accordion accordion-flush" id="faq">
                        <div className="accordion-item">
                            <h2 className="accordion-header text-link" id="shop-heading">
                                <button
                                    id="terms-of-use"
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#shop-acc"
                                    aria-expanded="false"
                                    aria-controls="shop-acc"
                                    onClick={() => this.openTermsOfUse()}>
                                    Terms of Service
                                </button>
                            </h2>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header text-link" id="shop-heading">
                                <button
                                    id="privacy-policy"
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#shop-acc"
                                    aria-expanded="false"
                                    aria-controls="shop-acc"
                                    onClick={() => this.openPrivacy()}>
                                    Privacy
                                </button>
                            </h2>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header text-link" id="shop-heading">
                                <button
                                    id="Disclaimer"
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#shop-acc"
                                    aria-expanded="false"
                                    aria-controls="shop-acc"
                                    onClick={() => this.openDisclaimer()}>
                                    Disclaimer
                                </button>
                            </h2>
                        </div>
                    </div>


                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(LegalInformation)