import Actions from '../action'
const initialState = {
    isLoading: false,
    cartDetails: [],
    cartOffers: {},
    error: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.CART_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CART_DETAILS_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                cartDetails: action?.data?.response || {},
                cartOffers: action?.data?.offers || {},
                error: false
            };
        }
        case Actions.CART_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.CLEAR_CART: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};