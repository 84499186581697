
const NAME = "SOCKET";
export const SOCKET = `${NAME}/SOCKET`;
export const SOCKET_SUCCESS = `${NAME}/SOCKET_SUCCESS`;
export const SOCKET_FAILURE = `${NAME}/SOCKET_FAILURE`;

export const getSocket = store => store[NAME]

export const socket = (params) => {
    return {
        type: SOCKET,
        params
    }
}

export const socketSuccess = (data) => {
    return {
        type: SOCKET_SUCCESS,
        data
    }
}

export const socketFailure = (error) => {
    return {
        type: SOCKET_FAILURE,
        error
    }
}

