import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getQueryParams } from '../../Utility/validation';

const initializeDropDownValues = () => {
    return [
        {
            value: 0,
            label: 'All Categories'
        }, {
            value: 1,
            label: 'Bistro'
        }, {
            value: 2,
            label: 'Groceries'
        },
    ];
}
class HeaderSearch extends React.Component {

    constructor(props) {
        super(props);
        const qparams = getQueryParams(this.props.location.search);
        const searchText = qparams.get('search');
        const selectOptions = initializeDropDownValues();
        this.inputRef = React.createRef();
        this.state = {
            selectOptions,
            selectedDropDownValue: selectOptions[0].value,
            searchText: searchText || '',
            searchError: null
        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname || this.props.location.search
            !== prevProps.location.search) {
            const selectOptions = initializeDropDownValues();
            const qparams = getQueryParams(this.props.location.search);
            const searchText = qparams.get('search');
            const dropdownvalue = qparams.get('c');
            const intValue = parseInt(dropdownvalue);
            this.setState({ selectOptions, searchText: searchText || '', selectedDropDownValue: isNaN(intValue) ? selectOptions[0].value : intValue })
        }
    }


    onClickSearch() {
        const { searchText } = this.state;
        this.setState({ searchError: null })
        const { history } = this.props;
        const { search, pathname } = history.location;
        const searchParamsArr = search.split('&');
        const searchIndex = searchParamsArr.findIndex(f => f.includes('search'));
        /**
         * check search characters exists if else
         */
         let searchQueryString = `search=${searchText}`;
        if (searchIndex !== -1) {
            const searchQuery = searchParamsArr[searchIndex];
            const searchQueryArr = searchQuery.split('=');
            searchQueryArr[1] = searchText;
            const concatSearchString = searchQueryArr.join('=');
            searchParamsArr.splice(searchIndex, 1, concatSearchString);
            searchQueryString = searchParamsArr.join('&')
        } else {
            searchQueryString = search ? `${search}&${searchQueryString}` : `?${searchQueryString}`;
        }

        /**
         * navigate on search
         */
         let searchQueryParams = '';
        if (pathname.includes('bistro/products')) {
            searchQueryParams = `/bistro/products${searchQueryString}`;
        } else if (pathname.includes('/products')) {
            searchQueryParams = `/products${searchQueryString}`;
        } else {
            searchQueryParams = `/searchAll${searchQueryString}`;
        }
        history.push(searchQueryParams)
    }

    clearInputValue() {
        this.setState({ searchText: '' }, () => {
            this.inputRef.current && this.inputRef.current.focus();
        })
    }

    render() {
        const { searchText } = this.state;
        const qparams = getQueryParams(this.props.location.search);
        const queryParamsText = qparams.get('search');
        return (
            <div className="d-lg-inline-block me-lg-5">
                <div className="input-group">
                    <input
                        ref={this.inputRef}
                        id='message'
                        className="form-control header-search"
                        value={searchText}
                        onKeyDown={(e) => {
                            e.stopPropagation();
                            if (e.key === 'Enter') {
                                this.onClickSearch(queryParamsText?.length > 0 && searchText.length === 0)
                            }
                        }}
                        onChange={({ target: { value } }) => this.setState({ searchText: value })}
                        placeholder="Search..."
                    />
                    <button className="btn header-search-close p-2 text-dark mt-3 mt-md-0"
                        type="button"
                        onClick={() => this.clearInputValue()}
                        id="button-addon2">X</button>
                </div>
            </div>
        )
    }
}

export default connect()(withRouter(HeaderSearch));
