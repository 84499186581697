import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import action from "../../redux/action";
import Constants from '../../Utility/Constants'
import BoldError from "../BoldError";
import MobileNumberInput from "../MobileNumberInput";

var Modal = require("react-bootstrap-modal");

class ResendEmail extends React.Component {
    constructor(props) {
        super(props);
        const mobileNumber = props?.mobileNumber?.substring(props.mobileNumber.length - 10, props.mobileNumber.length) || '';
        this.state = {
            mobileNumberState: mobileNumber,
            mobileCountryCode: props.mobileNumber.substring(0, props.mobileNumber.length - 10),
            isMobileNumberValid: mobileNumber.length === 10,
            errorMessage: null,
            mobileNumberAlreadyRegistered: false
        };
    }

    updateEmailId() {
        const { onCloseModal } = this.props;
        const { mobileNumberState, isMobileNumberValid, mobileCountryCode } = this.state;
        if (mobileNumberState.length !== 10) {
            this.setState({ errorMessage: Constants.LOGIN.error.mobileNumber });
            return;
        }
        if (isMobileNumberValid && mobileNumberState) {
            this.setState({ errorMessage: null });
            this.props.updateEmailId({ mobileNumber: mobileCountryCode + mobileNumberState });
            onCloseModal(mobileCountryCode + mobileNumberState);
        } else {
            this.setState({ errorMessage: Constants.LOGIN.error.mobileNumber });
        }
    }

    static getDerivedStateFromProps(props, state) {
        return state.mobileNumberState === '' ? { mobileNumberState: props.mobileNumber } : {};
    }

    render() {
        const { onCloseModal, isModalOpen, mobileNumber } = this.props;
        const { isMobileNumberValid,
            errorMessage, mobileCountryCode,
            mobileNumberState, mobileNumberAlreadyRegistered } = this.state;
        return (
            <div className="login">
                <Modal show={isModalOpen} aria-labelledby="ModalHeader">
                    <Modal.Header>
                        <button className="close" aria-label="Close Modal">
                            <span aria-hidden="true" id='close' onClick={() => onCloseModal(mobileNumber)}>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="login text-center">
                        <Modal.Title id="ModalHeader" className="text-center">
                            <span>
                                <h4>{Constants.UPDATE_EMAIL.title}</h4>
                            </span>
                        </Modal.Title>

                        <div className="form-group">
                            <BoldError message={errorMessage} />
                            <MobileNumberInput
                                mobileNumberDefaultValue={mobileNumberState}
                                value={mobileNumberState}
                                dropdownDefaultValue={mobileCountryCode}
                                onChangeDropDown={(e) => this.setState({ mobileCountryCode: e.target.value })}
                                onChangeMobileNumber={(e) => this.setState({ mobileNumberState: e.target.value })}
                                onError={(number) => this.setState({ mobileNumberAlreadyRegistered: number })}
                            />
                        </div>
                        <button
                            className="primary-btn-verify btn-sm" id='update'
                            style={{ backgroundColor: this.props?.mobileNumber === mobileCountryCode + mobileNumberState ? 'gray' : '' }}
                            disabled={this.props?.mobileNumber === mobileCountryCode + mobileNumberState}
                            onClick={() => {
                                !mobileNumberAlreadyRegistered && isMobileNumberValid && this.updateEmailId();
                            }}
                        >
                            {Constants.UPDATE_EMAIL.update}
                        </button>
                    </Modal.Body>
                </Modal>
            </div>
        );

    }
}

export default connect(null, {
    updateEmailId: action.updateEmail
})(withRouter(ResendEmail));
