import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../../redux/action";
import Constants from "../../Utility/Constants";
import Placeholder from '../../asset/img/no-preview.png'
import AddToCartButton from "../../component/AddToCartButton";
import CartQuantityAdjuster from "../../component/CartQuantityAdjuster";
import { getQueryParams } from "../../Utility/validation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routes from "../../config/routes";
import EmptyPage from "../../component/emptyPage";
import NoSearch from '../../component/noSearch';
import BreadCrumbs from './BreadCrumbs';
import { getRandomNumber } from "../../Utility/Utils";

class PimProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subCategoryList: null,
      productsTitle: "Products",
      subCategoryTitle: null,
      expanded: false,
      isOpenDrawer: false,
      isDropDown: false,
      currentPage: 0,
      categoryImage: null
    };
  }

  invokeApiCall() {
    const { getProductGroup, location } = this.props;
    const { currentPage } = this.state;
    const queryPrams = getQueryParams(location.search);
    const categoryId = queryPrams.get("categoryId");
    const subCategoryId = queryPrams.get("subCategoryId");
    const search = queryPrams.get("search");
    let groupIds = 0
    let redirect = false;
    if (!categoryId && !search && location.pathname !== '/searchAll') {
      redirect = true;
    }

    if (getProductGroup) {
      let revealGrocery = getProductGroup?.findIndex(
        (itm) => itm?.groupName === Constants.PRODUCT_GROUP.GROCERY
      );

      if (getProductGroup[revealGrocery]) {
        const getCurrentGroup = getProductGroup[revealGrocery]
        groupIds = getCurrentGroup && getCurrentGroup.groupId;
      }
    }

    if (categoryId) {
      const allCategoryList = getProductGroup?.map((m) => (m.categoryList ? m.categoryList : [])).flat();
      if (!allCategoryList?.length) {
        redirect = true;
      }


      const category = allCategoryList?.find((c) => c.categoryId === parseInt(categoryId, 10));
      if (category?.categoryName) {
        this.setState({ productsTitle: category.categoryName, categoryImage: category?.picture && category?.picture + '?$' + getRandomNumber() });
      } else {
        redirect = true;
      }
      if (subCategoryId) {
        const subCat = category?.subcategoryList?.find(({ subcategoryId: subId }) => subId === parseInt(subCategoryId, 10));
        if (subCat) this.setState({ subCategoryTitle: subCat.subcategoryName, categoryImage: subCat?.picture && subCat?.picture + '?$' + getRandomNumber() });
        else redirect = true;
      }
    }


    if (redirect) {
      this.props.history.replace("/");
      return;
    }
    let params = {
      searchKey: search || "",
      storeId: "1",
      size: 6,
      page: currentPage,
      groupId: groupIds
    };
    if (categoryId) {
      params["categoryId"] = categoryId;
    }
    if (subCategoryId) {
      params["subCategoryId"] = subCategoryId;
    }
    if (!search) {
      const allCategoryList = getProductGroup
        ?.map((m) => (m.categoryList ? m.categoryList : []))
        .flat();
      if (allCategoryList?.length > 0) {
        const category = allCategoryList.find((c) => c.categoryId === parseInt(categoryId, 10));
        this.setState({ subCategoryList: category?.subcategoryList },
          () => {
            this.props.pimProducts(params);
          }
        );
      }
    } else {
      this.props.pimProducts(params);
    }
  }
  _renderEmptyContent() {
    const queryPrams = getQueryParams(this.props.location.search);
    const search = queryPrams.get("search");

    return search ? <NoSearch /> : <EmptyPage
      content={Constants.NOT_FOUND.filter}
    />
  }
  componentDidMount() {
    const { getProductGroup } = this.props;
    if (getProductGroup && getProductGroup.length === 0) {
      this.props.productGroupInformationActions();
    }
    this.invokeApiCall();
  }

  pimCartCallback = (response) => {
    const { response_code } = response;
    if (response_code !== 0) {
      toast.error(response?.response_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  addToCart(o) {
    const params = {
      productSku: o.sku,
      quantity: 1,
      storeId: 1,
      addOnIds: [],
      bistroProduct: false,
    };
    this.props.createPimCart(params, this.pimCartCallback);
  }

  quantityIncreament(obj, cartDetails) {
    let getCart = cartDetails && cartDetails.find((o) => o.sku === obj.sku);
    let { quantity } = getCart || {};
    if (quantity) {
      const params = {
        productSku: getCart.sku,
        quantity: getCart.quantity + 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: false,
        cartItemId: getCart?.cartItemId,
      };
      this.props.updatePimCart(params, this.pimCartCallback);
    } else {
      const params = {
        productSku: getCart.sku,
        quantity: getCart.quantity,
        storeId: 1,
        addOnIds: [],
        bistroProduct: false,
      };
      this.props.updatePimCart(params, this.pimCartCallback);
    }
  }
  quantityDecrement(obj, cartDetails) {
    let getCart = cartDetails && cartDetails.find((o) => o.sku === obj.sku);
    let { quantity } = getCart || {};
    if (quantity) {
      const params = {
        productSku: getCart.sku,
        quantity: getCart.quantity - 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: false,
        cartItemId: getCart?.cartItemId,
      };

      this.props.updatePimCart(params, this.pimCartCallback);
    } else {
      const params = {
        productSku: getCart.sku,
        quantity: getCart.quantity,
        storeId: 1,
        addOnIds: [],
        bistroProduct: false,
      };
      this.props.updatePimCart(params, this.pimCartCallback);
    }
  }

  quantitybtn(obj, cartDetails) {
    let getCart = cartDetails && cartDetails.find((o) => o.sku === obj.sku);
    if (getCart) {
      if (getCart?.quantity > 0) {
        return (
          <CartQuantityAdjuster
            onMinus={() => this.quantityDecrement(obj, cartDetails)}
            onPlus={() => this.quantityIncreament(obj, cartDetails)}
            count={getCart?.quantity}
          />
        );
      } else {
        return <AddToCartButton onClick={() => this.addToCart(obj)} />;
      }
    } else {
      return <AddToCartButton onClick={() => this.addToCart(obj)} />;
    }
  }

  onClickOfSubCategory(subId) {
    const { location } = this.props;
    const queryPrams = getQueryParams(location.search);
    const categoryId = queryPrams.get("categoryId");
    this.props.history.push(`/products?categoryId=${categoryId}&subCategoryId=${subId}`)
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      window.location.reload();
    }
  };
  componentWillUnmount() {
    this.props.loaderOff()
  }
  navProductDetails(id) {
    this.props.history.push(routes.PIM_PRODUCTS_DETAILS.replace(":sku", id))
  }
  openSideDrawer() {
    this.setState({ isOpenDrawer: !this.state.isOpenDrawer })
  }
  openDropDown() {
    this.setState({ isDropDown: !this.state.isDropDown })
  }
  render() {
    const { subCategoryList, productsTitle, subCategoryTitle, isOpenDrawer, categoryImage } = this.state;
    const { pimProducts, isLoading } = this.props.getPimProducts || {};
    const queryPrams = getQueryParams(this.props.location.search);
    const search = queryPrams.get("search");
    const {
      pimCart: { products },
    } = this.props.getPimCartDetails;
    return (
      <div>
        {!this.props.searchScreen &&
          <section className="category-subnav bg-black">
            <div className="wrap">
              <div className="row pt-lg-5">
                <div className="col-auto">
                  {
                    subCategoryTitle ? <div className="sub-category-hide"></div> :
                      <img
                        src={categoryImage ? categoryImage : Placeholder} alt="dairy category"
                        className="cat-thumb"
                      />
                  }
                </div>
                <div className="col">
                  <div className="row align-items-center my-3 cat-heading">
                    <div className="col">
                      <h1 className="text-white d-inline-block d-lg-block title-case"> {subCategoryTitle?.toLowerCase() || productsTitle?.toLowerCase()}</h1>
                    </div>
                    {
                      subCategoryList && subCategoryList.length > 0 && <div className="col text-end d-lg-none" onClick={() => this.openSideDrawer()}>
                        <img
                          src={require("../../asset/img/grid-menu-icon.svg")} alt="View Categories"
                          className="grid-icon"
                        />
                      </div>
                    }

                  </div>

                  <BreadCrumbs
                    subCategoryList={subCategoryList}
                    categoryName="Products"
                    productsTitle={productsTitle}
                    subCategoryTitle={subCategoryTitle} />
                  <div className={`cat-offcanvas shadow ${isOpenDrawer ? 'show' : ''}`}>
                    <span id='close' onClick={() => this.openSideDrawer()} className="close-cat-offcanvas">
                      close
                    </span>
                    <nav>
                      {subCategoryList?.map(
                        ({ subcategoryName, subcategoryId, index }) => {
                          return (
                            <span
                              onClick={() =>
                                this.onClickOfSubCategory(subcategoryId)
                              }
                              className={`title-case ${subcategoryName === subCategoryTitle ? 'menu-active' : ''}`}
                            >
                              {subcategoryName?.toLowerCase()}
                            </span>
                          );
                        }
                      )}
                    </nav>
                  </div>
                </div>

                <div className="col-12 d-block d-md-none">
                  <BreadCrumbs
                    subCategoryList={subCategoryList}
                    productsTitle={productsTitle}
                    subCategoryTitle={subCategoryTitle}
                    categoryName="Products"
                    isMobile={true} />
                </div>

              </div>
            </div>
          </section>}

        <div className={this.props.searchScreen ? '' : 'wrap py-35'}>
          <div className="row">
            {
              (!this.props.searchScreen && !search) && <div className="col-12 col-lg-3 d-none d-lg-block">
                <div className="cat-list">
                  <nav>
                    {subCategoryList?.map(
                      ({ subcategoryName, subcategoryId, index }) => {
                        return (
                          <span
                            key={index}
                            className={`title-case ${subcategoryName === subCategoryTitle ? 'menu-active' : ''} cursor-pointer`}
                            onClick={() =>
                              this.onClickOfSubCategory(subcategoryId)
                            }
                          >
                            {subcategoryName?.toLowerCase()}
                          </span>
                        );
                      }
                    )}
                  </nav>
                </div>
              </div>
            }
            <div className={`${this.props.searchScreen ? 'col-12' : 'col-12 col-lg-9'}`}>
              <div className="row g-5 g-lg-4 mb-5 products-grid">
                {pimProducts &&
                  pimProducts?.products?.map((o, index) => {
                    let { productName, imageUrl, price, productDescription, sku, ageRestricted } = o;
                    return (
                      <div className="col-12 col-md-6 col-lg-4" key={index}>
                        <div className="card card-body px-0 border-0">
                          <span
                            className="product-thumb"
                            onClick={() => this.navProductDetails(sku)}
                          >
                            <img
                              src={
                                imageUrl
                                  ? imageUrl + '?$' + getRandomNumber()
                                  : Placeholder
                              }
                              alt="1"
                              className="img-fluid text-link"
                            />
                          </span>
                          {ageRestricted && <span className="badge age-restrict">Age Restricted</span>}
                          <div className="d-flex flex-column mh-65">
                            <h2 className="font16 product-list title-case">{productName?.toLowerCase()}</h2>
                            {
                              productDescription ? <p className="txtgrey desc-wrap">{productDescription}</p> : null
                            }

                            <strong className="font16">
                              {Constants.COMMONS.usd}
                              {price.toFixed(2)}
                            </strong>
                          </div>
                          {this.quantitybtn(o, products)}
                        </div>
                      </div>

                    );
                  })}
              </div>
              {pimProducts?.products?.length === 0 && !isLoading && (
                this._renderEmptyContent()
              )}
            </div>

          </div>

          <div className="my-75 text-center">
            {pimProducts?.hasLoadMore ? (
              <button
                className="btn btn-dark big-btn"
                name=""
                onClick={() => this.setState(({ currentPage }) => ({
                  currentPage: currentPage + 1
                }), () => {
                  this.invokeApiCall();
                })}
              >
                LOAD MORE
              </button>
            ) : null}
          </div>
        </div>

      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getPimProducts: Actions.getPimProducts(state),
    getPimCartDetails: Actions.getPimCartDetails(state),
    getProductGroup:
      Actions.getProductGroupInformation(state)?.productGroupInformation,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    pimProducts: Actions.pimProducts,
    createPimCart: Actions.createPimCart,
    updatePimCart: Actions.updatePimCart,
    productGroupInformationActions: Actions.productGroupInformation,
    loaderOff: Actions.loaderOff
  })(PimProducts)
);
