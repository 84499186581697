
const NAME = "GET_STORE_LIST";
export const GET_STORE_LIST = `${NAME}/GET_STORE_LIST`;
export const GET_STORE_LIST_SUCCESS = `${NAME}/GET_STORE_LIST_SUCCESS`;
export const GET_STORE_LIST_FAILURE = `${NAME}/GET_STORE_LIST_FAILURE`;

export const STORE_LIST = `${NAME}/STORE_LIST`;
export const STORE_LIST_SUCCESS = `${NAME}/STORE_LIST_SUCCESS`;
export const STORE_LIST_FAILURE = `${NAME}/STORE_LIST_FAILURE`;

export const SELECTED_STORE_LIST = `${NAME}/SELECTED_STORE_LIST`;



export const getStoreList = store => store[NAME]
export const storeList = (params) => {
    return {
        type: GET_STORE_LIST,
        params
    }
}

export const storeListSuccess = (data) => {
    return {
        type: GET_STORE_LIST_SUCCESS,
        data
    }
}

export const storeListFailure = (error) => {
    return {
        type: GET_STORE_LIST_FAILURE,
        error
    }
}

export const getMultipleStoreList = (params, callback) => {
    return {
        type: STORE_LIST,
        params,
        callback
    }
}

export const getMultipleStoreListSuccess = (data) => {
    return {
        type: STORE_LIST_SUCCESS,
        data
    }
}

export const getMultipleStoreListFailure = (error) => {
    return {
        type: STORE_LIST_FAILURE,
        error
    }
}
export const selectedStoreList = (data) => {
    return {
        type: SELECTED_STORE_LIST,
        data

    }
}
