const NAME = "SUBSCRIPTION";
export const GET_SUBSCRIPTION_LIST = `${NAME}/GET_SUBSCRIPTION_LIST`;
export const GET_SUBSCRIPTION_LIST_SUCCESS = `${NAME}/GET_SUBSCRIPTION_LIST_SUCCESS`;
export const GET_SUBSCRIPTION_LIST_FAILURE = `${NAME}/GET_SUBSCRIPTION_LIST_FAILURE`;


export const getAllSubscriptionList = store => store[NAME].subscriptionList || {}
export const getSubscriptionListLoading = store => store[NAME].isLoading;

export const getSubscriptionList = (params, callback) => {
    return {
        type: GET_SUBSCRIPTION_LIST,
        params,
        callback
    }
}

export const getSubscriptionListSuccess = (data) => {
    return {
        type: GET_SUBSCRIPTION_LIST_SUCCESS,
        data
    }
}

export const getSubscriptionListFailure = (error) => {
    return {
        type: GET_SUBSCRIPTION_LIST_FAILURE,
        error
    }
}
