import React, { useState } from 'react';

const InputField = ({ showEyeIcon = false, value: fieldValue, name: fieldName, type, placeholder,
    onChange, onKeyUp, error, defaultValue, className, ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    return <>
        <div className="form-group">
            <div className="p-relative">
                <input
                    {...props}
                    className={className}
                    type={(() => {
                        if (showPassword && type === 'password') {
                            return 'text'
                        } else {
                            return type || 'text';
                        }
                    })()}
                    name={fieldName}
                    defaultValue={defaultValue}
                    value={fieldValue}
                    placeholder={placeholder}
                    onChange={({ target: { value, name } }) => onChange(value, name)}
                />
                {showEyeIcon && <i id="setShowPassword" onClick={() => setShowPassword(s => !s)} style={{ paddingTop: '3px' }} className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"} password-icon`} ></i>}
            </div>

            {error && <p className="errorMsg">{error}</p>}
        </div>
    </>
}

export default InputField;