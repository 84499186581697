import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import moment from "moment";
import Refund from "../../component/refund";
import Constants from "../../Utility/Constants";
import ChatScreen from "../chat/ChatScreen";
import { getQueryParams } from "../../Utility/validation";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import routes from "../../config/routes";
import OrderSummaryOfferDetails from "./OrderSummaryOfferDetails";
import InvoiceSummary from "./InvoiceSummary";
import { ProfileDetails } from "../../config/profileDetails";
import DeliveryInstruction from "../../component/alert/deliveryInstruction";
import QrCode from "../../component/qrCode";
import Qrimage from '../../asset/img/qrImage.png'
import RefundPreviewModal from '../../component/refund/previewModal'
import ViewRefundReceipt from "../../component/viewRefundReceipt";
import OrderStatus from "../orderSummary/OrderStatus";
import { getInvoiceDate } from "../../Utility/Utils";
import OrderTrackStatus from "../orderSummary/OrderTrackStatus";

export const sortCheckoutOrders = (a, b) => {
    if (a.type < b.type) {
        return -1;
    }

    if (a.type > b.type) { return 1; }
    return 0;
}


export const reduceCheckourOrders = (previousValue, currentValue) => {
    if (previousValue.length === 0) {
        if (currentValue.orderType === 1) {
            currentValue.sectionHeader = Constants.FOOTER.bistro;
        } else if (currentValue.orderType === 2) {
            currentValue.sectionHeader = Constants.FOOTER.groceries;
        }
        return [...previousValue,
            currentValue];
    }

    if (previousValue[previousValue.length - 1].orderType === currentValue.orderType) {
        return [...previousValue, currentValue];
    } else if (previousValue[previousValue.length - 1].orderType !== currentValue.orderType) {
        if (currentValue.orderType === 1) {
            currentValue.sectionHeader = Constants.FOOTER.bistro;
        } else if (currentValue.orderType === 2) {
            currentValue.sectionHeader = Constants.FOOTER.groceries;
        }
        return [...previousValue,
            currentValue];
    }
};

export const _renderOrderTypes = (type, natureOfOrder) => {
    let message;
    switch (type) {
        case Constants.ORDER_TYPES.CREATED_BY_HITACHI:
            message = 'WIWO Store'
            break;
        case Constants.ORDER_TYPES.CREATED_BY_USER_ONLINE_ORDER:
            message = natureOfOrder === 1 ? 'Door Delivery' : 'Store Pickup'
            break;
        case Constants.ORDER_TYPES.CREATED_BY_VISION_CHECKOUT:
            message = 'Vision Checkout'
            break;
        case Constants.ORDER_TYPES.CREATED_BY_SCAN_AND_GO:
            message = 'Scan n Go'
            break;
        default:
            message = natureOfOrder === 1 ? 'Door Delivery' : 'Store Pickup'
            break;
    }
    return message
}


export const _renderDeliveryAddress = (address, orderType, storeAddress) => {
    if (orderType === 1) {
        let deliveryAddress = {}
        try {
            deliveryAddress = JSON.parse(address)
        } catch (e) {
            deliveryAddress = {}
        }
        let storeAddressData = {}
        try {
            storeAddressData = JSON.parse(storeAddress)
        } catch (e) {
            storeAddressData = {}
        }
        let { formattedAddress, street, landmark, addresstype } = deliveryAddress || {}
        return (
            <React.Fragment>
                {addresstype ? <p><b>Residence type :</b> {addresstype?.type}</p> : ''}
                <span>{street ? `${street},` : ''} {landmark ? `${landmark},` : ''} {formattedAddress}</span>
                {storeAddressData && <div className="row align-items-center mt-4"> <h4 className="font16 fw-bold mb-2">Store Address</h4></div>}
                {storeAddressData ?
                    <span>{storeAddressData?.branch_name ? `${storeAddressData?.branch_name},` : ''} {storeAddressData?.address ? `${storeAddressData?.address},` : ''} {storeAddressData?.zip_code ? `${storeAddressData?.zip_code}` : ''}</span>
                    : ""}
            </React.Fragment>
        )
    } else {
        let storeAddressData = {}
        try {
            storeAddressData = JSON.parse(storeAddress)
        } catch (e) {
            storeAddressData = {}
        }
        const { city, landmark, street, zip } = Constants.STORE_ADDRESS
        return (
            <React.Fragment>
                {storeAddressData ?
                    <span>{storeAddressData?.branch_name ? `${storeAddressData?.branch_name},` : ''} {storeAddressData?.street_name ? `${storeAddressData?.street_name},` : ''} {storeAddressData?.landmark || ''} {storeAddressData?.location ? `${storeAddressData?.location},` : ''} {storeAddressData?.zip_code ? `${storeAddressData?.zip_code}` : ''}</span>
                    : <span>{landmark}, {street}, {city}, {zip}</span>}
            </React.Fragment>
        )
    }
}

class OrderSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRefundModal: false,
            showChatSuportWindow: false,
            showHideChat: false,
            openInstructionModal: false,
            openQrModal: false,
            openRefundPreviewModal: false,
            refundImage: null,
            qrImages: null,
            viewRefundModal: false
        }
    }

    componentDidMount() {
        let { invoiceId } = this.props.match.params;
        let params = { invoiceId: invoiceId };
        this.props.orderDetails(params);
        this.setState({ invoiceId });
        this.openchatIfQueryParamsExists()
        this.removeVoucher()
        this.getCartDetails()
        this.props.updateBistroComments('')
    }

    componentDidUpdate = (prevProps) => {
        const prevPath = prevProps.location.pathname;
        const currPath = this.props.location.pathname;
        if (prevPath.includes('/order/summaryDetails') && currPath.includes('/order/summaryDetails') && prevPath !== currPath) {
            window.location.reload();
        }
    };


    removeVoucher() {
        this.setState({
            voucherError: '',
            voucher: ''
        })
        this.props.updateCartAfterVoucher('', '')
    }
    getCartDetails() {
        let { token } = this.props.getUserDetail || {}
        if (token) {
            this.props.getCartDetails()
        }
    }

    openchatIfQueryParamsExists() {
        const queryParams = getQueryParams(this.props.location.search);
        const isOpenChat = !!queryParams.get('openChat');
        if (isOpenChat) {
            this.setState({ showChatSuportWindow: true })
        }
    }

    manageChat(isOpen) {
        this.setState({ showHideChat: isOpen || !this.state.showHideChat })
    }

    onRefund = () => {
        this.setState({ isRefundModal: true });
    }
    _renderInvoiceDate(date) {
        return moment(date).format('MM/DD/YYYY - hh:mm A')
    }
    refundPage() {
        this.setState({ isRefundModal: true });
    }
    viewRefundReceipt() {
        this.setState({ viewRefundModal: true });
    }
    closeViewRefundReceipt() {
        this.setState({ viewRefundModal: false });
    }
    isRefundPageClose(msg) {
        if (msg) {
            let { invoiceId } = this.props.match.params;
            let params = { invoiceId: invoiceId };
            this.props.orderDetails(params);
        }
        this.setState({ isRefundModal: false });
    }


    sendToEmail() {
        const callback = (res) => {
            if (res?.response_code === 0) {
                toast.success('Receipt successfully sent', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(res?.response_message, { position: toast.POSITION.TOP_RIGHT, });
            }
        }
        const { invoiceId } = this.props.match.params;
        const params = { invoiceId };
        this.props.sendToEmail(params, callback)

    }
    getCartObject(invoiceOrderDetailsList) {
        let invoiceOrgOrderList = []
        if (invoiceOrderDetailsList && invoiceOrderDetailsList.length > 0) {
            invoiceOrderDetailsList.forEach((item, index) => {
                invoiceOrgOrderList = [
                    ...invoiceOrgOrderList,
                    ...item.invoiceOrderProductDetailsList,
                ];
            });
        }
        return invoiceOrgOrderList
    }



    navigateToChatScreen() {
        this.setState({ showChatSuportWindow: true })
    }
    navigateToTrackMyBot(botOrderId) {
        if (botOrderId)
            this.props.history.push(routes.accountOrdersTrack(botOrderId));
    }

    _renderRefundButton(invoiceDate, invoiceOrderDetailsList, paymentStatus, createdBy) {
        const isRefundNotExpired = moment().isBefore(moment(invoiceDate).add(7, 'days'))
        let indexofNonCompleted = invoiceOrderDetailsList && invoiceOrderDetailsList.findIndex(i =>
            i.orderStatus !== Constants.ORDER_STATUS.DELIVERED &&
            i.orderStatus !== Constants.ORDER_STATUS.DECLINE_ORDER,
        );
        let indexofCompleted = invoiceOrderDetailsList &&
            invoiceOrderDetailsList.findIndex(i => i.orderStatus === Constants.ORDER_STATUS.DELIVERED);
        return (
            <React.Fragment>
                {paymentStatus === 0 && isRefundNotExpired && indexofNonCompleted === -1 &&
                    indexofCompleted !== -1 && createdBy !== Constants.ORDER_TYPES.CREATED_BY_VISION_CHECKOUT && (
                        <div className="mb-5">
                            <button data-bs-toggle="modal"
                                onClick={() => this.onRefund()}
                                data-bs-target="#refund-modal"
                                className="btn btn-dark next-btn px-4 text-uppercase"
                                name="">{Constants.ORDER_SUMMARY.refund}</button>
                        </div>
                    )
                }
                &nbsp;&nbsp;
            </React.Fragment>
        )
    }
    _getPriceOfProduct = (item, orderDetails) => {
        let { unitPrice, invoiceProductAddOnMappingList, quantity, acceptedQuantity } = item;
        let sellingPrice = parseFloat(unitPrice);
        if (invoiceProductAddOnMappingList && invoiceProductAddOnMappingList.length !== 0) {
            sellingPrice = sellingPrice +
                invoiceProductAddOnMappingList.reduce((accumulator, i) => {
                    return accumulator + parseFloat(i.unitPrice);
                }, 0);
        }
        return ((orderDetails?.orderStatus !== 1 ? acceptedQuantity : quantity) * sellingPrice)?.toFixed(2);
    };
    openQrCodeModal(image) {
        this.setState({ openQrModal: true, qrImages: image })
    }
    closeQrCodeModal() {
        this.setState({ openQrModal: false })
    }
    getOfferDetailsList() {
        const { orderDetails } = this.props.getOrderDetails || {};
        const { promotionJson } = orderDetails || {};
        let promotions = [];
        try {
            promotions.push(JSON.parse(promotionJson));
        } catch (e) {
            promotions = []
        }
        return promotions.length > 0 ? promotions : [];
    }
    openInstructionsModal() {
        this.setState({ openInstructionModal: !this.state.openInstructionModal })
    }
    closeRefundPreview() {
        this.setState({ openRefundPreviewModal: false })
    }
    openRefundPreview(refundImage) {
        this.setState({ openRefundPreviewModal: true, refundImage })
    }
    render() {
        const { isRefundModal, showHideChat, openInstructionModal, openQrModal, openRefundPreviewModal, qrImages, viewRefundModal } = this.state;
        const { orderDetails, isLoading } = this.props.getOrderDetails || {};
        const { invoiceDate, totalAmountExcludingTax, processedAmount,
            deliveryAddress, totalTaxAmount, totalAmount,
            invoiceId, invoiceOrderDetailsList, paymentStatus, refundDetails,
            natureOfOrder, createdBy, manualRefunds, storeAddress } = orderDetails || {};
        let invoiceProductList = [];
        let { invoiceId: currentInvoiceId } = this.props.match.params;
        if (invoiceOrderDetailsList && invoiceOrderDetailsList.length > 0) { invoiceProductList = invoiceOrderDetailsList?.filter((o) => o.orderStatus === 5) }
        const finalAmount = invoiceOrderDetailsList?.findIndex((o) => o?.orderStatus === 1);
        return (
            (!isLoading && orderDetails) && (
                <div className="col ps-md-5">
                    <div className="row mb-5 align-items-center">
                        <div className="col-12 col">
                            <span
                                id="myAccountDetails"
                                className="btn btn-sm mb-2 cursor-pointer"
                                onClick={() => this.props.history.push(routes.myAccountDetails(ProfileDetails.Orders))}>
                                <img src={require("../../asset/img/left-arrow.svg")} alt="&lt;&lt;" />
                            </span>

                            <h1
                                className="d-inline font18 fw-bold">Order Details</h1>
                            {
                                natureOfOrder === 1 && <span
                                    id="openInstructionsModal"
                                    className="btn btn-sm mb-2 cursor-pointer float_right"
                                    onClick={() => this.openInstructionsModal()}>
                                    <img src={require("../../asset/img/info.svg")} alt="&lt;&lt;" />
                                </span>
                            }

                        </div>
                    </div>
                    {invoiceOrderDetailsList &&
                        invoiceOrderDetailsList.sort(sortCheckoutOrders)
                            .reduce(reduceCheckourOrders, []).map((object) => {
                                return (
                                    <>
                                        <div id="Products">
                                            <div className="row">
                                                <div className="col"><h3 className="font18 fw-bold mb-4 text-uppercase">{object.sectionHeader}</h3>
                                                </div>
                                                {(object?.orderStatus === 4 && natureOfOrder === 2) && <div className="col-auto text-end">
                                                    <h1 className="font20 fw-bold cursor-pointer"
                                                        onClick={() => this.openQrCodeModal(object?.encodedOrderId)}><img height={'30px'} src={Qrimage} alt="QrImage"></img></h1>
                                                </div>}
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h3 className="font14 mb-1">{Constants.ORDER_SUMMARY.orderId}</h3>
                                                    <strong
                                                        className="font16">{object.invoiceOrderDetailsId}</strong></div>
                                                <div className="col">
                                                    <h3 className="font14 mb-1">Order Type</h3>
                                                    <strong
                                                        className="font16">{_renderOrderTypes(createdBy, natureOfOrder)}</strong>
                                                </div>
                                                <div className="col">
                                                    <h3 className="font14 mb-1">Order Date </h3>
                                                    <strong
                                                        className="font16">{getInvoiceDate(invoiceDate)}</strong>
                                                </div>
                                            </div>
                                            {
                                                createdBy === Constants.ORDER_TYPES.CREATED_BY_USER_ONLINE_ORDER ? <>
                                                    <div className="row">
                                                        <>{(natureOfOrder === 1 && object?.botOrderId &&
                                                            object?.orderStatus === Constants.ORDER_STATUS.READY_TO_PICKUP) && <div className="col">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-dark text-uppercase"
                                                                    onClick={() => this.navigateToTrackMyBot(object?.botOrderId)}>
                                                                    Track
                                                                </button>
                                                            </div>}
                                                        </>
                                                    </div>
                                                    <div className="card card-body text-center mt-3 py-4">
                                                        <hr className="status-line w-75 m-auto" />
                                                        <div className="row status-row">
                                                            <OrderTrackStatus object={object} natureOfOrder={natureOfOrder} />
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center mt-4">
                                                        {object.orderType === 2 ? <div className="col">
                                                            <h4 className="font16 fw-bold mb-2">{`${natureOfOrder === 1 ? 'Delivery Address' : ' Pickup Address'}`}</h4>                                                    <p>
                                                                {_renderDeliveryAddress(deliveryAddress, natureOfOrder, storeAddress)}</p>
                                                        </div> : null}
                                                    </div>
                                                </> : null}
                                        </div>
                                        <hr className="thick mt-5 mb-4" />
                                    </>
                                )
                            })
                    }

                    <div className="card card-body p-4 bill-details my-5 mb-lg-3">
                        <div className="row">
                            <div className="col">
                                <h3 className="font16 fw-bold mb-4">Bill Details</h3>
                            </div>
                            <div className="col-auto text-end">
                                <OrderStatus paymentStatus={paymentStatus} />
                            </div>
                        </div>
                        <div className="list-of-items pt-3 mb-2">
                            {invoiceOrderDetailsList && invoiceOrderDetailsList.sort(sortCheckoutOrders).reduce(reduceCheckourOrders, []).map((object) => {
                                const { invoiceOrderProductDetailsList: data, customerComment, orderStatus } = object;
                                return <>{data.map((o) => {
                                    let unfilledQty = o?.quantity - o?.acceptedQuantity
                                    return (
                                        <div className="row mb-2">
                                            <div className={`col`}>
                                                <div className={`title-case ${(o?.acceptedQuantity === 0 && orderStatus !== 1) ? 'strike' : ''}`}>
                                                    {orderStatus !== 1 && o?.acceptedQuantity !== 0 ? o?.acceptedQuantity : o?.quantity} x {o?.name?.toLowerCase()} (SKU : {o?.upc})
                                                    {o?.ageRestricted && <span style={{ color: 'red' }}>18+</span>}
                                                    {o.invoiceProductAddOnMappingList?.length > 0 && (<>
                                                        <p style={{ fontSize: '1rem', paddingLeft: '10px' }} className={`mt-1 ${(o?.acceptedQuantity === 0 && orderStatus !== 1) ? 'strike' : ''}`}><b>{`Your Customization`}</b>:</p>
                                                        <p className={`title-case mt-1 ${(o?.acceptedQuantity === 0 && orderStatus !== 1) ? 'strike' : ''}`} style={{ fontSize: '1rem', paddingLeft: '10px' }}>
                                                            {o.invoiceProductAddOnMappingList?.map((addons) => addons?.name?.toLowerCase()).join(',')}
                                                        </p>
                                                    </>)}
                                                </div>

                                                {
                                                    (orderStatus !== 1 && paymentStatus < 11 && o?.acceptedQuantity !== 0
                                                        && o?.acceptedQuantity !== o?.quantity) ? <p style={{ color: 'red', paddingLeft: '10px' }}
                                                            className="mt-1">Unfulfilled Quantity: {unfilledQty}</p> : null
                                                }
                                            </div>
                                            <div className="col-auto text-end">
                                                ${this._getPriceOfProduct(o, object)}
                                            </div>
                                        </div>
                                    )
                                })}
                                    {customerComment && <div> <b>Bistro notes :</b>{customerComment}</div>}

                                </>
                            })}
                            {invoiceOrderDetailsList && invoiceOrderDetailsList.sort(sortCheckoutOrders).reduce(reduceCheckourOrders, []).map((object) => {
                                const { partialAcceptComment } = object;
                                return <>
                                    {
                                        partialAcceptComment && <div><b>{object.orderType === 2 ? "Product Comment: " : "Bistro Comment: "}</b> {partialAcceptComment}</div>
                                    }
                                </>
                            })}


                        </div>
                        {this.getOfferDetailsList().map((offers, index) => {
                            return <OrderSummaryOfferDetails
                                cartOffer={offers}
                                cartItems={this.getCartObject(invoiceOrderDetailsList)}
                            />
                        })}
                        <div className="dotted-divider pt-2 mt-2 mb-3">
                            <div className="row mb-2">
                                <div
                                    className="col">Receipt no</div>
                                <div
                                    className="col-auto text-end">{currentInvoiceId}</div>
                            </div>
                            <div className="row mb-2">
                                <div
                                    className="col">Items total</div>
                                <div
                                    className="col-auto text-end">{`${Constants.COMMONS.usd}${parseFloat(totalAmountExcludingTax)?.toFixed(2)}`}</div>
                            </div>
                            <div className="row mb-2">
                                <div
                                    className="col">Sales tax</div>
                                <div
                                    className="col-auto text-end">{`${Constants.COMMONS.usd}${parseFloat(totalTaxAmount)?.toFixed(2)}`}</div>
                            </div>
                            {this.getOfferDetailsList().map((offers, index) => {
                                return <InvoiceSummary
                                    cartOffer={offers}
                                    natureOfOrder={natureOfOrder}
                                />
                            })}
                        </div>
                        <div className="row mb-4">
                            <div className="col fw-bold">{createdBy !== 3 && (finalAmount !== -1 || paymentStatus === 1 || paymentStatus === 2 || paymentStatus === 3 || paymentStatus === 4) ? 'Pending amount' : 'Paid amount'}</div>
                            <div className="col-auto text-end fw-bold">
                                {createdBy !== 3 && (finalAmount !== -1 || paymentStatus === 1 || paymentStatus === 2 || paymentStatus === 3 || paymentStatus === 4) ? `${Constants.COMMONS.usd}${parseFloat(totalAmount)?.toFixed(2)}` : `${Constants.COMMONS.usd}${parseFloat(processedAmount)?.toFixed(2)}`}
                            </div>
                        </div>
                        {(paymentStatus === 11 || paymentStatus === 12 || paymentStatus === 13) && refundDetails && refundDetails?.refundDetailsId &&
                            <div className="row mb-4">
                                <div className="col fw-bold  "></div>
                                <div className="col-auto text-end fw-bold cursor-pointer text-primary" onClick={() => this.viewRefundReceipt()}>View Refund Receipt</div>
                            </div>}

                        <hr className="thick" />
                        {
                            manualRefunds && manualRefunds?.length > 0 &&
                            <div className="card">
                                <div className="card-header"><b>Refund Initiated by store manager</b></div>
                                <div className="card-body">
                                    {
                                        manualRefunds && manualRefunds.map((o, i) => {
                                            return (
                                                <div className="row" key={i}>
                                                    <div
                                                        className="col fw-bold"> Date: {moment(o?.lastModified).format('DD-MMM | hh:mm A')}</div>
                                                    <div
                                                        className="col-auto text-end">${o?.refundAmount?.toFixed(2)}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    {paymentStatus === 12 && <p style={{ color: 'blue', fontWeight: '500', paddingTop: '5px' }}>Note : {Constants.ORDER_SUMMARY.paymentType.refundApprocedAfter}</p>}
                                </div>
                            </div>
                        }
                        {totalAmount > 0 && this._renderRefundButton(invoiceDate, invoiceOrderDetailsList, paymentStatus, createdBy)}
                        {isRefundModal && orderDetails &&
                            <Refund
                                id="refundModal"
                                isRefundOpen={isRefundModal}
                                orderDetails={invoiceProductList}
                                closeRefundModal={(msg) => this.isRefundPageClose(msg)}
                                invoiceId={invoiceId}
                            />}
                        <div className="row mt-5">
                            <div
                                className="col text-link" id="sendToEmail"
                                onClick={() => this.sendToEmail()}>
                                <span className="font14 text-dark fw-bold cursor-pointer">
                                    <img src={require("../../asset/img/mail.svg")}
                                        alt="mail"
                                        className="me-2"
                                        height="21" />Email me receipt</span>
                            </div>
                            {
                                moment().isBefore(moment(invoiceDate).add(7, 'days'),) || (paymentStatus === Constants.PAYMENT_STATUS.REFUND_INIT && moment().isBefore(moment(invoiceDate).add(30, 'days'),)) ?
                                    <div
                                        className="col text-end text-link cursor-pointer" id="manageChat"
                                        onClick={() => this.manageChat()}>
                                        <span className="font14 text-dark fw-bold">
                                            <img src={require("../../asset/img/chat.svg")}
                                                alt="mail"
                                                className="me-2"
                                                height="21" />Chat with us</span>
                                    </div> : null
                            }

                        </div>

                    </div>
                    {
                        openRefundPreviewModal && <RefundPreviewModal
                            id='RefundPreviewModal'
                            onFailure={() => this.closeRefundPreview()}
                            image={this.state.refundImage}
                        />

                    }

                    {
                        openInstructionModal && <DeliveryInstruction
                            id="instructionModal"
                            onFailure={() => this.openInstructionsModal()} />
                    }
                    {
                        openQrModal &&
                        <QrCode title={'Pickup QR code'}
                            id='openQrModal'
                            onFailure={() => this.closeQrCodeModal()}
                            qrImages={qrImages}
                        // invoiceOrderDetailsList={invoiceOrderDetailsList}
                        />

                    }
                    {
                        moment().isBefore(moment(invoiceDate).add(7, 'days'),) || (paymentStatus === Constants.PAYMENT_STATUS.REFUND_INIT && moment().isBefore(moment(invoiceDate).add(30, 'days'),)) ? <ChatScreen
                            id="chatScreen"
                            showHideChat={showHideChat}
                            manageChat={(isOpen) => this.manageChat(isOpen)}
                        /> : null
                    }
                    {
                        viewRefundModal && <ViewRefundReceipt
                            id="#viewRefundRec"
                            onClose={() => this.closeViewRefundReceipt()}
                            openRefundPreview={() => this.openRefundPreview()} />
                    }
                </div >
            )
        );
    }
}


export const mapStateToProps = (state) => {
    return {
        getOrderDetails: Actions.getOrderDetails(state),
        getUserDetail: Actions.getUserDetail(state),
    };
};

export default withRouter(connect(mapStateToProps, {
    orderDetails: Actions.orderDetails,
    sendToEmail: Actions.sendToEmail,
    refundOrder: Actions.refundOrder,
    getCartDetails: Actions.getPimCartList,
    updateCartAfterVoucher: Actions.updateCartAfterVoucher,
    updateBistroComments: Actions.updateBistroComments
})(OrderSummary));
