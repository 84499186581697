import React from 'react'
import { withRouter } from 'react-router-dom'

class RefundPreviewModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { image, onFailure } = this.props
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-50">
                                <img
                                    id='alert-icon'
                                    src={require("../../asset/img/modal-close.svg")}
                                    alt="X"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="modal-close"
                                    onClick={() => onFailure()} />
                                <form>
                                    <div className="row mb-4">
                                        <div className="col text-center">
                                            <h3 className="font18 fw-bold mb-3">Preview</h3>
                                            <img src={image} style={{ width: '50%' }} alt="preview"/>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div >
                <div className="modal-backdrop show"></div>
            </React.Fragment >
        )
    }
}

export default withRouter(RefundPreviewModal)