import React from 'react'

class TermsOfUse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="section">
                <div className="container" style={{ height: "300px", alignItems: 'center' }}>
                    <h4 className="text-center">No Data</h4>
                </div>
            </div>
        )
    }
}

export default TermsOfUse;