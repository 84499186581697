import { takeLatest, all, put, cancelled } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthPost, doAuthPut, doContionalGet } from '../../service/index'
import { PIM_CART_DETAILS, PIM_ADD_TO_CART, UPDATE_PIM_CART, STORE_DEACTIVATE } from '../../config/api_endpoint'
export function* pimCart() {
  try {
    const response = yield doContionalGet(PIM_CART_DETAILS);
    yield put(Actions.getPimCartListSuccess(response))
  }
  catch (error) {
    yield put(Actions.getPimCartListFailure({ error }))
  }
}

export function* createPimCart({ params, callback }) {
  try {
    const response = yield doAuthPost(PIM_ADD_TO_CART, params);
    callback && callback(response)
    if (response?.response_code === 0) {
      yield put(Actions.createPimCartSuccess(response));
    } else {
      yield put(Actions.createPimCartFailure())
    }
  }
  catch (error) {
    callback && callback({ error })
    yield put(Actions.createPimCartFailure({ error }))
  }
}


export function* updatePimCart({ params, callback }) {
  try {
    const response = yield doAuthPost(UPDATE_PIM_CART, params);
    if (response?.response_code === 0) {
      yield put(Actions.updatePimCartSuccess(response));
    } else {
      yield put(Actions.updatePimCartFailure())
    }
    callback && callback(response)
  }
  catch (error) {
    callback && callback({ error })
    yield put(Actions.updatePimCartFailure({ error }))
  } finally {
    if (yield cancelled())
      yield put(Actions.updatePimCartFailure({ error: {} }))
  }
}

export function* clearCart({ params, callback }) {
  try {
    const response = yield doAuthPut(STORE_DEACTIVATE, params);
    callback && callback(response)
  }
  catch (error) {
    callback && callback({ error })
  }
}
export default function* pimCartsWatcher() {
  yield all([
    takeLatest(Actions.PIM_CART_LIST, pimCart),
    takeLatest(Actions.CREATE_PIM_CART, createPimCart),
    takeLatest(Actions.UPDATE_PIM_CART, updatePimCart),
    takeLatest(Actions.CART_DEACTIVATE, clearCart)
  ]);
}
