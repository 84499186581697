import { combineReducers } from 'redux';
import getAddress from './getAddress'
import addAddress from './addAddress'
import updateAddress from './updateAddress'
import deleteAddress from './deleteAddress'

export default combineReducers({
    getAddress,
    addAddress,
    updateAddress,
    deleteAddress
});