import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import BoldError from '../BoldError';
import Constants from "../../Utility/Constants";
import cloneDeep from "lodash.clonedeep";
import ModalPopup, { ModalFooter, ModalHeader } from "../../component/ModalPopup";
import Placeholder from '../../asset/img/no-preview.png'
import { getRandomNumber } from "../../Utility/Utils";
class Refund extends React.Component {
  constructor(props) {
    super(props);
    const { orderDetails } = props;
    const invoiceArray = cloneDeep(orderDetails)
    this.state = {
      selectedItems: [],
      errorMessage: '',
      invoiceOrderDetailsListState: invoiceArray,
      uploadImages: []
    };
  }
  _error = (refundQuantity, categorySelect) => {
    return refundQuantity && categorySelect === undefined
  }

  _renderRefundCategory(o, parentIndex, i) {
    const { categorySelect, refundQuantity } = o;

    return (
      <>
        <div className="alert alert-danger font12 py-2 mt-4" role="alert">{Constants.ORDER_SUMMARY.refundText}</div>
        <hr className="thick" />
        <h3 className="font16 fw-bold text-uppercase mb-3"> Refund Category</h3>

        <div className="row mb-3">
          {this._error(refundQuantity, categorySelect) && <span style={{ color: 'red', fontWeight: '500' }}>Please select the Category type</span>}
          <div className="col">
            <select id="refundType" className="form-select custom-field rounded-0 border-0"
              name="refundType"
              onChange={(e) => this.handleRefundCategoryChange(parentIndex, i, e)}>
              <option disabled selected>Select</option>
              <option value="0">Wrong Product</option>
              <option value="1">Damaged Product</option>
              <option value="2">Wrong amount</option>
              <option value="3">Other</option>
            </select>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <textarea
              id="refundReason"
              rows="1"
              className="form-control custom-field"
              name="refundReason"
              placeholder="More information (optional)"
              onChange={(e) => this.handleRefundReasonChange(parentIndex, i, e)}
            >
            </textarea>
          </div>
        </div>
      </>
    )
  }
  handleRefundReasonChange(parentIndex, index, e) {
    const { invoiceOrderDetailsListState } = this.state;
    const detailsObj = { ...invoiceOrderDetailsListState[parentIndex] };
    const productObj = { ...detailsObj.invoiceOrderProductDetailsList[index] };
    productObj.categoryReason = e.target.value;
    invoiceOrderDetailsListState.splice(parentIndex, 1, detailsObj);
    invoiceOrderDetailsListState[parentIndex].invoiceOrderProductDetailsList.splice(index, 1, productObj);
    this.setState({ invoiceOrderDetailsListState })
  }

  handleRefundCategoryChange(parentIndex, index, e) {

    const { invoiceOrderDetailsListState } = this.state;
    const detailsObj = { ...invoiceOrderDetailsListState[parentIndex] };
    const productObj = { ...detailsObj.invoiceOrderProductDetailsList[index] };
    productObj.categorySelect = e.target.value;
    invoiceOrderDetailsListState.splice(parentIndex, 1, detailsObj);
    invoiceOrderDetailsListState[parentIndex].invoiceOrderProductDetailsList.splice(index, 1, productObj);
    this.setState({ invoiceOrderDetailsListState })
  }


  quantityIncreament(obj, parentIndex, index) {
    const { invoiceOrderDetailsListState } = this.state;
    const detailsObj = { ...invoiceOrderDetailsListState[parentIndex] };
    const productObj = { ...detailsObj.invoiceOrderProductDetailsList[index] };
    if (productObj.refundQuantity === undefined)
      productObj.refundQuantity = 1;
    else {
      productObj.refundQuantity += 1;
    }
    if (productObj.refundQuantity >= productObj.acceptedQuantity) {
      productObj.refundQuantity = productObj.acceptedQuantity;
    }
    invoiceOrderDetailsListState.splice(parentIndex, 1, detailsObj);
    invoiceOrderDetailsListState[parentIndex].invoiceOrderProductDetailsList.splice(index, 1, productObj);
    this.setState({ invoiceOrderDetailsListState })
  }


  quantityDecrement(obj, parentIndex, index) {
    const { invoiceOrderDetailsListState } = this.state;
    const detailsObj = { ...invoiceOrderDetailsListState[parentIndex] };
    const productObj = { ...detailsObj.invoiceOrderProductDetailsList[index] };
    if (!productObj.refundQuantity) {
      productObj.refundQuantity = 0;
    } else {
      productObj.refundQuantity -= 1;
    }
    if (productObj.refundQuantity === 0) {
      productObj.categorySelect = undefined;
      productObj.categoryReason = undefined;
    }
    invoiceOrderDetailsListState.splice(parentIndex, 1, detailsObj);
    invoiceOrderDetailsListState[parentIndex].invoiceOrderProductDetailsList.splice(index, 1, productObj);
    this.setState({ invoiceOrderDetailsListState })
  }

  closeRefundModal(msg) {
    const { orderDetails } = this.props;
    const invoiceArray = cloneDeep(orderDetails)
    const resetState = {
      selectedItems: [],
      errorMessage: '',
      invoiceOrderDetailsListState: invoiceArray
    }
    this.setState(resetState)
    if (msg) {
      this.props.closeRefundModal(msg)
    } else {
      this.props.closeRefundModal()
    }
  }



  removeImage(id) {
    const { uploadImages } = this.state
    let tempArray = uploadImages?.findIndex((o) => o?.id === id)
    uploadImages.splice(tempArray, 1)
    this.setState({ uploadImages })
  }

  uploadImageCallBack = (res) => {
    if (res?.response_code === 0) {
      let { uploadImages } = this.state
      uploadImages.push(res?.response)
      this.setState({ uploadImages })
    }
  }

  getBase64(file, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = error => callback(error);
  }
  async uploadAvatar(e) {
    e.preventDefault();
    let refundImageBase64 = '';
    this.getBase64(e.target.files[0], (obj) => {
      refundImageBase64 = obj
      let params = {
        invoiceId: this.props.invoiceId,
        imageUrl: refundImageBase64
      }
      if (refundImageBase64) {
        this.props.uploadRefundImage(params, this.uploadImageCallBack)
      }
    })
  }
  createRefund() {
    const { invoiceOrderDetailsListState, uploadImages } = this.state

    const callback = (response) => {
      let { response_code, response_message } = response
      if (response_code === Constants.RESPONSE_CODE.SUCCESS) {
        this.closeRefundModal('sucess');
        this.setState({ errorMessage: '' })
      } else {
        this.setState({ errorMessage: response_message })
      }
    }

    let arr = []
    invoiceOrderDetailsListState.forEach(item => {
      item.invoiceOrderProductDetailsList.forEach(details => {
        let { categorySelect, categoryReason, refundQuantity, invoiceProductDetailsId, } = details;
        arr.push({
          invoiceOrderProductDetailsId: invoiceProductDetailsId,
          refundType: categorySelect,
          refundReason: categoryReason, refundQuantity
        });
      })
    });

    const items = arr.filter(d => d.refundQuantity && d.refundQuantity > 0).map(m => {
      return {
        ...m,
        refundReason: m.refundReason || ''
      }
    });

    if (items.length === 0) {
      this.setState({ errorMessage: 'Please add your refund item.' })
      return
    }

    if (items.length > 0) {
      const hasError = items.some(({ refundType, refundQuantity }) => this._error(refundQuantity, refundType))
      if (hasError)
        return;
    }
    const revealDamagedProduct = items && items.filter((o) => o?.refundType === '1')
    if (revealDamagedProduct && revealDamagedProduct?.length > 0 && uploadImages?.length === 0) {
      this.setState({ errorMessage: "For damaged product's, Image attachments are mandatory to validate the request." })
      return;
    }
    let reqParams = {
      invoiceId: this.props.invoiceId,
      items: items
    }

    if (uploadImages?.length > 0) {
      reqParams["imageIds"] = uploadImages?.map((o) => o?.id)
    } else {
      reqParams["imageIds"] = []
    }
    this.props.refundOrder(reqParams, callback)
  }
  render() {
    const { isRefundOpen } = this.props;
    const { errorMessage, invoiceOrderDetailsListState, uploadImages } = this.state
    return (
      <React.Fragment>
        {isRefundOpen && <ModalPopup id="refund-modal" isRefund={true}
          onCloseModal={() => this.closeRefundModal()}>
          <ModalHeader title="Initiate Refund" />
          <form>
            {invoiceOrderDetailsListState &&
              invoiceOrderDetailsListState?.map((item, parentIndex) => {
                return item?.invoiceOrderProductDetailsList.map((o, i) => {
                  let { name, imageUrl, refundQuantity, acceptedQuantity } = o;
                  return (
                    <>
                      {
                        acceptedQuantity !== 0 && <React.Fragment>
                          <div className="row align-items-center mb-1">
                            <div className="col-auto"><span>
                              <img src={imageUrl ? imageUrl + '?$' + getRandomNumber() : Placeholder}
                                alt="1" className="" /> </span></div>
                            <div className={`col px-0 ${acceptedQuantity === 0 ? 'strike' : ''}`}>{name}
                              {
                                o.invoiceProductAddOnMappingList?.length > 0 && <>
                                  <p><b>Your customization:</b></p>
                                  <p>{o.invoiceProductAddOnMappingList?.map((k) => k?.name).join(',')}</p>
                                </>
                              }
                            </div>
                            <div className="col-auto">
                              <div className="input-group quantity">
                                <button id="quantityDecrement" className="btn px-1" type="button" onClick={() => this.quantityDecrement(o, parentIndex, i)}> <img src={require("../../asset/img/minus.svg")} alt="-" width="12" /> </button>
                                <input type="text" className="form-control px-1 text-center" value={refundQuantity || 0} />
                                <button id="quantityIncreament" className="btn px-1" type="button" onClick={() => this.quantityIncreament(o, parentIndex, i)}> <img src={require("../../asset/img/plus.svg")} alt="+" height="12" /> </button>
                              </div>
                            </div>
                          </div>
                          {(refundQuantity && refundQuantity > 0 &&
                            this._renderRefundCategory(o, parentIndex, i)) || ''}
                        </React.Fragment>
                      }
                    </>
                  )
                }
                )
              })}


            <div className="drop-zone">
              <div className="row">
                {
                  uploadImages && uploadImages.map((o, i) => {
                    return (
                      <div className="col-3 mt-1" key={i}>
                        <div className="modal-product-close" id="removeImage" onClick={() => this.removeImage(o?.id)}>
                          <img id='alert-icon'
                            src={require("../../asset/img/modal-close.svg")}
                            alt="X" data-bs-dismiss="modal"
                            aria-label="Close" />
                        </div>
                        <div className="d-flex justify-content-center align-items-center h-100 bg-ash">
                          <div className="card">
                            <img src={o?.imageUrl ? o?.imageUrl + '?$' + getRandomNumber() : Placeholder} alt="placeholder" />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className="text-center mt-3">
              <div className="media">
                <div className="media-body">
                  <div className="custom-file custom-file-naked d-block mb-1">
                    <input type="file"
                      className="custom-file-input d-none"
                      id="avatar-file"
                      onChange={(e) => {
                        this.uploadAvatar(e)
                        e.target.value = null
                      }} accept="image/*"></input>
                    <label className="custom-file-label position-relative"
                      htmlFor="avatar-file">
                      <span className="btn btn-sm upload-avatar">
                        Upload Image
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <BoldError message={errorMessage} />
          <ModalFooter id="createRefund"
            onSuccess={() => this.createRefund()}
            buttonName="SUBMIT" />
        </ModalPopup>}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getOrderDetails: Actions.getOrderDetails(state),
    refundDetails: Actions.refundDetails(state)
  };
};

export default withRouter(connect(mapStateToProps, {
  refundOrder: Actions.refundOrder,
  uploadRefundImage: Actions.uploadRefundImage
})(Refund));

