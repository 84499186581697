import Actions from '../action'
const initialState = {
    isLoading: false,
    pimProducts: [],
    pimProductsDetails: [],
    bistroProductsDetails: [],
    hasLoadMore: false,
    error: false,
    isDetailLoading: false,
    isBistroDetailLoading: false,
    isPimBistroLoading: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.PIM_PRODUCTS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PIM_PRODUCTS_SUCCESS: {
            return {
                ...state,
                isLoading: action?.data?.isLoading ? true : false,
                pimProducts: action?.data?.response,
                hasLoadMore: !!action?.data?.hasLoadMore,
                error: false
            };
        }
        case Actions.PIM_PRODUCTS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case Actions.BISTRO_PIM_PRODUCTS: {
            return {
                ...state,
                isPimBistroLoading: true,
            };
        }
        case Actions.BISTRO_PIM_PRODUCTS_SUCCESS: {

            return {
                ...state,
                isPimBistroLoading: action?.data?.isLoading ? true : false,
                pimProducts: action?.data?.response,
                hasLoadMore: !!action?.data?.hasLoadMore,
                error: false
            };
        }
        case Actions.BISTRO_PIM_PRODUCTS_FAILURE: {
            return {
                ...state,
                isPimBistroLoading: false,
                error: action.error,
            };
        }
        case Actions.PIM_PRODUCTS_DETAILS: {
            return {
                ...state,
                isDetailLoading: true,
            };
        }
        case Actions.PIM_PRODUCTS_DETAILS_SUCCESS: {

            return {
                ...state,
                isDetailLoading: false,
                pimProductsDetails: action?.data?.response,
                error: false
            };
        }
        case Actions.PIM_PRODUCTS_DETAILS_FAILURE: {
            return {
                ...state,
                isDetailLoading: false,
                error: action.error,
            };
        }
        case Actions.BISTRO_PRODUCTS_DETAILS: {
            return {
                ...state,
                isBistroDetailLoading: true,
            };
        }
        case Actions.BISTRO_PRODUCTS_DETAILS_SUCCESS: {

            return {
                ...state,
                isBistroDetailLoading: false,
                bistroProductsDetails: action?.data?.response,
                error: false
            };
        }
        case Actions.BISTRO_PRODUCTS_DETAILS_FAILURE: {
            return {
                ...state,
                isBistroDetailLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
