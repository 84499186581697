export const SIGN_UP = 'SIGN_UP';

export const SIGN_UP_VERIFY_EMAIL = `${SIGN_UP}_VERIFY_EMAIL`;
export const SIGN_UP_SUCCESS = `${SIGN_UP}_SUCCESS`;
export const SIGN_UP_FAILURE = `${SIGN_UP}_FAILURE`;

export const SIGN_UP_VERIFY_OTP = `SIGN_UP_VERIFY_OTP`;
export const SIGN_UP_VERIFY_OTP_SUCCESS = `${SIGN_UP_VERIFY_OTP}_SUCCESS`;
export const SIGN_UP_VERIFY_OTP_FAILURE = `${SIGN_UP_VERIFY_OTP}_FAILURE`;

export const SIGN_UP_RESEND_OTP = `SIGN_UP_RESEND_OTP`;
export const SIGN_UP_RESEND_OTP_SUCCESS = `${SIGN_UP_RESEND_OTP}_SUCCESS`;
export const SIGN_UP_RESEND_OTP_FAILURE = `${SIGN_UP_RESEND_OTP}_FAILURE`;

export const SIGN_UP_UPDATE_EMAIL = `SIGN_UP_UPDATE_EMAIL`;
export const SIGN_UP_UPDATE_EMAIL_SUCCESS = `${SIGN_UP_UPDATE_EMAIL}_SUCCESS`;
export const SIGN_UP_UPDATE_EMAIL_FAILURE = `${SIGN_UP_UPDATE_EMAIL}_FAILURE`;

export const SIGN_UP_VERIFY_MOBILE_NUMBER = `${SIGN_UP}_MOBILE_NUMBER`;

export const verifyEmailAction = (params, callback) => {
    return {
        type: SIGN_UP_VERIFY_EMAIL,
        params,
        callback
    }
}

export const signUpAction = (params, callback) => {
    return {
        type: SIGN_UP,
        params,
        callback
    }
}

export const signUpSuccess = (data) => ({
    type: SIGN_UP_SUCCESS,
    data
});

export const signUpFailure = data => ({
    type: SIGN_UP_FAILURE,
    data
});

export const signupVerifyOTP = (params, callback) => ({
    type: SIGN_UP_VERIFY_OTP,
    params,
    callback
});

export const verifyOtpSuccess = data => ({
    type: SIGN_UP_VERIFY_OTP_SUCCESS,
    data
});
export const verifyOtpFailure = data => ({
    type: SIGN_UP_VERIFY_OTP_FAILURE,
    data
});

export const resendOtp = callback => ({
    type: SIGN_UP_RESEND_OTP,
    callback
});

export const resendOTPSuccess = data => ({
    type: SIGN_UP_RESEND_OTP_SUCCESS,
    data
});

export const resendOTPFailure = data => ({
    type: SIGN_UP_RESEND_OTP_FAILURE,
    data
});

export const updateEmail = params => ({
    type: SIGN_UP_UPDATE_EMAIL,
    params
});

export const updateEmailSuccess = data => ({
    type: SIGN_UP_UPDATE_EMAIL_SUCCESS,
    data
});

export const updateEmailFailure = data => ({
    type: SIGN_UP_UPDATE_EMAIL_FAILURE,
    data
});


export const checkMobileNumberAlreadyExistsAction = (mobileNumber, callback) => ({
    type: SIGN_UP_VERIFY_MOBILE_NUMBER,
    mobileNumber,
    callback
})