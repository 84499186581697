const NAME = "MANAGE_CARDS";
export const GET_SAVED_CARD_DETAILS = `${NAME}/GET_SAVED_CARD_DETAILS`;
export const GET_SAVED_CARD_DETAILS_SUCCESS = `${NAME}/GET_SAVED_CARD_DETAILS_SUCCESS`;
export const GET_SAVED_CARD_DETAILS_FAILURE = `${NAME}/GET_SAVED_CARD_DETAILS_FAILURE`;


export const SEND_OTP_CREAE_PIN = `${NAME}/SEND_OTP_CREAE_PIN`;
export const SEND_OTP_CREAE_PIN_SUCCESS = `${NAME}/SEND_OTP_CREAE_PIN_SUCCESS`;
export const SEND_OTP_CREAE_PIN_FAILURE = `${NAME}/SEND_OTP_CREAE_PIN_FAILURE`;

export const SAVE_OTP_PIN = `${NAME}/SAVE_OTP_PIN`;
export const SAVE_OTP_PIN_SUCCESS = `${NAME}/SAVE_OTP_PIN_SUCCESS`;
export const SAVE_OTP_PIN_FAILURE = `${NAME}/SAVE_OTP_PIN_FAILURE`;

export const ENTRY_OTP_PIN = `${NAME}/ENTRY_OTP_PIN`;

export const DELETE_CARD = `${NAME}/DELETE_CARD`
export const DELETE_CARD_SUCCESS = `${NAME}/DELETE_CARD_SUCCESS`;
export const DELETE_CARD_FAILURE = `${NAME}/DELETE_CARD_FAILURE`;


export const MAKE_PRIMARY_CARD = `${NAME}/MAKE_PRIMARY_CARD`
export const MAKE_PRIMARY_CARD_SUCCESS = `${NAME}/MAKE_PRIMARY_CARD_SUCCESS`;
export const MAKE_PRIMARY_CARD_FAILURE = `${NAME}/MAKE_PRIMARY_CARD_FAILURE`;

export const FORGET_PIN = `${NAME}/FORGET_PIN`;


export const RESET_PIN = `${NAME}/RESET_PIN`
export const RESET_PIN_SUCCESS = `${NAME}/RESET_PIN_SUCCESS`;
export const RESET_PIN_FAILURE = `${NAME}/RESET_PIN_FAILURE`;

export const USER_PAYMENT_OTP = 'USER_PAYMENT_OTP'
export const USER_PAYMENT_OTP_SUCCESS = 'USER_PAYMENT_OTP_SUCCESS'
export const USER_PAYMENT_OTP_FAILURE = 'USER_PAYMENT_OTP_FAILURE'


export const getSavedCardDetails = store => store[NAME]
export const getSavedCardList = (callback) => {
    return {
        type: GET_SAVED_CARD_DETAILS,
        callback
    }
}

export const getSavedCardListSuccess = (data) => {
    return {
        type: GET_SAVED_CARD_DETAILS_SUCCESS,
        data
    }
}

export const getSavedCardListFailure = (error) => {
    return {
        type: GET_SAVED_CARD_DETAILS_FAILURE,
        error
    }
}


export const sendOtpCreatePin = (callback) => {
    return {
        type: SEND_OTP_CREAE_PIN,
        callback
    }
}

export const sendOtpCreatePinSuccess = (data) => {
    return {
        type: SEND_OTP_CREAE_PIN_SUCCESS,
        data
    }
}

export const sendOtpCreatePinFailure = (error) => {
    return {
        type: SEND_OTP_CREAE_PIN_FAILURE,
        error
    }
}


export const saveOtpPin = (params, callback) => {
    return {
        type: SAVE_OTP_PIN,
        params,
        callback
    }
}

export const saveOtpPinSuccess = (data) => {
    return {
        type: SAVE_OTP_PIN_SUCCESS,
        data
    }
}

export const saveOtpPinFailure = (error) => {
    return {
        type: SAVE_OTP_PIN_FAILURE,
        error
    }
}

export const entryPin = (data) => {
    return {
        type: ENTRY_OTP_PIN,
        data
    }
}

export const forgetPin = (data) => {
    return {
        type: FORGET_PIN,
        data
    }
}

export const deletePaymentCard = (params, callback) => {
    return {
        type: DELETE_CARD,
        params,
        callback
    }
}

export const deleteCardSuccess = (data) => {
    return {
        type: DELETE_CARD_SUCCESS,
        data
    }
}

export const deleteCardFailure = (error) => {
    return {
        type: DELETE_CARD_FAILURE,
        error
    }
}

export const makePrimaryPaymentCard = (params, callback) => {
    return {
        type: MAKE_PRIMARY_CARD,
        params,
        callback
    }
}

export const makePrimaryPaymentCardSuccess = (data) => {
    return {
        type: MAKE_PRIMARY_CARD_SUCCESS,
        data
    }
}

export const makePrimaryPaymentCardFailure = (error) => {
    return {
        type: MAKE_PRIMARY_CARD_FAILURE,
        error
    }
}


export const resetPin = (params, callback) => {
    return {
        type: RESET_PIN,
        params,
        callback
    }
}

export const resetPinSuccess = (data) => {
    return {
        type: RESET_PIN_SUCCESS,
        data
    }
}

export const resetPinFailure = (error) => {
    return {
        type: RESET_PIN_FAILURE,
        error
    }
}
export const userPaymentOtp = (params, callback) => {
    return {
        type: USER_PAYMENT_OTP,
        params,
        callback
    }
}

export const userPaymentOtpSuccess = (data) => {
    return {
        type: USER_PAYMENT_OTP_SUCCESS,
        data
    }
}

export const userPaymentOtpFailure = (error) => {
    return {
        type: USER_PAYMENT_OTP_FAILURE,
        error
    }
}