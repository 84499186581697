import React from 'react'
import { withRouter } from 'react-router-dom'
import BoldError from '../../component/BoldError';

class PinModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { onFailure, onSuccess, handleChange, errorMessage } = this.props
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-50">
                                <img
                                    id='alert-icon'
                                    src={require("../../asset/img/modal-close.svg")}
                                    alt="X"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="modal-close"
                                    onClick={() => onFailure()} />
                                <form>
                                    <div
                                        className="row mb-4">
                                        <div
                                            className="col">
                                            <span className="font13 mb-3">OTP has been sent to the registered E-mail address. Please check the inbox and spam folder. OTP will be valid for 15 minutes</span>
                                        </div>
                                    </div>
                                    <div
                                        className="row mb-4">
                                        <div className="col">
                                            {errorMessage && <BoldError
                                                message={errorMessage}
                                            />}
                                            <input
                                                id="phone"
                                                className="form-control custom-field"
                                                type="number"
                                                name="otp"
                                                placeholder="OTP"
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div
                                            id='alert-onSuccess'
                                            className="col pe-0" onClick={() => onSuccess('success')}>
                                            <span className="btn btn-success font14 w-100 p-3">Submit</span> </div>
                                        <div
                                            id='alert-onFailure'
                                            className="col text-end" onClick={() => onFailure()}>
                                            <span className="btn btn-outline-danger font14 w-100 p-3">Cancel</span> </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </React.Fragment>
        )
    }
}

export default withRouter(PinModal)