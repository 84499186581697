import axios from 'axios';
import { BASE_URL, CLIENT_CREDENTIALS } from '../config/api_endpoint';
import { store } from '../redux/createStore';
import Actions from '../redux/action';
import Utils from '../Utility/Utils';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});




export const handleErrorToast = (error) => {
    // try {
    //     const errmsg = JSON.stringify(error)
    //     if (errmsg?.includes('500') || errmsg?.includes('401'))
    //         toast.error(Constants.API.something, {
    //             position: toast.POSITION.TOP_RIGHT,
    //             toastId: 401,
    //         });

    // } catch (e) {
    //     //
    // }
    return error;
}


export const publicHeaders = () => {
    return {
        'Authorization': "Basic dXN0and0Y2xpZW50aWQ6dXN0UmQ5M0NmNFdfRm13WiY=",
        "Content-Type": "multipart/form-data"
    }
}

export const httpAuthHeader = () => {
    const token = store?.getState()?.USER?.token?.access_token;
    const selectedStore = store?.getState()?.GET_STORE_LIST?.selectedStoreList;
    return {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/json',
        'storeId': selectedStore?.value || 1
    }
}


export const clientCredentialsHeaders = () => {
    const token = store?.getState()?.CLIENT_CREDENTIALS?.token?.access_token;
    const selectedStore = store?.getState()?.GET_STORE_LIST?.selectedStoreList;
    return {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/json',
        'storeId': selectedStore?.value || 1
    }
}


export const signupHeaders = () => {
    const token = store?.getState()?.SIGN_UP?.token?.access_token;
    const userToken = store?.getState()?.USER?.token?.access_token;
    return {
        'Authorization': `bearer ${userToken || token}`,
        'Content-Type': 'application/json',
    }
}

//generate client credentials
export const clientCredentials = () => {
    let data = new FormData()
    data.append('grant_type', 'client_credentials')
    const config = {
        headers: publicHeaders()
    };
    return axiosInstance.post(CLIENT_CREDENTIALS, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}


// Access Request

export const doAuthGet = (url, params) => {
    const config = {
        params,
        headers: httpAuthHeader(),
    };
    return axiosInstance.get(url, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}


export const doAuthPost = (url, data) => {
    const config = {
        headers: httpAuthHeader()
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}


export const doAuthPut = (url, data) => {
    const config = {
        headers: httpAuthHeader()
    };
    return axiosInstance.put(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}

// Client Request


export const doClientGet = (url, params) => {
    const config = {
        params,
        headers: clientCredentialsHeaders(),
    };
    return axiosInstance.get(url, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}

export const doClientPost = (url, data) => {
    const config = {
        headers: clientCredentialsHeaders(),
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}

export const doSignupPost = (url, data) => {
    const config = {
        headers: signupHeaders(),
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}

// conditional function


export const doContionalGet = (url, params) => {
    const token = store?.getState()?.USER?.token?.access_token;
    return token ? doAuthGet(url, params) : doClientGet(url, params)
}


export const doContionalPost = (url, params) => {
    const token = store?.getState()?.USER?.token?.access_token;
    return token ? doAuthPost(url, params) : doClientPost(url, params)
}

export const doDelete = (url, data) => {
    const config = {
        data,
        headers: httpAuthHeader(),
    };
    return axiosInstance.delete(url, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}

export const doPut = (url, data) => {
    const config = {
        headers: httpAuthHeader(),
    };
    return axiosInstance.put(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}


export const refreshToken = refresh_token => {
    let data = new FormData()
    data.append('grant_type', 'refresh_token')
    data.append('refresh_token', refresh_token)
    const config = {
        headers: publicHeaders()
    };
    return axiosInstance.post(CLIENT_CREDENTIALS, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
};


// added interceptors for refresh token strategy
axiosInstance.interceptors.request.use(
    async config => {
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const token = Actions.getUserDetail(store?.getState())?.token?.refresh_token;
        if (token) {
            const refreshTokenObj = await refreshToken(token);
            if (refreshTokenObj && refreshTokenObj.access_token) {
                refreshTokenObj.expMilliseconds = Utils.convertExpirySecToMilliSec(refreshTokenObj.expires_in);
            }
            store.dispatch(Actions.loginSuccess(refreshTokenObj))
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshTokenObj.access_token;
            originalRequest.headers.Authorization = `bearer ${refreshTokenObj.access_token}`
            return axiosInstance(originalRequest);
            // if refreshtoken throws error
        }
    } else if (error.response.status >= 400 && originalRequest?.url?.includes(CLIENT_CREDENTIALS)) {
        store.dispatch(Actions.logoutSuccess())
    }
    return Promise.reject(error);
});

//SocketRefreshToken
export const getSocketRefreshToken = async () => {
    const refresh_token = Actions.getUserDetail(store?.getState())?.token?.refresh_token;
    if (refresh_token) {
        let data = new FormData()
        data.append('grant_type', 'refresh_token')
        data.append('refresh_token', refresh_token)
        const config = { headers: publicHeaders() }
        return axiosInstance.post(CLIENT_CREDENTIALS, data, config)
            .then(function (response) {
                const refreshTokenObj = response?.data
                if (refreshTokenObj && refreshTokenObj.access_token) {
                    refreshTokenObj.expMilliseconds = Utils.convertExpirySecToMilliSec(refreshTokenObj.expires_in);
                }
                store.dispatch(Actions.loginSuccess(refreshTokenObj))
                return refreshTokenObj
            }).catch((error) => {
                console.log(error, 'err')
                throw error
            })
    }

}