import React from "react";
import { withRouter } from "react-router-dom";
import PasswordInput from "../PasswordInput";
import eventBus, { EVENT_RESET_PASSWORD_CLOSE } from '../../Utility/event';
import { connect } from "react-redux";
import Actions from "../../redux/action";
import Constants from "../../Utility/Constants";
import ModalPopup, { ModalHeader } from "../ModalPopup";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      retypePassword: '',
      passwordValid: false,
      errorMessage: null
    };
  }

  closeResetModal() {
    const { password, passwordValid, retypePassword } = this.state;

    if (!password) {
      this.setState({ errorMessage: 'Please Enter the password' });
      return;
    } else if (!passwordValid) {
      this.setState({ errorMessage: 'Passowrd does not match the requirements.' });
      return;
    } else if (password !== retypePassword) {
      this.setState({ errorMessage: 'Password and Re-type password does not match.' });
      return;
    }
    const callback = (response) => {
      let { response_code, response_message } = response
      if (response_code === 0) {
        eventBus.dispatch(EVENT_RESET_PASSWORD_CLOSE, {});
        this.setState({ errorMessage: null });
        // refresh the page to get user related data's
        // window.location.reload();
      } else {
        this.setState({ errorMessage: response_message })
      }
    }

    let params = {
      password
    }

    this.props.updateNewPassword(params, callback)
  }

  onCloseResetModal = () => {
    eventBus.dispatch(EVENT_RESET_PASSWORD_CLOSE, {});
  }

  render() {
    const { isModalOpen } = this.props;
    const { password, errorMessage, retypePassword } = this.state;
    return (
      <React.Fragment>
        {isModalOpen && <ModalPopup onCloseModal={this.onCloseResetModal}>
          <ModalHeader title="Reset Password" onCloseModal={this.onCloseResetModal} isSkip={true} />
          {errorMessage && <div
            className="alert alert-danger"
            role="alert">{errorMessage}</div>}
          <form>
            <div className="row mb-5">
              <div className="col"> Please enter your new password with which you would like to access</div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <PasswordInput
                  id="password"
                  showEyeIcon
                  value={password}
                  defaultValue={password}
                  onChange={(value) => this.setState({ password: value })}
                  isPasswordValid={passwordValid => this.setState({ passwordValid })} />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <PasswordInput
                  id="RetypePassword"
                  showEyeIcon
                  hidePopover
                  className="form-control custom-field"
                  type="password"
                  name="password"
                  placeholder="Retype Password"
                  defaultValue={retypePassword}
                  value={retypePassword}
                  error={retypePassword !== '' && password !== retypePassword ? Constants.REGISTER.error.passkeyNotMatch : false}
                  onChange={(value) => this.setState({ retypePassword: value })}
                />
              </div>
            </div>

            <div className="text-center pt-4">
              <button type="button" data-bs-toggle="modal" data-bs-target="#verify-modal" className="btn btn-dark big-btn w-100 px-5 text-uppercase" id="reset" onClick={() => this.closeResetModal()}>Update Password</button>
            </div>

          </form>
        </ModalPopup>}
      </React.Fragment>

    );
  }
}

export default connect(null, {
  updateNewPassword: Actions.updatePassword

})(withRouter(ResetPassword));
