const NAME = "ORDERS_LIST";
export const ORDERS_LIST = `${NAME}/ORDERS_LIST`;
export const ORDERS_LIST_SUCCESS = `${NAME}/ORDERS_LIST_SUCCESS`;
export const ORDERS_LIST_FAILURE = `${NAME}/ORDERS_LIST_FAILURE`;

export const SEND_TO_MAIL = `/SEND_TO_MAIL`;
export const SEND_TO_MAIL_SUCCESS = `/SEND_TO_MAIL_SUCCESS`;
export const SEND_TO_MAIL_FAILURE = `/SEND_TO_MAIL_FAILURE`;

export const orderList = store => store[NAME].orderList
export const getOrdersList = (params) => {
    return {
        type: ORDERS_LIST,
        params
    }
}

export const getOrdersListSuccess = (data) => {
    return {
        type: ORDERS_LIST_SUCCESS,
        data
    }
}

export const getOrdersListFailure = (error) => {
    return {
        type: ORDERS_LIST_FAILURE,
        error
    }
}



export const sendToEmail = (data,callback) => {
    return {
        type: SEND_TO_MAIL,
        data,
        callback
    }
}

export const sendToEmailSuccess = (response,data) => {
    return {
        type: SEND_TO_MAIL_SUCCESS,
        response,
        data
    }
}

export const sendToEmailFailure = (error) => {
    return {
        type: SEND_TO_MAIL_FAILURE,
        error
    }
}