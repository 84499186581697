import Actions from '../action'
const initialState = {
    isLoading:false,
    error:false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.FRANCHISE_MAIL: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FRANCHISE_MAIL_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.FRANCHISE_MAIL_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
