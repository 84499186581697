import React, { Component } from "react";
import Constants from "../../Utility/Constants";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import ProfileAddressPopUp from "../../component/profileAddressPopUp";
import routes from "../../config/routes";
import EmptyPage from "../../component/emptyPage";
import Alert from '../../component/alert'
let isAgeRestricted = false
class ChooseAddress extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(this.props.location.search);
    let rewardsApplied = urlParams.get("rewardsApplied");
    rewardsApplied = rewardsApplied === "true";

    this.state = {
      addAddressModal: false,
      editAddressModal: false,
      fields: {
        active: false
      },
      errors: {},
      addressType: "2",
      rewardsApplied,
      selectedAddress: Constants.STORE_ADDRESS,
      daxbotInfo: null,
      daxBotError: null,
      alertModal: false,
      params: null,
      response_message: null,
      addressModal: false,
      oldAddress: null,
      isOpenDeliveryCheck: false,
      finalAmount: null
    };
  }

  componentDidMount() {
    const callback = (res) => {
      if (res?.response_code === 0) {
        this.handleDefaultAddressChange(res?.response);
      }
    }
    this.props.getPimCartList();
    this.props.getAddress(callback);
    this.props.entryPin(null)
    this.props.forgetPin(false)
    this.props.getStoreList()
  }


  handleDefaultAddressChange(params) {
    if (this.state.addressType === "2") {
      this.setState({ daxBotError: null });
      this.selectDeliveryAddress(Constants.STORE_ADDRESS);
    } else {
      if (params && params[0]) {
        this.selectDeliveryAddress(params && params[0]);
      } else if (params?.length === 0) {
        this.setState({ selectedAddress: null });
      }
    }
  }

  onEditAddress(obj) {
    let { fields } = this.state;
    fields["doorNo"] = obj.street;
    fields["landmark"] = obj.landmark;
    fields["addressId"] = obj.id;
    fields["mobileNumber"] = obj.mobileNumber;
    fields["lat"] = obj.latitude;
    fields["lng"] = obj.longitude;
    fields["active"] = obj?.active;
    fields['formattedAddress'] = obj?.formattedAddress
    fields['addressType'] = obj?.addresstype?.id
    this.setState({ editAddressModal: true, fields });
  }
  onOpenAlertModal(addressId, index) {
    this.setState({ addressModal: !this.state.addressModal, addressId, index })
  }

  onDeleteAddress = () => {
    const { selectedAddress, addressId } = this.state;
    const callback = (response) => {
      if (response && response.response_code === 0) {
        toast.success("Address deleted successfully.");
        const selectedAddressDelete = selectedAddress && selectedAddress?.id === addressId;
        if (selectedAddressDelete) {
          this.setState({
            addressModal: false,
            errorMessage: false,
            daxbotInfo: null,
            daxBotError: null,
            selectedAddress: null
          });
        } else {
          this.setState({
            addressModal: false,
            errorMessage: false,
          });
        }


      } else {
        toast.error(`${response?.response_message}`);
      }
    };
    let params = {
      addressId,
    };
    this.props.deleteAddress(params, callback);
  };

  handleAddressChange() {
    if (this.state.addressType === "2") {
      this.setState({ daxBotError: null, oldAddress: this.state.selectedAddress });
      this.selectDeliveryAddress(Constants.STORE_ADDRESS);
    } else {
      const { addressDetails } = this.props.getAddressDetails;
      if (addressDetails && addressDetails[0]) {
        this.selectDeliveryAddress(this.state.oldAddress);
      } else if (addressDetails?.length === 0) {
        this.setState({ selectedAddress: null });
      }
    }
  }

  componentDidUpdate(nextProps) {
    const { addressDetails } = nextProps?.getAddressDetails;
    const { selectedAddress } = this.state;
    if (!selectedAddress && addressDetails?.length > 0) {
      this.selectDeliveryAddress(addressDetails?.[0]);
    }
  }

  selectDeliveryAddress(item = {}) {
    this.setState({ selectedAddress: item, daxBotError: null }, () => {
      if (this.state.addressType === '1') {
        const params = {
          lat: item?.latitude,
          lon: item?.longitude,
        };
        this.daxbotAvailability(params);
      }

    });
  }

  daxbotAvailability(params) {
    this.setState(
      {
        daxbotInfo: null,
      },
      () => {
        const callback = (res) => {
          if (res?.response_code === 0) {
            this.setState({ daxBotError: null, daxbotInfo: res?.response });
          } else {
            this.setState({
              daxbotInfo: null,
              daxBotError: res?.response_message,
            });
          }
        };
        this.props.daxbotAvailabilty(params, callback);
      }
    );
  }

  addressDetails = () => {
    this.setState({ addAddressModal: true });
  };

  handleSubmitValidation(fields) {
    let isFormValid = true;
    fields.forEach((field) => {
      if (!this.handleValidation(field)) {
        isFormValid = false;
      }
    });
    return isFormValid;
  }

  handleValidation(field) {
    let { fields, errors } = this.state;
    if (field === "mobileNumber") {
      if (!fields[field] || fields[field].length !== 10) {
        if (fields[field] && fields[field].length !== 10) {
          errors[field] = Constants.CHECKOUT.errorMessages.mobileNumberLength;
        } else errors[field] = Constants.CHECKOUT.errorMessages.mobileNumber;
        this.setState({ errors });
        return false;
      }
    }
    errors[field] = "";
    this.setState({ errors });
    return true;
  }

  onUpdateAddressCloseModal() {
    this.setState({
      editAddressModal: false,
      fields: {
        active: false
      },
      errors: {},
      errorMessage: "",
    });
  }

  onCreateAddressCloseModal() {
    this.setState({
      addAddressModal: false,
      fields: {
        active: false
      },
      errors: {},
      errorMessage: "",
    });
  }

  createAddress(isCreate) {
    if (isCreate) {
      let { fields } = this.state;
      let { formattedAddress, landmark, mobileNumber, lat, lng, doorNo, active, addressType } = fields;
      const callback = (res) => {
        if (res && res.response_code === 0) {
          const currentAddress = res && res.response?.reverse()
          this.selectDeliveryAddress(currentAddress && currentAddress[0])
          this.onCreateAddressCloseModal();
          toast.success("address created successfully");
        } else if (res && res.error) {
          this.setState({ errorMessage: res?.response_message });
        } else {
          this.setState({ errorMessage: res?.response_message });
        }
      };
      let error = null;
      if (!formattedAddress) {
        error = 'Please choose a location';
      } else if (!mobileNumber) {
        error = Constants.CHECKOUT.errorMessages.mobileNumber;
      } else if (mobileNumber?.length !== 10) {
        error = Constants.CHECKOUT.errorMessages.mobileNumberLength;
      } else if (!addressType) {
        error = 'Please enter residence type';
      } else {
        error = ''
      }

      this.setState({ errorMessage: error });
      if (error) {
        return;
      }
      let params = {
        formattedAddress,
        street: doorNo,
        landmark,
        mobileNumber,
        latitude: lat,
        longitude: lng,
        active: active,
        addressType: addressType
      };
      this.props.addAddress(params, callback);
    } else {
      this.onCreateAddressCloseModal();
    }
  }

  updateAddress(isUpdate) {
    if (isUpdate) {
      let { fields } = this.state;
      let {
        landmark,
        addressId,
        mobileNumber,
        lat,
        lng,
        active,
        formattedAddress,
        doorNo,
        addressType
      } = fields;
      const callback = (res) => {
        const { addressType } = this.props.getAddressType || {}
        if (res && res.response_code === 0) {
          let selectedAddress = { ...fields }
          selectedAddress.id = fields.addressId
          selectedAddress.latitude = fields.lat
          selectedAddress.longitude = fields.lng
          selectedAddress.street = fields.doorNo
          selectedAddress.addresstype = addressType && addressType.find((o) => o?.id === parseInt(fields?.addressType))
          this.selectDeliveryAddress(selectedAddress)
          this.onUpdateAddressCloseModal();
          toast.success("address updated successfully");
        } else if (res && res.error) {
          this.setState({ errorMessage: res.response_message });
        } else {
          this.setState({ errorMessage: res.response_message });
        }
      };
      let error = null;
      if (!formattedAddress) {
        error = 'Please choose a location';
      } else if (!mobileNumber) {
        error = Constants.CHECKOUT.errorMessages.mobileNumber;
      } else if (mobileNumber?.length !== 10) {
        error = Constants.CHECKOUT.errorMessages.mobileNumberLength;
      }
      else if (!addressType) {
        error = 'Please enter residence type';
      } else {
        error = ''
      }

      this.setState({ errorMessage: error });
      if (error) {
        return;
      }
      let params = {
        formattedAddress,
        street: doorNo,
        landmark,
        mobileNumber,
        latitude: lat,
        longitude: lng,
        active: active,
        addressId,
        addressType: addressType
      };
      this.props.updateAddress(params, callback);
    } else {
      this.onUpdateAddressCloseModal();
    }
  }

  handleChange(value, name) {
    let { fields } = this.state;
    fields[name] = value;
    this.setState({ fields }, () => {
      this.handleValidation(name);
    });
  }

  handleDefaultAddress = (e) => {
    const { checked, name } = e?.target || {};
    let { fields } = this.state;
    fields[name] = checked;
    this.setState({ fields });
  };


  initiateOrders(params) {
    this.props.initializeAuthPaymentAction(params, (resp) => {
      if (resp?.response_code === 0) {
        let data = resp?.response;
        this.setState({ params: '', response_message: '' })
        if (data && data?.invoiceId) {
          toast.success(`Order successfully placed`);
          this.props.history.replace(
            routes.ORDER_SUMMARY.replace(":invoiceId", data?.invoiceId)
          );
          this.props.getPimCartList();
          return;
        }

      } else if (resp.response_code === 101) {
        params = {
          ...params,
          fulfillmentRestrictionOverridden: true,
        };
        this.setState({
          alertModal: true,
          params: params, response_message: resp?.response_message
        })
      } else if (resp?.response_code === 103) {
        const { transactionId } = resp?.response
        this.props.history.push(`/customercare/transactions/${transactionId}`)
      } else {
        toast.error(`${resp?.response_message}`);
      }
    })
  }

  checkDeliveryLocations(finalAmount) {
    this.setState({ finalAmount })
    const { addressType, selectedAddress } = this.state;
    const { pickupEntrance } = selectedAddress?.addresstype || {}
    if (addressType === '1' && pickupEntrance) {
      this.setState({ isOpenDeliveryCheck: true })
    } else {
      this.createOrders(finalAmount)
    }

  }

  closePickupModal() {
    this.setState({ isOpenDeliveryCheck: false })

  }

  createOrders(finalAmount) {
    const { selectedAddress, addressType, rewardsApplied } = this.state;
    const deliveryAddress = JSON.stringify(
      addressType === "2" ? Constants.STORE_ADDRESS : selectedAddress
    );
    this.setState({ isOpenDeliveryCheck: false })

    if ((selectedAddress && addressType === "1") || addressType === "2") {
      let params = {
        natureOfOrder: addressType,
        address: deliveryAddress,
        rewardsApplied: rewardsApplied,
      };
      if (this.props.pimCartDetails?.voucher) {
        params["couponCode"] = this.props.pimCartDetails?.voucher;
      }
      if (this.props.pimCartDetails?.bistroComments) {
        params["bistroComment"] = this.props.pimCartDetails?.bistroComments;
      }
      if (Number(finalAmount) > 0) {
        const isGuestCustomer = localStorage.getItem("isGuestCustomer")
        if (isGuestCustomer) {
          params.isGuestCustomer = true
          this.initiateOrders(params)
        } else {
          this.props.saveCheckoutParams(params)
          this.props.history.push(routes.CHECKOUT_PAYMENT)
        }

      } else {
        this.initiateOrders(params)
      }
    }
  }

  initiateLateOrders() {
    const { params } = this.state || {}
    this.initiateOrders(params)
  }
  cancelLateOrders() {
    this.setState({ alertModal: false })
  }
  openMapURL(lat, lng) {
    window.open("https://maps.google.com?q=" + lat + "," + lng, "_blank");
  }
  render() {
    const {
      selectedAddress,
      editAddressModal,
      addAddressModal,
      fields,
      errors,
      errorMessage,
      addressType,
      rewardsApplied,
      daxBotError,
      daxbotInfo,
      alertModal,
      response_message,
      addressModal,
      isOpenDeliveryCheck
    } = this.state;
    const { addressDetails } = this.props.getAddressDetails || {};
    const { getStoreList } = this.props.getStoreAvailability || {};
    const { storeAddress } = getStoreList
    let storeAddressData = {}
    try {
      storeAddressData = JSON.parse(storeAddress)
    } catch (e) {
      storeAddressData = {}
    }
    const { response } = daxbotInfo || {}
    const revealAddress = addressDetails && addressDetails.find((o) => o?.id === selectedAddress?.id)
    let {
      pimCart: { grandTotal, deliveryDiscount, deliveryFee, rewardAmount, products = [] },
      voucherInfo,
    } = this.props.pimCartDetails || {};

    let cartTotalPrice = voucherInfo ? voucherInfo.grandTotal : grandTotal;
    cartTotalPrice =
      rewardsApplied && rewardAmount && cartTotalPrice >= rewardAmount
        ? cartTotalPrice - rewardAmount
        : cartTotalPrice;

    const revealRestrictedProducts = products && products.find((o) => o.ageRestricted === true)
    if (addressType === '1' && revealRestrictedProducts) {
      isAgeRestricted = true
    } else {
      isAgeRestricted = false
    }
    return (
      <div className="wrap py-50 mb-5">
        <div className="row gx-5">
          <div className="col-12 col-md-8">
            <h2 className="section-heading mb-5">Choose Delivery</h2>
            <ul className="nav nav-tabs" id="user-delivery" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link text-uppercase ${addressType === "1" ? "me-1 active" : ""
                    }`}
                  id="deliver-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#deliver"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() =>
                    this.setState({ addressType: "1" }, () => {
                      this.handleAddressChange();
                    })
                  }
                >
                  Home Delivery
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link text-uppercase ${addressType === "2" ? "me-1 active" : ""
                    }`}
                  id="pickup-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#pickup"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  onClick={() =>
                    this.setState({ addressType: "2" }, () => {
                      this.handleAddressChange();
                    })
                  }
                >
                  <span className="d-none d-lg-inline">Store</span> Pick Up
                </button>
              </li>
            </ul>
            <div className="tab-content" id="user-delivery-content">
              {addressType === "1" ? (
                <div
                  className="tab-pane py-5 fade show active"
                  id="deliver"
                  role="tabpanel"
                  aria-labelledby="deliver-tab"
                >
                  <h3 className="font18 mb-4">Select Address</h3>

                  <form name="">
                    <div
                      id="addr-grid"
                      className="row row-cols-1 row-cols-md-3 g-4 mb-4"
                    >
                      {addressDetails &&
                        addressDetails.map((obj, i) => (
                          <div key={obj.id} className="col">
                            <div className="card rounded-0">
                              <div
                                className="card-body pb-4"
                              >
                                <div className="row mb-2">
                                  <div
                                    className="col">
                                    <input
                                      type="radio"
                                      name="addr-type"
                                      id="add"
                                      className="mb-3"
                                      onChange={() => this.selectDeliveryAddress(obj)}
                                      checked={obj.id === selectedAddress?.id}
                                    />
                                  </div>
                                  <div
                                    className="col-auto text-end">
                                    <b>{obj.addresstype?.type ? obj.addresstype?.type : ''}</b>
                                  </div>
                                </div>
                                <label htmlFor="addr1">
                                  <p className="card-text">
                                    {(obj.street ? obj.street + ',' : '') + (obj.landmark ? obj.landmark + ',' : '') + obj.formattedAddress}
                                  </p>
                                </label>
                              </div>
                              <div className="card-footer text-end">
                                <div className="row">
                                  {
                                    obj?.active && <div className="col text-start">
                                      <small className="text-dark fw-bold">Default</small>
                                    </div>
                                  }
                                  <div className="col text-end">

                                    <small className="text-muted">
                                      <snap
                                        data-bs-toggle="modal"
                                        data-bs-target="#edit-addr-modal"
                                        className="text-dark text-link"
                                        id="EditAddress"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          this.onEditAddress(obj);
                                        }}
                                      >
                                        Edit
                                      </snap>
                                      |
                                      <snap
                                        className="text-dark text-link"
                                        id="DeleteAddress"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          this.onOpenAlertModal(obj.id, i);
                                        }}
                                      >
                                        Delete
                                      </snap>
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <button
                      className="btn btn-outline-dark text-uppercase px-5 mb-5"
                      type="button"
                      id="addressDetails"
                      onClick={() => this.addressDetails()}
                    >
                      + Add New Address
                    </button>
                  </form>
                </div>
              ) : (
                <div
                  className="tab-pane py-5 fade show active"
                  id="pickup"
                  role="tabpanel"
                  aria-labelledby="pickup-tab"
                >
                  <h4 className="font16 fw-bold mb-2">Store Address</h4>
                  <p className="font16 mb-2">
                    {storeAddressData ?
                      <span>{storeAddressData?.branch_name ? `${storeAddressData?.branch_name},` : ''} {storeAddressData?.street_name ? `${storeAddressData?.street_name},` : ''} {storeAddressData?.landmark || ''} {storeAddressData?.location ? `${storeAddressData?.location},` : ''} {storeAddressData?.zip_code ? `${storeAddressData?.zip_code}` : ''}</span>
                      : <span>2nd Floor, M-2, Type II, Dr. VSI Estate, Thiruvanmiyur, Chennai, Tamil Nadu 600041</span>}
                  </p>
                  <a className="fw-bold mb-4"
                    rel="noopener noreferrer"
                    href={"https://www.google.com/maps/place/Proglint+Software+Solutions/@12.97827,80.2479331,17z/data=!3m1!4b1!4m5!3m4!1s0x3a525d9cefda9b4d:0x9ca09b258703413c!8m2!3d12.9782648!4d80.2501218"} target="_blank">View on Map</a>

                  {getStoreList && getStoreList?.storeOpeningTime?.map((o) => {
                    return <div className="row mb-2">
                      <div className="col">{o?.dayOfWeek}</div>
                      <div className="col">{o?.startTime} - {o?.endTime} </div>
                    </div>
                  })}

                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-4">
            <h3 className="font16 fw-bold mb-5">Proceed to Payment </h3>

            {
              (isAgeRestricted && addressType === '1') && (
                <div
                  className="alert alert-primary mb-4 border-0 rounded-0 font12"
                  role="alert"
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <img src={require("../../asset/img/info.svg")} alt="!" />
                    </div>
                    <div className="col">
                      {Constants.ADDRESS.ageVerifyProducts}
                    </div>
                  </div>
                </div>
              )
            }
            {
              (!isAgeRestricted && daxBotError && addressType === '1') && (
                <div
                  className="alert alert-primary mb-4 border-0 rounded-0 font12"
                  role="alert"
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <img src={require("../../asset/img/info.svg")} alt="!" />
                    </div>
                    <div className="col">
                      {daxBotError}
                    </div>
                  </div>
                </div>
              )
            }
            {(!isAgeRestricted && addressType === '1' && selectedAddress && !daxBotError) ?
              <div className="row mb-2">
                {
                  deliveryDiscount ? (
                    <>
                      <div
                        className="col">{Constants.CHECKOUT.deliveryFee}</div>
                      <div
                        className="col"
                        style={{ textDecoration: 'line-through' }}>
                        {Constants.COMMONS.usd}{deliveryDiscount?.toFixed(2)}
                      </div>
                      <div
                        className="col text-end"> {`${Constants.COMMONS.usd}${(deliveryFee - deliveryDiscount)?.toFixed(2)}`}
                      </div>
                    </>
                  ) : <><div
                    className="col">{Constants.CHECKOUT.deliveryFee}</div>
                    <div
                      className="col text-end"> {`${Constants.COMMONS.usd}${deliveryFee?.toFixed(2)}`}
                    </div> </>
                }
              </div> : ''
            }
            {
              (addressType === '1' && revealAddress && !isAgeRestricted) && (
                <div className="row mb-2">
                  {response?.time_to_arrival ? <>
                    <div
                      className="col">ETA</div>
                    <div
                      className="col text-end">
                      {(response.time_to_arrival)?.toFixed(2) + ' mins'}
                    </div>
                  </> : ''}
                </div>
              )
            }
            <button
              id="proceedToPayment"
              className="btn btn-dark big-btn next-btn w-100 text-uppercase mb-5"
              name=""
              type="button createOrders"
              onClick={() => this.checkDeliveryLocations(
                selectedAddress && addressType === '1'
                  ? (cartTotalPrice + (deliveryFee - deliveryDiscount))?.toFixed(2)
                  : cartTotalPrice?.toFixed(2)
              )}
              disabled={((addressType === '1' && daxBotError ? true : false) || (addressType === '1' && !daxbotInfo)) || (!selectedAddress)}
            >
              Proceed to pay <b>
                {Constants.COMMONS.usd}
                {selectedAddress && addressType === '1'
                  ? (cartTotalPrice + (deliveryFee - deliveryDiscount))?.toFixed(2)
                  : cartTotalPrice?.toFixed(2)}
              </b>
              <img
                src={require("../../asset/img/right-arrow.svg")}
                alt=""
                className="ms-2"
              />
            </button>
          </div>
        </div>
        {addAddressModal && (
          <ProfileAddressPopUp
            id="ProfileAddressPopUp"
            isModalOpen={addAddressModal}
            label="Add Address"
            button="Create Address"
            closeAddressModal={(value) => this.createAddress(value)}
            handleChange={(value, name) => this.handleChange(value, name)}
            handleDefaultAddress={this.handleDefaultAddress}
            fields={fields}
            errors={errors}
            errorMessage={errorMessage}
          />
        )}
        {editAddressModal && (
          <ProfileAddressPopUp
            id="ProfileAddressPopUp1"
            isModalOpen={editAddressModal}
            label="Update Address"
            button="Update"
            closeAddressModal={(value) => this.updateAddress(value)}
            handleChange={(value, name) => this.handleChange(value, name)}
            handleDefaultAddress={this.handleDefaultAddress}
            fields={fields}
            errors={errors}
            errorMessage={errorMessage}
          />
        )}
        {
          selectedAddress === '1' && addressDetails && addressDetails.length === 0 && <EmptyPage
            content={Constants.NOT_FOUND.address}
          />
        }
        {
          alertModal && <Alert
            id="Alert"
            description={response_message}
            proceed={Constants.proceed}
            cancel={Constants.cancel}
            onSuccess={() => this.initiateLateOrders()}
            onFailure={() => this.cancelLateOrders()}
          />
        }

        {addressModal && <Alert
          id="AlertInfo"
          title={"Info"}
          description={'Do you want to delete the record?'}
          proceed={'Delete'}
          cancel={'Cancel'}
          onSuccess={() => this.onDeleteAddress()}
          onFailure={() => this.onOpenAlertModal()}
        />}

        {
          isOpenDeliveryCheck && <Alert
            id="AlertInfo"
            title={"Attention"}
            description={'By clicking Agree, You agreed to pickup the order from the entrance to your building'}
            proceed={'Agree'}
            cancel={'Cancel'}
            onSuccess={() => this.createOrders(this.state.finalAmount)}
            onFailure={() => this.closePickupModal()}
          />
        }

      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getAddressDetails: Actions.getAddressDetails(state),
    pimCartDetails: Actions.getPimCartDetails(state),
    getUserDetail: Actions.getUserDetail(state),
    getStoreAvailability: Actions.getStoreList(state),
    getAddressType: Actions.getAddressType(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAddress: Actions.getAddress,
    deleteAddress: Actions.deleteAddress,
    updateAddress: Actions.updateAddress,
    addAddress: Actions.addAddress,
    initializeAuthPaymentAction: Actions.initializeAuthPaymentAction,
    daxbotAvailabilty: Actions.daxbotCheckAvailability,
    getPimCartList: Actions.getPimCartList,
    getAddressSuccess: Actions.getAddressSuccess,
    saveCheckoutParams: Actions.saveCheckoutParams,
    entryPin: Actions.entryPin,
    forgetPin: Actions.forgetPin,
    getStoreList: Actions.storeList,
    createOrder: Actions.createOrder,
  })(ChooseAddress)
);
