import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doPut, doAuthPost, doAuthGet } from '../../service/index'
import { UPDATE_ACCOUNT_NAME, UPDATE_ACCOUNT_PASSWORD, GET_USER_DETAILS } from '../../config/api_endpoint';

export function* updateAccountName({ params, callback }) {
    try {
        const response = yield doPut(UPDATE_ACCOUNT_NAME, params);
        yield put(Actions.updateFirstAndLastNameSuccess(response));
        callback && callback(response)
    } catch (error) {
        callback && callback(error)
        yield put(Actions.updateFirstAndLastNameFailure(error));
    }
}

export function* updateAccountPassword({ params, callback }) {
    try {
        const response = yield doAuthPost(UPDATE_ACCOUNT_PASSWORD, params);
        callback && callback(response)
        if (response && response.response_code === 0) {
            yield put(Actions.updateAccountPasswordSuccess(response));
            yield put(Actions.loginSuccess(response.response))
        } else {
            yield put(Actions.updateAccountPasswordFailure());
        }

    } catch (error) {
        callback && callback(error)
        yield put(Actions.updateAccountPasswordFailure(error));
    }
}

export function* getUserDetails({ callback }) {
    try {
        const response = yield doAuthGet(GET_USER_DETAILS);
        callback && callback(response)
        if (response && response.response_code === 0) {
            yield put(Actions.getUserDetailSuccess(response));
        } else {
            yield put(Actions.getUserDetailFailure());
        }

    } catch (error) {
        yield put(Actions.getUserDetailFailure(error));
    }
}

export default function* accountWatcher() {
    yield all([
        takeLatest(Actions.UPDATE_FIRST_LAST_NAME, updateAccountName),
        takeLatest(Actions.UPDATE_ACCOUNT_PASSWORD, updateAccountPassword),
        takeLatest(Actions.GET_USER_DETAIL, getUserDetails)
    ]);
}