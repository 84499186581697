import Actions from '../../action'
const initialState = {
    isLoading: false,
    orderDetails: {},
    isNewOrder: false,
    error: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.ORDER_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ORDER_DETAILS_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                orderDetails: action.data.response,
                error: false
            };
        }
        case Actions.ORDER_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.SET_IS_NEW_ORDER_STATUS:
            return {
                ...state,
                isNewOrder: action.data
            };
        default:
            return state;
    }
};
