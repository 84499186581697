export const DELETE_ADDRESS = `DELETE_ADDRESS`;
export const DELETE_ADDRESS_SUCCESS = `DELETE_ADDRESS_SUCCESS`;
export const DELETE_ADDRESS_FAILURE = `DELETE_ADDRESS_FAILURE`;

export const deleteAddress = (params, callback) => {
    return {
        type: DELETE_ADDRESS,
        params,
        callback
    }
}

export const deleteAddressSuccess = (data) => {
    return {
        type: DELETE_ADDRESS_SUCCESS,
        data
    }
}

export const deleteAddressFailure = (error) => {
    return {
        type: DELETE_ADDRESS_FAILURE,
        error
    }
}
