const NAME = "CLIENT_CREDENTIALS";
export const CLIENT_CREDENTIALS = `${NAME}/CLIENT_CREDENTIALS`;
export const CLIENT_CREDENTIALS_SUCCESS = `${NAME}/CLIENT_CREDENTIALS_SUCCESS`;
export const CLIENT_CREDENTIALS_FAILURE = `${NAME}/CLIENT_CREDENTIALS_FAILURE`;


export const getClientCredentials = store => store[NAME]
export const clientCredentials = (callback) => {
    return {
        type: CLIENT_CREDENTIALS,
        callback
    }
}

export const clientCredentialsSuccess = (data) => {
    return {
        type: CLIENT_CREDENTIALS_SUCCESS,
        data
    }
}

export const clientCredentialsFailure = (error) => {
    return {
        type: CLIENT_CREDENTIALS_FAILURE,
        error
    }
}