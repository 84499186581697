export const EVENT_SIGN_IN_OPEN = "signInOpen";
export const EVENT_SIGN_IN_CLOSE = "signInClose";
export const EVENT_SIGN_UP_OPEN = "signUpOpen";
export const EVENT_SIGN_UP_CLOSE = "signUpClose";
export const EVENT_RESEND_EMAIL_OPEN = "resendEmailOpen";
export const EVENT_RESEND_EMAIL_CLOSE = "resendEmailClose";
export const EVENT_VERIFY_OTP_OPEN = "verifyOpen";
export const EVENT_VERIFY_OTP_CLOSE = "verifyClose";
export const EVENT_FORGET_PASSWORD_OPEN = 'forgetOpen';
export const EVENT_FORGET_PASSWORD_CLOSE = 'forgetClose';
export const EVENT_LOGIN_VERIFY_OTP_OPEN = 'loginVerifyOpen';
export const EVENT_LOGIN_VERIFY_OTP_CLOSE = 'loginVerifyClose';
export const EVENT_RESET_PASSWORD_OPEN = "resetOpen"
export const EVENT_RESET_PASSWORD_CLOSE = 'resetClose';
export const EVENT_ADD_TO_CART_OPEN = 'addToCart';
export const EVENT_FEEDBACK_OPEN = 'feedbackOpen';
export const EVENT_FRANCHISE_ENQUIRY_OPEN = 'franchiseEnquiryOpen';

export default {
    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail));
    },
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        document.removeEventListener(event, callback);
    },
};
