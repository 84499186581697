import React from 'react'
import { withRouter } from 'react-router-dom'

export class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div className="section">
                <div className="container" style={{ height: "300px", alignItems: 'center' }}>
                    <h4 className="text-center">No Data</h4>
                </div>
            </div>
        )
    }
}

export default withRouter(Contact)