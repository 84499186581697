import Actions from '../action'
const initialState = {
    isLoading: false,
    isTransLoading: undefined,
    error: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.CREATE_GUEST_CUSTOMER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CREATE_GUEST_CUSTOMER_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.CREATE_GUEST_CUSTOMER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.GET_GUEST_TRANSACTION_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_GUEST_TRANSACTION_DETAILS_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.GET_GUEST_TRANSACTION_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
