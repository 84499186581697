import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../../redux/action";
import Constants from "../../Utility/Constants";
import AddToCartButton from "../../component/AddToCartButton";
import { getQueryParams } from "../../Utility/validation";
import AddOnModal from "../../component/addon";
import AlertModal from "../../component/AlertModal";
import routes from "../../config/routes";
import { toast } from "react-toastify";
import NoSearch from '../../component/noSearch'
import EmptyPage from "../../component/emptyPage";
import CartQuantityAdjuster from "../../component/CartQuantityAdjuster";
import Placeholder from '../../asset/img/no-preview.png'
import RepeatCustomization from "../../component/repeatCustomization";
import BreadCrumbs from './BreadCrumbs';
import { getRandomNumber } from "../../Utility/Utils";

export class BistroProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subCategoryList: null,
      productsTitle: "Products",
      subCategoryTitle: null,
      openAddonModal: false,
      productInfo: {},
      errorMessage: null,
      itemsToShow: 8,
      expanded: false,
      isOpenDrawer: false,
      isDropDown: false,
      isOpenRepeatCustomModal: false,
      currentPage: 0,
      categoryImage: null
    };
  }

  _renderEmptyContent() {
    const queryPrams = getQueryParams(this.props.location.search);
    const search = queryPrams.get("search");

    return search ? <NoSearch /> : <EmptyPage
      content={Constants.NOT_FOUND.filter}
    />
  }

  invokeApiCall() {
    const { getProductGroup, location } = this.props;
    const { currentPage } = this.state;
    const queryPrams = getQueryParams(location.search);
    const categoryId = queryPrams.get("categoryId");
    const subCategoryId = queryPrams.get("subCategoryId");
    const search = queryPrams.get("search");
    let groupIds = 0
    let redirect = false;

    if (getProductGroup) {
      let revealBistro = getProductGroup?.findIndex(
        (itm) => itm?.groupName === Constants.PRODUCT_GROUP.BISTRO
      );
      if (getProductGroup && getProductGroup[revealBistro]) {
        const getCurrentGroup = getProductGroup[revealBistro]
        groupIds = getCurrentGroup && getCurrentGroup.groupId;
      }
    }

    if (!categoryId && !search && location.pathname !== '/searchAll') {
      redirect = true;
    } else if (categoryId) {
      const allCategoryList = getProductGroup
        ?.map((m) => (m.categoryList ? m.categoryList : []))
        .flat();
      if (!allCategoryList?.length) {
        redirect = true;
      }
      const category = allCategoryList?.find(
        (c) => c.categoryId === parseInt(categoryId, 10)
      );
      if (category?.categoryName) {
        this.setState({ productsTitle: category.categoryName, categoryImage: category?.picture && category?.picture + '?$' + getRandomNumber() });
      } else {
        redirect = true;
      }
      if (subCategoryId) {
        const subCat = category?.subcategoryList?.find(
          ({ subcategoryId: subId }) => subId === parseInt(subCategoryId, 10)
        );
        if (subCat) this.setState({ subCategoryTitle: subCat.subcategoryName, categoryImage: subCat?.picture && subCat?.picture + '?$' + getRandomNumber() });
        else redirect = true;
      }
    }

    if (redirect) {
      this.props.history.replace("/");
      return;
    }

    let params = {
      searchKey: search || "",
      storeId: "1",
      categoryId: categoryId ? categoryId : "",
      subCategoryId: subCategoryId ? subCategoryId : "",
      size: 6,
      page: currentPage,
      groupId: groupIds
    };

    if (!search) {
      const allCategoryList = getProductGroup
        ?.map((m) => (m.categoryList ? m.categoryList : []))
        .flat();
      if (allCategoryList?.length > 0) {
        const category = allCategoryList.find(
          (c) => c.categoryId === parseInt(categoryId, 10)
        );
        this.setState(
          {
            subCategoryList: category?.subcategoryList,
          },
          () => {
            this.props.bistroPimProducts(params);
          }
        );
      }
    } else {
      this.props.bistroPimProducts(params);
    }
  }

  componentDidMount() {
    const { getProductGroup } = this.props;
    if (getProductGroup && getProductGroup.length === 0) {
      this.props.productGroupInformationActions();
    }
    this.invokeApiCall();
  }
  componentWillUnmount() {
    this.props.loaderOff()
  }
  pimCartCallback = (response) => {
    const { response_code } = response;
    if (response_code !== 0) {
      toast.error(response?.response_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  addToCart(o) {
    const { hasAddOns } = o;
    if (hasAddOns) {
      this.setState({ openAddonModal: true, productInfo: o });
    } else {
      const params = {
        productSku: o.sku,
        quantity: 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: true,
      };
      this.props.createPimCart(params, this.pimCartCallback);
    }
  }

  quantityIncreament(obj, cartDetails) {
    if (obj?.hasAddOns) {
      this.setState({ productInfo: obj, isOpenRepeatCustomModal: true });
    } else {
      let getCart = cartDetails && cartDetails.find((o) => o.sku === obj.sku);
      let { quantity } = getCart || {};
      if (quantity) {
        const params = {
          productSku: getCart.sku,
          quantity: getCart.quantity + 1,
          storeId: 1,
          addOnIds: [],
          bistroProduct: true,
          cartItemId: getCart?.cartItemId,
        };
        this.props.updatePimCart(params, this.pimCartCallback);
      } else {
        const params = {
          productSku: getCart.sku,
          quantity: getCart.quantity,
          storeId: 1,
          addOnIds: [],
          bistroProduct: true,
        };
        this.props.updatePimCart(params, this.pimCartCallback);
      }
    }
  }
  quantityDecrement(obj, cartDetails) {
    const { hasAddOns } = obj || {};
    const count = cartDetails?.reduce((n, val) => {
      return n + (val.sku === obj.sku);
    }, 0);
    if (hasAddOns && count > 1) {
      this.setState({
        errorMessage: {
          productInfo: obj,
          message: Constants.PRODUCTS.cartMessage,
        },
      });
      return;
    }
    this.decreseQuantity(obj, cartDetails);
  }
  goToCartScreen() {
    this.props.history.push(routes.CHECKOUT);
  }
  decreseQuantity(obj, cartDetails) {
    let getCart = cartDetails && cartDetails.find((o) => o.sku === obj.sku);
    let { quantity } = getCart || {};
    if (quantity) {
      const params = {
        productSku: getCart.sku,
        quantity: getCart.quantity - 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: true,
        cartItemId: getCart?.cartItemId,
      };
      this.props.updatePimCart(params, this.pimCartCallback);
    } else {
      const params = {
        productSku: getCart.sku,
        storeId: 1,
        bistroProduct: true,
        cartItemId: getCart?.cartItemId,
      };
      this.props.updatePimCart(params, this.pimCartCallback);
    }
  }

  quantitybtn(obj, cartDetails) {
    const itemFilter = cartDetails?.filter((f) => f?.sku === obj?.sku);
    const quantity = itemFilter?.reduce((acc, o) => {
      return o.quantity + acc;
    }, 0);
    if (quantity > 0) {
      return <CartQuantityAdjuster
        onMinus={() => this.quantityDecrement(obj, cartDetails)}
        onPlus={() => this.quantityIncreament(obj, cartDetails)}
        count={quantity}
      />;
    } else {
      return <AddToCartButton onClick={() => this.addToCart(obj)} />;
    }
  }

  onClickOfSubCategory(subId) {
    const { location } = this.props;
    const queryPrams = getQueryParams(location.search);
    const categoryId = queryPrams.get("categoryId");
    this.props.history.push(
      `/bistro/products?categoryId=${categoryId}&subCategoryId=${subId}`
    );
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      window.location.reload();
    }
  };

  showMore = () => {
    const { pimProducts } = this.props.getPimProducts || {};
    this.state.itemsToShow === 8
      ? this.setState({
        itemsToShow: pimProducts?.products.length,
        expanded: true,
      })
      : this.setState({ itemsToShow: 8, expanded: false });
  };
  navBistroPage(id) {
    this.props.history.push(routes.BISTRO_PRODUCTS_DETAILS.replace(":sku", id));
  }

  openSideDrawer() {
    this.setState({ isOpenDrawer: !this.state.isOpenDrawer })
  }
  openDropDown() {
    this.setState({ isDropDown: !this.state.isDropDown })
  }

  closeCustomModal() {
    this.setState({ isOpenRepeatCustomModal: false })
  }

  repeatCustom() {
    const { productInfo } = this.state
    const {
      pimCart: { products },
    } = this.props.getPimCartDetails;
    const revealCart = products && products?.filter((o) => o.sku === productInfo?.sku)
    let findLastItem = revealCart?.sort((a, b) => {
      return new Date(b.lastModified) - new Date(a.lastModified);
    });

    if (findLastItem?.length > 0) {
      let cartElement = findLastItem[0];
      const params = {
        productSku: cartElement.sku,
        quantity: cartElement.quantity + 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: true,
        cartItemId: cartElement?.cartItemId,
      };
      this.closeCustomModal()
      this.props.updatePimCart(params, this.pimCartCallback);
    }
  }

  chooseNew() {
    this.closeCustomModal()
    this.setState({ openAddonModal: true, })
  }

  render() {
    const queryPrams = getQueryParams(this.props.location.search);
    const search = queryPrams.get("search");
    const {
      subCategoryList,
      productsTitle,
      openAddonModal,
      productInfo,
      errorMessage,
      isOpenDrawer,
      isOpenRepeatCustomModal,
      categoryImage,
      subCategoryTitle
    } = this.state;
    const { pimProducts, isPimBistroLoading, hasLoadMore } = this.props.getPimProducts || {};
    const {
      pimCart: { products }
    } = this.props.getPimCartDetails;
    return (
      <React.Fragment>
        {!this.props.searchScreen && <section className="category-subnav bg-black">
          <div className="wrap">
            <div className="row pt-lg-5">
              <div className="col-auto">
                {
                  subCategoryTitle ? <div className="sub-category-hide"></div> : <img
                    src={categoryImage ? categoryImage : Placeholder}
                    alt="dairy category"
                    className="cat-thumb"
                  />
                }

              </div>
              <div className="col">
                <div className="row align-items-center my-3 cat-heading">
                  <div className="col">
                    <h1 className="text-white d-inline-block d-lg-block title-case">
                      {subCategoryTitle?.toLowerCase() || productsTitle?.toLowerCase()}
                    </h1>
                  </div>
                  {
                    !subCategoryTitle && <div className="col text-end d-lg-none" id="openSideDrawer" onClick={() => this.openSideDrawer()}>
                      <img
                        src={require("../../asset/img/grid-menu-icon.svg")}
                        alt="View Categories"
                        className="grid-icon"
                      />
                    </div>
                  }

                </div>

                <BreadCrumbs
                  subCategoryList={subCategoryList}
                  categoryName="Bistro"
                  productsTitle={productsTitle}
                  subCategoryTitle={subCategoryTitle} />
                <div className={`cat-offcanvas shadow ${isOpenDrawer ? 'show' : ''}`}>
                  <span className="close-cat-offcanvas" onClick={() => this.openSideDrawer()}>
                    close
                  </span>
                  <nav>
                    {subCategoryList?.map(
                      ({ subcategoryName, subcategoryId, index }) => {
                        return (

                          <span
                            className={`${subcategoryName === subCategoryTitle ? 'menu-active' : ''}cursor-pointer title-case`}
                            onClick={() =>
                              this.onClickOfSubCategory(subcategoryId)
                            }
                          >
                            {subcategoryName?.toLowerCase()}
                          </span>
                        );
                      }
                    )}
                  </nav>
                </div>
              </div>
              <div className="col-12 d-block d-md-none">
                <BreadCrumbs
                  subCategoryList={subCategoryList}
                  productsTitle={productsTitle}
                  subCategoryTitle={subCategoryTitle}
                  categoryName="Bistro"
                  isMobile={true} />
              </div>
            </div>
          </div>
        </section>}

        <div className="wrap py-35">
          <div className="row">
            {
              (!search) && <div className="col-12 col-lg-3 d-none d-lg-block">
                <div className="cat-list">
                  <nav>
                    {subCategoryList?.map(
                      ({ subcategoryName, subcategoryId, index }) => {
                        return (
                          <span
                            className={`${subcategoryName === subCategoryTitle ? 'menu-active' : ''} cursor-pointer title-case`}
                            onClick={() =>
                              this.onClickOfSubCategory(subcategoryId)
                            }
                          >
                            {subcategoryName?.toLowerCase()}
                          </span>
                        );
                      }
                    )}
                  </nav>
                </div>
              </div>
            }
            <div className={`${this.props.searchScreen ? 'col-12' : 'col-12 col-lg-9'}`}>
              <div className="row g-5 g-lg-4 mb-5 products-grid">
                {pimProducts?.response &&
                  pimProducts?.response?.map((o, index) => {
                    let {
                      productName,
                      imageUrl,
                      price,
                      productDescription,
                      sku,
                      hasAddOns,
                      ageRestricted
                    } = o;
                    return (
                      <div className="col-12 col-md-6 col-lg-4" key={sku + index}>
                        <div className="card card-body px-0 border-0">
                          <div
                            className="product-thumb"
                            onClick={() => this.navBistroPage(sku)}
                          >
                            <img
                              src={
                                imageUrl
                                  ? imageUrl + '?$' + getRandomNumber()
                                  : Placeholder
                              }
                              alt="1"
                              className="img-fluid text-link"
                            />
                            {ageRestricted && <span className="badge age-restrict">Age Restricted</span>}
                          </div>
                          <div className="d-flex flex-column mh-65">

                            <h2 className="font16 product-list title-case">{productName?.toLowerCase()}</h2>
                            {
                              productDescription ? <p className="txtgrey desc-wrap">{productDescription}</p> : null
                            }
                            <div className="row">
                              <div className="col-auto">
                                <strong className="font16">
                                  {Constants.COMMONS.usd}
                                  {price.toFixed(2)}
                                </strong>
                              </div>
                              <div className="col text-end">
                                {hasAddOns ? (
                                  <div className="col text-end">
                                    <span className="badge product-tag">
                                      {Constants.PRODUCTS.customize}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          {this.quantitybtn(o, products)}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {(pimProducts?.response?.length === 0) && !isPimBistroLoading ? (
                this._renderEmptyContent()
              ) : null}
            </div>
          </div>
          <div className="my-75 text-center">
            {hasLoadMore ? (
              <button
                className="btn btn-dark big-btn"
                name=""
                id='load'
                onClick={() => this.setState(({ currentPage }) => ({
                  currentPage: currentPage + 1
                }), () => {
                  this.invokeApiCall();
                })}
              >
                LOAD MORE
              </button>
            ) : null}
          </div>

        </div>



        {openAddonModal && (
          <AddOnModal
            id='openAddonModal'
            isAddOnModalOpen={openAddonModal}
            closeAddOnModal={() => this.setState({ openAddonModal: false })}
            productInfo={productInfo}
          />
        )}
        {
          isOpenRepeatCustomModal && (
            <RepeatCustomization
              id='RepeatCustomization'
              isModalOpen={isOpenRepeatCustomModal}
              closeCustomModal={() => this.closeCustomModal()}
              repeatCustom={() => this.repeatCustom()}
              chooseNew={() => this.chooseNew()}
            />
          )
        }
        {errorMessage && (
          <AlertModal
            id='errorMessage'
            message={errorMessage.message}
            title={errorMessage?.productInfo?.productName}
            onClose={() => this.setState({ errorMessage: null })}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
              }}
            >
              <button
                type="button"
                id="goToCartScreen"
                className="btn btn-dark big-btn px-5 mb-4 me-2 text-uppercase"
                onClick={() => this.goToCartScreen()}>Go to Cart</button>
            </div>
          </AlertModal>
        )}

      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getPimProducts: Actions.getPimProducts(state),
    getPimCartDetails: Actions.getPimCartDetails(state),
    getProductGroup: Actions.getProductGroupInformation(state)?.productGroupInformation
  };
};

export default withRouter(
  connect(mapStateToProps, {
    bistroPimProducts: Actions.bistroPimProducts,
    createPimCart: Actions.createPimCart,
    updatePimCart: Actions.updatePimCart,
    productGroupInformationActions: Actions.productGroupInformation,
    loaderOff: Actions.loaderOff
  })(BistroProducts)
);
