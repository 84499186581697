import { combineReducers } from 'redux';
import orderList from './list'
import orderDetails from './details'
import createOrders from './create'
import refundOrders from './refund'
export default combineReducers({
    orderList,
    orderDetails,
    createOrders,
    refundOrders
});