import React from 'react';
import { connect } from 'react-redux';
import Actions from "../redux/action";
import { isValidEmail } from '../Utility/validation';
import InputField from './InputField';


export class EmailField extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            emailId: '',
            emailAlreadExists: null
        }
    }


    render() {
        const { defaultValue, onChange, ...rest } = this.props;
        const { emailId } = this.state;
        return (
            <InputField
                className="form-control custom-field"
                type="email"
                name="email"
                placeholder="Email"
                defaultValue={defaultValue}
                error={(() => {
                    if (!emailId.length) {
                        return null
                    }
                    if (!isValidEmail(emailId)) {
                        return 'Email Id is not valid.';
                    }
                    return null
                })()}
                onChange={(value) => {
                    onChange(value);
                    this.setState({ emailId: value });
                    if (isValidEmail(value))
                        this.props.isEmailValid(true)
                    else
                        this.props.isEmailValid(false)
                }}
                {...rest}
            />
        )
    }
}

EmailField.defaultProps = {
    onChange: () => null,
    defaultValue: '',
    isEmailValid: () => null,
}

export default connect(null, {
    verifyEmail: Actions.verifyEmailAction,
})(EmailField)