import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import Constants from "../../Utility/Constants";
import routes from "../../config/routes";
import Placeholder from '../../asset/img/no-preview.png'
import StorePopUp from "../../component/ModalPopup/StorePopUp";
import EmptyPage from "../../component/emptyPage";
import { getRandomNumber } from "../../Utility/Utils";
let DISPLAY_LIMIT = 6;
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showStorePopUp: false,
    };
  }
  componentDidMount() {
    const defaultFilter = {
      page: 0,
      size: 100,
    };
    const callback = (resp) => {
      const { getMultipleStoreList } = this.props.getSelectedStoreList
      if (resp) {
        const { selectedStoreList } = this.props.getSelectedStoreList || {}
        if (selectedStoreList?.length <= 0 && getMultipleStoreList?.length > 1) {
          this.setState({ showStorePopUp: true })
        } else if (getMultipleStoreList?.length === 1) {
          const defaultData = getMultipleStoreList?.map(d => ({
            label: `${d?.branchName} (${d?.address} ${d?.zipCode})`, value: d?.id
          }));
          this.props.StoreList(...defaultData)
          this.setState({ showStorePopUp: false })
        }
      }
      this.props.productGroupInformationActions();
    };
    this.props.getMultipleStoreList(defaultFilter, callback)
    // this.props.productGroupInformationActions(); //for multiStore
    this.props.loaderOff()

  }

  navProducts(type, id) {
    if (type === "products") {
      this.props.history.push(`/products?categoryId=${id}`);
    } else if (type === "bistro") {
      this.props.history.push(`/bistro/products?categoryId=${id}`);
    } else {
      this.props.history.push(`/products?categoryId=${id}`);
    }
  }
  _renderBistro(bistroTodisplay) {
    let DISPLAY_BISTRO_LIMIT = 6;
    return (
      <>
        <h2 className="section-heading mb-3">Order From Bistro</h2>
        <div className="row g-4 home-menu">
          {bistroTodisplay &&
            bistroTodisplay.map((obj, index) => {
              return (
                <div
                  className="col-12 col-md-6 col-lg-4" id="navproduct"
                  onClick={() => this.navProducts("bistro", obj.categoryId)}
                  key={index}
                >
                  <span>
                    <div className="card card-body p-0 border-0 text-link">
                      <img
                        src={obj.picture ? obj.picture + '?$' + getRandomNumber() : Placeholder}
                        alt="#"
                      // className=""
                      />
                      <h3 className="title-case">{obj.categoryName?.toLowerCase()} </h3>
                    </div>
                  </span>
                </div>
              );
            })}
        </div>
        {bistroTodisplay?.length > DISPLAY_BISTRO_LIMIT && (
          <div className="view-all">
            <span
              className="btn btn-transparent bg-white" id='bistro1'
              onClick={() => this.props.history.push(routes.bistroCategory())}
            >
              View all
              <img
                className="ms-2" id='bistro2'
                src={require("../../asset/img/right-arrow.svg")}
                onClick={() => this.props.history.push(routes.bistroCategory())}
                alt="#"
              />
            </span>
          </div>
        )}
      </>
    );
  }
  render() {
    const { productGroupInformation, isLoading } = this.props.getProductGroup || {};
    const { selectedStoreList } = this.props.getSelectedStoreList || {}
    let categoryList = [];
    let bistroCategoryList = [];
    let revealGrocery = productGroupInformation?.findIndex(
      (itm) => itm?.groupName === Constants.PRODUCT_GROUP.GROCERY
    );
    let revealBistro = productGroupInformation?.findIndex(
      (itm) => itm?.groupName === Constants.PRODUCT_GROUP.BISTRO
    );
    let categoriesTodisplay = [];
    let bistroTodisplay = [];
    if (revealGrocery !== -1) {
      if (productGroupInformation && productGroupInformation[revealGrocery].categoryList) {
        categoryList = [...productGroupInformation[revealGrocery].categoryList];
        categoriesTodisplay = categoryList?.slice(0, DISPLAY_LIMIT);
      } else {
        categoriesTodisplay = categoryList;
      }
    }

    if (revealBistro !== -1) {
      if (productGroupInformation && productGroupInformation[revealBistro].categoryList) {
        bistroCategoryList = [
          ...productGroupInformation[revealBistro].categoryList,
        ];
        bistroTodisplay = bistroCategoryList?.slice(0, DISPLAY_LIMIT);
      } else {
        bistroTodisplay = bistroCategoryList;
      }
    }

    return (
      <React.Fragment>
        <div className="wrap pt-1 mt-2 mt-lg-4 rounded-3 home-banner-wrap">
          <div className="home-banner rounded-3"></div>
        </div>
        <div className="wrap py-35">
          {
            categoriesTodisplay && categoriesTodisplay.length > 0 && <>
              <h2 className="section-heading mb-3">Order Products</h2>
              <div className="row g-4 home-menu">
                {categoriesTodisplay &&
                  categoriesTodisplay?.map((obj, index) => {
                    return (
                      <div
                        id='navProducts'
                        className="col-12 col-md-6 col-lg-4"
                        // id='grocery'
                        onClick={() => this.navProducts("products", obj.categoryId)}
                        key={index}
                      >
                        <span>
                          <div className="card card-body p-0 border-0 text-link card_height">
                            <img className="card_images"
                              src={obj.picture ? obj.picture + '?$' + getRandomNumber() : Placeholder}
                              alt="#"
                            // className=""
                            />
                            <h3 className="title-case">{obj.categoryName?.toLowerCase()} </h3>
                          </div>
                        </span>
                      </div>
                    );
                  })}
              </div>
            </>
          }

          {categoryList?.length > DISPLAY_LIMIT && (
            <div className="view-all">
              <span
                className="btn btn-transparent bg-white" id='category'
                onClick={() => this.props.history.push('/category')}
              >
                View all
                <img
                  className="ms-2" id='category1'
                  src={require("../../asset/img/right-arrow.svg")}
                  alt="#"
                  onClick={() => this.props.history.push('/category')}
                />
              </span>
            </div>
          )}
          {bistroTodisplay && bistroTodisplay?.length > 0 && (
            <>{this._renderBistro(bistroTodisplay)}
              {bistroCategoryList?.length > DISPLAY_LIMIT && (
                <div className="view-all">
                  <span
                    className="btn btn-transparent bg-white" id='categoryBistro1'
                    onClick={() => this.props.history.push('/category/bistro')}
                  >
                    View all
                    <img
                      className="ms-2" id='categoryBistro'
                      src={require("../../asset/img/right-arrow.svg")}
                      onClick={() => this.props.history.push('/category/bistro')}
                      alt="#"
                    />
                  </span>
                </div>
              )}
            </>
          )}
          {
            (productGroupInformation && productGroupInformation.length === 0 && !isLoading) && (
              <EmptyPage
                content={Constants.MULTI_STORE.STORE_PRODUCT_NOT_FOUND}
              />
            )
          }
        </div>
        {
          selectedStoreList && selectedStoreList?.length === 0 &&
          <StorePopUp
            onCloseModal={() => this.setState({ showStorePopUp: false })}
            isModalOpen={this.state.showStorePopUp}
          />
        }
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getProductGroup: Actions.getProductGroupInformation(state),
    getSelectedStoreList: Actions.getStoreList(state),

  };
};
export default withRouter(
  connect(mapStateToProps, {
    productGroupInformationActions: Actions.productGroupInformation,
    StoreList: Actions.selectedStoreList,
    getMultipleStoreList: Actions.getMultipleStoreList,
    loaderOff: Actions.loaderOff
  })(Home)
);
