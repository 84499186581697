import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthPost } from '../../service/index'
import { UPDATE_CART } from '../../config/api_endpoint';


export function* updateCart({ params, callback }) {
    try {
        const response = yield doAuthPost(UPDATE_CART, params);
        yield put(Actions.updateCartSuccess(response));
        yield put(Actions.getCartDetailsFailure(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.updateCartFailure(error));
    }
}


export default function* cartWatcher() {
    yield all([
        takeLatest(Actions.UPDATE_CART, updateCart),
    ]);
}
