export const REFUND_ORDER = 'REFUND_ORDER';
export const REFUND_ORDER_SUCCESS = 'REFUND_ORDER_SUCCESS'
export const REFUND_ORDER_FAILURE = 'REFUND_ORDER_FAILURE'

export const UPLOAD_REFUND_IMAGE = 'UPLOAD_REFUND_IMAGE'
export const UPLOAD_REFUND_IMAGE_SUCCESS = 'UPLOAD_REFUND_IMAGE_SUCCESS'
export const UPLOAD_REFUND_IMAGE_FAILURE = 'UPLOAD_REFUND_IMAGE_FAILURE'

export const REFUND_DETAILS = 'REFUND_DETAILS';
export const REFUND_DETAILS_SUCCESS = 'REFUND_DETAILS_SUCCESS'
export const REFUND_DETAILS_FAILURE = 'REFUND_DETAILS_FAILURE'

export const refundDetails = store => store['ORDERS_LIST'].refundOrders

export const refundOrder = (data, callback) => {
    return {
        type: REFUND_ORDER,
        data,
        callback
    }
}

export const refundOrderSuccess = (response, data) => {
    return {
        type: REFUND_ORDER_SUCCESS,
        response,
        data
    }
}

export const refundOrderFailure = (error) => {
    return {
        type: REFUND_ORDER_FAILURE,
        error
    }
}


export const uploadRefundImage = (data, callback) => {
    return {
        type: UPLOAD_REFUND_IMAGE,
        data,
        callback
    }
}

export const uploadRefundImageSuccess = (data) => {
    return {
        type: UPLOAD_REFUND_IMAGE_SUCCESS,
        data
    }
}

export const uploadRefundImageFailure = (error) => {
    return {
        type: UPLOAD_REFUND_IMAGE_FAILURE,
        error
    }
}

export const getRefundDetails = (data, callback) => {
    return {
        type: REFUND_DETAILS,
        data,
        callback
    }
}

export const getRefundDetailsSuccess = (data) => {
    return {
        type: REFUND_DETAILS_SUCCESS,
        data
    }
}

export const getRefundDetailsFailure = (error) => {
    return {
        type: REFUND_DETAILS_FAILURE,
        error
    }
}