import React from 'react';
import { connect } from 'react-redux';
import { getQueryParams } from '../../Utility/validation';
import PIMProducts from '../products/Products';
import BistroProduct from '../products/Bistro';
import routes from '../../config/routes';

class SearchPage extends React.Component {

    constructor(props) {
        super(props);
        const queryParams = getQueryParams(this.props.location.search);
        const tab = queryParams.get('tab');
        const queryTabIndex = parseInt(tab, 10);
        this.state = {
            tabIndex: tab ? queryTabIndex ? queryTabIndex : 0 : 0
        }
    }

    insertUrlParam = (key, value) => {
        if (window.history.pushState) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set(key, value);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    onClickTab = (index) => {
        const queryParams = getQueryParams(this.props.location.search);
        queryParams.set('tab', index)
        this.props.history.replace({
            pathname: routes.SEARCH_ALL,
            search: "?" + queryParams.toString()
        });
        this.setState({ tabIndex: index });
    }

    render() {
        const qparams = getQueryParams(this.props.location.search);
        const queryParamsText = qparams.get('search');
        const { tabIndex } = this.state;
        return (
            <div className="wrap py-35">
                {queryParamsText && <div className="row mb-3">
                    <div className="col font18">
                        Results for “{queryParamsText}"
                    </div>
                </div>}
                <ul className="nav nav-tabs" id="search-result-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${tabIndex === 0 ? 'active' : ''}`}
                            id="grocery-tab" data-bs-toggle="tab"
                            data-bs-target="#grocery-content"
                            type="button" role="tab"
                            aria-controls="home"
                            aria-selected={tabIndex === 0 ? 'true' : 'false'}
                            onClick={() => this.onClickTab(0)}>Products</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link ${tabIndex === 1 ? 'active' : ''}`}
                            id="bistro-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#bistro-content" type="button" role="tab"

                            aria-controls="profile"
                            aria-selected={tabIndex === 1 ? 'true' : 'false'}
                            onClick={() => this.onClickTab(1)}
                        >Bistro</button>
                    </li>
                </ul>

                <div className="tab-content pt-4" id="search-result-content">
                    <div className="row g-5 g-lg-4 mb-5 products-grid">
                        {
                            tabIndex === 0 ? <div className="tab-pane fade show active"
                                id="grocery-content"
                                role="tabpanel"
                                aria-labelledby="grocery-tab">
                                <PIMProducts searchScreen />
                            </div> :
                                <div className="tab-pane fade show active"
                                    id="bistro-content"
                                    role="tabpanel"
                                    aria-labelledby="bistro-tab">
                                    <div className="row g-5 g-lg-4 mb-5 products-grid">
                                        <BistroProduct searchScreen />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(SearchPage)