import { takeLatest, all, put, select } from 'redux-saga/effects';
import { FORGET_PASSWORD, UPDATE_FORGOT_PASSWORD, FORGOT_PASSWORD_VERIFY_OTP, CLIENT_CREDENTIALS } from '../../config/api_endpoint';
import { doClientPost, doAuthPost, clientCredentials } from '../../service/index'
import Utils from '../../Utility/Utils';
import Actions from '../action';

export function* requestOtpSaga({ data, callback }) {

  try {
    const response = yield clientCredentials(CLIENT_CREDENTIALS);
    if (response && response.access_token) {
      response.expMilliseconds = Utils.convertExpirySecToMilliSec(response.expires_in);
    }
    yield put(Actions.clientCredentialsSuccess(response))
  }
  catch (error) {
    yield put(Actions.clientCredentialsFailure({ error }))
  }


  try {
    let response = yield doClientPost(FORGET_PASSWORD, data)
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
  }
}

export function* forgetPasswordVerifyOtp({ data, callback }) {
  try {
    let response = yield doClientPost(FORGOT_PASSWORD_VERIFY_OTP, data);
    if (response && response?.response) {
      response.response.isTemp = true;
      yield put(Actions.loginSuccess(response?.response))
    } else {
      yield put(Actions.forgetPasswordVerifyOtpFailure());
    }
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(Actions.forgetPasswordVerifyOtpFailure());
    if (callback) {
      callback({ error })
    }
  }
}

export function* resetPassword({ data, callback }) {
  try {
    let response = yield doAuthPost(UPDATE_FORGOT_PASSWORD, data);
    const userToken = yield select(s => Actions.getUserDetail(s));
    userToken.token.isTemp = false;
    yield put(Actions.loginSuccess(userToken))
    yield put(Actions.loginSuccess(response && response.response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(Actions.updatePasswordFailure());
    if (callback) {
      callback({ error })
    }
  }
}


export default function* forgotPassword() {
  yield all([
    takeLatest(Actions.REQUEST_OTP, requestOtpSaga),
    takeLatest(Actions.FORGET_PASSWORD_VERIFY_OTP, forgetPasswordVerifyOtp),
    takeLatest(Actions.UPDATE_PASSWORD, resetPassword)
  ]);
}
