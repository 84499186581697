
const NAME = "PIM_PRODUCTS";
export const PIM_PRODUCTS = `${NAME}/PIM_PRODUCTS`;
export const PIM_PRODUCTS_SUCCESS = `${NAME}/PIM_PRODUCTS_SUCCESS`;
export const PIM_PRODUCTS_FAILURE = `${NAME}/PIM_PRODUCTS_FAILURE`;

export const BISTRO_PIM_PRODUCTS = `${NAME}/BISTRO_PIM_PRODUCTS`;
export const BISTRO_PIM_PRODUCTS_SUCCESS = `${NAME}/BISTRO_PIM_PRODUCTS_SUCCESS`;
export const BISTRO_PIM_PRODUCTS_FAILURE = `${NAME}/BISTRO_PIM_PRODUCTS_FAILURE`;

export const PIM_PRODUCTS_DETAILS = `${NAME}/PIM_PRODUCTS_DETAILS`;
export const PIM_PRODUCTS_DETAILS_SUCCESS = `${NAME}/PIM_PRODUCTS_DETAILS_SUCCESS`;
export const PIM_PRODUCTS_DETAILS_FAILURE = `${NAME}/PIM_PRODUCTS_DETAILS_FAILURE`;

export const BISTRO_PRODUCTS_DETAILS = `${NAME}/BISTRO_PRODUCTS_DETAILS`;
export const BISTRO_PRODUCTS_DETAILS_SUCCESS = `${NAME}/BISTRO_PRODUCTS_DETAILS_SUCCESS`;
export const BISTRO_PRODUCTS_DETAILS_FAILURE = `${NAME}/BISTRO_PRODUCTS_DETAILS_FAILURE`;

export const getPimProducts = store => store[NAME]
export const pimProducts = (params) => {
    return {
        type: PIM_PRODUCTS,
        params
    }
}

export const pimProductsSuccess = (data) => {
    return {
        type: PIM_PRODUCTS_SUCCESS,
        data
    }
}

export const pimProductsFailure = (error) => {
    return {
        type: PIM_PRODUCTS_FAILURE,
        error
    }
}

export const bistroPimProducts = (params) => {
    return {
        type: BISTRO_PIM_PRODUCTS,
        params
    }
}

export const bistroPimProductsSuccess = (data) => {
    return {
        type: BISTRO_PIM_PRODUCTS_SUCCESS,
        data
    }
}

export const bistroPimProductsFailure = (error) => {
    return {
        type: BISTRO_PIM_PRODUCTS_FAILURE,
        error
    }
}

export const pimProductsDetails = (params) => {
    return {
        type: PIM_PRODUCTS_DETAILS,
        params
    }
}

export const pimProductsDetailsSuccess = (data) => {
    return {
        type: PIM_PRODUCTS_DETAILS_SUCCESS,
        data
    }
}

export const pimProductsDetailsFailure = (error) => {
    return {
        type: PIM_PRODUCTS_DETAILS_FAILURE,
        error
    }
}

export const bistroProductsDetails = (params) => {
    return {
        type: BISTRO_PRODUCTS_DETAILS,
        params
    }
}

export const bistroProductsDetailsSuccess = (data) => {
    return {
        type: BISTRO_PRODUCTS_DETAILS_SUCCESS,
        data
    }
}

export const bistroProductsDetailsFailure = (error) => {
    return {
        type: BISTRO_PRODUCTS_DETAILS_FAILURE,
        error
    }
}
