
const BASE_URL = 'https://shop-api.proglint.com/'; // Dev Server
const BASE_URL_SOCKET_IO = 'https://shop-socket.proglint.com/'; // Dev Server
const MAP_API_KEY = 'AIzaSyCq4bnWtoKb5qVePwcBwxb6kglg_5J6shc';

const DEV_URL = 'https://shop.proglint.com/'
export {
    BASE_URL,
    BASE_URL_SOCKET_IO,
    MAP_API_KEY,
    DEV_URL,
}