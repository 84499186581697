const NAME = "ACCOUNT_DELETE";
export const ACCOUNT_DELETE = `${NAME}/ACCOUNT_DELETE`;
export const ACOUNT_DELETE_SUCCESS = `${NAME}/ACOUNT_DELETE_SUCCESS`;
export const  ACCOUNT_DELETE_FAILURE = `${NAME}/ ACCOUNT_DELETE_FAILURE`;

export const accountDelete = (params ,callback) => {
    return {
        type: ACCOUNT_DELETE,
        params,
        callback
    }
   }
    export const accountDeleteSuccess = (data) => {
        return {
            type: ACOUNT_DELETE_SUCCESS,
            data
        }
    }
    
    export const accountDeleteFailure = (error) => {
        return {
            type: ACCOUNT_DELETE_FAILURE,
            error
        }
    }  





