const NAME = "FRANCHISE_MAIL";
export const FRANCHISE_MAIL = `${NAME}/FRANCHISE_MAIL`;
export const FRANCHISE_MAIL_SUCCESS = `${NAME}/FRANCHISE_MAIL_SUCCESS`;
export const  FRANCHISE_MAIL_FAILURE = `${NAME}/ FRANCHISE_MAIL_FAILURE`;

export const franchiseMail = (params ,callback) => {
    return {
        type: FRANCHISE_MAIL,
        params,
        callback
    }
   }
    export const franchiseMailSuccess = (data) => {
        return {
            type: FRANCHISE_MAIL_SUCCESS,
            data
        }
    }
    
    export const franchiseMailFailure = (error) => {
        return {
            type: FRANCHISE_MAIL_FAILURE,
            error
        }
    }  





