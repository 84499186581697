import React from 'react'
import { withRouter } from 'react-router-dom'
import BoldError from '../../component/BoldError';
import { connect } from "react-redux";
import Actions from "../../redux/action";
import routes from '../../config/routes';
import { toast } from 'react-toastify';
class EntryPin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entryPin: null,
            errorMessage: undefined
        }
    }
    handleEntryPin(e) {
        this.setState({ entryPin: e?.target?.value })
    }
    handleSumbit() {
        const { entryPin } = this.state
        if (!entryPin) {
            this.setState({ errorMessage: 'Please enter otp' })
        } else {
            const callback = (res) => {
                if (res?.response_code === 0) {
                    this.props.history.push(routes.CHECKOUT_PAYMENT)
                } else {
                    toast.error(`${res?.response_message}`)
                }
            }

            let params = {
                otp: entryPin
            }
            this.props.userPaymentOtp(params, callback)
        }

    }

    render() {
        const { errorMessage } = this.state
        return (
            <div className="container mt-3">
                <div className='card'>
                    <div className="card-body">
                        <BoldError message={errorMessage} />
                        <h5>ENTER OTP</h5>
                        <div className="mt-5">
                            <input
                                className='form-control custom-field'
                                type="number"
                                id='entryPin'
                                name="entryPin"
                                placeholder="Please enter otp"
                                onChange={(e) => this.handleEntryPin(e)}
                            />
                        </div>
                        <div className='mt-5 pt-5'>
                            <button type="button"
                                onClick={() => this.handleSumbit()}
                                className="btn btn-dark big-btn w-100 px-5 text-uppercase"
                                id="handleSumbit">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps, {
    userPaymentOtp: Actions.userPaymentOtp,
})(EntryPin))