import React from 'react';
import { useSelector } from 'react-redux';
import action from '../redux/action';
import eventBus, { EVENT_SIGN_IN_OPEN } from '../Utility/event';

const AddToCartButton = ({ onClick = () => null }) => {

  const userObj = useSelector(action.getUserDetail);

  const onClickHandler = () => {
    const { token, customer = {} } = userObj;
    if (token && customer.smsOtpVerified) {
      onClick();
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, { isAddToCart: true });
    }
  }

  return (<button
      className="btn btn-outline-dark font15 fw-bold mt-2"
      onClick={() => onClickHandler()}
    >
      + Add to cart
    </button>)
}

export default AddToCartButton;