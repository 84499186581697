import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doClientPost } from '../../service/index'
import { FRANCHISE_MAIL } from '../../config/api_endpoint';

export function* franchiseMail({ params, callback }) {
    try {
        const response = yield doClientPost(FRANCHISE_MAIL, params);
        yield put(Actions.franchiseMailSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.franchiseMailFailure(error));
    }
}


export default function* franchiseWatcher() {
    yield all([
        takeLatest(Actions.FRANCHISE_MAIL, franchiseMail),
    ]);
}
