import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkMobileNumberAlreadyExistsAction } from '../redux/action/signup';
import CountryDropDown from './CountryDropDown';
import InputField from './InputField';

const MobileNumberInput = ({
    onChangeMobileNumber = () => null,
    onChangeDropDown = () => null,
    className,
    onError = () => null,
    dropdownDefaultValue = '+1',
    mobileNumberDefaultValue = '',
    ...props
}) => {

    const [dropdownState, setDropDownState] = useState(dropdownDefaultValue);
    const [mobileNumberState, setMobileNumberState] = useState(mobileNumberDefaultValue);
    const [errorMessage, setErrorMessage] = useState(null);
    const dispatch = useDispatch();


    const checkMobileNumberAlreadyExists = useCallback(() => {
        if (mobileNumberState.length === 10) {
            dispatch(checkMobileNumberAlreadyExistsAction(dropdownState + mobileNumberState, resp => {
                if (resp.response_code === 0) {
                    setErrorMessage(null)
                    onError(false)
                } else {
                    setErrorMessage(resp.response_message);
                    onError(true)
                }
            }))
        }
    }, [mobileNumberState, dispatch, dropdownState, onError])


    useEffect(() => {
        checkMobileNumberAlreadyExists()
    }, [checkMobileNumberAlreadyExists])

    return <>
        <div className="row align-items-center">
            <div className="col-2">
                <CountryDropDown
                    id="CountryDropDown"
                    defaultValue={dropdownState}
                    onChange={(v) => {
                        setDropDownState(v)
                        onChangeDropDown({ target: { value: v, name: 'dropdown' } })
                    }} />
            </div>
            <div className="col">
                <InputField
                    {...props}
                    style={{ width: '100%' }}
                    className={className}
                    type="number"
                    name="mobileNumber"
                    placeholder="Mobile Number"
                    defaultValue={mobileNumberDefaultValue}
                    onChange={(value, name) => {
                        setMobileNumberState(value)
                        onChangeMobileNumber({ target: { value, name } })
                    }}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                    }}
                />
            </div>
            {errorMessage && <p className="errorMsg">{errorMessage}</p>}
        </div>
    </>
}

export default MobileNumberInput;