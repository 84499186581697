import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Actions from "../../redux/action";
import routes from '../../config/routes';
import { toast } from 'react-toastify';
import Alert from '../../component/alert';
import Constants from '../../Utility/Constants';
import SavedCards from '../../component/SavedCards'
import ConfirmModal from '../../component/alert/confirmModal';

class ManageCards extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            alertModal: false,
            defaultModal: false,
            isShowCards: false,
            defaultPaymentMethod: null,
            isSetPrimaryAccountModal: false
        }
    }

    addNewCard() {
        const callback = (res) => {
            if (res?.response_code === 2) {
                toast.error(`${res?.response_message}`)
            } else {
                const { response } = res || {}
                this.props.history.push({
                    pathname: routes.SAVE_CARD_PAYMENT,
                    state: {
                        htmlTemplate: response
                    }
                })
            }

        }
        this.props.initializePaymentAction(callback)
    }
    deleteCard() {
        const { customerTokenDetailsId, defaultPaymentMethod } = this.state
        const { paymentCardsList } = this.props.getCardDetails || []

        const callback = (res) => {
            if (res?.response_code === 0) {
                toast.success('Successfully deleted')
                if (paymentCardsList && paymentCardsList.length !== 0 && defaultPaymentMethod) {
                    this.setState({ isSetPrimaryAccountModal: true })
                }
            } else {
                toast.error('Something went wrong,please try again later')
            }
            this.setState({ alertModal: false })
        }
        const params = {
            tokenDetailsId: customerTokenDetailsId
        }
        this.props.deletePaymentCard(params, callback)
    }
    closePrimaryCardModal() {
        this.setState({ isSetPrimaryAccountModal: false })
    }
    makeDefaultAlert(tokenDetailsId, defaultPaymentMethod) {
        if (!defaultPaymentMethod) {
            this.setState({
                defaultModal: !this.state.defaultModal,
                tokenDetailsId
            })
        }

    }
    deletePopup(customerTokenDetailsId, defaultPaymentMethod) {
        this.setState({
            alertModal: !this.state.alertModal,
            customerTokenDetailsId: customerTokenDetailsId,
            defaultPaymentMethod: defaultPaymentMethod
        })
    }
    makePrimaryCard() {
        const { tokenDetailsId } = this.state || {}
        const params = {
            tokenDetailsId
        }
        const callback = (res) => {
            if (res?.response_code !== 0) {
                toast.error('Something went wrong,please try again later')
            }
            this.setState({ defaultModal: false })
        }
        this.props.makePrimaryCard(params, callback)
    }
    render() {
        const { alertModal, defaultModal, isSetPrimaryAccountModal } = this.state
        const { paymentCardsList, isLoading } = this.props.getCardDetails || []
        const finalArray = paymentCardsList?.sort(value => {
            return value?.defaultPaymentMethod ? -1 : 1;
        });
        return (
            <React.Fragment>
                <div className="col ps-md-5">
                    {
                        !this.props.isCart && <div className="row mb-5 align-items-center">
                            <div className="col-12 col-md">
                                <h1 className="font18 fw-bold pb-3 pb-md-0">Manage payments</h1>
                            </div>
                            <div className="col-12 col-md text-md-end">
                                <button
                                    id='addNewCard'
                                    className="btn btn-dark big-btn p-2 px-5 text-uppercase"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add-addr-modal"
                                    name=""
                                    onClick={() => this.addNewCard()}
                                >+ Add Payment</button>
                            </div>
                        </div>
                    }

                    <div id="manage-addr-grid"
                        className="row row-cols-1 row-cols-md-2 g-4 mb-4 manage_payment">
                        {
                            finalArray && finalArray.map((obj, index) => <SavedCards
                                id='SavedCards'
                                cardDetails={obj}
                                index={index}
                                makeDefaultAlert={(data, defaultPaymentMethod) => this.makeDefaultAlert(data, defaultPaymentMethod)}
                                deletePopup={(data, defaultPaymentMethod) => this.deletePopup(data, defaultPaymentMethod)}
                                isCart={this.props.isCart}
                                chooseCheckoutCard={(value) => this.props.chooseCheckoutCard(value)}
                                selectedCartId={this.props.selectedCartId}
                            />)
                        }

                        {
                            !isLoading && paymentCardsList?.length === 0 &&
                            <div className='text-center'>
                                <p><b>Please configure payment to save cards.</b></p>
                            </div>
                        }
                    </div>
                </div>
                {alertModal && <Alert
                    id="Alert"
                    title="Remove Card"
                    description={'Do you want to remove this card?'}
                    proceed={Constants.LOG_OUT.proceed}
                    cancel={Constants.LOG_OUT.cancel}
                    onSuccess={() => this.deleteCard()}
                    onFailure={() => this.deletePopup()}
                />}

                {defaultModal && <Alert
                    id="defaultModal"
                    title="Primary Card"
                    description={'Do you want to make this as primary card?'}
                    proceed={Constants.LOG_OUT.proceed}
                    cancel={Constants.LOG_OUT.cancel}
                    onSuccess={() => this.makePrimaryCard()}
                    onFailure={() => this.makeDefaultAlert()}
                />}

                {isSetPrimaryAccountModal && <ConfirmModal
                    id="confirmModal"
                    title="Set Primary Card"
                    description={'Please choose/add a primary card'}
                    proceed={'OK'}
                    onSuccess={() => this.closePrimaryCardModal()}
                />}

            </React.Fragment>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        getCardDetails: Actions.getSavedCardDetails(state),
    };
};

export default withRouter(connect(mapStateToProps, {
    initializePaymentAction: Actions.addPaymantInformation,
    deletePaymentCard: Actions.deletePaymentCard,
    makePrimaryCard: Actions.makePrimaryPaymentCard,
    entryPin: Actions.entryPin,
})(ManageCards))