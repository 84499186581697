import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import eventBus, { EVENT_SIGN_UP_CLOSE, EVENT_VERIFY_OTP_CLOSE, EVENT_VERIFY_OTP_OPEN, EVENT_SIGN_IN_OPEN } from "../../Utility/event";
import Actions from "../../redux/action";
import PasswordInput from "../PasswordInput";
import InputField from "../InputField";
import EmailField from "../EmailField";
import { isValidEmail, isValidNumber } from "../../Utility/validation";
import Constants from "../../Utility/Constants";
import BoldError from "../BoldError";
import routes from "../../config/routes";
import MobileNumberInput from "../MobileNumberInput";
import ModalPopup, { ModalHeader } from "../../component/ModalPopup";
import { DEV_URL } from '../../config/base';

// eslint-disable-next-line no-useless-escape
const onlyCharactersRegex = new RegExp(/^[a-zA-Z\s-]+$/);

const ERROR_FIRST_NAME = 'First Name field accepts only alphabets.';
const ERROR_LAST_NAME = 'Last Name field accepts only alphabets.';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerifyOtpModal: false,
      firstName: '',
      lastName: '',
      emailId: '',
      mobileNumber: '',
      mobileCountryCode: '+1',
      mobileNumberAlreadyRegistered: false,
      password: '',
      retypePassword: '',
      showPasswordPopup: false,
      errorMessage: null,
      passwordValid: false,
      isEmailValid: false,
      termsCheckbox: false,
      resetCount: 1
    };
    this.mobileNumberOnError = this.mobileNumberOnError.bind(this)
  }

  mobileNumberOnError(mobileNumberAlreadyRegistered) {
    this.setState({ mobileNumberAlreadyRegistered })
  }
  closeVerifyOtp() {
    eventBus.dispatch(EVENT_VERIFY_OTP_CLOSE, {});
  }

  invokeSignUpApi() {
    const {
      firstName,
      lastName,
      emailId,
      mobileNumber,
      password,
      passwordValid,
      isEmailValid,
      retypePassword,
      termsCheckbox,
      mobileCountryCode,
      mobileNumberAlreadyRegistered
    } = this.state;
    let error = null;
    if (mobileNumberAlreadyRegistered) {
      return;
    } else if (firstName.length > 0 && !onlyCharactersRegex.test(firstName)) {
      error = ERROR_FIRST_NAME;
    } else if (lastName.length > 0 && !onlyCharactersRegex.test(lastName)) {
      error = ERROR_LAST_NAME;
    } else if (firstName.trim().length < 3) {
      error = Constants.REGISTER.error.firstName;
    } else if (lastName.trim().length === 0) {
      error = Constants.REGISTER.error.lastName;
    } else if (emailId.trim().length === 0) {
      error = Constants.REGISTER.error.enterEmailId;
    } else if (!isValidEmail(emailId)) {
      error = Constants.REGISTER.error.validEmail;
    } else if (!isEmailValid) {
      error = Constants.REGISTER.error.email;
    } else if (!mobileNumber) {
      error = Constants.REGISTER.error.mobileNumber;
    } else if (!isValidNumber(mobileNumber) || mobileNumber.length !== 10) {
      error = Constants.REGISTER.error.enterMobileNumber;
    } else if (!passwordValid) {
      error = Constants.REGISTER.error.passkey;
    } else if (retypePassword !== password) {
      error = Constants.REGISTER.error.passkeyMismatch;
    } else if (!termsCheckbox) {
      error = Constants.REGISTER.error.termsCheckbox;
    } else {
      error = ''
    }

    this.setState({ errorMessage: error });
    if (error) {
      return;
    }
    const params = {
      firstName,
      lastName,
      emailId,
      password,
      mobileNumber: mobileCountryCode + mobileNumber,
      // type: 1
    };

    this.props.signUpAction(params, (resp) => {
      if (resp.response_code === 0) {
        eventBus.dispatch(EVENT_VERIFY_OTP_OPEN, { mobileNumber: params.mobileNumber });
        eventBus.dispatch(EVENT_SIGN_UP_CLOSE, {});
        this.setState({ errorMessage: null })
      } else {
        this.setState({ errorMessage: resp?.response_message || 'Something went wrong.' })
      }
    })
  }

  openPrivacy = () => {
    this.props.history.push("/privacy")
    eventBus.dispatch(EVENT_SIGN_UP_CLOSE, {});
  }

  openTermsOfUse = () => {
    this.props.history.push("/termsofuse")
    eventBus.dispatch(EVENT_SIGN_UP_CLOSE, {});
  }

  openSignup = () => {
    eventBus.dispatch(EVENT_SIGN_UP_CLOSE, {});
    eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
  }

  closeSignup = () => {
    eventBus.dispatch(EVENT_SIGN_UP_CLOSE, {});
  }

  franchiseEnquiry() {
    const { onCloseModal } = this.props;
    this.props.history.push(routes.FRANCHISE_ENQUIRY)
    onCloseModal()
  }

  onResetSignup = () => {
    this.setState((prevState) => ({
      firstName: '', lastName: '',
      emailId: '', mobileNumber: '',
      password: '', retypePassword: '',
      resetCount: prevState.resetCount + 1
    }))
  }

  render() {
    const { errorMessage,
      firstName,
      lastName,
      emailId,
      mobileNumber,
      password,
      retypePassword,
    } = this.state
    const { isModalOpen } = this.props;
    return (
      <React.Fragment>
        {isModalOpen && <ModalPopup onCloseModal={this.closeSignup}>
          <ModalHeader title="Sign Up" onCloseModal={this.closeSignup} isSkip={true} />
          <BoldError message={errorMessage} />
          <form>
            <div className="row mb-4">
              <div className="col">
                <InputField
                  id="FirstName"
                  className="form-control custom-field"
                  type="text"
                  name="first-name"
                  placeholder="First Name"
                  defaultValue={firstName}
                  value={firstName}
                  error={(() => {
                    return firstName.length > 0 && !onlyCharactersRegex.test(firstName) ? ERROR_FIRST_NAME : null
                  })()}
                  onChange={(value) => this.setState({ firstName: value })}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <InputField
                  className="form-control custom-field"
                  type="text"
                  id="LastName"
                  name="last-name"
                  placeholder="Last Name"
                  error={(() => {
                    return lastName.length > 0 && !onlyCharactersRegex.test(lastName) ? ERROR_LAST_NAME : null
                  })()}
                  defaultValue={lastName}
                  value={lastName}
                  onChange={(value) => this.setState({ lastName: value })}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <EmailField
                  id="EmailField"
                  defaultValue={emailId}
                  value={emailId}
                  onChange={(value) => this.setState({ emailId: value })}
                  isEmailValid={(isEmailValid) => this.setState({ isEmailValid })} />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <MobileNumberInput
                  id="MobileNumberInput"
                  className='form-control custom-field'
                  mobileNumberDefaultValue={mobileNumber}
                  value={mobileNumber}
                  onChangeDropDown={(e) => this.setState({ mobileCountryCode: e.target.value })}
                  onChangeMobileNumber={(e) => this.setState({ mobileNumber: e.target.value })}
                  onError={this.mobileNumberOnError}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <PasswordInput
                  id="password"
                  showEyeIcon
                  defaultValue={password} value={password}
                  onChange={(value) => this.setState({ password: value })}
                  isPasswordValid={passwordValid => this.setState({ passwordValid })} />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <PasswordInput
                  id="RetypePassword"
                  showEyeIcon
                  hidePopover
                  className="form-control custom-field"
                  type="password"
                  name="password"
                  placeholder="Retype Password"
                  defaultValue={retypePassword}
                  value={retypePassword}
                  onChange={(value) => this.setState({ retypePassword: value })}
                  error={retypePassword !== '' && password !== retypePassword ? Constants.REGISTER.error.passkeyNotMatch : false}
                />
              </div>
            </div>
            <div className="terms-check-box">
              <div className="form-group form-check terms-conditions">
                <input type="checkbox" className="form-check-input" onClick={() => this.setState({ termsCheckbox: !this.state.termsCheckbox })} id="exampleCheck1" />
                <label className="form-check-label terms-checkbox sign-up-label" for="exampleCheck1">I have read and understood the <a
                  className="terms"
                  href={`${DEV_URL}policy/privacy_policy.html`} target="blank">data protection</a> and <a className="terms"
                    href={`${DEV_URL}policy/tos.html`} target="blank">terms of service</a> information</label>
              </div>
            </div>
            <div className="text-center pt-4 mb-5">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#verify-modal"
                className="btn btn-dark big-btn w-100 px-5 text-uppercase" id='signup' onClick={() => this.invokeSignUpApi()}>SIGN UP</button>
            </div>

            <div className="text-start">
              <div className="text-dark text-link" id='login' onClick={() => this.openSignup()}><strong>Back to login</strong></div>
            </div>
          </form>
        </ModalPopup>}
      </React.Fragment>
    );
  }
}

const dispatchToProps = {
  signUpAction: Actions.signUpAction
}
export default withRouter(connect(null, dispatchToProps)(Register));
