import React from 'react'
import { withRouter } from 'react-router-dom'

class NutritionInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { item } = this.props
        return (
            <React.Fragment>
                <div className="borderLine">
                    <div className="card order-summary-nutrition  card-body  ">
                        <h2 className="font16 fw-bold ">Nutritions Facts</h2>
                        <div className="lineNutri" />
                        {item?.nutritionalInformation?.displayEcommerceCalories && <><div className="row">
                            <div
                                className="col">Calories</div>
                            <div
                                className="col text-end">{item && item?.nutritionalInformation?.calories?.toFixed(2)}%</div>
                        </div>
                            <div className="smalllineNutri" /></>}
                        {item?.nutritionalInformation?.displayEcommerceCarbohydrates && <><div className="row">
                            <div
                                className="col">Carbohydrates</div>
                            <div
                                className="col text-end">{item && item?.nutritionalInformation?.carbohydrates?.toFixed(2)}%</div>
                        </div>
                            <div className="smalllineNutri" /></>}
                        {item?.nutritionalInformation?.displayEcommerceFats && <><div className="row">
                            <div
                                className="col">Fats</div>
                            <div
                                className="col text-end">{item && item?.nutritionalInformation?.fats?.toFixed(2)}%</div>
                        </div>
                            <div className="smalllineNutri" /></>}
                        {item?.nutritionalInformation?.displayEcommerceProtein && <><div className="row">
                            <div className="col">Protein</div>
                            <div className="col text-end">{item && item?.nutritionalInformation?.protein?.toFixed(2)}%</div>
                        </div>
                            <div className="smalllineNutri" /></>}
                        {item?.nutritionalInformation?.displayEcommerceSugar &&
                            <div className="row ">
                                <div className="col">Sugar</div>
                                <div className="col text-end">{item && item?.nutritionalInformation?.sugar?.toFixed(2)}%</div>
                            </div>
                        }

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(NutritionInfo)