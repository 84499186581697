import React, { Component } from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import ModalPopup, { ModalFooter, ModalHeader } from "../../component/ModalPopup";

class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      showOldPassword: false,
    };
  }

  deleteAccount() {
    let { token } = this.props.getUserDetail || {};
    const params = {
      id: token?.customerId,
      password: this.state.oldPassword,
    };

    const callback = (response) => {
      let { response_code, response_message } = response || {};
      if (response_code === 0) {
        toast.success("Succesfully Deleted", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.props.logout();
      } else {
        toast.error(response_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    this.props.deleteAccount(params, callback);
    this.props.onCloseModal()
  }

  onShowPassword = (value, details) => {
    if (details === "oldPassword") {
      this.setState({ showOldPassword: value });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };


  render() {
    const { isModalOpen, onCloseModal } = this.props;
    const { oldPassword, showOldPassword } = this.state;
    return (
      <React.Fragment>
        {isModalOpen && <ModalPopup onCloseModal={onCloseModal}>
          <ModalHeader title="Are your sure ?" />
          <h5 className="">Do you want delete your account?</h5>
          <div className="input-group mb-4">
            <input
              id="InputField"
              type={showOldPassword ? "text" : "password"}
              className="form-control custom-field"
              name="oldPassword"
              placeholder="Password"
              aria-describedby="view-pass"
              defaultValue={oldPassword}
              onChange={(e) => this.handleChange(e)}
              value={oldPassword}
            />
            <button
              className="btn custom-field"
              type="button"
              id="view-opass"
            >
              {showOldPassword === true ? (
                <span id='truePwd'
                  onClick={() => this.onShowPassword(false, "oldPassword")}
                >
                  <i className="fa fa-eye" />
                </span>
              ) : (
                <span id='falsePwd'
                  onClick={() => this.onShowPassword(true, "oldPassword")}
                >
                  <i className="fas fa-eye-slash"></i>
                </span>
              )}
            </button>
          </div>
          <ModalFooter onSuccess={() => this.deleteAccount()} buttonName="Yes, Delete My Account" className="btn btn-dark p-3 text-uppercase" />
        </ModalPopup>}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getUserDetail: Actions.getUserDetail(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    deleteAccount: Actions.accountDelete,
    logout: Actions.logout,
  })(DeleteAccount)
);
