
const NAME = "FOOD_PRODUCT_LIST";
export const FOOD_PRODUCT_LIST = `${NAME}/FOOD_PRODUCT_LIST`;
export const FOOD_PRODUCT_LIST_SUCCESS = `${NAME}/FOOD_PRODUCT_LIST_SUCCESS`;
export const FOOD_PRODUCT_LIST_FAILURE = `${NAME}/FOOD_PRODUCT_LIST_FAILURE`;


export const getFoodProductList = store => store[NAME]
export const foodProductList = (params) => {
    return {
        type: FOOD_PRODUCT_LIST,
        params
    }
}

export const foodProductListSuccess = (data) => {
    return {
        type: FOOD_PRODUCT_LIST_SUCCESS,
        data
    }
}

export const foodProductListFailure = (error) => {
    return {
        type: FOOD_PRODUCT_LIST_FAILURE,
        error
    }
}

