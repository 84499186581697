import { combineReducers } from 'redux';
import login from './login';
import addToCart from './addToCart'
import groceryList from './groceryList'
import clientCredentials from './clientCredentials'
import forgotPassword from './forgotPassword'
import signup from './signup';
import foodProductList from './foodProductList'
import cartDetails from './cartDetails'
import address from './address'
import orders from './orders'
import updateCartDetails from './updateCartDetails';
import franchise from './franchise'
import feedBack from './feedBack';
import chatsReducer from './chatsReducer';
import account from './account';
import productGroupInformation from './productGroupInformation'
import pimProducts from './pimProducts';
import pimCart from './pimCart'
import daxbotCheckAvailability from './daxbotCheckAvailability';
import subscriptionList from './subscription';
import Actions from '../action';
import Rewards from './rewardsReducer'
import accountDelete from './accountDelete'
import managePayment from './managePayment'
import manageCards from './manageCards'
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import addressType from './addressType';
import getStoreList from './getStoreList';
import guestCustomer from './CustomerCare'
const transforms = {
  storage: storage,
  transforms: [
    encryptTransform({
      secretKey: 'nnbCustomerReduxPersistSecretKey',
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
}

const loginPersistConfig = {
  key: 'login',
  ...transforms
};

const addToCartPersistConfig = {
  key: 'addToCart',
  ...transforms
};

const groceryListPersistConfig = {
  key: 'groceryList',
  ...transforms
};

const clientCredentialsPersistConfig = {
  key: 'clientCredentials',
  ...transforms
};

const forgotPasswordPersistConfig = {
  key: 'forgotPassword',
  ...transforms
};

const signupPersistConfig = {
  key: 'signup',
  ...transforms
};

const getStoreListPersistConfiq = {
  key: 'getStoreList',
  ...transforms
};

const foodProductListPersistConfig = {
  key: 'foodProductList',
  ...transforms
};

const cartDetailsPersistConfig = {
  key: 'cartDetails',
  ...transforms
};

const addressPersistConfig = {
  key: 'address',
  ...transforms
};

const ordersPersistConfig = {
  key: 'orders',
  ...transforms
};

const updateCartDetailsPersistConfig = {
  key: 'updateCartDetails',
  ...transforms
};

const accountPersistConfig = {
  key: 'account',
  ...transforms
};

const chatsReducerPersistConfig = {
  key: 'chatsReducer',
  ...transforms
};

const feedBackPersistConfig = {
  key: 'feedBack',
  ...transforms
};

const franchisePersistConfig = {
  key: 'franchise',
  ...transforms
};

const pimProductsPersistConfig = {
  key: 'pimProducts',
  ...transforms,
  blacklist: ['isDetailLoading', 'isBistroDetailLoading', 'isPimBistroLoading']
};

const pimCartPersistConfig = {
  key: 'pimCart',
  ...transforms
};

const daxbotCheckAvailabilityPersistConfig = {
  key: 'daxbotCheckAvailability',
  ...transforms
};

const accountDeletePersistConfig = {
  key: 'accountDelete',
  ...transforms
};


const managePaymentPersistConfig = {
  key: 'managePayment',
  ...transforms
};


const subscriptionListPersistConfig = {
  key: 'subscriptionList',
  ...transforms
};

const rewardsPersistConfig = {
  key: 'accountDelete',
  ...transforms
};
const addressTypePersistConfig = {
  key: 'addressType',
  ...transforms
};
const groupPersistConfig = {
  key: 'productGroup',
  ...transforms,
  blacklist: ['isLoading']
};

const manageCardsConfig = {
  key: 'savedCards',
  ...transforms
};

const manageGuestCustomerConfig = {
  key: 'guestCustomer',
  ...transforms
};

const appReducer = combineReducers({
  USER: persistReducer(loginPersistConfig, login),
  ADD_TO_CART: persistReducer(addToCartPersistConfig, addToCart),
  GROCERY_LIST: persistReducer(groceryListPersistConfig, groceryList),
  CLIENT_CREDENTIALS: persistReducer(clientCredentialsPersistConfig, clientCredentials),
  FORGOT_PASSWORD: persistReducer(forgotPasswordPersistConfig, forgotPassword),
  SIGN_UP: persistReducer(signupPersistConfig, signup),
  FOOD_PRODUCT_LIST: persistReducer(foodProductListPersistConfig, foodProductList),
  CART_DETAILS: persistReducer(cartDetailsPersistConfig, cartDetails),
  ADDRESS: persistReducer(addressPersistConfig, address),
  ORDERS_LIST: persistReducer(ordersPersistConfig, orders),
  UPDATE_CART: persistReducer(updateCartDetailsPersistConfig, updateCartDetails),
  FRANCHISE_MAIL: persistReducer(franchisePersistConfig, franchise),
  FEED_BACK: persistReducer(feedBackPersistConfig, feedBack),
  CHAT_SUPPORT: persistReducer(chatsReducerPersistConfig, chatsReducer),
  ACCOUNT: persistReducer(accountPersistConfig, account),
  PRODUCT_GROUP_INFORMATION: persistReducer(groupPersistConfig, productGroupInformation),
  PIM_PRODUCTS: persistReducer(pimProductsPersistConfig, pimProducts),
  PIM_CART: persistReducer(pimCartPersistConfig, pimCart),
  DAXBOT_CHECK_AVAILABILITY: persistReducer(daxbotCheckAvailabilityPersistConfig, daxbotCheckAvailability),
  ACCOUNT_DELETE: persistReducer(accountDeletePersistConfig, accountDelete),
  SUBSCRIPTION: persistReducer(subscriptionListPersistConfig, subscriptionList),
  REWARDS: persistReducer(rewardsPersistConfig, Rewards),
  ADDRESS_TYPE: persistReducer(addressTypePersistConfig, addressType),
  MANAGE_PAYMENT: persistReducer(managePaymentPersistConfig, managePayment),
  MANAGE_CARDS: persistReducer(manageCardsConfig, manageCards),
  GET_STORE_LIST: persistReducer(getStoreListPersistConfiq, getStoreList),
  CUSTOMER_CARE: persistReducer(manageGuestCustomerConfig, guestCustomer)
});


const rootReducer = (state, action) => {
  if (action.type === Actions.LOGOUT_SUCCESS) {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload()
    }, 0);
  }
  return appReducer(state, action)
}

export default rootReducer;