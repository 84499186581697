import React from "react";
import { withRouter } from "react-router-dom";
import Constants from "../../Utility/Constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import { isFieldEmpty } from "../../Utility/validation";
import ModalPopup, { ModalFooter, ModalHeader } from "../../component/ModalPopup";

class FeedBack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        feedBackType: "",
        feedBack: "",
      },
      errors: {},
    };
  }

  handleSubmitValidation(fields) {
    let isFormValid = true;
    fields.forEach((field) => {
      if (!this.handleValidation(field)) {
        isFormValid = false;
      }
    });
    return isFormValid;
  }

  handleChange(e) {
    let { fields } = this.state;
    let { name, value } = e.target;
    fields[name] = value;
    this.setState({ name }, () => {
      this.handleValidation(name);
    });
  }

  handleValidation(field) {
    let { fields, errors } = this.state;
    switch (field) {
      case "feedBack":
        if (isFieldEmpty(fields[field])) {
          errors[field] = "Please enter message";
          this.setState({ errors });
          return false;
        }
        break;
      case "feedBackType":
        if (isFieldEmpty(fields[field])) {
          errors[field] = "Please choose feed back type";
          this.setState({ errors });
          return false;
        }
        break;
      default:
        return null;
    }
    errors[field] = "";
    this.setState({ errors });
    return true;
  }

  sendFeedBack() {
    const { fields } = this.state;
    if (this.handleSubmitValidation(["feedBackType", "feedBack"])) {
      const callback = (response) => {
        let { response_code, response_message } = response || {};
        if (response_code === 0) {
          toast.dismiss("feedback_success");
          toast.success(response_message, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "feedback_success",
          });
        } else {
          toast.error(response_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };

      let params = {
        type: fields.feedBackType,
        message: fields.feedBack,
      };

      this.props.feedBack(params, callback);
      this.props.onCloseModal()
    }
  }
  render() {
    const { isModalOpen, onCloseModal } = this.props;
    const { fields, errors } = this.state;
    return (
      <React.Fragment>
        {isModalOpen && <ModalPopup onCloseModal={onCloseModal}>
          <ModalHeader title="Feedback" />
          <div className="row mb-4">
            <div className="col">
              <select
                id="feedBackType"
                className="form-select custom-field rounded-0 border-0"
                name="feedBackType"
                onChange={(e) => this.handleChange(e)}
              >
                <option value="">Choose feedback type</option>
                <option value={Constants.FEEDBACK.GENERAL}>General</option>
                <option value={Constants.FEEDBACK.SUGGESTION}>Suggestion</option>
                <option value={Constants.FEEDBACK.PROBLEM}>Problem</option>
              </select>
            </div>
            {errors && (
              <p className="errorMsg">{errors["feedBackType"]}</p>
            )}
          </div>

          <div className="row mb-4">
            <div className="col">
              <textarea
                rows="1"
                className="form-control custom-field"
                name="feedBack"
                id="feedBack"
                placeholder="Type your Feedback"
                value={fields["feedBack"]}
                onChange={(e) => this.handleChange(e)}
              ></textarea>
            </div>
            {errors && <p className="errorMsg">{errors["feedBack"]}</p>}
          </div>

          <ModalFooter onSuccess={() => this.sendFeedBack()} buttonName="SUBMIT" />
        </ModalPopup>
        }
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, {
    feedBack: Actions.feedBack,
  })(FeedBack)
);