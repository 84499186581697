import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { clientCredentials } from '../../service/index'
import { CLIENT_CREDENTIALS } from '../../config/api_endpoint';
import Utils from '../../Utility/Utils';

export function* getClientCredentials({ data={}, callback }) {
    try {
        const response = yield clientCredentials(CLIENT_CREDENTIALS, data);
        if (response && response.access_token) {
            response.expMilliseconds = Utils.convertExpirySecToMilliSec(response.expires_in);
        }
        if(callback) {
            callback(response)
        }
        yield put(Actions.clientCredentialsSuccess(response))
    }
    catch (error) {
        if(callback) {
            callback({error})
        }
        yield put(Actions.clientCredentialsFailure({ error }))
    }
}


export default function* credentialsWatcher() {
    yield all([
        takeLatest(Actions.CLIENT_CREDENTIALS, getClientCredentials),
    ]);
}
