import React from "react";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import ModalPopup from "../component/ModalPopup/ModalPopUpStore";
import { REAL_LIFE_AR_URL, REAL_LIFE_IMAGE_URL } from "../config/api_endpoint";
var QRCode = require('qrcode.react');

class RealLifeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { isModalOpen, onCloseModal, realLifeSku } = this.props;
        return (
            <React.Fragment>
                {isModalOpen && <ModalPopup className="modal-lg" onCloseModal={onCloseModal} closeButton={true}>

                    <div className="row">
                        <div className="col-8" >
                            <div className="iframe-container">
                                <iframe
                                    id="iframe_rack"
                                    title="real-3d"
                                    className="iframe"
                                    src={`${REAL_LIFE_IMAGE_URL}${realLifeSku?.trim('')}`}
                                >
                                </iframe>
                            </div>
                        </div>
                        <div className="col-4">
                            <center>
                                <QRCode size={170}
                                    imageSettings={{
                                        height: 124,
                                        width: 124,
                                    }}
                                    value={`${REAL_LIFE_AR_URL}${realLifeSku}`} />
                            </center>
                            <div className="text-center mt-2">
                                <h6 className="fw-bold text-uppercase">SCAN THIS QR WITH YOUR PHONE TO VIEW THE PRODUCT IN YOUR SPACE</h6>
                            </div>
                        </div>
                    </div>
                </ModalPopup>
                }
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps, {})(RealLifeComponent));