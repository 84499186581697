import * as login from './login'
import * as addToCart from './addToCart'
import * as groceryList from './groceryList'
import * as clientCredentials from './clientCredentials'
import forgotPassword from './forgotPassword'
import * as signup from './signup';
import * as foodProductList from './foodProductList'
import * as cartDetails from './cartDetails'
import address from './address'
import orders from './orders'
import * as updateCartDetails from './updateCartDetails'
import * as socket from './socket';
import * as paymentActions from './paymentAction';
import * as franchise from './franchise'
import * as feedBack from './feedback';
import * as chatActions from './chatsAction';
import account from './account';
import * as productGroupInformation from './productGroupInformation'
import * as pimProducts from './pimProducts'
import * as pimCart from './pimCart'
import * as daxbotCheckAvailability from './daxbotCheckAvailability'
import * as asaccountDelete from './accountDelete'
import subscription from './subscription';
import * as rewards from './rewardsAction'
import * as addressType from './addressType'
import * as managePayment from './managePayment'
import * as manageCards from './manageCards'
import * as getStoreList from './getStoreList'
import * as guestCustomer from './CustomerCare'
export default {
    ...login,
    ...addToCart,
    ...groceryList,
    ...clientCredentials,
    ...forgotPassword,
    ...signup,
    ...foodProductList,
    ...cartDetails,
    ...address,
    ...orders,
    ...updateCartDetails,
    ...socket,
    ...paymentActions,
    ...franchise,
    ...feedBack,
    ...chatActions,
    ...account,
    ...productGroupInformation,
    ...pimProducts,
    ...pimCart,
    ...daxbotCheckAvailability,
    ...asaccountDelete,
    ...subscription,
    ...rewards,
    ...addressType,
    ...managePayment,
    ...manageCards,
    ...getStoreList,
    ...guestCustomer
}
