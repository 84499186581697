import React from 'react';
import { useSelector } from 'react-redux';
import action from '../redux/action';
import eventBus, { EVENT_SIGN_IN_OPEN } from '../Utility/event';

const AddToCartButtonDetails = ({ onClick = () => null }) => {

    const userObj = useSelector(action.getUserDetail);

    const onClickHandler = () => {
        const { token, customer = {} } = userObj;
        if (token && customer.smsOtpVerified) {
            onClick();
        } else {
            eventBus.dispatch(EVENT_SIGN_IN_OPEN, { isAddToCart: true });
        }
    }

    return <button className="btn btn-dark big-btn mb-5 fw-bold text-uppercase" name="" onClick={() => onClickHandler()}>+ Add to Cart</button>
}

export default AddToCartButtonDetails;