import React from 'react';
import loader from "../../asset/img/loader.svg"

export default () => {
    return (
        <>
            <div className="modal d-block " id="loader" tabIndex="-1" aria-labelledby="loader" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered p-3">
                    <div className="modal-content p-4 ">
                        <div className="modal-body text-center">
                            <h2 className="font26 fw-normal breathe">Loading</h2>
                            <img src={loader} alt="loading..." />
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}