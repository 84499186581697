import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import routes from '../config/routes';
import Actions from "../redux/action";

import eventBus, { EVENT_SIGN_IN_OPEN } from "../Utility/event";
import { toast } from 'react-toastify';

class PaymentVerification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    componentDidMount() {

        //  Verify customer login the existing application //
        const { token, customer = {} } = this.props.userDeatils;
        const { id } = this.props.match.params

        if (token && customer?.isLiveAgent) {
            toast.error("Customer only can able to use this link")
            this.props.history.push("/")
            return;
        }

        if (token && customer?.smsOtpVerified) {
            localStorage.removeItem("previousCartId")
            const callback = (resp) => {
                if (!resp?.error) {
                    this.props.history.push({ pathname: routes.CHOOSE_ADDRESS })
                }
            }
            let params = { cartId: id }
            this.props.cardSwap(params, callback)
        } else {
            localStorage.setItem("previousCartId", id)
            eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
        }

    }
    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        userDeatils: Actions.getUserDetail(state),
    };
};

export default withRouter(connect(mapStateToProps, {
    getGuestTransactions: Actions.getGuestTransactions,
    cardSwap: Actions.cardSwap
})(PaymentVerification))