export const isValidEmail = (email) => {
    const validEmailRegex = RegExp(/^[a-zA-Z0-9+.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)
    return validEmailRegex.test(email);
}


export const isFieldEmpty = (field) => {
    return (!field || field.trim().length === 0)
}


export const isValidNumber = (field = '') => {
    // eslint-disable-next-line no-useless-escape
    var letters = /^\d+$/;
    return letters.test(field);
}

export const isValidMobile = (field) => {
    // eslint-disable-next-line no-useless-escape
    var phoneNo = /^\d{10}$/;
    return phoneNo.test(field)
}

export const getQueryParams = (searchPath) => {
    return new URLSearchParams(searchPath);
     
}