import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootWatchers from './saga';
import rootReducer from './reducer'
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';


const sagaMiddleware = createSagaMiddleware();
let middleware;
middleware = applyMiddleware(sagaMiddleware);

export const store = createStore(rootReducer, {}, composeWithDevTools(middleware),);
export const persistor = persistStore(store);

sagaMiddleware.run(rootWatchers);



