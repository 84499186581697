import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthPost } from '../../service/index'
import { ADD_TO_CART } from '../../config/api_endpoint';

export function* addToCart({ params, callback }) {
    try {
        const response = yield doAuthPost(ADD_TO_CART, params);
        yield put(Actions.addToCartSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.addToCartFailure(error));
    }
}


export default function* menuWatcher() {
    yield all([
        takeLatest(Actions.ADD_TO_CART, addToCart),
    ]);
}
