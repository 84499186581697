const NAME = "SUBSCRIPTION";
export const GET_SUBSCRIBED_LIST = `${NAME}/GET_SUBSCRIBED_LIST`;
export const GET_SUBSCRIBED_LIST_SUCCESS = `${NAME}/GET_SUBSCRIBED_LIST_SUCCESS`;
export const GET_SUBSCRIBED_LIST_FAILURE = `${NAME}/GET_SUBSCRIBED_LIST_FAILURE`;

export const INITIALIZE_SUBSCRIPTION_PAYMENT = `${NAME}/INITIALIZE_SUBSCRIPTION_PAYMENT`;
export const CANCEL_SUBSCRIPTION_ACTION = `${NAME}/CANCEL_SUBSCRIPTION_ACTION`;



export const getAllSubscribedList = store => store[NAME].subscribedList || {};
export const getSubscribedListLoading = store => store[NAME].isLoading;

export const getSubscribedList = (params, callback) => {
    return {
        type: GET_SUBSCRIBED_LIST,
        params,
        callback
    }
}

export const getSubscribedListSuccess = (data) => {

    return {
        type: GET_SUBSCRIBED_LIST_SUCCESS,
        data
    }
}

export const getSubscribedListFailure = (error) => {
    return {
        type: GET_SUBSCRIBED_LIST_FAILURE,
        error
    }
}

export const initializeSubscriptionPayment = (params, callback) => {
    return {
        type: INITIALIZE_SUBSCRIPTION_PAYMENT,
        params,
        callback
    }
}

export const cancelSubscriptionAction = (params, callback) => {
    return {
        type: CANCEL_SUBSCRIPTION_ACTION,
        params,
        callback
    }
}
