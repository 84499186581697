export const UPDATE_ADDRESS = `UPDATE_ADDRESS`;
export const UPDATE_ADDRESS_SUCCESS = `UPDATE_ADDRESS_SUCCESS`;
export const UPDATE_ADDRESS_FAILURE = `UPDATE_ADDRESS_FAILURE`;

export const updateAddress = (params, callback) => {
    return {
        type: UPDATE_ADDRESS,
        params,
        callback
    }
}
export const updateAddressSuccess = (data) => {
    return {
        type: UPDATE_ADDRESS_SUCCESS,
        data
    }
}

export const updateAddressFailure = (error) => {
    return {
        type: UPDATE_ADDRESS_FAILURE,
        error
    }
}





