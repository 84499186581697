const ADD_PAYMENT_INFO_ACTION = 'ADD_PAYMENT_INFO_ACTION';
const GET_CARD_INFO_ACTION = 'GET_CARD_INFO_ACTION';
const INIT_PRE_AUTH_PAYMENT = 'INIT_PRE_AUTH_PAYMENT';
export {
    ADD_PAYMENT_INFO_ACTION,
    GET_CARD_INFO_ACTION,
    INIT_PRE_AUTH_PAYMENT
}


export const addPaymantInformation = (callback) => {
    return {
        type: ADD_PAYMENT_INFO_ACTION,
        callback
    }
}


export const getPaymentcardInfoAction = (callback) => {
    return {
        type: GET_CARD_INFO_ACTION,
        callback
    }
}

export const initializeAuthPaymentAction = (params, callback) => {
    return {
        type: INIT_PRE_AUTH_PAYMENT,
        params,
        callback
    }
}