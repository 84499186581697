import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../../redux/action";
import Placeholder from "../../asset/images/img.jpg";
import cloneDeep from "lodash.clonedeep";
import { toast } from "react-toastify";
import ModalPopup from "../../component/ModalPopup";
import { getRandomNumber } from "../../Utility/Utils";
export class AddOn extends React.Component {
  constructor(props) {
    super(props);
    const cartProductInfo = props?.getPimCartDetails?.pimCart?.products
      ?.reverse()
      .find(
        (cartDetailsProductList) =>
          cartDetailsProductList.sku === props.productInfo.sku
      );
    const cartInfo = cartProductInfo?.selectedAddOns;
    const getSelctedAddons = cartInfo?.reduce((previous, current) => {
      previous.push(current.upc);
      return previous;
    }, []);
    this.state = {
      checkedItemsIds: getSelctedAddons || [],
      isUpdateProduct: !!cartInfo,
    };
  }

  onChangeCheckbox(details) {
    const { checkedItemsIds } = this.state;
    const index = checkedItemsIds?.findIndex((s) => s === details?.upc);
    if (index === -1) {
      this.setState({ checkedItemsIds: [...checkedItemsIds, details?.upc] });
    } else {
      const items = [...checkedItemsIds];
      items.splice(index, 1);
      this.setState({ checkedItemsIds: items });
    }
  }

  addOrUpdate() {
    const { productInfo } = this.props;
    const { checkedItemsIds } = this.state;
    const {
      pimCart: { products },
    } = this.props.getPimCartDetails;
    const allProductsPerId = products?.filter(
      (data) => data.sku === productInfo.sku
    );
    const cartElement = allProductsPerId?.find((cartProduct) => {
      const arr = cartProduct?.selectedAddOns?.map((ml) => ml.upc);
      return arr && arr.sort().join(",") === checkedItemsIds.sort().join(",");
    });
    if (cartElement) {
      this.updateItemToCart(cartElement);
    } else {
      this.addAddonsToCartItem();
    }
  }

  pimCartCallback = (response) => {
    const { closeAddOnModal } = this.props
    const { response_code } = response;
    if (response_code === 0) {
      closeAddOnModal();
    } else {
      toast.error(response?.response_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  addAddonsToCartItem() {
    const { productInfo } = this.props;
    const { checkedItemsIds } = this.state;
    const params = {
      bistroProduct: true,
      productSku: productInfo?.sku,
      storeId: 1,
      quantity: 1,
      addOnIds: checkedItemsIds ? checkedItemsIds : [],
    };

    this.props.createPimCart(params, this.pimCartCallback);
  }

  updateItemToCart(cartElement) {
    let params = {
      bistroProduct: true,
      cartItemId: cartElement?.cartItemId,
      storeId: 1,
      quantity: cartElement?.quantity + 1,
      addOnIds: [],
    };
    this.props.updatePimCart(params, this.pimCartCallback);
  }

  _totalAmount() {
    const { checkedItemsIds } = this.state;
    const { productInfo } = this.props;
    const { addOnList, sellingPrice } = productInfo;
    const count = checkedItemsIds.reduce((prev, curr) => {
      const currentObj = addOnList?.find((f) => f.upc === curr);
      if (currentObj?.sellingPrice === 0) return prev;
      else return prev + currentObj?.sellingPrice;
    }, 0);
    const totalCost = count + sellingPrice;
    return totalCost?.toFixed(2);
  }
  render() {
    const { closeAddOnModal, productInfo } = this.props;
    const { addOnList } = cloneDeep(
      productInfo || {}
    );
    const { checkedItemsIds } = this.state;
    const productAddOnMappingList = [
      {
        info: "Choice of dressing",
        data: [...addOnList.filter((i) => i.sellingPrice === 0)],
      },
      {
        info: "Add-ons",
        data: [...addOnList.filter((i) => i.sellingPrice > 0)],
      },
    ];
    return (
      <ModalPopup onCloseModal={closeAddOnModal}>
        <div className="row align-items-center mb-4 pb-3 border-bottom">
          <div className="col-auto">
            <img
              alt="images"
              height="60px"
              src={
                productInfo.imageUrl
                  ? productInfo.imageUrl + '?$' + getRandomNumber()
                  : Placeholder
              }
            />
          </div>
          <div className="col font16 fw-bold">
            Customize {productInfo?.productName}
          </div>
        </div>
        <>
          {productAddOnMappingList.map((pAddons) => {
            return (
              <form>
                {pAddons?.info && pAddons?.data?.length ? (
                  <div className="row mt-5 mb-3">
                    <div className="col fw-bold">
                      {pAddons?.info}
                    </div></div>
                ) : null}

                {pAddons.data.map((addons) => {
                  const price =
                    addons?.sellingPrice !== 0
                      ? `$${addons?.sellingPrice?.toFixed(2)}`
                      : "Free";
                  return (
                    <>
                      <div className="row mb-2">
                        <div className="col-auto">
                          <div className="form-check" style={{ cursor: 'pointer' }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`checkboxNoLabel_${addons.upc}`}
                              aria-label="..."
                              defaultChecked={checkedItemsIds.includes(
                                addons?.upc
                              )}
                              onClick={() =>
                                addons && this.onChangeCheckbox(addons)
                              }
                              checked={checkedItemsIds.includes(addons?.upc)}

                            />
                            <label style={{ cursor: 'pointer' }} className="form-check-label" for={`checkboxNoLabel_${addons.upc}`}>
                              {addons?.productName}
                            </label>
                          </div>
                        </div>
                        <div className="col text-end"> {`${price}`}</div>
                      </div>
                    </>
                  );
                })}
              </form>
            );
          })}
        </>
        <div className="text-center pt-5 mb-3">
          <button
            type="button"
            className="btn btn-dark big-btn w-100 px-5 text-uppercase"
            onClick={() => this.addOrUpdate()}
          >
            Add Items <strong>$ {this._totalAmount()}</strong>
          </button>
        </div>
      </ModalPopup>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getFoodProductList: Actions.getFoodProductList(state),
    getPimCartDetails: Actions.getPimCartDetails(state),
  };
};
export default withRouter(
  connect(mapStateToProps, {
    createPimCart: Actions.createPimCart,
    updatePimCart: Actions.updatePimCart,
  })(AddOn)
);
