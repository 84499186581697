import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../../redux/action";
import Constants from "../../Utility/Constants";
import { isFieldEmpty } from "../../Utility/validation";
import 'react-toastify/dist/ReactToastify.css';
import InputField from "../InputField";
import eventBus, { EVENT_SIGN_IN_OPEN, EVENT_FORGET_PASSWORD_CLOSE, EVENT_RESET_PASSWORD_OPEN } from "../../Utility/event";
import ModalPopup, { ModalHeader } from "../../component/ModalPopup";
import CountryDropDown from '../../component/CountryDropDown';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        mobileNumber: '',
        dropdown: '+1',
        otp: ''
      },
      errors: {
        mobileNumber: ''
      },
      error: '',
      errorMessage: '',
      isSendOtp: false
    };
  }

  handleChange(e) {
    let { fields } = this.state
    let { name, value } = e.target
    fields[name] = value
    this.setState({ fields }, () => {
      this.handleValidation(name)
    })
  }

  handleValidation(field) {
    let { fields, errors } = this.state;
    if (field === 'mobileNumber') {
      if (isFieldEmpty(fields[field])) {
        errors[field] = Constants.FORGET_PASSWORD.error.mobileNumberEmpty;
        this.setState({ errors })
        return false
      } else if (fields[field].length !== 10) {
        errors[field] = Constants.FORGET_PASSWORD.error.mobileNumber;
        this.setState({ errors })
        return false
      }
    }
    errors[field] = ''
    this.setState({ errors })
    return true;
  }

  handleSubmitValidation(fields) {
    let isFormValid = true;
    fields.forEach((field) => {
      if (!this.handleValidation(field)) {
        isFormValid = false
      }
    })
    return isFormValid;
  }
  requestOtp() {
    let { fields } = this.state
    if (this.handleSubmitValidation(["mobileNumber"])) {
      const callback = (response) => {
        let { response_code, response_message } = response
        if (response_code === 2) {
          this.setState({ errorMessage: response_message })
        } else {
          this.props.successEmail(fields.dropdown + fields.mobileNumber)
          this.setState({ isSendOtp: true, errorMessage: '' })
        }
      }

      let params = {
        userName: fields.dropdown + fields.mobileNumber,
      }

      this.props.requestOtp(params, callback)
    }
  }

  closeForgetModal = (msg) => {
    if (msg) {
      this.props.onCloseModal(msg)
    } else {
      this.props.onCloseModal()
    }
    this.setState({ error: '', fields: {}, errorMessage: '' })
  }

  openLoginModal() {
    eventBus.dispatch(EVENT_FORGET_PASSWORD_CLOSE, {})
    eventBus.dispatch(EVENT_SIGN_IN_OPEN, {})
  }

  verifyOtp() {
    const { otp, mobileNumber } = this.state.fields;
    if (otp === null || otp.length <= 0) {
      this.setState({ error: Constants.VERIFY_OTP.emptyField });
    } else {
      const { fields } = this.state;
      const callback = (response) => {
        let { response_code, response_message } = response
        if (response_code === 0) {
          this.closeForgetModal('success')
          eventBus.dispatch(EVENT_RESET_PASSWORD_OPEN, {})
        } else {
          this.setState({ error: response_message })
        }
      }
      let params = {
        mobileNumber: fields.dropdown + mobileNumber,
        smsOtp: otp
      }
      this.props.forgetPasswordVerifyOtp(params, callback)
    }
  }

  render() {
    const { isModalOpen } = this.props;
    const { errorMessage, isSendOtp, errors, error } = this.state;
    return (
      <React.Fragment>
        {isModalOpen && <ModalPopup id="closeForgetModal" onCloseModal={() => this.closeForgetModal()}>
          <ModalHeader title="Forgot Password" onCloseModal={() => this.closeForgetModal()} isSkip={true} />
          <form>
            <div className="row mb-5">
              <div className="col"> We will send a one-time password to the mobile number to reset your password</div>
            </div>
            {
              (errorMessage) && (<div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>)
            }
            <div className="row align-items-center  mb-5">
              <div className="col-2">
                <CountryDropDown
                  id="CountryDropDown"
                  defaultValue='+1'
                  onChange={(v) => this.handleChange({ target: { value: v, name: 'dropdown' } })} />
              </div>
              <div className="col">
                <InputField
                  style={{ width: '100%' }}
                  className="form-control custom-field forgot-input"
                  type="number"
                  id="number"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  onChange={(value, name) => this.handleChange({ target: { value, name } })}
                  disabled={isSendOtp}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                  }}
                />
              </div>
              {errors && <p className="errorMsg">{errors['mobileNumber']}</p>}
            </div>

            {isSendOtp &&
              <div className="row mb-5">
                <div className="col-12">
                  <InputField
                    id="otpField"
                    name="otp"
                    type="number"
                    autoComplete="off"
                    className="form-control custom-field mb-3"
                    tabIndex="1"
                    placeholder="Please enter otp"
                    maxLength="6"
                    onChange={(value, name) => this.handleChange({ target: { value, name } })}
                  />
                  {error && <p className="errorMsg">{error}</p>}
                </div>
              </div>}

            <div className="text-center pt-4 mb-5">
              <button
                type="button"
                id="verifyOtp"
                data-bs-toggle="modal"
                data-bs-target="#verify-modal"
                onClick={isSendOtp ? () => this.verifyOtp() : () => this.requestOtp()}
                className="btn btn-dark big-btn w-100 px-5 text-uppercase">
                {isSendOtp ? "RESET" : "SEND OTP"}
              </button>
            </div>

            <div className="text-start">
              <div id="login" onClick={() => this.openLoginModal()} className="text-dark text-link"><strong>Back to login</strong></div>
            </div>
          </form>
        </ModalPopup>}
      </React.Fragment>
    );
  }
}


export const mapStateToProps = (state) => {
  return {
  };
};

export default withRouter(connect(mapStateToProps, {
  requestOtp: Actions.requestOtpAction,
  forgetPasswordVerifyOtp: Actions.forgetPasswordVerifyOtp,
})(ForgotPassword))
