import React from 'react';
import BoldError from './BoldError';
import ModalPopup, { ModalHeader } from "../component/ModalPopup";


const AlertModal = ({ onClose = () => null, message = null, children, title = '' }) => {
    return <ModalPopup onCloseModal={() =>
        onClose()}>
        <ModalHeader title={title} onCloseModal={() => onClose} />
        <BoldError message={message} />
        {children}
    </ModalPopup>
}


export default AlertModal;