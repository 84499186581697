import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthGet, doContionalGet } from '../../service/index'
import { GET_STORE_LIST, STORE_LIST } from '../../config/api_endpoint';

export function* getStoreList(params) {
    try {
        const response = yield doAuthGet(GET_STORE_LIST);
        yield put(Actions.storeListSuccess(response));
    } catch (error) {
        yield put(Actions.storeListFailure(error));
    }
}
export function* getMultipleStoreList({ params, callback }) {
    try {
        const response = yield doContionalGet(STORE_LIST, params);
        if (response) {
            yield put(Actions.getMultipleStoreListSuccess(response));
        }
        callback && callback(response?.response)
    }
    catch (error) {
        yield put(Actions.getMultipleStoreListFailure({ error }));
        callback && callback([])
    }
}


export default function* cartWatcher() {
    yield all([
        takeLatest(Actions.GET_STORE_LIST, getStoreList),
        takeLatest(Actions.STORE_LIST, getMultipleStoreList),
    ]);
}
