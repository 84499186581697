import React from "react";
import Actions from "../../redux/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Constants from "../../Utility/Constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalPopup, {
  ModalFooter,
  ModalHeader,
} from "../../component/ModalPopup";
import { isValidEmail, isValidMobile } from "../../Utility/validation";
class Franchise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      message: "",
      error: "",
      errorMessage: "",
    };
  }

  onSendFranchiseMail = () => {
    const { firstName, lastName, email, mobileNumber, message } = this.state;

    let error = null;
    if (!firstName) {
      error = Constants.FRANCHISE.errorMessages.firstName;
    } else if (!lastName) {
      error = Constants.FRANCHISE.errorMessages.lastName;
    } else if (!email) {
      error = Constants.FRANCHISE.errorMessages.enterEmailId;
    } else if (!isValidEmail(email)) {
      error = Constants.FRANCHISE.errorMessages.validEmail;
    } else if (!mobileNumber) {
      error = Constants.FRANCHISE.errorMessages.mobileNumber;
    } else if (!isValidMobile(mobileNumber)) {
      error = Constants.FRANCHISE.errorMessages.vailMobileNumber;
    } else if (!message) {
      error = Constants.FRANCHISE.errorMessages.message;
    }

    this.setState({ errorMessage: error });
    if (error) {
      return;
    }

    const callback = (res) => {
      if (res?.response_code === 0) {
        toast.dismiss("franchise_success");
        toast.success(res?.response_message, {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "franchise_success",
        });
      } else {
        toast.error(res?.response_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    const params = {
      firstName,
      lastName,
      email,
      mobileNumber,
      message,
    };

    this.props.franchiseMail(params, callback);
    this.props.onCloseModal();
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { firstName, lastName, email, mobileNumber, message, errorMessage } =
      this.state;
    const { isModalOpen, onCloseModal } = this.props;

    return (
      // <div
      //   className="modal fade"
      //   id="franchise-modal"
      //   tabindex="1"
      //   aria-labelledby="franchise"
      //   aria-hidden="true"
      // >
      <React.Fragment>
        {isModalOpen && (
          <ModalPopup onCloseModal={onCloseModal}>
            <ModalHeader title="Franchise Inquiry" />
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <form>
              <div className="row mb-4">
                <div className="col">
                  <input
                    type="text"
                    className="form-control custom-field"
                    name="firstName"
                    placeholder="First Name"
                    onChange={this.handleChange}
                    defaultValue={firstName}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <input
                    type="text"
                    className="form-control custom-field"
                    name="lastName"
                    placeholder="Last Name"
                    defaultValue={lastName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <input
                    type="email"
                    className="form-control custom-field"
                    name="email"
                    placeholder="Email"
                    defaultValue={email}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <input
                    type="tel"
                    className="form-control custom-field"
                    name="mobileNumber"
                    placeholder="Phone Number"
                    defaultValue={mobileNumber}
                    onChange={this.handleChange}
                    maxLength="10"
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col">
                  <textarea
                    rows="2"
                    className="form-control custom-field"
                    name="message"
                    placeholder="Type your Message"
                    value={message}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
              </div>
              <ModalFooter
                onSuccess={() => this.onSendFranchiseMail()}
                buttonName="SUBMIT"
              />
            </form>
          </ModalPopup>
        )}
      </React.Fragment>
      // </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, {
    franchiseMail: Actions.franchiseMail,
  })(Franchise)
);
