import React from 'react';
import tick from '../asset/images/tick.png';
import times from '../asset/images/times.png';

const FieldValidation = ({ isSuccess, name }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img src={isSuccess ? tick : times} alt="" style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '12px',
                marginTop: '5px',
                marginRight: '10px',
                width: '12px'
            }}></img>
            <div>{name}</div>
        </div>
    )
}

export const ONE_LETTER = '[a-zA-Z0-9]+';
export const ONE_CAPITAL_LETTER = '[A-Z]+';
export const ONE_NUMBER = '.*[0-9].*';
export const ONE_SMALL_LETTER = '[a-z]';
export const ONE_SPECIAL_CHARACTER = "(?=.*[!@#$%^&*])";

export const testRegex = (regex, value) => new RegExp(regex).test(value);


const TRIANGLE = {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 10,
    borderBottomWidth: 10,
    borderLeftWidth: 10,
    marginLeft: '150px',
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'red',
    borderLeftColor: 'transparent',
};
const PasswordCheckPopover = ({ value }) => {

    return <div style={{
        position: 'relative', display: 'flex', flex: 1,
        justifyContent: 'center', alignItems: 'center', zIndex: 10
    }}>
        <div style={{ position: 'absolute', marginTop: '150px', }}>
            <div style={TRIANGLE} />
            <div className="rounded" style={{
                borderRadius: '5px',
                padding: '10px',
                border: '1px solid green',
                backgroundColor: 'white',
                overflow: 'hidden'
            }}>
                <div><h6>{'Password must meet the following\n requirements:'}</h6></div>
                <FieldValidation name="At least one letter" isSuccess={testRegex(ONE_LETTER, value)} />
                <FieldValidation name="At least one capital letter" isSuccess={testRegex(ONE_CAPITAL_LETTER, value)} />
                <FieldValidation name="At least one digit" isSuccess={testRegex(ONE_NUMBER, value)} />
                <FieldValidation name="Be at least 8 characters" isSuccess={value.length > 7} />
                <FieldValidation name="At least one special character" isSuccess={testRegex(ONE_SPECIAL_CHARACTER, value)} />
                <FieldValidation name="At least one small letter" isSuccess={testRegex(ONE_SMALL_LETTER, value)} />
            </div>
        </div>
    </div>;
}


export default PasswordCheckPopover;