const NAME = "ORDERS_LIST";

export const ORDER_DETAILS = 'ORDER_DETAILS';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE';

export const SET_IS_NEW_ORDER_STATUS = 'SET_IS_NEW_ORDER_STATUS';

export const getOrderDetails = store => store[NAME].orderDetails
export const orderDetails = (data, callback) => {
    return {
        type: ORDER_DETAILS,
        data,
        callback
    }
}

export const orderDetailsSuccess = (data) => {
    return {
        type: ORDER_DETAILS_SUCCESS,
        data
    }
}

export const orderDetailsFailure = (error) => {
    return {
        type: ORDER_DETAILS_FAILURE,
        error
    }
}

export const setIsNewOrderAction = (data) => {
    return {
        type: SET_IS_NEW_ORDER_STATUS,
        data, // boolean
    }
}