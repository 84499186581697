import React, { Component } from "react";
import Constants from "../../Utility/Constants";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import moment from "moment";
import routes from "../../config/routes";
import Pagination from "react-js-pagination";
import CartEmpty from "../../component/cartEmpty";
import { queryStringParser } from "../../Utility/Utils";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderSummary: false,
      offset: 0,
      limit: 5,
      activePage: 1,
    };
  }
  componentDidMount() {
    const { page = 1 } = queryStringParser(this.props.location.search);
    const pageNumber = isNaN(parseInt(page)) ? 1 : parseInt(page)
    this.props.entryPin(null)
    this.props.forgetPin(false)
    this.setState({ activePage: pageNumber, offset: pageNumber * 5 - this.state.limit }, () => this.getOrdersList())
  }

  getOrdersList = () => {
    const { offset, limit } = this.state;
    const params = {
      offset,
      limit,
    };
    this.props.getOrdersList(params);
  }

  handleChange(pageNumber) {
    this.setState(
      { activePage: pageNumber, offset: pageNumber * 5 - this.state.limit },
      () => {
        this.replaceHistory();
        this.orderListPageChange();
      }
    );
  }

  replaceHistory = () => {
    const { activePage } = this.state
    let nextURL = window.location.href;
    nextURL = `${nextURL.split('?')[0]}`
    nextURL = `${nextURL}?page=${activePage}`
    const nextTitle = 'N and B Customer';
    const nextState = {};
    window.history.replaceState(nextState, nextTitle, nextURL);
  }

  orderListPageChange() {
    let { offset, limit } = this.state;
    const params = {
      offset,
      limit,
    };
    this.props.getOrdersList(params);
  }
  _renderDeliveryDate(date) {
    return moment(date).format(Constants.DATE_TIME);
  }
  paymentStatus(value) {
    if (value === Constants.PAYMENT_STATUS.COMPLETED) {
      return (
        <div className="col-3 font14 text-end text-success">
          <img src={require("../../asset/img/green-tick.svg")} alt="Yes" className="me-1" />{Constants.ORDER_SUMMARY.paymentType.completed}
        </div>
      )
    } else if (value === Constants.PAYMENT_STATUS.PENDING || value === Constants.PAYMENT_STATUS.SCHEDULED) {
      return (<div className="col-3 font14 text-end text-warning">
        <img src={require("../../asset/img/pending.svg")} alt="Yes" className="me-1" />
        {Constants.ORDER_SUMMARY.paymentType.pending}
      </div>
      )
    } else if (value === Constants.PAYMENT_STATUS.PROCESSING) {
      return (<div className="col-3 font14 text-end text-warning">
        <img src={require("../../asset/img/pending.svg")} alt="Yes" className="me-1" /> {Constants.ORDER_SUMMARY.paymentType.processing}
      </div>
      )
    } else if (value === Constants.PAYMENT_STATUS.FAILED) {
      return (<div className="col-3 font14 text-end text-warning">
        <img src={require("../../asset/img/pending.svg")} alt="Yes" className="me-1" /> {Constants.ORDER_SUMMARY.paymentType.failed}
      </div>
      )
    } else if (value === Constants.PAYMENT_STATUS.REFUND_INIT) {
      return (<div className="col-3 font14 text-end text-warning">
        <img src={require("../../asset/img/pending.svg")} alt="Yes" className="me-1" />
        {Constants.ORDER_SUMMARY.paymentType.refundInitiated}
      </div>
      )
    } else if (value === Constants.PAYMENT_STATUS.REFUND_COMPLETED) {
      return (<div className="col-3 font14 text-end text-success">
        <img src={require("../../asset/img/green-tick.svg")} alt="Yes" className="me-1" />
        {Constants.ORDER_SUMMARY.paymentType.refundApproved}
      </div>
      )
    } else if (value === Constants.PAYMENT_STATUS.REFUND_FAILED) {
      return (<div className="col-3 font14 text-end text-red">
        <img src={require("../../asset/img/alert-triangle.svg")} alt="Yes" className="me-1" />
        {Constants.ORDER_SUMMARY.paymentType.refundDenied}
      </div>
      )
    }


  }

  navOrderSummary(id) {
    this.props.history.push(routes.accountOrdersSummary(id));
  }
  render() {
    const { ordersList, invoiceCount, isLoading } = this.props.orderList || {};
    const { limit, activePage } = this.state;
    return (
      <div className="col ps-md-5">
        <div className="row mb-5 align-items-center text-link">
          <div className="col-12 col">
            <h1 className="font18 fw-bold">Orders</h1>
          </div>
        </div>

        {ordersList && ordersList.map((o) => {
          let { invoiceDate, totalAmount, invoiceId, invoiceOrderDetailsList, processedAmount, createdBy, paymentStatus } = o;
          const finalAmount = invoiceOrderDetailsList?.findIndex((obj) => obj?.orderStatus === 1);
          return (
            <React.Fragment>
              <span className="row align-items-center text-link" id='navOrderSummary' onClick={() => this.navOrderSummary(invoiceId)}>
                <div className="col">
                  <h3 className="font14 mb-2">{Constants.ORDER_SUMMARY.orderNumber}: <strong>{invoiceId}</strong></h3>
                  {o.invoiceOrderDetailsList.map((i) => {
                    return (
                      <div className="text-truncate text-truncate--2 title-case">
                        {(i.invoiceOrderProductDetailsList).map((j) => j?.name?.toLowerCase()).join(', ')}
                      </div>
                    )
                  })}
                  <div className="row font14 fw-bold">
                    <div className="col">{this._renderDeliveryDate(invoiceDate)}</div>
                    <div className="col"> Paid: {createdBy !== 3 && (finalAmount !== -1 || paymentStatus === 1 || paymentStatus === 2 || paymentStatus === 3 || paymentStatus === 4) ? `${Constants.COMMONS.usd}${parseFloat(totalAmount)?.toFixed(2)}` : `${Constants.COMMONS.usd}${parseFloat(processedAmount)?.toFixed(2)}`} </div>
                  </div>
                </div>
                {this.paymentStatus(o.paymentStatus)}
              </span>
              <hr className="bg-ltgrey-1 mb-4" />
            </React.Fragment>
          )
        })
        }
        {
          (ordersList && ordersList.length === 0 && !isLoading) && <CartEmpty
            id='CartEmpty'
            buttonName={"REFRESH NOW"}
            buttonName1={"BACK TO SHOPPING"}
            body={"Please start shopping to fill your orders"}
            title={"Your Order is currently empty"}
            buttonAction={() => this.getOrdersList()}
            buttonAction1={() => this.props.history.push(`/`)}
            breadCrems={false}
            image={false}
          />
        }


        {ordersList && ordersList.length > 0 && (
          <div className="col ps-md-5">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={limit}
              totalItemsCount={invoiceCount}
              pageRangeDisplayed={5}
              onChange={this.handleChange.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    orderList: Actions.orderList(state),
    getUserDetail: Actions.getUserDetail(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getOrdersList: Actions.getOrdersList,
    entryPin: Actions.entryPin,
    forgetPin: Actions.forgetPin
  })(Orders)
);
