import React from 'react'
import { withRouter } from 'react-router-dom'

class ConfirmModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { title, description, proceed, onSuccess } = this.props
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-50">
                                <form>
                                    <div
                                        className="row mb-4">
                                        <div
                                            className="col">
                                            {
                                                title && <h3
                                                    className="font18 fw-bold mb-3">{title}</h3>
                                            }
                                            <p>{description}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div
                                            id='alert-onSuccess'
                                            className="col pe-0" onClick={() => onSuccess('success')}>
                                            <span className="btn btn-success font14 w-100 p-3">{proceed}</span> </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </React.Fragment>
        )
    }
}

export default withRouter(ConfirmModal)