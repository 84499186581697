import { all, fork } from 'redux-saga/effects';
import login from './login';
import groceryList from './groceryList';
import clientCredentials from './clientCredentials'
import forgotPassword from './forgotPassword'
import signup from './signup';
import foodProductList from './foodProductList';
import addToCart from './addToCart';
import cartDetails from './cartDetails'
import address from './address'
import orders from './order'
import updateCartDetails from './updateCartDetails';
import paymentSaga from './paymentSaga';
import pimProducts from './pimProducts'
import accountDelete from './accountDelete'
import franchise from './franchise';
import feedBack from './feedBack';
import chatsSaga from './chatsSaga'
import account from './account';
import productGroupInformation from './productGroupInformation';
import pimCart from './pimCart'
import daxbotCheckAvailability from './daxbotCheckAvailability';
import subscriptionSaga from './subscriptionSaga';
import rewardsSaga from './rewardsSaga'
import addressTypeSaga from './addressType'
import getStoreList from './getStoreList'
import manageCards from './manageCards';
import guestCustomer from './GuestCustomer'
export default function* root() {
  yield all([
    fork(login),
    fork(groceryList),
    fork(clientCredentials),
    fork(signup),
    fork(forgotPassword),
    fork(foodProductList),
    fork(addToCart),
    fork(cartDetails),
    fork(address),
    fork(orders),
    fork(updateCartDetails),
    fork(paymentSaga),
    fork(franchise),
    fork(feedBack),
    fork(chatsSaga),
    fork(account),
    fork(productGroupInformation),
    fork(pimProducts),
    fork(pimCart),
    fork(daxbotCheckAvailability),
    fork(accountDelete),
    fork(subscriptionSaga),
    fork(rewardsSaga),
    fork(addressTypeSaga),
    fork(manageCards),
    fork(getStoreList),
    fork(guestCustomer)
  ]);
}