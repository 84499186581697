import React from 'react'

class DeliveryInstruction extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { onFailure } = this.props
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabindex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-50">
                                <img
                                    id='alert-icon'
                                    src={require("../../asset/img/modal-close.svg")}
                                    alt="X"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="modal-close"
                                    onClick={() => onFailure()} />
                                <form>
                                    <div
                                        className="row mb-4">
                                        <div
                                            className="col">
                                            <h3
                                                className="font18 fw-bold mb-3 text-center"><u><b>Delivery Instruction</b></u></h3>
                                            <p>1. Please make sure, Delivery person arrives.</p>
                                            <p>2. Please show your order page to Delivery person to confirm your order Id.</p>
                                            <p>3. On Order Id verification, collect your order for the Delivery person.</p>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </React.Fragment>
        )
    }
}

export default DeliveryInstruction