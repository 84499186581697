const NAME = "CART_DETAILS";
export const CART_DETAILS = `${NAME}/CART_DETAILS`;
export const CART_DETAILS_SUCCESS = `${NAME}/CART_DETAILS_SUCCESS`;
export const CART_DETAILS_FAILURE = `${NAME}/CART_DETAILS_FAILURE`;

export const CLEAR_CART = `/CART_DETAILS_FAILURE`;



export const cartDetails = store => store[NAME]
export const getCartDetails = () => {
    return {
        type: CART_DETAILS
    }
}

export const getCartDetailsSuccess = (data) => {
    return {
        type: CART_DETAILS_SUCCESS,
        data
    }
}

export const getCartDetailsFailure = (error) => {
    return {
        type: CART_DETAILS_FAILURE,
        error
    }
}

export const clearCart = () => {
    return {
        type: CLEAR_CART,
    }
}


