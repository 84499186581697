
const NAME = "REWARDS";
export const GET_REWARDS = `${NAME}/GET_REWARDS`;
export const GET_REWARDS_SUCCESS = `${NAME}/GET_REWARDS_SUCCESS`;
export const GET_REWARDS_FAILURE = `${NAME}/GET_REWARDS_FAILURE`;


export const getRewardsList = store => store[NAME]
export const getRewards = (params) => {
    return {
        type: GET_REWARDS,
        params
    }
}

export const getRewardsSuccess = (data) => {
    return {
        type: GET_REWARDS_SUCCESS,
        data
    }
}

export const getRewardsFailure = (error) => {
    return {
        type: GET_REWARDS_FAILURE,
        error
    }
}

