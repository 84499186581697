import Actions from '../action'
const initialState = {
    isLoading: false,
    paymentCardsList: [],
    error: false,
    isCreateLoading: false,
    isCreatedError: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_PAYMENT_OPTIONS_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_PAYMENT_OPTIONS_LIST_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                paymentCardsList: action.data?.response || [],
                error: false
            };
        }
        case Actions.GET_PAYMENT_OPTIONS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.CREATE_PAYMENT_CARD: {
            return {
                ...state,
                isCreateLoading: true,
            };
        }
        case Actions.CREATE_PAYMENT_CARD_SUCCESS: {
            return {
                ...state,
                isCreateLoading: false,
                isCreatedError: false
            };
        }
        case Actions.CREATE_PAYMENT_CARD_FAILURE: {
            return {
                ...state,
                isCreateLoading: false,
                isCreatedError: action.error,
            };
        }

        default:
            return state;
    }
};
