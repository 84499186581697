import React from 'react'
import { withRouter } from 'react-router-dom'
var QRCode = require('qrcode.react');
class QrCode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            qrCode: {}
        }
    }

    render() {
        const { title, onFailure, qrImages } = this.props

        return (
            qrImages && <React.Fragment>
            <div className="modal d-block"
                id="unblock-modal"
                tabIndex="-1"
                aria-labelledby="unblock"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-50">
                            <img
                                id='alert-icon'
                                src={require("../../src/asset/img/modal-close.svg")}
                                alt="X"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                className="modal-close"
                                onClick={() => onFailure()} />

                            <div
                                className="row mb-4">
                                <div
                                    className="col text-center">
                                    <p>{title}</p>
                                </div>
                            </div>
                            < center>
                                <QRCode size={170} imageSettings={{

                                    height: 124,
                                    width: 124,
                                }} value={qrImages} />
                            </center>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </React.Fragment>
        )
    }
}
export default withRouter(QrCode)