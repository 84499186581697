import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthGet } from '../../service/index'
import { GET_REWARDS_API } from '../../config/api_endpoint'
export function* getRewards({ params }) {
    try {
        const response = yield doAuthGet(GET_REWARDS_API, params);
        yield put(Actions.getRewardsSuccess(response))
    }
    catch (error) {
        yield put(Actions.getRewardsFailure({ error }))
    }
}


export default function* rewardsWatcher() {
    yield all([
        takeLatest(Actions.GET_REWARDS, getRewards),
    ]);
}
