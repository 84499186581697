import Actions from '../action'
const initialState = {
    isLoading: false,
    productGroupInformation: null,
    error: false
}
export default (state = initialState, action = {}) => {

    switch (action.type) {
        case Actions.PRODUCT_GROUP_INFORMATION: {
            return {
                ...state,
                isLoading: !state.productGroupInformation ? true : false,
            };
        }
        case Actions.PRODUCT_GROUP_INFORMATION_SUCCESS: {
            const { response } = action.data || {}
            return {
                ...state,
                isLoading: false,
                productGroupInformation: response?.response,
                error: false
            };
        }
        case Actions.PRODUCT_GROUP_INFORMATION_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
