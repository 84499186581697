import React from 'react'
import { withRouter } from 'react-router-dom'
import QRCode from "qrcode.react";
import { connect } from 'react-redux';
import { DEV_URL } from '../config/base';
import Loader from '../component/loader';
import { toast } from 'react-toastify';

class CustomerTransactions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            link: null
        }
    }
    componentDidMount() {
        const { id } = this.props.match.params
        const makeLink = `${DEV_URL}customercare/payment/${id}`
        this.setState({ link: makeLink })
    }
    copyClipBoard() {
        navigator.clipboard.writeText(this.state.link)
        toast.success('Link copied in clipboard')
    }
    render() {
        const { link } = this.state
        return (
            link ? <div className='d-flex justify-content-center'>
                <div className='card w-50 text-center mt-3'>
                    <div className='card-body'>
                        <div className='mt-3'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-sm-5'>
                                    <span className='text-center'>Scan QR code to payment</span> <br />
                                    <div className='mt-2'>
                                        <QRCode
                                            value={link}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-2'>
                                    <span>OR</span>
                                </div>
                                <div className='col-sm-5'>
                                    <div className='justify-content-center align-items-center'>
                                        <span className='copyText' onClick={() => this.copyClipBoard()}>Copy here</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <Loader />

        )
    }
}
export const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps, {
})(CustomerTransactions))