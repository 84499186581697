import React, { Component } from 'react';
import { withRouter } from "react-router-dom";


class CartEmpty extends Component {
    render() {
        const { buttonName, buttonName1, body, title, buttonAction, buttonAction1, breadCrems, image } = this.props
        return (
            <>
                {breadCrems && <div className="wrap">
                    <div className="py-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item text-link"><span id="home" onClick={() => this.props.history.push("/")}>Home</span></li>
                                <li className="breadcrumb-item active text-link" aria-current="page">Cart</li>
                            </ol>
                        </nav>
                    </div>
                </div>}


                <div className="wrap py-100 mb-5">

                    <div className="text-center">


                        {image && <img src={require("../../asset/img/card-empty.svg")} alt="Empty Cart" className="img-fluid mb-5" />}

                        <h2 className="font16 mb-4 fw-bold">{title}</h2>

                        <p className="font16 mb-5 fw-normal">{body}</p>

                        <button id="cancel" className="btn btn-dark big-btn mb-5 me-md-4" onClick={() => buttonAction()}>{buttonName}</button>
                        <button id="submit" className="btn btn-dark big-btn mb-5" onClick={() => buttonAction1()}>{buttonName1}</button>
                    </div>

                </div>
            </>
        );
    }
}

export default withRouter(CartEmpty);