import React from 'react';

const CartQuantityAdjuster = ({ onMinus = () => null, onPlus = () => null, count = 0, className='' }) => {
  return  (<div className={`input-group quantity grid-btn mt-2 ${className}`}>
    <button className="btn px-1" id="CartQuantityAdjusterMinus" type="button" onClick={() => onMinus()}> <img src={require("../asset/img/minus.svg")} alt="-" width="12" /> </button>
    <input type="text" className="form-control px-1 text-center" value={count} readOnly={true} />
    <button className="btn px-1" id="CartQuantityAdjusterPlus" type="button" onClick={() => onPlus()}> <img src={require("../asset/img/plus.svg")} alt="+" height="12" /> </button>
  </div>)
}

export default CartQuantityAdjuster;