const NAME = "ACCOUNT";

export const GET_USER_DETAIL = `GET_USER_DETAIL`;
export const GET_USER_DETAIL_SUCCESS = `GET_USER_DETAIL_SUCCESS`;
export const GET_USER_DETAIL_FAILURE = `GET_USER_DETAIL_FAILURE`;
export const UPDATE_PROFILE_NAME = 'UPDATE_PROFILE_NAME';

export const getCustomerDetails = store => store[NAME].userDetails
export const getUserDetails = (callback) => {
    return {
        type: GET_USER_DETAIL,
        callback
    }
}
export const getUserDetailSuccess = (data) => {
    return {
        type: GET_USER_DETAIL_SUCCESS,
        data
    }
}

export const getUserDetailFailure = (error) => {
    return {
        type: GET_USER_DETAIL_FAILURE,
        error
    }
}


export const updateProfileName = (data) => {
    return {
        type: UPDATE_PROFILE_NAME,
        data
    }
}