import React from "react";
import Actions from "../../redux/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Constants from "../../Utility/Constants";
import CartOffers from "./CartOffers";
import CartEmpty from "../../component/cartEmpty";
import routes from '../../config/routes'
import Placeholder from '../../asset/img/no-preview.png'
import { getRandomNumber } from "../../Utility/Utils";

let quantitycheck = false;
let outOfProducts = null
class Checkout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      quantitycheck: false,
      errorMessage: '',
      addressType: '',
      selectedAddress: null,
      voucher: this.props.pimCartDetails?.voucher ? this.props.pimCartDetails?.voucher : null,
      showVoucherInput: this.props.pimCartDetails?.voucher ? true : false,
      voucherError: null,
    }
  }


  componentDidMount() {
    const { voucher, pimCart: { products = [] } } = this.props.pimCartDetails
    if (voucher && products?.length) {
      this.applyVoucher()
    }
    this.props.getPaymentCardInfo();
    this.props.getPimCartList();
  }
  componentWillUnmount() {
    this.props.loaderOff()
  }

  updatePimCartCallback = (response) => {
    const { response_code } = response || {}
    if (response_code === Constants.RESPONSE_CODE.SUCCESS) {
      const { voucher } = this.props.pimCartDetails || {}
      if (voucher) {
        this.applyVoucher()
      }
    } else {
      toast.error(`${response?.response_message}`)
    }
  }


  quantityDecrement(obj) {
    let { quantity, sku } = obj || {};
    if (quantity) {
      const params = {
        productSku: sku,
        quantity: quantity - 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: obj?.bistroProduct,
        cartItemId: obj?.cartItemId
      };
      this.props.updatePimCart(params, this.updatePimCartCallback)
    } else {
      const params = {
        productSku: sku,
        quantity: quantity,
        storeId: 1,
        addOnIds: [],
        bistroProduct: obj?.bistroProduct,
        cartItemId: obj?.cartItemId
      };
      this.props.updatePimCart(params, this.updatePimCartCallback)
    }
  }


  removeItemFromCart(obj) {
    let { sku } = obj || {};
    const params = {
      productSku: sku,
      quantity: 0,
      storeId: 1,
      addOnIds: [],
      bistroProduct: obj?.bistroProduct,
      cartItemId: obj?.cartItemId
    };
    this.setState({ quantitycheck: false })
    let { pimCart: { products = [] } } = this.props.pimCartDetails || {}
    if (products && products.length === 0) {
      this.removeVoucher();
    }
    this.props.updatePimCart(params, this.updatePimCartCallback)

  }

  quantityIncreament(obj) {
    let { quantity, sku, bistroProduct } = obj || {};
    if (quantity) {
      const params = {
        productSku: sku,
        quantity: quantity + 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: bistroProduct,
        cartItemId: obj?.cartItemId
      };
      this.props.updatePimCart(params, this.updatePimCartCallback)
    }
  }

  _getPriceOfProduct = (obj) => {
    let {
      sellingPrice, selectedAddOns
    } = obj;
    if (selectedAddOns && selectedAddOns.length !== 0) {
      sellingPrice =
        sellingPrice +
        selectedAddOns.reduce((accumulator, i) => {
          return accumulator + i.sellingPrice;
        }, 0);
    }
    return sellingPrice?.toFixed(2);
  };

  _renderCartItem(o, isBistro) {
    let { token } = this.props.getUserDetail || {};
    if (o?.storeLocationInventoryList) {
      let cartQty = o?.storeLocationInventoryList?.reduce((accumulator, i) => {
        return accumulator + i.qty;
      }, 0);
      if (o?.quantity > cartQty - token?.pimQuantityLimit) {
        quantitycheck = true;
        // this.setState({ quantitycheck: true })
        outOfProducts = o?.sku
      }
    }
    return (
      <div className="card card-body single-product py-2 py-md-0" key={JSON.stringify(o)}>
        <div className="row align-items-center">
          <div className="col-auto pe-0 pe-md-4">
            <div className="product-thumb">
              <span><img src={o.imageUrl ? o.imageUrl + '?$' + getRandomNumber() : Placeholder} alt="" /></span>
            </div>
          </div>
          <div className="col col-md-6">
            <span><h3 className="product-name text-link title-case">{o.productName?.toLowerCase()}</h3></span>
            <span><h6 className="product-name text-link" style={{ fontSize: '1rem' }}>{o.sku}</h6></span>
            {
              o.selectedAddOns?.length > 0 && (<>
                <span style={{ fontSize: '1rem' }}><b>{`YOUR CUSTOMIZATION`}</b>:</span> <br />
                <span className="title-case" style={{ fontSize: '1rem' }}>{o.selectedAddOns?.map((addons) => addons?.productName?.toLowerCase()).join(',')}</span>
              </>
              )
            }
            {o?.ageRestricted && <span className="badge age-restrict-text">Age Restricted</span>}
            {quantitycheck && (outOfProducts === o?.sku) && <span style={{ color: 'red' }}>Out of Stock</span>}
          </div>
          <div className="col-auto col-md px-0 ps-md-0 pe-md-2">
            <div className="input-group quantity">
              <button
                className="btn px-1"
                id="quantityDecrement"
                type="button"
                onClick={() => this.quantityDecrement(o, false)}>
                <img src={require("../../asset/img/minus.svg")} width="12" alt="-" />
              </button>
              <input
                type="text"
                className="form-control px-1 text-center"
                value={o.quantity} />
              <button
                className="btn px-1"
                id="quantityIncreament"
                type="button"
                onClick={() => this.quantityIncreament(o, false)}>
                <img src={require("../../asset/img/plus.svg")} height="12" alt="+" />
              </button>
            </div>
          </div>
          <div
            className="col-auto price">{Constants.COMMONS.usd}{this._getPriceOfProduct(o)}</div>
          <div className="col-auto px-0 d-none d-md-flex" id="removeItemFromCart" onClick={() => this.removeItemFromCart(o)}>
            <span className="cursor-pointer">
              <img src={require("../../asset/img/delete.svg")} alt="X" className="px-md-2" />
            </span>
          </div>
          <div className="col-12 d-block d-md-none text-end">
            <span id="removeItemFromCart" onClick={() => this.removeItemFromCart(o)} className="btn btn-sm btn-dark py-0 font14 adjust-remove">remove</span>
          </div>
        </div>
      </div>
    )
  }


  removeVoucher() {
    this.setState({ voucherError: '', voucher: '' })
    this.props.updateCartAfterVoucher('', '')
  }

  applyVoucher(isClickApplyVoucher) {
    let { voucher } = this.state
    if (!voucher && isClickApplyVoucher) {
      this.setState({ voucherError: 'Please enter voucher code.' })
    } else {
      const callback = (response) => {
        let { response_code, response_message, } = response || {}
        if (response_code === Constants.RESPONSE_CODE.SUCCESS) {
          this.setState({ voucherError: '' })
          this.props.updateCartAfterVoucher(voucher, response?.response)
        } else {
          this.props.updateCartAfterVoucher('', '')
          this.setState({ voucherError: response_message })
        }
      }
      let params = {
        couponCode: voucher
      }
      this.setState({ voucherError: '' })
      this.props.applyCustomerVoucher(params, callback)
    }
  }

  handleChangeVoucher(e) {
    this.setState({ voucher: e.target.value })
  }
  handleBistroComments(e) {
    this.props.updateBistroComments(e.target.value)
  }

  _renderGrocery(grocery) {
    return (
      <React.Fragment>
        <h3 className="font18 mb-3 text-link">PRODUCTS</h3>
        {grocery && grocery.map((o) => this._renderCartItem(o))}
      </React.Fragment>
    )
  }

  _renderBistro(bistro) {
    return (
      <React.Fragment>
        <h3 className="font18 mt-5 mb-3 text-link">BISTRO</h3>
        {bistro && bistro.map((o) => this._renderCartItem(o, true))}
      </React.Fragment>
    )
  }
  _renderBistroComments() {
    return (
      <>
        <div className="col-12">
          <div className="input-group bg-white py-2 border">
            <textarea
              id="voucher"
              type="text"
              className="form-control bg-white"
              name=""
              placeholder="Please enter bistro comments"
              value={this.props.voucherDetails?.bistroComments}
              onChange={(e) => this.handleBistroComments(e)}
            />
          </div>
        </div>
      </>
    )
  }

  _renderApplyVoucher() {
    const { voucher, voucherError } = this.state
    return (
      <>
        <div className="col-12">
          <div className="input-group bg-white py-2 border">
            <input
              id="voucher"
              type="text"
              className="form-control bg-white"
              name=""
              placeholder="Enter Coupon Code"
              value={voucher}
              disabled={this.props.voucherDetails?.voucher ? true : false}
              onChange={(e) => this.handleChangeVoucher(e)}
              aria-describedby="coupon" />

            {!this.props.voucherDetails?.voucher ? <button
              className="btn bg-white text-uppercase"
              type="button"
              onClick={() => this.applyVoucher(true)}
              id="coupon"><b>Apply</b></button> : <button
                className="btn bg-white text-uppercase"
                type="button"
                onClick={() => this.removeVoucher()}
                id="coupon"><b>Remove</b></button>}
          </div>
        </div>
        {voucherError ?
          <div className="col-12 mt-3">
            <div className="alert alert-danger font12 py-2" role="alert">{voucherError} </div>
          </div>
          : ''}
      </>
    )
  }
  _renderDollarOffer = () => {
    if (!this.props.cartOffer?.dollarOff) {
      return null;
    }
    const dollarOffObj = this.props.cartOffer?.dollarOff;
    const dollarValue = dollarOffObj.offerType === 'DOLLAR'
      ? 'Flat $' +
      dollarOffObj.offerValue?.toFixed(2) +
      ' discount applied'
      : dollarOffObj.offerValue?.toFixed(2) +
      '% discount applied (Max $' +
      dollarOffObj.maxDiscount?.toFixed(2) +
      ')';
    return dollarOffObj ? <div className="row mb-3" key={JSON.stringify(dollarOffObj)}>
      <div className="col">
        {dollarValue}
      </div>
      <div className="col text-end">
        - ${dollarOffObj?.discount?.toFixed(2)}
      </div>
    </div> : null;
  }
  updateRedeemPoints(e) {
    let { checked } = e.target
    this.props.updatedReedemPoints(checked)
  }

  openVoucher() {
    this.setState({ showVoucherInput: true })
  }

  // To be open the payment details pop up for live agent

  navChooseAddress(rewardsApplied, cartId) {
    const { isLiveAgent } = this.props.getUserDetail?.customer || {}
    if (isLiveAgent) {
      this.props.history.push(`/customercare/transactions/${cartId}`)
    } else {
      this.props.history.push(`${routes.CHOOSE_ADDRESS}?rewardsApplied=${rewardsApplied}`)
    }

  }

  render() {
    let { addressType, showVoucherInput } = this.state;
    let { pimCart: { products = [], subTotal, tax, grandTotal,
      deliveryDiscount, deliveryFee,
      rewardAmount,
      rewardPoints,
      totalPoints,
      cartId,
    }, voucherInfo, rewardsApplied } = this.props.pimCartDetails || {}

    const bistroProduct = products && products.filter((o) => o?.bistroProduct === true)
    const groceryProduct = products && products.filter((o) => o?.bistroProduct === false)

    let cartTotalPrice = voucherInfo ? voucherInfo.grandTotal : grandTotal;
    if (rewardsApplied && cartTotalPrice < rewardAmount) {
      this.props.updatedReedemPoints(false);
    }

    cartTotalPrice = rewardsApplied && rewardAmount && cartTotalPrice >= rewardAmount
      ? cartTotalPrice - rewardAmount
      : cartTotalPrice;


    const verifyRewardAmount = rewardAmount &&
      rewardPoints <= totalPoints &&
      (rewardsApplied || grandTotal > rewardAmount)


    const grandTotalValue = `${Constants.COMMONS.usd}${addressType && addressType === '1'
      ? (cartTotalPrice + (deliveryFee - deliveryDiscount))?.toFixed(2)
      : cartTotalPrice?.toFixed(2)}`
    return (
      <div className="wrap py-50 mb-5">
        {products && products.length > 0 ? <div className="row gx-md-5">
          <div className="col-12 col-lg-8">
            <h2 className="section-heading mb-4 mt-5 mt-lg-0">My Cart</h2>
            <div id="grocery" className="my-cart-list">
              {
                groceryProduct && groceryProduct.length > 0 && this._renderGrocery(groceryProduct)
              }
              {
                bistroProduct && bistroProduct.length > 0 && this._renderBistro(bistroProduct)
              }
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card card-body border-0 bg-ltgrey order-summary mb-3 mt-5 mt-lg-0">
              <h3 className="font16 fw-bold mb-3">Order Summary</h3>
              <CartOffers
              />
              {
                verifyRewardAmount ? <>
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="form-check"
                        onClick={(e) => this.updateRedeemPoints(e)}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          style={{ cursor: 'pointer' }}
                          checked={rewardsApplied}
                          defaultChecked={rewardsApplied}
                          id="user-reward" />
                        <label
                          className="form-check-label"
                          for="user-reward"
                        > Use your <strong>${rewardAmount}</strong> reward point value </label>
                      </div>
                    </div>
                  </div>

                </> : null
              }
              {
                bistroProduct && bistroProduct.length > 0 && <div className="row mb-3">
                  {this._renderBistroComments()}
                </div>
              }
              {
                cartTotalPrice !== 0 ? <div className="row mb-2">
                  <div
                    className="col cursor-pointer" id='openVoucher' onClick={() => this.openVoucher()}>Have a coupon?<b>Apply now</b></div>
                </div> : null
              }

              {
                showVoucherInput ? <div className="row mb-3">
                  {this._renderApplyVoucher()}
                </div> : null
              }

              <div className="row mb-2">
                <div
                  className="col">Sub Total</div>
                <div
                  className="col text-end">{Constants.COMMONS.usd}{subTotal && subTotal.toFixed(2)}</div>
              </div>
              <div className="row mb-2">
                <div className="col">Sales Tax</div>
                <div className="col text-end">{Constants.COMMONS.usd}{tax && tax.toFixed(2)}</div>
              </div>


              {this._renderDollarOffer()}
              {
                rewardsApplied && <div className="row mb-4">
                  <div className="col">Rewards discount</div>
                  <div className="col text-end">
                    - {Constants.COMMONS.usd}{rewardAmount?.toFixed(2)}
                  </div>
                </div>
              }
              {voucherInfo && <>
                <hr className="my-2" />
                <div className="row mb-4">
                  <div className="col">Discount</div>
                  <div className="col text-end">- {Constants.COMMONS.usd} {voucherInfo?.discount?.toFixed(2)}</div>
                </div>
              </>}
              <div className="row mb-4">
                <div className="col font16 fw-bold">Grand Total</div>
                <div className="col font16 fw-bold text-end">
                  {grandTotalValue}
                </div>
              </div>

            </div>

            <button
              disabled={this.state.quantitycheck}
              onClick={() => this.navChooseAddress(rewardsApplied, cartId)}
              className="btn btn-dark big-btn next-btn w-100 text-uppercase mb-5 mb-lg-0 btn_align">Select Delivery Options
              <img src={require("../../asset/img/right-arrow.svg")} alt="" className="ms-3" /> </button>
          </div>
        </div> :
          <CartEmpty
            id="products"
            buttonName={"SHOP PRODUCTS"}
            buttonName1={"ORDER FROM BISTRO"}
            body={"Please start shopping to fill your cart and checkout"}
            title={"Your cart is currently empty"}
            buttonAction={() => this.props.history.push(routes.CATEGORY_LIST)}
            buttonAction1={() => this.props.history.push(routes.bistroCategory())}
            breadCrems={true}
            image={true}
          />}
      </div>
    );
  }
}
export const mapStateToProps = (state) => {
  return {
    addressDetails: Actions.getAddressDetails(state),
    pimCartDetails: Actions.getPimCartDetails(state),
    daxbotInfo: Actions.getDaxbotCheckAvailability(state),
    voucherDetails: state?.PIM_CART || {},
    cartOffer: state?.PIM_CART?.pimCart?.offers || {},
    getUserDetail: Actions.getUserDetail(state)
  };
};

export default withRouter(connect(mapStateToProps, {
  addToCart: Actions.addToCart,
  getCartDetails: Actions.getCartDetails,
  getAddress: Actions.getAddress,
  deleteAddress: Actions.deleteAddress,
  addAddress: Actions.addAddress,
  updateAddress: Actions.updateAddress,
  createOrder: Actions.createOrder,
  updatePimCart: Actions.updatePimCart,
  getPaymentCardInfo: Actions.getPaymentcardInfoAction,
  initializeAuthPaymentAction: Actions.initializeAuthPaymentAction,
  getPimCartList: Actions.getPimCartList,
  daxbotAvailabilty: Actions.daxbotCheckAvailability,
  applyCustomerVoucher: Actions.applyCustomerVoucher,
  updateCartAfterVoucher: Actions.updateCartAfterVoucher,
  updatedReedemPoints: Actions.updatedReedemPoints,
  updateBistroComments: Actions.updateBistroComments,
  loaderOff: Actions.loaderOff
})(Checkout));
