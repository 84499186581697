export const COLORS = {
    appThemeHex: '#1e1f29',
    appThemeHover: 'rgba(30, 31, 41, .5)',
    loadingBackround: 'rgba(255, 255, 255, .4)',
    listBackground: 'rgba(30, 31, 41, .2)',

}
export default {
    DATE_FORMAT: 'DD-MM-YYYY',
    DATE_TIME: 'MM/DD/YYYY - hh:mm A',
    DATE_WITH_TIME_FORMAT: 'MM/DD/YYYY - HH:mm',
    ROUTE_PATH: {
        BISTRO_PATH: 'bistro',
        GROCERIES_PATH: 'groceries',
        ALL_CATEGORIES: 'all'
    },
    ORDER_STATUS: {
        WAITING_FOR_ACCEPT: 1,
        ACCEPT_ORDER: 2,
        DECLINE_ORDER: 3,
        READY_TO_PICKUP: 4,
        DELIVERED: 5
    },
    PAYMENT_STATUS: {
        COMPLETED: 0,
        PENDING: 1,
        PROCESSING: 2,
        FAILED: 3,
        SCHEDULED: 4,
        REFUND_INIT: 11,
        REFUND_COMPLETED: 12,
        REFUND_FAILED: 13,
    },
    PRODUCT_GROUP: {
        GROCERY: 'PRODUCTS',
        BISTRO: 'BISTRO',
    },
    COMMONS: {
        okay: 'Okay',
        edit: 'Edit',
        delete: 'Delete',
        submit: 'Submit',
        addToCart: 'Add to cart',
        close: '×',
        minus: '-',
        plus: '+',
        usd: '$',
        separator: '****'
    },
    API: {
        something: 'Sorry something went wrong, please try again later.'
    },

    HEADER: {
        appName: 'PROGLINT MARKET',
        logout: 'Logout',
        login: 'Login',
        register: 'Register',
        search: 'Search'
    },
    RESET_PASSWORD: {
        title: "Reset Password",
        reset: "Reset"
    },
    FOOTER: {
        aboutUs: 'About Us',
        address: '5 Polaris Way',
        address1: 'Aliso Viejo, CA 92656, United States',
        categories: 'Categories',
        bistro: 'Bistro',
        groceries: 'Grocery',
        myAccount: 'My Account',
        viewCart: 'View Cart',
        wishlist: 'Wishlist',
        trackOrder: 'Track My Order',
        help: 'Help',
        settings: 'Settings',
        information: 'Information',
        contactUs: 'Contact',
        privacyPolicy: 'Privacy Policy',
        ordersRetuns: 'Orders and Returns',
        teams: 'Terms of use',
        allCategories: 'All Categories',
        franchise: 'Franchisee Inquiry',
        about: 'About',
        feedBack: 'FeedBack',
        subscription: 'Subscribe',
        whatIsNB: 'What is Proglint Market?',
        payment: 'Payment and Billing',
        howTo: 'How to Shop?',
        legalInformation: 'Legal information',
        imprint: 'Imprint'
    },
    LOGIN: {
        title: "Login",
        dontHaveAccount: `Don't have an account?`,
        signup: "SignUp",
        forgotPassword: "Forgot Password?",
        error: {
            emailId: 'Please enter a valid Email Id.',
            mobileNumber: 'Please enter a valid mobile number.',
            emailIdEmpty: 'Please enter Email Id.',
            passkey: 'Please enter Password.',
            passkeyLimit: 'Password needs to be at least 8 characters.',
            mobileNumberEmpty: 'Please enter mobile number.'
        }
    },
    FORGET_PASSWORD: {
        title: "Forgot Password",
        sendButton: "Send",
        error: {
            mobileNumber: 'Please enter valid Mobile number.',
            mobileNumberEmpty: 'Please enter Mobile number.'
        }
    },
    REGISTER: {
        signUp: 'SignUp',
        reset: 'Reset',
        error: {
            firstName: 'First Name must be atleast 3 characters.',
            lastName: 'Please enter last name.',
            passkey: 'Password does not match the requirements.',
            email: 'Email Id, is already registered, please try to login.',
            passkeyMismatch: 'Password and Re-type password does not match.',
            enterEmailId: 'Please enter Email Id.',
            passkeyNotMatch: 'Password does not match.',
            validEmail: 'Email Id is not valid.',
            termsCheckbox: 'Please select terms of use.',
            mobileNumber: 'Please enter mobile number.',
            enterMobileNumber: 'Please enter a valid mobile number.',
        }
    },
    VERIFY_OTP: {
        title: 'Verify Otp',
        editMobile: 'Do u want edit your Mobile number? ',
        resend: 'Resend Code',
        didntReceive: `Don't receive the code?`,
        verify: 'Verify',
        cancel: 'Cancel',
        successMessage: 'Successfully Verified',
        emptyField: 'Please enter the otp',
        validTwoMinutes: 'The OTP has been sent to your mobile number.The One time password will be valid for ',
        errorMessage: 'Request Failed'
    },
    UPDATE_EMAIL: {
        title: 'Update Mobile Number',
        update: 'Update'
    },
    STORE_ADDRESS: {
        "city": 'Viejo',
        "landmark": 'Aliso',
        "street": '5 Polaris Way',
        "zip": 'CA 92656'
    },
    ADDRESS: {
        chooseAddress: 'Please choose a address',
        ageVerifyProducts: 'All age-restricted items should be picked at store'
    },
    ORDER_SUMMARY: {
        title: 'My Orders',
        description: 'Below is the summary of all orders you have made in the past. Click on each order to receive detailed information and status of the order.',
        view: 'View',
        date: 'Date',
        orderNumber: 'Receipt no',
        price: 'Price',
        orderSummary: 'Order Summary',
        deliveryAddress: 'Delivery Address:',
        pickupAddress: 'Pickup Address:',
        orderId: 'Order no',
        noAddons: '-',
        orderTypes: {
            orderPlaced: 'Order Placed',
            packing: 'Packing',
            preparing: 'Preparing',
            readyToPickup: 'Ready to Pickup',
            delivered: 'Delivered',
            declined: 'Not Available',
            outforDelivery: 'Out for delivery'
        },
        paymentType: {
            completed: 'Paid',
            pending: 'Pending',
            processing: 'Processing',
            failed: 'Failed',
            pendingFailed: 'Pending Failed',
            refundInitiated: 'Refund Initiated',
            refundApproved: 'Refund Processed',
            refundDenied: 'Refund Declined',
            refundApprocedAfter: 'Your refund will be processed within 3 to 5 working days'
        },
        orderDetails: {
            title: 'Order Status',
            invoiceNo: 'Receipt no: #',
            orderDate: 'Order Date:',
            orderStatus: 'Payment Status:'
        },
        tableColumns: {
            image: 'Product Image',
            name: 'Name',
            sku: 'SKU',
            desc: 'Description',
            addons: 'Add-ons',
            quantity: 'Quantity',
            price: 'Price',
            subTotal: 'Sub Total',
            salesTax: 'Sales Tax',
            tax: 'Tax',
            totalAmount: 'Total Amount',
            refundInformation: 'Refund Information'

        },
        net: 'Sub Total',
        vat: 'Taxes',
        total: 'Total',
        thankyou: 'THANK YOU FOR YOUR PURCHASE',
        refund: 'Request Refund',
        emailInvoice: 'Email Invoice',
        refundText: 'Please return the product’s to the store to process your refund'
    },
    CHECKOUT: {
        checkout: 'Checkout',
        storeAddess: 'Store Address',
        storeAddress1: '5 Polaris Way',
        storeAddress2: 'Aliso Viejo, CA 92656',
        home: 'Home',
        deliveryMode: 'Delivery Mode',
        selectOption: 'Select your option',
        storePickup: 'Pick up from store',
        delivery: 'Delivery',
        yourOrder: 'Your Order',
        product: 'PRODUCT',
        quantity: 'QUANTITY',
        price: 'PRICE',
        placeOrder: 'Place order',
        cartEmpty: 'Cart is empty',
        net: 'NET',
        vat: 'Taxes',
        deliveryFee: 'Delivery fee',
        toPay: 'To Pay',
        errorMessages: {
            street: 'Please enter the street.',
            landmark: 'Please enter the landmark.',
            zipcode: 'Please enter the zip code.',
            city: 'Please enter the city.',
            mobileNumber: 'Please enter mobile number',
            mobileNumberLength: 'Mobile number must be at least 10 digits',
            doorNo: 'Please enter yout door no'
        }
    },
    PRODUCTS: {
        cartMessage: 'Please go to cart screen for removing the quantity.',
        customize: 'Customizable',
        goToCart: 'Go to cart'
    },
    FRANCHISE: {
        errorMessages: {
            firstName: 'Please enter the first name.',
            lastName: 'Please enter the last name.',
            mobileNumber: 'Please enter the mobile number.',
            message: 'Please enter the message.',
            enterEmailId: 'Please enter Email Id.',
            validEmail: 'Email Id is not valid',
            vailMobileNumber: 'Please enter a valid mobile number.',
        }
    },
    FEEDBACK: {
        GENERAL: '1',
        SUGGESTION: '2',
        PROBLEM: '3'
    },
    PAYMENT: {
        successMessage: 'Order placed successfully.',
        failureMessage: 'Payment failed. Try again!.',
        subscriptionSuccess: 'Subscribed successfully.',
        saveCard: 'Successfully created',
        failureCard: 'Sorry something went wrong please try again later'
    },
    SUBSCRIPTION: {
        cancel: 'Cancel',
        confirm: 'Confirm',
        subscriptionCancelled: 'Subscription cancelled successfully.'
    },
    ORDER_TYPES: {
        CREATED_BY_HITACHI: 1,
        CREATED_BY_USER_ONLINE_ORDER: 2,
        CREATED_BY_VISION_CHECKOUT: 3,
        CREATED_BY_SCAN_AND_GO: 4
    },
    RESPONSE_CODE: {
        SUCCESS: 0,
    },
    REWARDS: {
        TITLE: 'Points',
        SUB_TITLE: 'Current Points',
        CONTENT: 'Each 1 point for every 1$ spent',
        SUB_CONTENT: 'Earn $5 off reward every 200 points',
        TOTAL_POINTS: 200
    },

    ACCOUNTS: {
        mailto: "mailto:jagadish@proglint.com"
    },

    NOT_FOUND: {
        filter: 'Product Information not found',
        commonSearch: 'Sorry, looks like we dont have the item you are looking for at this moment.',
        address: 'Address not added. Please add new one',
        category: 'No category available'
    },

    LOG_OUT: {
        desc: "Do you want to logout?",
        proceed: "Yes",
        cancel: "Cancel",
    },
    cancel: "Cancel",
    proceed: "Proceed",

    MULTI_STORE: {
        STORE_PRODUCT_NOT_FOUND: 'Product not found',
        TITLE: "Please choose the store, to proceed shopping in E- commerce",
        STORE_TITLE: 'Items already in cart',
        SUB_TITLE_STORE: 'Your cart contains items from another store.Would you like to reset your cart by switching to this store?',
        ITEM_ALREADY: 'Items already in cart',
        ITEM_ALREADY_BUTTON: "Yes, I’ll start fresh",
        CANCEL_BUTTON: "No",
    }
}

export const RefundOptions = (type) => {
    switch (type) {
        case 0:
            return 'Wrong Product'
        case 1:
            return 'Damaged Product'
        case 2:
            return 'Wrong amount'
        case 3:
            return 'Other'
        default:
            return null
    }
}