import Actions from '../../action'
const initialState = {
    isLoading: false,
    error: false,
    imageUploading: false,
    imageUploadError: false,
    uploadedImages: [],
    refundDetailsData: {},
    isRefundError: false,
    isRefundDetailLoading: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.REFUND_ORDER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.REFUND_ORDER_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.REFUND_ORDER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case Actions.UPLOAD_REFUND_IMAGE: {
            return {
                ...state,
                imageUploading: true,
            };
        }
        case Actions.UPLOAD_REFUND_IMAGE_SUCCESS: {
            return {
                ...state,
                imageUploading: false,
                imageUploadError: false
            };
        }
        case Actions.UPLOAD_REFUND_IMAGE_FAILURE: {
            return {
                ...state,
                imageUploading: false,
                imageUploadError: action.error,
            };
        }
        case Actions.REFUND_DETAILS: {
            return {
                ...state,
                isRefundDetailLoading: true,
            };
        }
        case Actions.REFUND_DETAILS_SUCCESS: {
            return {
                ...state,
                isRefundDetailLoading: false,
                refundDetailsData: action.data.response || {},
                isRefundError: false
            };
        }
        case Actions.REFUND_DETAILS_FAILURE: {
            return {
                ...state,
                isRefundDetailLoading: false,
                isRefundError: action.error,
            };
        }
        default:
            return state;
    }
};
