import React from 'react'
import Checkout from "./container/checkout";
import Home from "./container/home";
import routes from './config/routes';
import OrderSummary from './container/orderSummary';
import Privacy from './container/terms/privacy';
import TermsOfUse from './container/terms/termsOfUse';
import Franchise from './container/franchise';
import Feedback from './container/feedback';
import About from './container/about';
import MyAccount from './container/account';
import TermsAndConditions from './container/termsAndConditions';
import PaymentScreen from './container/payment/PaymentScreen';
import TractDaxBot from './container/orderSummary/daxbot/TrackDaxBot';
import Subscription from './container/subscription/subscription';
import BistroProduct from './container/products/Bistro'
import PIMProducts from './container/products/Products';
import CategoryList from './container/products/CategoryList';
import SubscriptionPaymentScreen from './container/payment/SubscriptionPaymentScreen';
import Contact from './container/contact';
import ChooseAddress from './container/chooseAddress';
import PimProductDetails from './container/productsDetails/grocery';
import BistroProductDetails from './container/productsDetails/bistro';
import SearchAll from './container/search/SearchAll';
import AddPaymentScreen from './container/payment/AddPaymentScreen';
import CheckoutPayment from './container/paymentCheckout'
import EntryPin from './container/managePayment/entryPin';
import CustomerCare from './container/CustomerCare';
import CustomerTranscations from './container/CustomerTranscations';
import PaymentVerification from './container/PaymentVerification';
const RedirectSummary = () => <> </>

const PAYMENT_REDIRECT_ROUTES = [{
    screenName: 'payment success redirect',
    component: RedirectSummary,
    exact: true,
    isAuthenticated: false,
    path: routes.ORDER_SUMMARY_REDIRECT,
    noHeaderFooter: true
},
{
    screenName: 'payment failure redirect',
    component: RedirectSummary,
    exact: true,
    isAuthenticated: false,
    path: routes.ORDER_FAILURE_REDIRECT,
    noHeaderFooter: true
},]

const Routes = [
    {
        screenName: 'Home page',
        component: Home,
        exact: true,
        isAuthenticated: false,
        path: '/'
    }, {
        screenName: 'Customer Care Page',
        component: CustomerCare,
        exact: true,
        isAuthenticated: false,
        path: '/customercare'
    },
    {
        screenName: 'Checkout',
        component: Checkout,
        exact: true,
        isAuthenticated: true,
        path: routes.CHECKOUT
    },
    {
        screenName: 'My Account',
        component: MyAccount,
        exact: true,
        isAuthenticated: true,
        path: routes.MYACCOUNT
    },
    {
        screenName: 'My Account',
        component: MyAccount,
        exact: true,
        isAuthenticated: true,
        path: routes.MYACCOUNTS
    },
    {
        screenName: 'Choose Delivery',
        component: ChooseAddress,
        exact: true,
        isAuthenticated: true,
        path: routes.CHOOSE_ADDRESS
    },
    {
        screenName: 'Order Summary',
        component: OrderSummary,
        exact: true,
        isAuthenticated: true,
        path: routes.ORDER_SUMMARY
    },
    {
        screenName: 'Account Order Summary',
        component: MyAccount,
        exact: true,
        isAuthenticated: true,
        path: routes.ACCOUNT_ORDER_SUMMARY
    },
    {
        screenName: 'Account Order Tracking',
        component: MyAccount,
        exact: true,
        isAuthenticated: true,
        path: routes.ACCOUNT_ORDER_TRACK
    },
    {
        screenName: 'Privacy',
        component: Privacy,
        exact: true,
        isAuthenticated: false,
        path: routes.PRIVACY
    },
    {
        screenName: 'Terms Of Use',
        component: TermsOfUse,
        exact: true,
        isAuthenticated: false,
        path: routes.TERMS_OF_USE
    },
    {
        screenName: 'Franchise',
        component: Franchise,
        exact: true,
        isAuthenticated: false,
        path: routes.FRANCHISE_ENQUIRY
    },
    {
        screenName: 'Feedback',
        component: Feedback,
        exact: true,
        isAuthenticated: false,
        path: routes.FEED_BACK
    },
    {
        screenName: 'About',
        component: About,
        exact: true,
        isAuthenticated: false,
        path: routes.ABOUT_US
    },
    {
        screenName: 'Terms and Conditions',
        component: TermsAndConditions,
        exact: true,
        isAuthenticated: false,
        path: routes.TERMSANDCONDITION
    },
    {
        screenName: 'Make Payment',
        component: PaymentScreen,
        exact: true,
        isAuthenticated: false,
        path: routes.PAYMENT_SCREEN,
        noHeaderFooter: true
    },

    {
        screenName: 'Track DaxBot',
        component: TractDaxBot,
        exact: true,
        isAuthenticated: true,
        path: routes.TRACK_DAXBOT_SCREEN,
        noHeaderFooter: true
    },
    {
        screenName: 'Subscription',
        component: Subscription,
        exact: true,
        isAuthenticated: true,
        path: routes.SUBSCRIPTION
    },
    {
        screenName: 'PIM Products',
        component: PIMProducts,
        exact: true,
        isAuthenticated: false,
        path: routes.PIM_PRODUCTS
    },
    {
        screenName: 'Categories',
        component: CategoryList,
        exact: true,
        isAuthenticated: false,
        path: routes.CATEGORY_LIST
    },
    {
        screenName: 'Bistro Categories',
        component: CategoryList,
        exact: true,
        isAuthenticated: false,
        path: routes.BISTRO_CATEGORY_LIST
    },
    {
        screenName: 'Payment Subscription',
        component: SubscriptionPaymentScreen,
        exact: true,
        isAuthenticated: true,
        path: routes.SUBSCRIPTION_PAYMENT,
        noHeaderFooter: true
    },
    {
        screenName: 'Points history',
        component: MyAccount,
        exact: true,
        isAuthenticated: true,
        path: routes.REWARDS_SUMMARY,
    },
    {
        screenName: 'Bistro Products',
        component: BistroProduct,
        exact: true,
        isAuthenticated: false,
        path: routes.BISTRO_PRODUCTS
    }, {
        screenName: 'Conatct us',
        component: Contact,
        exact: true,
        isAuthenticated: false,
        path: routes.CONTACT_US,
    },
    {
        screenName: 'Pim Product Details',
        component: PimProductDetails,
        exact: true,
        isAuthenticated: false,
        path: routes.PIM_PRODUCTS_DETAILS,
    },
    {
        screenName: 'Bistro Product Details',
        component: BistroProductDetails,
        exact: true,
        isAuthenticated: false,
        path: routes.BISTRO_PRODUCTS_DETAILS,
    },
    {
        screenName: 'Search All Products',
        component: SearchAll,
        exact: true,
        isAuthenticated: false,
        path: routes.SEARCH_ALL,
    },
    {
        screenName: 'Payment Card',
        component: AddPaymentScreen,
        exact: true,
        isAuthenticated: true,
        path: routes.SAVE_CARD_PAYMENT,
        noHeaderFooter: true
    },
    {
        screenName: 'CheckoutPayment',
        component: CheckoutPayment,
        exact: true,
        isAuthenticated: false,
        path: routes.CHECKOUT_PAYMENT,
    },
    {
        screenName: 'EntryPin',
        component: EntryPin,
        exact: true,
        isAuthenticated: false,
        path: routes.ENTRY_PIN,
    },
    {
        screenName: 'transactions',
        component: CustomerTranscations,
        exact: true,
        isAuthenticated: false,
        path: routes.GUEST_CUSTOMER_TRANSACTIONS,
    },
    {
        screenName: 'Guest Payment',
        component: PaymentVerification,
        exact: true,
        isAuthenticated: false,
        path: routes.GUEST_PAYMENT_SCREEN,
        noHeaderFooter: true
    },
    ...PAYMENT_REDIRECT_ROUTES
];



export default Routes;