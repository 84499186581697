import React from 'react'
import { withRouter } from 'react-router-dom'
import Constants from '../../Utility/Constants'

class OrderTrackStatus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}

    }
    _renderOrderTrackStatus(orderStatus, orderType, natureOfOrder, object) {
        if (orderStatus === Constants.ORDER_STATUS.WAITING_FOR_ACCEPT) {
            return (<React.Fragment>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")} alt="Done" className="mb-2" />
                    <p
                        className="font14 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.orderPlaced}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/grey-circle.svg")} alt="Pending" className="mb-2" />
                    <p
                        className="font14 fw-normal">{orderType === 1 ? Constants.ORDER_SUMMARY.orderTypes.preparing
                            : Constants.ORDER_SUMMARY.orderTypes.packing}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/grey-circle.svg")} alt="Pending" className="mb-2" />
                    <p
                        className="font14 fw-normal">{natureOfOrder === 1 ? Constants.ORDER_SUMMARY.orderTypes.outforDelivery
                            : Constants.ORDER_SUMMARY.orderTypes.readyToPickup}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/grey-circle.svg")} alt="Pending" className="mb-2" />
                    <p
                        className="font14 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.delivered}</p>
                </div>
            </React.Fragment>)
        } else if (orderStatus === Constants.ORDER_STATUS.ACCEPT_ORDER) {
            return (<React.Fragment>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")}
                        alt="Done"
                        className="mb-2" />
                    <p
                        className="font14 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.orderPlaced}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")}
                        alt="Pending"
                        className="mb-2" />
                    <p
                        className="font14 fw-normal">{orderType === 1 ? Constants.ORDER_SUMMARY.orderTypes.preparing
                            : Constants.ORDER_SUMMARY.orderTypes.packing}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/grey-circle.svg")}
                        alt="Pending"
                        className="mb-2" />
                    <p
                        className="font14 fw-normal">{natureOfOrder === 1 ? Constants.ORDER_SUMMARY.orderTypes.outforDelivery
                            : Constants.ORDER_SUMMARY.orderTypes.readyToPickup}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/grey-circle.svg")}
                        alt="Pending"
                        className="mb-2" />
                    <p
                        className="font14 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.delivered}</p>
                </div>
            </React.Fragment>)
        } else if (orderStatus === Constants.ORDER_STATUS.DECLINE_ORDER) {
            return (<React.Fragment>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")}
                        alt="Done"
                        className="mb-2" />
                    <p
                        className="font14 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.orderPlaced}</p>
                </div>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col">
                    <img
                        src={require("../../asset/img/progress_failed.png")}
                        style={{ width: '30px', height: '30px' }}
                        alt="Done"
                        className="mb-2" />
                    <p
                        className="font14 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.declined}</p>
                </div>
                {object?.orderComment &&
                    <div className="row status-row">
                        <div className="col">
                            Reason : <span style={{ color: 'red' }}>{object?.orderComment}</span>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                }

            </React.Fragment>)
        } else if (orderStatus === Constants.ORDER_STATUS.READY_TO_PICKUP) {
            return (<React.Fragment>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")}
                        alt="Done"
                        className="mb-2" />
                    <p
                        className="font14 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.orderPlaced}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")}
                        alt="Done" className="mb-2" />
                    <p
                        className="font14 fw-normal">{orderType === 1 ? Constants.ORDER_SUMMARY.orderTypes.preparing
                            : Constants.ORDER_SUMMARY.orderTypes.packing}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")}
                        alt="Done"
                        className="mb-2" />
                    <p
                        className="font14 fw-normal">{natureOfOrder === 1 ? Constants.ORDER_SUMMARY.orderTypes.outforDelivery
                            : Constants.ORDER_SUMMARY.orderTypes.readyToPickup}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/grey-circle.svg")}
                        alt="Pending"
                        className="mb-2" />
                    <p
                        className="font14 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.delivered}</p>
                </div>
            </React.Fragment>)
        } else if (orderStatus === Constants.ORDER_STATUS.DELIVERED) {
            return (<React.Fragment>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")}
                        alt="Done" className="mb-2" />
                    <p
                        className="font14 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.orderPlaced}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")}
                        alt="Done"
                        className="mb-2" />
                    <p
                        className="font14 fw-normal">{orderType === 1 ? Constants.ORDER_SUMMARY.orderTypes.preparing
                            : Constants.ORDER_SUMMARY.orderTypes.packing}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")}
                        alt="Done"
                        className="mb-2"
                    />
                    <p
                        className="font14 fw-normal">{natureOfOrder === 1 ? Constants.ORDER_SUMMARY.orderTypes.outforDelivery
                            : Constants.ORDER_SUMMARY.orderTypes.readyToPickup}</p>
                </div>
                <div className="col">
                    <img
                        src={require("../../asset/img/green-tick.svg")} alt="Pending" className="mb-2" />
                    <p
                        className="font14 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.delivered}</p>
                </div>
            </React.Fragment>)
        }
    }
    render() {
        const { object, natureOfOrder } = this.props
        return (
            <React.Fragment>
                {this._renderOrderTrackStatus(object?.orderStatus, object?.orderType, natureOfOrder, object)}
            </React.Fragment>
        )
    }
}

export default withRouter(OrderTrackStatus)