const NAME = "DAXBOT_CHECK_AVAILABILITY";
export const DAXBOT_CHECK_AVAILABILITY = `${NAME}/DAXBOT_CHECK_AVAILABILITY`;
export const DAXBOT_CHECK_AVAILABILITY_SUCCESS = `${NAME}/DAXBOT_CHECK_AVAILABILITY_SUCCESS`;
export const DAXBOT_CHECK_AVAILABILITY_FAILURE = `${NAME}/DAXBOT_CHECK_AVAILABILITY_FAILURE`;

export const DAXBOT_DELIVERY_INFO_ACTION = `${NAME}/DAXBOT_DELIVERY_INFO_ACTION`;
export const DAXBOT_DELIVERY_INFO_ACTION_SUCCESS = `${NAME}/DAXBOT_DELIVERY_INFO_ACTION_SUCCESS`;
export const DAXBOT_DELIVERY_INFO_ACTION_FAILURE = `${NAME}/DAXBOT_CHECK_AVAILABILITY_FAILURE`;


export const getDaxbotCheckAvailability = store => store[NAME]
export const daxbotCheckAvailability = (params, callback) => {
    return {
        type: DAXBOT_CHECK_AVAILABILITY,
        params,
        callback
    }
}

export const daxbotCheckAvailabilitySuccess = (data) => {
    return {
        type: DAXBOT_CHECK_AVAILABILITY_SUCCESS,
        data
    }
}

export const daxbotCheckAvailabilityFailure = (error) => {
    return {
        type: DAXBOT_CHECK_AVAILABILITY_FAILURE,
        error
    }
}

export const daxbotDeliveryInfoAction = (params, callback) => {
    return {
        type: DAXBOT_DELIVERY_INFO_ACTION,
        params,
        callback
    }
}

export const daxbotDeliveryInfoActionSuccess = (data) => {
    return {
        type: DAXBOT_DELIVERY_INFO_ACTION_SUCCESS,
        data
    }
}

export const daxbotDeliveryInfoActionFailure = (error) => {
    return {
        type: DAXBOT_DELIVERY_INFO_ACTION_FAILURE,
        error
    }
}

