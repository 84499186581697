import React, { Component } from 'react';

class NoSearch extends Component {
    render() {
        return (
            <div className="wrap py-100 mb-5">

                <div className="text-center">

                    <img src={require("../../asset/img/no-results.PNG")} alt="Nol Results" className="img-fluid mb-5" />

                    <h2 class ="font16 mb-4 fw-bold">Sorry, looks like we don't have the item you are looking for at this moment.</h2>

                </div>

            </div>
        );
    }
}

export default NoSearch;