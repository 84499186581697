const NAME = "ADDRESS";
export const GET_ADDRESS = `${NAME}/GET_ADDRESS`;
export const GET_ADDRESS_SUCCESS = `${NAME}/GET_ADDRESS_SUCCESS`;
export const GET_ADDRESS_FAILURE = `${NAME}/GET_ADDRESS_FAILURE`;


export const getAddressDetails = store => store[NAME].getAddress
export const getAddress = (callback) => {
    return {
        type: GET_ADDRESS,
        callback
    }
}

export const getAddressSuccess = (data) => {
    return {
        type: GET_ADDRESS_SUCCESS,
        data
    }
}

export const getAddressFailure = (error) => {
    return {
        type: GET_ADDRESS_FAILURE,
        error
    }
}
