export const ADD_ADDRESS = `ADD_ADDRESS`;
export const ADD_ADDRESS_SUCCESS = `ADD_ADDRESS_SUCCESS`;
export const ADD_ADDRESS_FAILURE = `ADD_ADDRESS_FAILURE`;

export const addAddress = (params, callback) => {
    return {
        type: ADD_ADDRESS,
        params,
        callback
    }
}

export const addAddressSuccess = (data) => {
    return {
        type: ADD_ADDRESS_SUCCESS,
        data
    }
}

export const addAddressFailure = (error) => {
    return {
        type: ADD_ADDRESS_FAILURE,
        error
    }
}





