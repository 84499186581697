import Actions from '../action'
const initialState = {
    accountDelete: [],
    isLoading:false,
    error:false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.ACCOUNT_DELETE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ACOUNT_DELETE_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                accountDelete: action.data,
                error: false
            };
        }
        case Actions.ACCOUNT_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
