import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import Constants from '../../Utility/Constants';
import Pagination from "react-js-pagination";
import { queryStringParser } from "../../Utility/Utils";

class Rewards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            limit: 5,
            activePage: 1
        }
    }

    handleChange(pageNumber) {
        this.setState(
            { activePage: pageNumber, offset: pageNumber * 5 - this.state.limit },
            () => {
                this.replaceHistory();
                this._getRewards();
            }
        );
    }

    replaceHistory = () => {
        const { activePage } = this.state
        let nextURL = window.location.href;
        nextURL = `${nextURL.split('?')[0]}`
        nextURL = `${nextURL}?page=${activePage}`
        const nextTitle = 'N and B Customer';
        const nextState = {};
        window.history.replaceState(nextState, nextTitle, nextURL);
    }
    componentDidMount() {
        const { page = 1 } = queryStringParser(this.props.location.search);
        const pageNumber = isNaN(parseInt(page)) ? 1 : parseInt(page)
        this.setState({ activePage: pageNumber, offset: pageNumber * 5 - this.state.limit }, () => this._getRewards())
    }
    _getRewards() {
        const { limit, offset } = this.state
        const params = { limit, offset }
        this.props.getRewards(params)
    }

    _renderRewardsCalculation(obj) {
        return Constants.REWARDS.TOTAL_POINTS - obj?.totalRewardPoints
    }

    render() {
        const { rewardsList: { rewards, invoiceCount } } = this.props
        const { activePage, limit } = this.state

        return (
            <div className="col ps-md-5">
                <div className="row mb-5 align-items-center">
                    <div className="col-12 col">
                        <h1 className="font18 fw-bold">Rewards</h1>
                    </div>
                </div>
                <div className="row gx-5">
                    <div className="col order-2 order-lg-1">
                        {typeof rewards?.response === 'object'
                            && rewards?.response?.length === 0 ? <h5>No Transactions Found</h5> : <>
                            <h3 className="font16 fw-bold mb-5">Transactions</h3>
                            {typeof rewards?.response === 'object' && rewards?.response?.map((o) => {
                                return (<>
                                    <div className="pb-3 mb-3 border-bottom">
                                        <h4 className={`font14 ${o?.rewardPoints >= 0 ? 'text-success' : 'text-danger'} fw-bold`}>{`${o?.rewardPoints >= 0 ? 'Earned' : 'Redeemed'}`}:{o.rewardPoints}</h4>
                                        <p className="text-secondary">{o.message}</p>
                                    </div>
                                </>)
                            })}
                        </>}
                        {typeof rewards?.response === 'object' && rewards?.response?.length > 0 && (<div className="text-center">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={limit}
                                totalItemsCount={invoiceCount}
                                pageRangeDisplayed={5}
                                onChange={this.handleChange.bind(this)}
                            />
                        </div>)}

                    </div>
                    <div className="col order-1 order-lg-2">
                        <div className="p-5 rounded-0 text-center bg-black text-white">
                            <img src={require("../../asset/img/crown.svg")} alt="Rewards" className="mb-3" width="56" />
                            <h3 className="font42 fw-bold mb-4">{rewards?.totalRewardPoints}</h3>

                            <p className="font14 fw-bold mb-1">Current Points</p>
                            <p className="font14 mb-2">{Constants.REWARDS.CONTENT}</p>
                        </div>
                        <div className="bg-ltcream p-4 rounded-0 text-center mb-5">
                            <p className="font13 mb-2">{Constants.REWARDS.SUB_CONTENT}</p>
                            <p className="font13 fw-bold mb-2"> {rewards?.totalRewardPoints < Constants.REWARDS.TOTAL_POINTS
                                ? `You're only ${Constants.REWARDS.TOTAL_POINTS -
                                rewards?.totalRewardPoints} points away from next reward!`
                                : "You're eligible to redeem $5 reward"}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        rewardsList: Actions.getRewardsList(state)
    };
};


export default withRouter(connect(mapStateToProps, {
    getRewards: Actions.getRewards
})(Rewards))