import Actions from '../action'
const initialState = {
    isLoading: false,
    pimCart: [],
    error: false,
    voucher: undefined,
    voucherInfo: undefined,
    rewardsApplied: false,
    createLoading: false,
    updateLoading: false,
    voucherLoading: false,
    bistroComments: undefined
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.PIM_CART_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PIM_CART_LIST_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                pimCart: action.data.response,
                error: false
            };
        }
        case Actions.PIM_CART_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.CREATE_PIM_CART: {
            return {
                ...state,
                createLoading: true,
            };
        }
        case Actions.CREATE_PIM_CART_SUCCESS: {

            return {
                ...state,
                createLoading: false,
                pimCart: action.data.response,
                error: false
            };
        }
        case Actions.CREATE_PIM_CART_FAILURE: {
            return {
                ...state,
                createLoading: false,
                error: action.error,
            };
        }

        case Actions.UPDATE_PIM_CART: {
            return {
                ...state,
                updateLoading: true,
            };
        }
        case Actions.UPDATE_PIM_CART_SUCCESS: {

            return {
                ...state,
                updateLoading: false,
                pimCart: action.data.response,
                error: false
            };
        }
        case Actions.UPDATE_PIM_CART_FAILURE: {
            return {
                ...state,
                updateLoading: false,
                error: action.error,
            };
        }
        case Actions.APPLY_VOUCHER: {
            return {
                ...state,
                voucherLoading: true,
            };
        }
        case Actions.APPLY_VOUCHER_SUCCESS: {

            return {
                ...state,
                voucherLoading: false,
                cartDetails: action?.data?.response || {},
                cartOffers: action?.data?.offers || {},
                error: false
            };
        }
        case Actions.APPLY_VOUCHER_FAILURE: {
            return {
                ...state,
                voucherLoading: false,
                error: action.error,
            };
        }

        case Actions.UPDATE_CART_AFTER_VOUCHER: {
            return {
                ...state,
                voucher: action.voucher,
                voucherInfo: action.voucherInfo
            };
        }
        case Actions.UPDATE_REDEEM_POINTS: {
            return {
                ...state,
                rewardsApplied: action.rewardsApplied,
            };
        }
        case Actions.UPDATE_BISTRO_COMMENTS: {
            return {
                ...state,
                bistroComments: action.data,
            };
        }
        case Actions.LOADER_OFF: {
            console.log('*Loader Off*')
            return {
                ...state,
                createLoading: false,
                updateLoading: false,
                voucherLoading: false,
            };
        }
        default:
            return state;
    }
};
