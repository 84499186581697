import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthGet, doAuthPost } from '../../service/index'
import { CALCULATE_CUSTOMER_COUPON, GET_CART_DETAILS } from '../../config/api_endpoint';

export function* getCartDetails() {
    try {
        const response = yield doAuthGet(GET_CART_DETAILS);
        yield put(Actions.getCartDetailsSuccess(response));
    } catch (error) {
        yield put(Actions.getCartDetailsFailure(error));
    }
}

export function* applyVoucher({ params, callback }) {
    try {
        const response = yield doAuthPost(CALCULATE_CUSTOMER_COUPON, params);
        callback && callback(response)
        yield put(Actions.applyCustomerVoucherSuccess(response));
    } catch (error) {
        callback && callback({ error })
        yield put(Actions.applyCustomerVoucherFailure({ error }));
    }
}

export default function* cartWatcher() {
    yield all([
        takeLatest(Actions.CART_DETAILS, getCartDetails),
        takeLatest(Actions.APPLY_VOUCHER, applyVoucher)
    ]);
}
