import React from 'react';
import routes from '../../config/routes';
import { useHistory } from 'react-router-dom';

export default function BreadCrumbs({ categoryName, productsTitle, subCategoryTitle, isMobile }) {
    const history = useHistory();
    return <nav className={`${isMobile ? 'pt-3 text-capitalize' : 'd-none d-md-block text-capitalize'}`} aria-label="breadcrumb">
        <ol className={`${isMobile ? 'breadcrumb' : 'breadcrumb white'}`}>
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            {<li className="breadcrumb-item ">
                <a href={categoryName === 'Products' ? routes.CATEGORY_LIST : '/category/bistro'} className='title-case'>{categoryName?.toLowerCase()}</a>
            </li>}
            {productsTitle ? <li className={`breadcrumb-item ${!subCategoryTitle ? 'active' : ''}`}>
                {subCategoryTitle ? <span
                    id='subCategory'
                    style={{ cursor: 'pointer' }}
                    onClick={() => subCategoryTitle && history.goBack()}
                    className='title-case text-white'
                >{productsTitle?.toLowerCase()}</span> : productsTitle?.toLowerCase()}
            </li> : null}
            {subCategoryTitle ? <li className={`title-case breadcrumb-item ${subCategoryTitle ? 'active' : ''}`} aria-current="page">{subCategoryTitle?.toLowerCase()}</li> : null}
        </ol>
    </nav>
}