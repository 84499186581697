export const FEED_BACK = `/FEED_BACK`;
export const FEED_BACK_SUCCESS = `/FEED_BACK_SUCCESS`;
export const FEED_BACK_FAILURE = `/ FEED_BACK_FAILURE`;

export const feedBack = (params, callback) => {
    return {
        type: FEED_BACK,
        params,
        callback
    }
}
export const feedBackSuccess = (data) => {
    return {
        type: FEED_BACK_SUCCESS,
        data
    }
}

export const feedBackFailure = (error) => {
    return {
        type: FEED_BACK_FAILURE,
        error
    }
}





