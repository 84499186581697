
import React from 'react';
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import "./asset/css/bootstrap.min.css";
import "./asset/css/styles.css"
import "./asset/css/customs.css";
import AppFullPageLoader from './component/hocComponent'
import { connect, useSelector } from "react-redux";
import Actions from "./redux/action";
import Loader from './component/loader';
import Header from './component/header';
import SocketMessage from './component/SocketMessage';
import APPROUTES from './Routes';
import ROUTES from './config/routes';
import Footer from './component/footer';

export const AuthRoute = ({ noHeaderFooter, component, ...rest }) => {
  return (
    <>
      <Route
        {...rest}
        component={component}
      />
      {!noHeaderFooter && <Footer />}
      <AppFullPageLoader />
    </>
  );
}

export const ProtectedRoute = ({ ...route }) => {
  const { token } = useSelector(Actions.getUserDetail) || {};
  const { access_token } = token || {};
  const { isAuthenticated } = route;

  return !access_token && isAuthenticated ? <Redirect
    path={APPROUTES[0].path}
    component={APPROUTES[0].component} /> : <AuthRoute {...route} />;
}

export class App extends React.Component {

  componentDidMount() {
    this.props.clientCredentials();
  }

  render() {
    let { token } = this.props.getClientCredentials || {};
    const { location } = this.props.history;
    const summaryScreen = location.pathname.includes(ROUTES.ORDER_SUMMARY_REDIRECT.replace(':invoiceId', ''))
      || location.pathname.includes(ROUTES.ORDER_FAILURE_REDIRECT);

    if (token) {
      const isFooterHidden = location.pathname === ROUTES.PAYMENT_SCREEN || location.pathname === ROUTES.SUBSCRIPTION_PAYMENT || location.pathname === ROUTES.SAVE_CARD_PAYMENT;
      return (
        <div className={`${!isFooterHidden ? 'page-content-container' : ''}`}>
          {!summaryScreen && <Header />}
          <Switch>
            {APPROUTES.map(route => <ProtectedRoute key={route.path} {...route} />)}
            <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          </Switch >
          <SocketMessage />
        </div>
      )
    } else {
      return (
        <Loader />
      )
    }
  }
}

export const mapStateToProps = (state) => {
  return {
    getClientCredentials: Actions.getClientCredentials(state),
    user: Actions.getUserDetail(state),
  };
};
export default withRouter(connect(mapStateToProps, {
  clientCredentials: Actions.clientCredentials,
  getRefreshToken: Actions.refreshTokenAction,
})(App))
