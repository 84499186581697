import GoogleMapReact from 'google-map-react';
import React from 'react';
import { MAP_API_KEY } from '../../config/api_endpoint';
import marker from '../../asset/img/map_marker.png'

export const UserMarker = ({ text }) => <span title={text}
    style={{
        left: '45%',
        position: 'absolute',
        top: '30%'
    }}>
    <img alt="text"
        src={marker}
        height="30px" width="30px" />
</span>

const AddressMapView = ({ zoom, center, botLatLng, onClick, style = { height: '100%', width: '100%' } }) => {
    return <div style={style}>
        <GoogleMapReact
            id="googleMapReact"
            options={
                { fullscreenControl: false }
            }
            bootstrapURLKeys={{ key: MAP_API_KEY }}
            center={center}
            defaultZoom={15}
            onChange={(e) => onClick({ lat: e?.center?.lat, lng: e?.center?.lng })}
        >

        </GoogleMapReact>
        <UserMarker
            {...center} />
    </div >
};


AddressMapView.defaultProps = {
    center: {
        lat: 59.95,
        lng: 30.33
    },
    botLatLng: {

    },
    onClick: () => null,
    zoom: 12
};


export default AddressMapView;