import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import moment from "moment";
import Refund from "../../component/refund";
import Constants from "../../Utility/Constants";
import ChatScreen from "../chat/ChatScreen";
import { getQueryParams } from "../../Utility/validation";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import routes from "../../config/routes";
import OrderSummaryOfferDetails from "./OrderSummaryOfferDetails";
import InvoiceSummary from "./InvoiceSummary";
import DeliveryInstruction from "../../component/alert/deliveryInstruction";
import QrCode from "../../component/qrCode";
import Qrimage from '../../asset/img/qrImage.png'
import RefundPreviewModal from '../../component/refund/previewModal'
import ViewRefundReceipt from "../../component/viewRefundReceipt";
import { getInvoiceDate, getRandomNumber } from "../../Utility/Utils";
import OrderStatus from "./OrderStatus";
import { _renderDeliveryAddress, _renderOrderTypes } from "../accountOrderSummary";
import OrderTrackStatus from "./OrderTrackStatus";

export const sortCheckoutOrders = (a, b) => {
    if (a.type < b.type) {
        return -1;
    }
    if (a.type > b.type) {
        return 1;
    }
    return 0;
}


export const reduceCheckourOrders = (previousValue, currentValue) => {
    if (previousValue.length === 0) {
        if (currentValue.orderType === 1) {
            currentValue.sectionHeader = Constants.FOOTER.bistro;
        } else if (currentValue.orderType === 2) {
            currentValue.sectionHeader = Constants.FOOTER.groceries;
        }
        return [...previousValue, currentValue];
    }

    if (previousValue[previousValue.length - 1].orderType === currentValue.orderType) {
        return [...previousValue, currentValue];
    } else if (previousValue[previousValue.length - 1].orderType !== currentValue.orderType) {
        if (currentValue.orderType === 1) {
            currentValue.sectionHeader = Constants.FOOTER.bistro;
        } else if (currentValue.orderType === 2) {
            currentValue.sectionHeader = Constants.FOOTER.groceries;
        }
        return [...previousValue, currentValue];
    }
};


class OrderSummary extends React.Component {
    constructor(props) {
        super(props);
        const { isNewOrder } = this.props.getOrderDetails || {};
        this.state = {
            isRefundModal: false,
            showChatSuportWindow: false,
            showHideChat: false,
            openInstructionModal: isNewOrder,
            openQrModal: false,
            openRefundPreviewModal: false,
            refundImage: null,
            qrImages: null,
            viewRefundModal: false
        };
    }

    componentDidMount() {
        const {
            match,
            orderDetails,
            updatedReedemPoints,
            setIsNewOrderAction,
            updateCartAfterVoucher,
            updateBistroComments
        } = this.props;
        const { invoiceId } = match.params;
        orderDetails({ invoiceId });
        updateCartAfterVoucher('', '');
        this.openchatIfQueryParamsExists();
        this.getCartDetails();
        updatedReedemPoints(false);
        setIsNewOrderAction(false);
        updateBistroComments('')
    }

    manageChat(isOpen) {
        this.setState({ showHideChat: isOpen || !this.state.showHideChat });
    }

    getCartDetails() {
        let { token } = this.props.getUserDetail || {}
        if (token) {
            this.props.getCartDetails()
        }
    }

    openchatIfQueryParamsExists() {
        const queryParams = getQueryParams(this.props.location.search);
        const isOpenChat = !!queryParams.get('openChat');

        if (isOpenChat) {
            this.setState({ showChatSuportWindow: true })
        }
    }

    onRefund = () => {
        this.setState({ isRefundModal: true });
    }
    openQrCodeModal(image) {
        this.setState({ openQrModal: true, qrImages: image })
    }
    closeQrCodeModal() {
        this.setState({ openQrModal: false })
    }
    refundPage() {
        this.setState({ isRefundModal: true });
    }
    isRefundPageClose(msg) {
        if (msg) {
            let { invoiceId } = this.props.match.params;
            let params = {
                invoiceId: invoiceId,
            };
            this.props.orderDetails(params);
        }
        this.setState({ isRefundModal: false });
    }





    sendToEmail() {
        const callback = (res) => {
            if (res?.response_code === 0) {
                toast.success('Receipt successfully sent', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(res?.response_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }

        const { invoiceId } = this.props.match.params;
        const params = {
            invoiceId,
        };
        this.props.sendToEmail(params, callback)

    }

    _getPriceOfProduct = (item, orderDetails) => {
        let { unitPrice, invoiceProductAddOnMappingList, quantity, acceptedQuantity } = item;
        let sellingPrice = parseFloat(unitPrice);
        if (
            invoiceProductAddOnMappingList &&
            invoiceProductAddOnMappingList.length !== 0
        ) {
            sellingPrice =
                sellingPrice +
                invoiceProductAddOnMappingList.reduce((accumulator, i) => {
                    return accumulator + parseFloat(i.unitPrice);
                }, 0);
        }
        return ((orderDetails?.orderStatus !== 1 ? acceptedQuantity : quantity) * sellingPrice)?.toFixed(2);
    };



    navigateToChatScreen() {
        this.setState({ showChatSuportWindow: true })
    }
    navigateToTrackMyBot(botOrderId) {
        if (botOrderId)
            this.props.history.push(routes.TRACK_DAXBOT_SCREEN.replace(':id', botOrderId))
    }


    _renderRefundButton(invoiceDate, invoiceOrderDetailsList, paymentStatus, createdBy) {
        const isRefundNotExpired = moment().isBefore(moment(invoiceDate).add(7, 'days'))
        let indexofNonCompleted = invoiceOrderDetailsList && invoiceOrderDetailsList.findIndex(i =>
            i.orderStatus !== Constants.ORDER_STATUS.DELIVERED &&
            i.orderStatus !== Constants.ORDER_STATUS.DECLINE_ORDER,
        );
        let indexofCompleted = invoiceOrderDetailsList &&
            invoiceOrderDetailsList.findIndex(i => i.orderStatus === Constants.ORDER_STATUS.DELIVERED);
        return (
            <React.Fragment>
                {
                    paymentStatus === 0 && isRefundNotExpired && indexofNonCompleted === -1 &&
                    indexofCompleted !== -1 && createdBy !== Constants.ORDER_TYPES.CREATED_BY_VISION_CHECKOUT && (

                        <div className="mb-5">
                            <button data-bs-toggle="modal" onClick={() => this.onRefund()} data-bs-target="#refund-modal" className="btn btn-dark next-btn px-4 text-uppercase" name="">{Constants.ORDER_SUMMARY.refund}</button>
                        </div>
                    )
                }
                &nbsp;&nbsp;
            </React.Fragment>
        )
    }


    getOfferDetailsList() {
        const { orderDetails } = this.props.getOrderDetails || {};
        const { promotionJson } = orderDetails || {};
        let promotions = [];
        try {
            promotions.push(JSON.parse(promotionJson));
        } catch (e) {
            promotions = []
        }
        return promotions.length > 0 ? promotions : [];
    }

    getCartObject(invoiceOrderDetailsList) {
        let invoiceOrgOrderList = []
        if (invoiceOrderDetailsList && invoiceOrderDetailsList.length > 0) {
            invoiceOrderDetailsList.forEach((item, index) => {
                invoiceOrgOrderList = [
                    ...invoiceOrgOrderList,
                    ...item.invoiceOrderProductDetailsList,
                ];
            });
        }
        return invoiceOrgOrderList
    }
    openInstructionsModal() {
        this.setState({ openInstructionModal: !this.state.openInstructionModal })
    }
    closeRefundPreview() {
        this.setState({ openRefundPreviewModal: false })
    }
    openRefundPreview(refundImage) {
        this.setState({ openRefundPreviewModal: true, refundImage })
    }

    viewRefundReceipt() {
        this.setState({ viewRefundModal: true });
    }
    closeViewRefundReceipt() {
        this.setState({ viewRefundModal: false });
    }
    render() {
        const { isRefundModal, showHideChat, openInstructionModal, openQrModal, qrImages, viewRefundModal } = this.state;
        const { orderDetails, isLoading } = this.props.getOrderDetails || {};
        const { invoiceDate, totalAmountExcludingTax, processedAmount,
            deliveryAddress, totalTaxAmount, totalAmount,
            invoiceId, invoiceOrderDetailsList, paymentStatus,
            natureOfOrder, createdBy, manualRefunds, refundDetails, storeAddress } = orderDetails || {};
        let { invoiceId: currentInvoiceId } = this.props.match.params;
        let invoiceProductList = [];
        if (invoiceOrderDetailsList && invoiceOrderDetailsList.length > 0) {
            invoiceProductList = invoiceOrderDetailsList?.filter((o) => o.orderStatus === 5)
        }
        const finalAmount = invoiceOrderDetailsList?.findIndex((o) => o?.orderStatus === 1);
        return (
            (!isLoading && orderDetails) && (
                <React.Fragment>
                    <div className="wrap py-50 mb-5">
                        <div className="row gx-5">
                            <div className="col-12 col-md-8">
                                <h2 className="section-heading mb-4">{Constants.ORDER_SUMMARY.orderDetails.title}
                                    {
                                        natureOfOrder === 1 && <span
                                            id="openModal"
                                            className="btn btn-sm cursor-pointer float_right"
                                            onClick={() => this.openInstructionsModal()}>
                                            <img src={require("../../asset/img/info.svg")} alt="&lt;&lt;" />
                                        </span>
                                    }
                                </h2>

                                <hr className="thick mb-4" />
                                {invoiceOrderDetailsList &&
                                    invoiceOrderDetailsList.sort(sortCheckoutOrders)
                                        .reduce(reduceCheckourOrders, []).map((object) => {
                                            const { botOrderId, orderStatus } = object;
                                            return (
                                                <React.Fragment>
                                                    <div id="products">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h3 className="font18 fw-bold mb-4 text-uppercase">{object.sectionHeader}</h3>
                                                            </div>
                                                            {(object?.orderStatus === 4 && natureOfOrder === 2) && <div className="col-auto text-end">
                                                                <h1 className="font20 fw-bold cursor-pointer"
                                                                    onClick={() => this.openQrCodeModal(object?.encodedOrderId)}>
                                                                    <img height={'30px'} src={Qrimage} alt="QrImage"></img></h1>
                                                            </div>}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <h3 className="font14 mb-1">{Constants.ORDER_SUMMARY.orderId}</h3>
                                                                <strong className="font16">{object.invoiceOrderDetailsId}</strong>
                                                            </div>
                                                            <div className="col">
                                                                <h3 className="font14 mb-1">Order Type</h3>
                                                                <strong className="font16">{_renderOrderTypes(createdBy, natureOfOrder)}</strong>
                                                            </div>
                                                            <div className="col">
                                                                <h3 className="font14 mb-1">Order Date </h3>
                                                                <strong className="font16">{getInvoiceDate(invoiceDate)}</strong>
                                                            </div>

                                                        </div>
                                                        {
                                                            createdBy === Constants.ORDER_TYPES.CREATED_BY_USER_ONLINE_ORDER ? <>
                                                                <div className="row">
                                                                    <>
                                                                        {
                                                                            (natureOfOrder === 1 &&
                                                                                orderStatus === Constants.ORDER_STATUS.READY_TO_PICKUP && botOrderId) && <div className="col">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-dark text-uppercase"
                                                                                    onClick={() => this.navigateToTrackMyBot(botOrderId)}>
                                                                                    Track
                                                                                </button>
                                                                            </div>}
                                                                    </>
                                                                </div>
                                                                <div className="card card-body text-center mt-3 py-4">
                                                                    <hr className="status-line w-75 m-auto" />
                                                                    <div className="row status-row">
                                                                        <React.Fragment>
                                                                            <OrderTrackStatus object={object} natureOfOrder={natureOfOrder} />
                                                                        </React.Fragment>
                                                                    </div>
                                                                </div>

                                                            </> : null
                                                        }

                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                }
                                {
                                    createdBy === Constants.ORDER_TYPES.CREATED_BY_USER_ONLINE_ORDER ? <>
                                        <div className="row align-items-center mt-4">
                                            <div className="col">
                                                <h4 className="font16 fw-bold mb-2">{`${natureOfOrder === 1 ? 'Delivery Address' : ' Pickup Address'}`}</h4>
                                                <p>{_renderDeliveryAddress(deliveryAddress, natureOfOrder, storeAddress)}</p>
                                            </div>
                                        </div> </> : null}

                                {
                                    manualRefunds && manualRefunds?.length > 0 &&
                                    <div className="card mt-4">
                                        <div className="card-header">
                                            <b>Refund Initiated by store manager</b>
                                        </div>
                                        <div className="card-body">
                                            {
                                                manualRefunds && manualRefunds.map((o, i) => {
                                                    return (
                                                        <div className="row" key={i}>
                                                            {/* <div
                                                    className="col-sm-4">{i + 1}</div> */}
                                                            <div
                                                                className="col fw-bold"> Date: {moment(o?.lastModified).format('DD-MMM | hh:mm A')}</div>
                                                            <div
                                                                className="col-auto text-end">${o?.refundAmount?.toFixed(2)}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                paymentStatus === 12 && <p style={{ color: 'blue', fontWeight: '500', paddingTop: '5px' }}>Note : {Constants.ORDER_SUMMARY.paymentType.refundApprocedAfter}</p>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="col-12 col-md-4">

                                <div className="card card-body border-0 p-0 bill-details my-5 mb-lg-3 mt-lg-0">
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="font16 fw-bold mb-4">Bill Details</h3>
                                        </div>
                                        <div className="col-auto text-end">
                                            <h3 className="font16 mb-4"><OrderStatus paymentStatus={paymentStatus} /></h3>
                                        </div>
                                    </div>
                                    {invoiceOrderDetailsList && invoiceOrderDetailsList.sort(sortCheckoutOrders).reduce(reduceCheckourOrders, []).map((object) => {
                                        const { invoiceOrderProductDetailsList: data, customerComment, orderStatus } = object;
                                        return <> {data.map((o) => {
                                            let unfilledQty = o?.quantity - o?.acceptedQuantity
                                            return (
                                                <div className="row mb-2">
                                                    <div className={`col`}>
                                                        <div className={`title-case ${(o?.acceptedQuantity === 0 && orderStatus !== 1) ? 'strike' : ''}`}>
                                                            {orderStatus !== 1 && o?.acceptedQuantity !== 0 ? o?.acceptedQuantity : o?.quantity} x {o?.name?.toLowerCase()} (SKU : {o?.upc})
                                                            {o?.ageRestricted && <span style={{ color: 'red' }}>18+</span>}
                                                        </div>

                                                        {
                                                            o?.invoiceProductAddOnMappingList?.length > 0 && (<>
                                                                <p style={{ paddingLeft: '10px' }} className={`mt-1 ${(o?.acceptedQuantity === 0 && orderStatus !== 1) ? 'strike' : ''}`}><b>{`Your Customization`}</b>:</p>
                                                                <p style={{ paddingLeft: '10px' }} className={`title-case mt-1 ${(o?.acceptedQuantity === 0 && orderStatus !== 1) ? 'strike' : ''}`}>{o?.invoiceProductAddOnMappingList?.map((addons) => addons?.name?.toLowerCase()).join(',')}</p>
                                                            </>
                                                            )
                                                        }
                                                        {
                                                            (object?.orderStatus !== 1 && paymentStatus < 11 && o?.acceptedQuantity !== 0 && o?.acceptedQuantity !== o?.quantity) ?
                                                                <p style={{ color: 'red', paddingLeft: '10px' }} className="mt-1">Unfulfilled Quantity: {unfilledQty}</p> : null
                                                        }
                                                    </div>

                                                    <div className="col-auto text-end"> ${this._getPriceOfProduct(o, object)}</div>
                                                </div>
                                            )
                                        })}
                                            {customerComment && <div> <b>Bistro notes :</b>{customerComment}</div>}</>
                                    })}
                                    {invoiceOrderDetailsList && invoiceOrderDetailsList.sort(sortCheckoutOrders).reduce(reduceCheckourOrders, []).map((object) => {
                                        const { partialAcceptComment } = object;
                                        return <>{partialAcceptComment && <div><b>{object.orderType === 2 ? "Product Comment: " : "Bistro Comment: "}</b> {partialAcceptComment}</div>}
                                        </>
                                    })}
                                    {this.getOfferDetailsList().map((offers, index) => {
                                        return <OrderSummaryOfferDetails
                                            cartOffer={offers}
                                            cartItems={this.getCartObject(invoiceOrderDetailsList)}
                                        />
                                    })}
                                    <div className="dotted-divider pt-3 my-3">
                                        <div className="row mb-2">
                                            <div className="col">Receipt no</div>
                                            <div className="col-auto text-end">{currentInvoiceId}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col">Items total</div>
                                            <div className="col-auto text-end">{`${Constants.COMMONS.usd}${parseFloat(totalAmountExcludingTax)?.toFixed(2)}`}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col">Sales tax</div>
                                            <div className="col-auto text-end"> {`${Constants.COMMONS.usd}${parseFloat(totalTaxAmount)?.toFixed(2)}`} </div>
                                        </div>
                                    </div>
                                    {this.getOfferDetailsList().map((offers, index) => {
                                        return <InvoiceSummary
                                            cartOffer={offers}
                                            natureOfOrder={natureOfOrder}
                                        />
                                    })}
                                    <div className="row mb-3">
                                        <div className="col fw-bold">{createdBy !== 3 && (finalAmount !== -1 || paymentStatus === 1 || paymentStatus === 2 || paymentStatus === 3 || paymentStatus === 4) ? 'Pending amount' : 'Paid amount'}</div>
                                        <div className="col-auto text-end fw-bold">
                                            {createdBy !== 3 && (finalAmount !== -1 || paymentStatus === 1 || paymentStatus === 2 || paymentStatus === 3 || paymentStatus === 4) ? `${Constants.COMMONS.usd}${parseFloat(totalAmount)?.toFixed(2)}` : `${Constants.COMMONS.usd}${parseFloat(processedAmount)?.toFixed(2)}`}
                                        </div>
                                    </div>
                                    {(paymentStatus === 11 || paymentStatus === 12 || paymentStatus === 13) && refundDetails && refundDetails?.refundDetailsId &&
                                        <div className="row mb-5">
                                            <div className="col fw-bold  "></div>
                                            <div id="RefundView" className="col-auto text-end fw-bold cursor-pointer text-primary" onClick={() => this.viewRefundReceipt()}>View Refund Receipt</div>
                                        </div>}

                                    {totalAmount > 0 && this._renderRefundButton(invoiceDate, invoiceOrderDetailsList, paymentStatus, createdBy)}
                                    {isRefundModal && orderDetails && <Refund
                                        id="refundModal"
                                        isRefundOpen={isRefundModal}
                                        orderDetails={invoiceProductList}
                                        closeRefundModal={(msg) => this.isRefundPageClose(msg)}
                                        invoiceId={invoiceId}
                                    />}

                                    <div className="row mt-5">
                                        <div id="sendToEmail" className="col" onClick={() => this.sendToEmail()}> <span className="font14 text-dark fw-bold text-link"> <img src={require("../../asset/img/mail.svg")} alt="mail" height="21" className="me-2" />Email me receipt </span></div>
                                        {
                                            moment().isBefore(moment(invoiceDate).add(7, 'days'),) || (paymentStatus === Constants.PAYMENT_STATUS.REFUND_INIT && moment().isBefore(moment(invoiceDate).add(30, 'days'),)) ?
                                                <div
                                                    className="col text-end">
                                                    <span id="manageChat" className="font14 text-dark text-link fw-bold cursor-pointer"
                                                        onClick={() => this.manageChat()}>
                                                        <img src={require("../../asset/img/chat.svg")}
                                                            alt="mail" height="21"
                                                            className="me-2" />Chat with us</span>
                                                </div> : null
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {
                        this.state.openRefundPreviewModal && <RefundPreviewModal
                            id="refundPreviewModal"
                            onFailure={() => this.closeRefundPreview()}
                            image={this.state.refundImage + '?$' + getRandomNumber()}
                        />

                    }
                    {
                        natureOfOrder === 1 && openInstructionModal && <DeliveryInstruction
                            id="InstructionsModal"
                            onFailure={() => this.openInstructionsModal()} />
                    }
                    {
                        openQrModal && <QrCode title={'Pickup QR code'}
                            id="qrCode"
                            onFailure={() => this.closeQrCodeModal()}
                            qrImages={qrImages} />

                    }
                    {
                        moment().isBefore(moment(invoiceDate).add(7, 'days'),) || (paymentStatus === Constants.PAYMENT_STATUS.REFUND_INIT && moment().isBefore(moment(invoiceDate).add(30, 'days'),)) ? <ChatScreen
                            id="chatScreen"
                            showHideChat={showHideChat}
                            manageChat={(isOpen) => this.manageChat(isOpen)}
                        /> : null
                    }

                    {
                        viewRefundModal && <ViewRefundReceipt
                            id="viewRefundRec"
                            onClose={() => this.closeViewRefundReceipt()}
                            openRefundPreview={() => this.openRefundPreview()} />
                    }

                </React.Fragment >
            )
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getOrderDetails: Actions.getOrderDetails(state),
        getUserDetail: Actions.getUserDetail(state),
    };
};

export default withRouter(connect(mapStateToProps, {
    orderDetails: Actions.orderDetails,
    sendToEmail: Actions.sendToEmail,
    refundOrder: Actions.refundOrder,
    getCartDetails: Actions.getPimCartList,
    updateCartAfterVoucher: Actions.updateCartAfterVoucher,
    updatedReedemPoints: Actions.updatedReedemPoints,
    setIsNewOrderAction: Actions.setIsNewOrderAction,
    updateBistroComments: Actions.updateBistroComments
})(OrderSummary));
