export const UPDATE_FIRST_LAST_NAME = `UPDATE_FIRST_LAST_NAME`;
export const UPDATE_FIRST_LAST_NAME_SUCCESS = `UPDATE_FIRST_LAST_NAME_SUCCESS`;
export const UPDATE_FIRST_LAST_NAME_FAILURE = `UPDATE_FIRST_LAST_NAME_FAILURE`;

export const updateFirstAndLastName = (params, callback) => {
    return {
        type: UPDATE_FIRST_LAST_NAME,
        params,
        callback
    }
}
export const updateFirstAndLastNameSuccess = (data) => {
    return {
        type: UPDATE_FIRST_LAST_NAME_SUCCESS,
        data
    }
}

export const updateFirstAndLastNameFailure = (error) => {
    return {
        type: UPDATE_FIRST_LAST_NAME_FAILURE,
        error
    }
}





