import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import moment from 'moment';
import routes from '../../config/routes';
import Constants from '../../Utility/Constants';
import { toast } from 'react-toastify';
import ModalPopup, { ModalHeader } from "../../component/ModalPopup";
import CartEmpty from "../../component/cartEmpty";

class Subscription extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showConfirmModal: null
        }
    }

    invokeListApis() {
        this.props.getSubscriptionList();
        this.props.getSubscribedList()
    }

    componentDidMount() {
        this.invokeListApis()
    }


    initSubscriptionPayment({ id }) {
        this.props.initializePaymentAction({ id }, (response) => {
            const { response: data } = response
            this.props.history.push({
                pathname: routes.SUBSCRIPTION_PAYMENT,
                state: {
                    htmlTemplate: data
                }
            })
        })
    }

    stopSubscriptionRenewal(o) {
        this.setState({ showConfirmModal: o })
    }

    cancelSubscription(showConfirmModal) {
        const callback = (resp) => {
            if (resp.response_code === 0) {
                this.invokeListApis()
                toast.success(Constants.SUBSCRIPTION.subscriptionCancelled);
                this.setState({ showConfirmModal: null });
            } else {
                toast.success(resp.response_message || Constants.API.something);
            }
        }
        let params = {
            id: showConfirmModal?.id
        }
        this.props.cancelSubscriptionAction(params, callback)
    }

    render() {
        const { showConfirmModal } = this.state;
        const { allSubscriptionList, allSubscribedList, isSubscribedListLoading, isSubscriptionListLoading } = this.props
        const { subscriptionList } = allSubscriptionList
        const { subscribedList } = allSubscribedList
        return (
            <React.Fragment>
                <div className="col ps-md-5">
                    <div className="row mb-5 align-items-center text-link">
                        <div className="col-12 col">
                            <h1 className="font18 fw-bold">Manage subscriptions</h1>
                        </div>
                    </div>

                    {(subscriptionList.length > 0 || subscribedList.length > 0) &&
                        subscriptionList && subscriptionList?.map(
                            (o, i) => {
                                let subscribedData = subscribedList.find(e => e.subscriptionId === o.id);
                                const hasSubscribed = subscribedData && !subscribedData?.subscriptionRenewalTimestamp
                                const joinButton = subscribedData && subscribedData?.subscriptionEndTimestamp
                                    ? 'Re-Join now'
                                    : 'Join now'
                                const notSubscribed = !subscribedData || !subscribedData?.subscriptionRenewalTimestamp
                                return (
                                    <React.Fragment>
                                        {subscribedData ? (
                                            <div className="card card-body p-4 mb-4">
                                                <div className="row align-items-center">
                                                    <div className="col-12 col">
                                                        <h3 className="font14 fw-bold mb-4 text-link">{o.name}</h3>
                                                        <div className="row mb-3">
                                                            <div className="col font12">{o.periodType}: <strong>${o.amount}</strong></div>
                                                            <div className="col font12">Valid till: <strong>{moment(subscribedData
                                                                .subscriptionEndTimestamp).format(
                                                                    'MM/DD/YYYY',
                                                                )}</strong></div>

                                                            {subscribedData
                                                                ?.subscriptionRenewalTimestamp && <div className="col font12">Renewal: <strong>
                                                                    {moment(subscribedData.subscriptionRenewalTimestamp).format(
                                                                        'MM/DD/YYYY',
                                                                    )}</strong></div>}
                                                        </div>
                                                        <p className="font14">{o.description}</p>
                                                    </div>
                                                    {subscribedData
                                                        ?.subscriptionRenewalTimestamp && <div className="col-12 col-2 text-end">
                                                            <button
                                                                className="btn btn-outline-dark mt-3 mt-lg-0" id="stop"
                                                                onClick={() => this.stopSubscriptionRenewal(subscribedData)}>Stop Renewal</button>
                                                        </div>}

                                                    {hasSubscribed ? <div className="col-12 col-2 text-end">
                                                        <button
                                                            className="btn btn-outline-dark mt-3 mt-lg-0" id="joinBtn"
                                                            onClick={() => this.initSubscriptionPayment(o)}> {joinButton}</button>
                                                    </div>
                                                        : null}

                                                </div>
                                            </div>

                                        ) : (
                                            <div className="card card-body p-4 mb-4">
                                                <div className="row align-items-center">
                                                    <div className="col-12 col">
                                                        <h3 className="font14 fw-bold mb-4">{o.name}</h3>
                                                        <div className="row mb-3">
                                                            <div className="col font12">{o.periodType}: <strong>${o.amount}</strong></div>
                                                        </div>
                                                        <p className="font14">{o.description}</p>
                                                    </div>
                                                    {notSubscribed ? <div className="col-12 col-2 text-end">
                                                        <button
                                                            className="btn btn-outline-dark mt-3 mt-lg-0"
                                                            onClick={() => this.initSubscriptionPayment(o)}>Join now</button>
                                                    </div> : null}
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )
                            }
                        )

                    }
                    {!isSubscribedListLoading && !isSubscriptionListLoading
                        && (subscriptionList?.length === 0) ?
                        <CartEmpty
                            id='CartEmpty'
                            buttonName={"REFRESH NOW"}
                            buttonName1={"GO BACK"}
                            body={"Currently no Subscriptions available"}
                            title={"Try back later"}
                            buttonAction={() => window.location.reload()}
                            buttonAction1={() => this.props.history.push(`/`)}
                            breadCrems={false}
                            image={false}
                        /> : null}
                    {!!showConfirmModal && <ModalPopup id='ModalPopup' onCloseModal={() => { this.setState({ showConfirmModal: null }) }} >
                        <ModalHeader title="Cancel Auto Renewal" />
                        <div>Do you want to stop <b>{showConfirmModal.name}</b> renewal?</div>
                        <div style={{ marginTop: '20px' }}>
                            <button
                            id='cancelSubscription'
                                className="btn btn-dark mt-3 mt-lg-0"
                                style={{ marginRight: '10px' }}
                                onClick={() => this.cancelSubscription(showConfirmModal)}
                            >
                                {Constants.SUBSCRIPTION.confirm}
                            </button>
                            <button
                            id='verifyOtp'
                            className="btn btn-outline-dark mt-3 mt-lg-0"
                                onClick={() => {
                                    this.setState({ showConfirmModal: null })
                                }}
                            >
                                {Constants.VERIFY_OTP.cancel}
                            </button>

                        </div>
                    </ModalPopup>
                    }

                </div>

            </React.Fragment>

        )
    }
}

export const mapStateToProps = (state) => {
    return {
        allSubscriptionList: Actions.getAllSubscriptionList(state),
        allSubscribedList: Actions.getAllSubscribedList(state),
        isSubscribedListLoading: Actions.getSubscribedListLoading(state),
        isSubscriptionListLoading: Actions.getSubscriptionListLoading(state),
    };
};

export default withRouter(connect(mapStateToProps, {
    getSubscriptionList: Actions.getSubscriptionList,
    getSubscribedList: Actions.getSubscribedList,
    initializePaymentAction: Actions.initializeSubscriptionPayment,
    cancelSubscriptionAction: Actions.cancelSubscriptionAction

})(Subscription))

