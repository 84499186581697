import Actions from '../../action'
const initialState = {
    isLoading: false,
    error: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.REQUEST_OTP: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.REQUEST_OTP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.REQUEST_OTP_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
