import React, { useRef } from 'react';
import India from '../asset/images/country/India.png';
import USA from '../asset/images/country/USA.png';



const COUNTRIES = [
    {
        name: 'USA',
        phoneCode: '+1',
        flag: USA,
        lengthNumber: 10,
    }
    , {
        name: 'India',
        phoneCode: '+91',
        flag: India,
        lengthNumber: 10,
    },
]

export default ({ defaultValue = '+1', onChange = () => null }) => {

    const optionsRef = useRef(COUNTRIES.map(d => ({
        value: d.name,
        label: <div>
            <span>
                {` (${d.phoneCode})`}
            </span>
        </div>,
        code: d.phoneCode
    })));
    return (
            <select className="form-control custom-field"
                onChange={({ target: { value, name } }) => onChange(value, name)}
                Value={optionsRef.current.find(d => d.code === defaultValue)}>
                {optionsRef.current.map((option) => (
                    <option value={option.code}>{option.code}</option>
                ))}
            </select>
    )
}