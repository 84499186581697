import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthPost } from '../../service/index'
import { FEED_BACK } from '../../config/api_endpoint';

export function* feedBack({ params, callback }) {
    try {
        const response = yield doAuthPost(FEED_BACK, params);
        yield put(Actions.feedBackSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.feedBackFailure(error));
    }
}


export default function* feedbackWatcher() {
    yield all([
        takeLatest(Actions.FEED_BACK, feedBack),
    ]);
}
