import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppMapView from '../../../component/map/AppMapView';
import Actions from '../../../redux/action';

const convertLatLngToInt = (v) => v ? parseFloat(v) : null
class TractDaxBot extends React.Component {

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.getDaxbotInfoById(id);
    }

    render() {
        const { daxbotInfo, isLoading } = this.props;
        const { response } = daxbotInfo || {};
        const botStatus = response?.bot_status;
        const botSummary = response?.bot_summary;
        const centerLatLng = {
            lat: convertLatLngToInt(response?.lat),
            lng: convertLatLngToInt(response?.lon)
        };
        const botLatLng = {
            lat: convertLatLngToInt(botStatus?.location?.lat),
            lng: convertLatLngToInt(botStatus?.location?.lon)
        }

        if (response && !response?.bot_status) {
            return <div style={{ margin: '10px', }}>
                <h2 className="section-heading mb-4">Track Order </h2>
                <div style={{
                    height: '100px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    fontWeight: 'bold', fontSize: '16px'
                }}>Waiting for bot's acceptance.</div>
            </div>
        }
        return !isLoading && centerLatLng?.lat ?
            <div className="wrap py-50 mb-5">
                <div className="row gx-5">
                    <div className="col-12 col-md-8">
                        <h2 className="section-heading mb-4">Track Order </h2>
                        <div className="mb-2">
                            <AppMapView center={centerLatLng} botLatLng={botLatLng} style={{ height: '400px', width: '100%' }} />
                        </div>
                    </div>
                    {(botStatus && botStatus?.drawer_temp && botStatus?.speed) && <div className="col-12 col-md-4">
                        <div className="card card-body border-0 p-0 bill-details my-5 mb-lg-3 mt-lg-0">
                            <h3 className="font16 fw-bold mb-4">Tracking Details</h3>
                            <div className="card card-body text-center my-3 py-4">
                                <hr className="status-line w-75 m-auto" />
                                <div className="row status-row">
                                    <div className="col">
                                        <img src={require("../../../asset/img/green-tick.svg")} alt="Done" className="mb-2" />
                                        <p className="font12 fw-normal">Order received</p>
                                    </div>
                                    <div className="col">
                                        <img src={require("../../../asset/img/green-tick.svg")} alt="Done" className="mb-2" />
                                        <p className="font12 fw-normal">Packed</p>
                                    </div>
                                    <div className="col">
                                        <img src={require("../../../asset/img/green-tick.svg")} alt="Done" className="mb-2" />
                                        <p className="font12 fw-normal">Picked</p>
                                    </div>
                                    <div className="col">
                                        <img src={require("../../../asset/img/grey-circle.svg")} alt="Pending" className="mb-2" />
                                        <p className="font12 fw-normal">Delivered</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col">Drawer temperature</div>
                                <div className="col-auto text-end fw-bold"> {botStatus?.drawer_temp?.toFixed(2)} °C</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">Running speed</div>
                                <div className="col-auto text-end fw-bold">{(botStatus?.speed * 2.236936)?.toFixed(2)} mph</div>
                            </div>
                            {
                                (botSummary && botSummary?.start_time) && <>
                                    <div className="row mb-2">
                                        <div className="col">Start time</div>
                                        <div className="col-auto text-end fw-bold"> {moment(botSummary?.start_time).format('MM/DD/YYYY - hh:mm A')}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">End time</div>
                                        <div className="col-auto text-end fw-bold"> {moment(botSummary?.end_time).format('MM/DD/YYYY - hh:mm A')}</div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>}
                </div>
            </div>
            : null
    }
}

export const mapStateToProps = (state) => {
    return {
        daxbotInfo: state.DAXBOT_CHECK_AVAILABILITY.daxbotInfo,
        isLoading: state.DAXBOT_CHECK_AVAILABILITY.isDaxbotInfoLoading
    }
}

const dispatchToProps = {
    getDaxbotInfoById: Actions.daxbotDeliveryInfoAction
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(TractDaxBot));