import React, { Component } from 'react';
import Constants from '../../Utility/Constants';
import eventBus, { EVENT_FEEDBACK_OPEN, EVENT_FRANCHISE_ENQUIRY_OPEN } from "../../Utility/event";

class Conatct extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    onOpenFeedback() {
        eventBus.dispatch(EVENT_FEEDBACK_OPEN, {});
    }
    onOpenFranchiseEnquirey() {
        eventBus.dispatch(EVENT_FRANCHISE_ENQUIRY_OPEN, {});
    }
    sendToEmail() {
        window.location = Constants.ACCOUNTS.mailto
    }
    render() {
        return (
            <div className="col ps-md-5">
                <div className="row mb-5 align-items-center">
                    <div className="col-12 col">
                        <h1 className="font18 fw-bold">Contact</h1>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="faq">
                    <div className="accordion-item">
                        <h2 className="accordion-header text-link" id="shop-heading">
                            <button
                                id="send-to-email"
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#shop-acc"
                                aria-expanded="false"
                                aria-controls="shop-acc"
                                onClick={() => this.sendToEmail()}>
                                Email customer support
                            </button>
                        </h2>
                    </div>


                    <div className="accordion-item">
                        <h2 className="accordion-header text-link" id="shop-heading">
                            <button
                                id="send-to-feedback"
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#shop-acc"
                                aria-expanded="false"
                                aria-controls="shop-acc"
                                onClick={() => this.onOpenFeedback()}>
                                Feedback
                            </button>
                        </h2>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header text-link" id="shop-heading">
                            <button
                                id="send-to-franchise-enquiry"
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#shop-acc"
                                aria-expanded="false"
                                aria-controls="shop-acc"
                                onClick={() => this.onOpenFranchiseEnquirey()}>
                                Franchise Inquiry
                            </button>
                        </h2>
                    </div>
                </div>
            </div>
        );
    }
}

export default Conatct;