import React from "react";
import { withRouter } from "react-router-dom";
import ForgotPassword from "../forgotPassword";
import eventBus, {
  EVENT_ADD_TO_CART_OPEN,
  EVENT_FORGET_PASSWORD_CLOSE, EVENT_FORGET_PASSWORD_OPEN,
  EVENT_LOGIN_VERIFY_OTP_CLOSE, EVENT_LOGIN_VERIFY_OTP_OPEN,
  EVENT_RESET_PASSWORD_CLOSE, EVENT_RESET_PASSWORD_OPEN,
  EVENT_SIGN_IN_CLOSE, EVENT_SIGN_UP_OPEN, EVENT_VERIFY_OTP_OPEN
} from '../../Utility/event';
import ResetPassword from "../resetPassword";
import VerifyOtp from "../verifyOtp";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import { isFieldEmpty } from "../../Utility/validation";
import Constants from "../../Utility/Constants";
import BoldError from "../BoldError";
import InputField from '../../component/InputField';
import ModalPopup, { ModalHeader } from "../../component/ModalPopup";
import CountryDropDown from '../../component/CountryDropDown';
import routes from "../../config/routes";
const errors = {
  mobileNumber: '',
  password: ''
}
const fields = {
  mobileNumber: '',
  password: '',
  dropdown: '+1'
}
const initialState = {
  isResetModal: false,
  isForgetModal: false,
  isVerifyOtpModal: false,
  fields: { ...fields },
  errorMessage: '',
  errors: { ...errors },
  forgotEmail: '',
  showPassword: false,
  countryCode: ''
};
export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }


  componentDidMount() {
    eventBus.on(EVENT_FORGET_PASSWORD_OPEN, () => {
      this.setState({ isForgetModal: true });
    });
    eventBus.on(EVENT_FORGET_PASSWORD_CLOSE, () => {
      this.setState({ isForgetModal: false });
    });
    eventBus.on(EVENT_LOGIN_VERIFY_OTP_OPEN, ({ mobileNumber }) => {
      const stateParam = { isVerifyOtpModal: true };
      if (mobileNumber) {
        stateParam.forgotEmail = mobileNumber;
      }
      this.setState(stateParam);
    });
    eventBus.on(EVENT_LOGIN_VERIFY_OTP_CLOSE, () => {
      this.setState({ isVerifyOtpModal: false, isForceOtp: false, forgotEmail: '' });
    });
    eventBus.on(EVENT_RESET_PASSWORD_OPEN, () => {
      this.setState({ isResetModal: true });
    });
    eventBus.on(EVENT_RESET_PASSWORD_CLOSE, () => {
      this.setState({ isResetModal: false });
    });
  }

  componentWillUnmount() {
    eventBus.remove(EVENT_FORGET_PASSWORD_OPEN);
    eventBus.remove(EVENT_FORGET_PASSWORD_CLOSE);
    eventBus.remove(EVENT_LOGIN_VERIFY_OTP_OPEN);
    eventBus.remove(EVENT_LOGIN_VERIFY_OTP_CLOSE);
    eventBus.remove(EVENT_RESET_PASSWORD_OPEN);
    eventBus.remove(EVENT_RESET_PASSWORD_CLOSE);
  }

  closeForgetModal(isClose) {
    if (isClose) {
      eventBus.dispatch(EVENT_FORGET_PASSWORD_CLOSE, {})
      eventBus.dispatch(EVENT_RESET_PASSWORD_OPEN, {})
    } else {
      eventBus.dispatch(EVENT_FORGET_PASSWORD_CLOSE, {})
    }
  }

  openForgotModal() {
    const resetState = { ...initialState, fields: { ...fields }, errors: { ...errors } }
    this.setState(resetState);
    eventBus.dispatch(EVENT_FORGET_PASSWORD_OPEN, {})
    eventBus.dispatch(EVENT_SIGN_IN_CLOSE, {})
  }

  openSignUpModal() {
    const resetState = { ...initialState, fields: { ...fields }, errors: { ...errors } }
    this.setState(resetState);
    eventBus.dispatch(EVENT_SIGN_UP_OPEN, {})
    eventBus.dispatch(EVENT_SIGN_IN_CLOSE, {})
  }

  closeVerifyOtp(isResetPassword) {
    eventBus.dispatch(EVENT_LOGIN_VERIFY_OTP_CLOSE, {});
    if (isResetPassword) {
      eventBus.dispatch(EVENT_RESET_PASSWORD_OPEN, {});
    }
  }


  handleNextKeyUp(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSubmit()
    }
  }

  handleSubmit() {
    if (this.handleSubmitValidation(["mobileNumber", "password"])) {
      const callback = (response) => {
        let { response_message, access_token } = response
        if (access_token) {
          if (!response?.customer?.active && !response?.customer?.smsOtpVerified) {
            this.closeLoginModal();
            eventBus.dispatch(EVENT_VERIFY_OTP_OPEN, {
              mobileNumber: response?.customer?.mobileNumber,
              hideChangeEmail: true
            });
          } else {
            const { isLiveAgent } = response?.customer || {}
            if (isLiveAgent) {
              this.closeLoginModal('success')
            } else {
              // Normal customer//
              let isLiveAgentCard = localStorage.getItem("previousCartId") ? localStorage.getItem("previousCartId") : null
              // Is Scanned Cart Id
              if (isLiveAgentCard) {
                const callback = (resp) => {
                  if (!resp?.error) {
                    localStorage.removeItem("previousCartId")
                    this.closeLoginModal('success')
                    this.props.history.push({ pathname: routes.CHOOSE_ADDRESS })
                  }
                }
                let params = { cartId: isLiveAgentCard }
                // Let's swap the cart live agent to login customer
                this.props.cardSwap(params, callback)
              } else {
                this.closeLoginModal('success')
              }
            }
          }

        } else {
          this.setState({ errorMessage: response_message || Constants.API.something })
        }
      }

      let params = {
        userName: this.state.fields.dropdown + this.state.fields.mobileNumber,
        password: this.state.fields.password
      }

      this.props.login(params, callback)
    }

  }

  handleSubmitValidation(verifyField) {
    let isFormValid = true;
    verifyField.forEach((field) => {
      if (!this.handleValidation(field)) {
        isFormValid = false
      }
    })
    return isFormValid;
  }

  handleChange(e) {
    let { fields: stateFields } = this.state
    let { name, value } = e.target
    stateFields[name] = value
    this.setState({ fields: stateFields }, () => {
      this.handleValidation(name);
    })
  }

  handleValidation(field) {
    let { fields: intialFields, errors: initialErrors } = this.state;
    switch (field) {
      case 'mobileNumber':
        if (isFieldEmpty(intialFields[field])) {
          initialErrors[field] = Constants.LOGIN.error.mobileNumberEmpty;
          this.setState({ errors: initialErrors })
          return false
        } else if (intialFields[field].length !== 10) {
          initialErrors[field] = Constants.LOGIN.error.mobileNumber;
          this.setState({ errors: initialErrors })
          return false
        }
        break;
      case 'password':
        if (isFieldEmpty(intialFields[field])) {
          initialErrors[field] = Constants.LOGIN.error.passkey;
          this.setState({ errors: initialErrors })
          return false
        } else if (intialFields[field].length < 8) {
          initialErrors[field] = Constants.LOGIN.error.passkeyLimit;
          this.setState({ errors: initialErrors })
          return false
        }
        break;
      default:
        return null
    }
    errors[field] = ''
    this.setState({ errors })
    return true;
  }

  closeLoginModal = (msg) => {
    const { isAddToCart } = this.props
    const resetState = { ...initialState, fields: { ...fields }, errors: { ...errors } }
    this.setState(resetState);
    if (msg) {
      this.props.onCloseModal(msg)
      if (isAddToCart) {
        eventBus.dispatch(EVENT_ADD_TO_CART_OPEN, {})
      }
    } else {
      this.props.onCloseModal()
      let isLiveAgentCard = localStorage.getItem("previousCartId") ? localStorage.getItem("previousCartId") : null
      if (isLiveAgentCard) {
        this.props.history.push("/")
      }
    }

  }

  setShowPassword() {
    this.setState({ showPassword: !this.state.showPassword })
  }

  render() {
    const { isModalOpen } = this.props;
    let { isForgetModal,
      isForceOtp, isResetModal, isVerifyOtpModal,
      errors: fieldsErrors, errorMessage, forgotEmail, showPassword } = this.state;
    return (
      <>
        {isModalOpen && <ModalPopup onCloseModal={() => this.closeLoginModal()} id="LoginModalPopup">
          <ModalHeader title="Login" onCloseModal={() => this.closeLoginModal()} />
          <BoldError message={errorMessage} />
          <form>
            <div className="row align-items-center  mb-5">
              <div className="col-2">
                <CountryDropDown
                  id="CountryDropDown"
                  defaultValue='+1'
                  onChange={(v) => this.handleChange({ target: { value: v, name: 'dropdown' } })} />
              </div>
              <div className="col">
                <InputField
                  style={{ width: '100%' }}
                  className="form-control custom-field"
                  type="number"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  onChange={(value, name) => this.handleChange({ target: { value, name } })}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                  }}
                />
              </div>
              {fieldsErrors && <p className="errorMsg">{fieldsErrors['mobileNumber']}</p>}
            </div>
            <div className="input-group mb-4">
              <input
                className="form-control custom-field"
                type={showPassword ? 'text' : 'password'}
                name="password"
                onChange={(e) => this.handleChange(e)}
                onKeyUp={(event) => this.handleNextKeyUp(event)}
                placeholder="Password"
                aria-describedby="view-pass" />
              <button className="btn custom-field"
                onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                type="button"
                id="view-pass">
                <i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} />
              </button>
            </div>
            {fieldsErrors && <p className="errorMsg">{fieldsErrors['password']}</p>}
            <div className="row mb-5">
              <div className="col">
                <span className="text-dark text-link"
                  id="signup"
                  data-bs-toggle="modal"
                  data-bs-target="#register-modal"
                  onClick={() => this.openSignUpModal()}>{Constants.LOGIN.signup}</span>
              </div>
              <div className="col text-end cursor-pointer">
                <span className="text-dark text-link"
                  id="forgot"
                  onClick={() => this.openForgotModal()}>{Constants.LOGIN.forgotPassword}</span>
              </div>
            </div>
            <div className="text-center pt-4">
              <button
                type="button" className="btn btn-dark big-btn w-100 px-5 text-uppercase"
                id="login"
                onClick={() => this.handleSubmit()}>LOGIN</button>
            </div>
          </form>
        </ModalPopup>}

        {isForgetModal && <ForgotPassword
          id="ForgotPassword"
          isModalOpen={isForgetModal}
          onCloseModal={(isClose) => this.closeForgetModal(isClose)}
          successEmail={(forgotEmailMsg) => this.setState({ forgotEmail: forgotEmailMsg })}
        />}

        {isResetModal && <ResetPassword
          id='ResetPassword'
          isModalOpen={isResetModal}
          onCloseModal={(newPassword) => this.closeResetModal(newPassword)}
        />}

        {isVerifyOtpModal && <VerifyOtp
          id='VerifyOtp'
          isModalOpen={isVerifyOtpModal}
          onCloseModal={(isResetPassword) => {
            this.closeVerifyOtp(isResetPassword)
          }}
          isForceOtp={isForceOtp}
          isLogin={true}
          forgotEmail={forgotEmail}
        />}
      </>
    );
  }
}



export const mapStateToProps = (state) => {
  return {
  };
};

export default withRouter(connect(mapStateToProps, {
  login: Actions.login,
  forgetPasswordVerifyOtp: Actions.forgetPasswordVerifyOtp,
  cardSwap: Actions.cardSwap
})(Login))
