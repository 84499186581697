import React, { Component } from 'react';
import Constants from '../../Utility/Constants';
import { toast } from 'react-toastify'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import InputField from "../InputField";
import DeleteAccount from '../deleteAccount'
import BoldError from '../../component/BoldError';
import { EmailField } from '../EmailField';
import { isValidEmail } from '../../Utility/validation';


const onlyCharactersRegex = new RegExp(/^[a-zA-Z\s-]+$/);

const ERROR_FIRST_NAME = 'First Name field accepts only alphabets.';
const ERROR_LAST_NAME = 'Last Name field accepts only alphabets.';

class EditAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            updateFirstName: "",
            updateLastName: "",
            updateEmailId: "",
            isEmailValid: false,
            isDeleteAccount: false,
            updateEmail: ''
        }
    }


    OnUpdateAccountDetails = () => {
        const { updateLastName = '', updateFirstName = '', updateEmailId = '' } = this.state;
        let error = null;
        if (updateFirstName.length > 0 && !onlyCharactersRegex.test(updateFirstName)) {
            error = ERROR_FIRST_NAME;
        } else if (updateFirstName.trim().length < 3) {
            error = Constants.REGISTER.error.firstName;
        } else if (updateLastName.trim().length === 0) {
            error = Constants.REGISTER.error.lastName;
        } else if (updateLastName.length > 0 && !onlyCharactersRegex.test(updateLastName)) {
            error = ERROR_LAST_NAME;
        } else if (updateEmailId.trim().length === 0) {
            error = Constants.REGISTER.error.enterEmailId;
        } else if (!isValidEmail(updateEmailId)) {
            error = Constants.REGISTER.error.validEmail;
        } else {
            error = ''
        }
        // else if (!isEmailValid) {
        //     error = Constants.REGISTER.error.email;
        // }

        const callback = (res) => {
            let { response_code } = res
            if (response_code === 0) {
                let { customer } = res?.response
                this.props.updateProfileName(customer)
                toast.dismiss("myAccount_Succes")
                toast.success(res?.response_message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "myAccount_Succes"
                }
                );
            }
        }

        this.setState({ userNameErrorMessage: error });
        if (error) {
            return;
        }

        let params = {
            firstName: updateFirstName,
            lastName: updateLastName,
            email: updateEmailId
        }

        this.props.updateFirstAndLastName(params, callback)
    }

    componentDidMount() {
        const callback = (res) => {
            if (res?.response_code === 0) {
                const { response } = res || {}
                this.setState({
                    updateFirstName: response?.firstName,
                    updateLastName: response?.lastName, updateEmailId: response?.emailId
                })
            }
        }
        this.props.getUserDetails(callback)
    }
    openDeleteAccount = () => this.setState({ isDeleteAccount: true });
    onCloseModalDeleteAccount = () => this.setState({ isDeleteAccount: false });

    render() {
        const { updateFirstName, updateLastName, isDeleteAccount, userNameErrorMessage, updateEmailId } = this.state
        const { userDetails } = this.props.getCustomerDetails || {}
        const { mobileNumber } = userDetails || {};
        return (
            <div className="col ps-md-5">

                <div className="row mb-5 align-items-center text-link">
                    <div className="col-12 col">
                        <h1 className="font18 fw-bold">Edit account</h1>
                    </div>
                </div>

                <div className="row mb-4 text-link">
                    <div className="col col-md-5">
                        <h3 className="font16 fw-bold"> {mobileNumber} </h3>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col col-md-5">
                        <BoldError message={userNameErrorMessage} />
                        <InputField
                            type="text"
                            name="updateFirstName"
                            id="FirstName"
                            className="form-control rounded-0 custom-field"
                            placeholder="First Name"
                            value={updateFirstName}
                            error={(() => {
                                return updateFirstName?.length > 0 && !onlyCharactersRegex.test(updateFirstName) ? ERROR_FIRST_NAME : null
                            })()}
                            onChange={(value) => this.setState({ updateFirstName: value })}
                        />
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col col-md-5">
                        <InputField
                            type="text"
                            id="LastName"
                            name="updateLastName"
                            className="form-control rounded-0 custom-field"
                            placeholder="Last Name"
                            value={updateLastName}
                            error={(() => {
                                return updateLastName?.length > 0 && !onlyCharactersRegex.test(updateLastName) ? ERROR_LAST_NAME : null
                            })()}
                            onChange={(value) => this.setState({ updateLastName: value })}
                        />
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col col-md-5">
                        <EmailField
                            id="EmailField"
                            defaultValue={updateEmailId}
                            value={updateEmailId}
                            onChange={(value) => this.setState({ updateEmailId: value })}
                            isEmailValid={(isEmailValid) => this.setState({ isEmailValid })} />
                    </div>
                </div>

                <div className="row pt-5 mb-4">
                    <div className="col col-md-5">
                        <button className="btn btn-dark big-btn text-uppercase" id="update" onClick={() => this.OnUpdateAccountDetails()}>Update</button>
                    </div>
                </div>
                <hr className="thick my-5" />
                <div className="row mb-3 align-items-center">
                    <div className="col-12 col">
                        <h2 className="font18 fw-bold">Want to Delete your Account?</h2>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col col-md-5">
                        <button className="btn btn-outline-dark big-btn text-uppercase" data-bs-toggle="modal" data-bs-target="#delete-account-modal" id="deleteAccount" name="" onClick={() => this.openDeleteAccount()}>Delete Account</button>
                    </div>
                </div>
                {isDeleteAccount && <DeleteAccount
                    isModalOpen={isDeleteAccount}
                    onCloseModal={this.onCloseModalDeleteAccount}
                />}
            </div>

        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getUserDetail: Actions.getUserDetail(state),
        getCustomerDetails: Actions.getCustomerDetails(state)
    };
};


export default withRouter(connect(mapStateToProps, {
    updateFirstAndLastName: Actions.updateFirstAndLastName,
    updateProfileName: Actions.updateProfileName,
    getUserDetails: Actions.getUserDetails
})(EditAccount))