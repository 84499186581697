import { takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthGet, doAuthPost } from '../../service/index'
import { INIT_PAYMENT_METHOD, GET_CARD_DETAILS, INIT_PYM_PAYMENT } from '../../config/api_endpoint';

export function* addPaymentCardInfoSaga({ callback }) {
    try {
        const params = { isMultiCard: true }
        const response = yield doAuthPost(INIT_PAYMENT_METHOD, params);
        callback && callback(response)
    }
    catch (error) {
        callback && callback(error)
    }
}


export function* getCardInfoSaga({ callback }) {
    try {
        const response = yield doAuthGet(GET_CARD_DETAILS, {});
        callback && callback(response)
    }
    catch (error) {
        callback && callback(error)
    }
}

export function* initPreAuthPaymentSaga({ params, callback }) {
    try {
        const response = yield doAuthPost(INIT_PYM_PAYMENT, params);
        callback && callback(response)
    }
    catch (error) {
        callback && callback(error)
    }
}


export default function* paymentSaga() {
    yield all([
        takeLatest(Actions.ADD_PAYMENT_INFO_ACTION, addPaymentCardInfoSaga),
        takeLatest(Actions.GET_CARD_INFO_ACTION, getCardInfoSaga),
        takeLatest(Actions.INIT_PRE_AUTH_PAYMENT, initPreAuthPaymentSaga)
    ]);
}
