import Actions from '../action'
const initialState = {
    cartDetails: [],
    isLoading: false,
    error: false,
    checkoutParams: {}
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.ADD_TO_CART: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ADD_TO_CART_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                cartDetails: action.data,
                error: false
            };
        }
        case Actions.ADD_TO_CART_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.SAVE_CHECKOUT_PARAMS: {
            return {
                ...state,
                checkoutParams: action.data,
            };
        }
        default:
            return state;
    }
};
