const NAME = "PIM_CART";
export const PIM_CART_LIST = `${NAME}/PIM_CART_LIST`;
export const PIM_CART_LIST_SUCCESS = `${NAME}/PIM_CART_LIST_SUCCESS`;
export const PIM_CART_LIST_FAILURE = `${NAME}/PIM_CART_LIST_FAILURE`;

export const CREATE_PIM_CART = `${NAME}/CREATE_PIM_CART`;
export const CREATE_PIM_CART_SUCCESS = `${NAME}/CREATE_PIM_CART_SUCCESS`;
export const CREATE_PIM_CART_FAILURE = `${NAME}/CREATE_PIM_CART_FAILURE`;

export const UPDATE_PIM_CART = `${NAME}/UPDATE_PIM_CART`;
export const UPDATE_PIM_CART_SUCCESS = `${NAME}/UPDATE_PIM_CART_SUCCESS`;
export const UPDATE_PIM_CART_FAILURE = `${NAME}/UPDATE_PIM_CART_FAILURE`;


export const APPLY_VOUCHER = `${NAME}/APPLY_VOUCHER`;
export const APPLY_VOUCHER_SUCCESS = `${NAME}/APPLY_VOUCHER_SUCCESS`;
export const APPLY_VOUCHER_FAILURE = `${NAME}/APPLY_VOUCHER_FAILURE`;
export const UPDATE_CART_AFTER_VOUCHER = `/UPDATE_CART_AFTER_VOUCHER`

export const UPDATE_REDEEM_POINTS = `UPDATE_REDEEM_POINTS`

export const UPDATE_BISTRO_COMMENTS = `UPDATE_BISTRO_COMMENTS`

export const CART_DEACTIVATE = 'CART_DEACTIVATE'
export const LOADER_OFF = 'LOADER_OFF'

export const getPimCartDetails = store => store[NAME]
export const getPimCartList = (params) => {
    return {
        type: PIM_CART_LIST,
        params
    }
}

export const getPimCartListSuccess = (data) => {
    return {
        type: PIM_CART_LIST_SUCCESS,
        data
    }
}

export const getPimCartListFailure = (error) => {
    return {
        type: PIM_CART_LIST_FAILURE,
        error
    }
}


export const createPimCart = (params, callback) => {
    return {
        type: CREATE_PIM_CART,
        params,
        callback
    }
}

export const createPimCartSuccess = (data) => {
    return {
        type: CREATE_PIM_CART_SUCCESS,
        data
    }
}

export const createPimCartFailure = (error) => {
    return {
        type: CREATE_PIM_CART_FAILURE,
        error
    }
}


export const updatePimCart = (params, callback) => {
    return {
        type: UPDATE_PIM_CART,
        params,
        callback
    }
}

export const updatePimCartSuccess = (data) => {
    return {
        type: UPDATE_PIM_CART_SUCCESS,
        data
    }
}

export const updatePimCartFailure = (error) => {
    return {
        type: UPDATE_PIM_CART_FAILURE,
        error
    }
}

export const applyCustomerVoucher = (params, callback) => {
    return {
        type: APPLY_VOUCHER,
        params,
        callback
    }
}

export const applyCustomerVoucherSuccess = (data) => {
    return {
        type: APPLY_VOUCHER_SUCCESS,
        data
    }
}

export const applyCustomerVoucherFailure = (error) => {
    return {
        type: APPLY_VOUCHER_FAILURE,
        error
    }
}


export const updateCartAfterVoucher = (voucher, voucherInfo) => {
    return {
        type: UPDATE_CART_AFTER_VOUCHER,
        voucher,
        voucherInfo
    }
}


export const updatedReedemPoints = (rewardsApplied) => {
    return {
        type: UPDATE_REDEEM_POINTS,
        rewardsApplied
    }
}

export const updateBistroComments = (data) => {
    return {
        type: UPDATE_BISTRO_COMMENTS,
        data
    }
}

export const cartEmpty = (params, callback) => {
    return {
        type: CART_DEACTIVATE,
        params,
        callback
    }
}

export const loaderOff = (data) => {
    return {
        type: LOADER_OFF,
        data
    }
}

