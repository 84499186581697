import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../../redux/action";
import CardList from '../managePayment/cardList';
import routes from "../../config/routes";
import { toast } from 'react-toastify';
import Alert from '../../component/alert';
import Constants from '../../Utility/Constants';
import Loader from '../../component/loader';
import PinModal from './pinModal';

class CheckoutPayment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            alertModal: false,
            checkoutParams: {},
            errorMessage: null,
            selectedCartId: null,
            isLoading: false,
            isOpenOtpPopUp: false
        }
    }
    initiateOrders(checkoutParams) {
        this.setState({ isLoading: true })
        this.props.initializeAuthPaymentAction(checkoutParams, (resp) => {
            if (resp?.response_code === 0) {
                this.setState({ params: '', response_message: '' })
                if (resp && resp?.response?.invoiceId) {
                    this.setState({ isLoading: false })
                    this.props.history.replace(
                        routes.ORDER_SUMMARY.replace(":invoiceId", resp?.response?.invoiceId)
                    );
                    this.props.getPimCartList();
                    return;
                }
            } else if (resp?.response_code === 101) {
                this.setState({ isLoading: false })
                checkoutParams = {
                    ...checkoutParams,
                    fulfillmentRestrictionOverridden: true,
                };
                this.setState({
                    alertModal: true,
                    checkoutParams: checkoutParams,
                    response_message: resp?.response_message
                })
            } else if (resp?.response_code === 102) {
                toast.success(`${resp?.response_message}`);
                this.setState({
                    isOpenOtpPopUp: true,
                    checkoutParams: checkoutParams,
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                })
                toast.error(`${resp?.response_message}`);
            }
        })
    }
    createOrders() {
        let { checkoutParams } = this.props.getCheckoutParams
        checkoutParams.paymentTokenId = this.state.selectedCartId;
        this.setState({ isLoading: true })
        this.initiateOrders(checkoutParams)
    }
    initiateLateOrders() {
        const { checkoutParams } = this.state
        this.setState({ isLoading: true })
        this.initiateOrders(checkoutParams)
    }
    handleChange(e) {
        let { value } = e.target
        this.setState({ otp: value })
    }
    cancelLateOrders() {
        this.setState({ alertModal: false })
    }
    cancelOtpModal() {
        this.setState({ isOpenOtpPopUp: false, errorMessage: false })
    }
    handleSubmit() {
        const { otp, checkoutParams } = this.state
        if (!otp) {
            this.setState({ errorMessage: 'Please enter otp' })
        } else {
            const callback = (res) => {
                if (res?.response_code === 0) {
                    this.setState({ isOpenOtpPopUp: false, isLoading: true, errorMessage: false })
                    this.initiateOrders(checkoutParams)
                } else {
                    toast.error(`${res?.response_message}`)
                }
            }

            let params = {
                otp
            }
            this.props.userPaymentOtp(params, callback)
        }
    }
    chooseCheckoutCard(value) {
        this.setState({ selectedCartId: value })
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { getCardDetails } = nextProps || {}
        if (!state.selectedCartId) {
            const defaultSelectId = getCardDetails?.paymentCardsList?.find((o) => o?.defaultPaymentMethod === true)
            return {
                selectedCartId: defaultSelectId?.customerTokenDetailsId
            }

        }
    }

    componentDidMount() {
        const callback = (res) => {
            if (res?.response_code === 2) {
                toast.error(`${res?.response_message}`)
            } 
        }
        this.props.getSavedCardList(callback)

    }

    addNewCard() {
        const callback = (res) => {
            if (res?.response_code === 2) {
                toast.error(`${res?.response_message}`)
            } else {
                const { response } = res || {}
                this.props.history.push({
                    pathname: routes.SAVE_CARD_PAYMENT,
                    state: {
                        htmlTemplate: response,
                        isCart: true
                    }
                })
            }
        }
        this.setState({ selectedCartId: null })
        this.props.initializePaymentAction(callback)
    }

    render() {
        const { entryPin } = this.props.getCardDetails || {}
        const { alertModal, response_message, isLoading, isOpenOtpPopUp, errorMessage } = this.state
        return (
            <div className="wrap py-50 mb-5">
                <div className="row gx-5">
                    <div className="col-12 col-md-8">
                        {
                            entryPin && <div className="row mb-5 align-items-center">
                                <div className="col-12 col-md">
                                    <h1 className="font18 fw-bold pb-3 pb-md-0">Select Payments</h1>
                                </div>
                            </div>
                        }
                        <CardList
                            isCart={true}
                            chooseCheckoutCard={(value) => this.chooseCheckoutCard(value)}
                            selectedCartId={this.state.selectedCartId}
                        />
                        <div className="col ps-md-5">
                            <button
                                className="btn btn-dark text-uppercase px-5 mb-5"
                                type="button"
                                id="addressDetails"
                                onClick={() => this.addNewCard()}
                            >
                                + Add Payment
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <h3 className="font16 fw-bold mb-5">Proceed to Payment </h3>
                        <button
                            id="proceedToPayment"
                            className="btn btn-dark big-btn next-btn w-100 text-uppercase mb-5"
                            name=""
                            type="button createOrders"
                            onClick={() => this.createOrders()}
                            disabled={this.state.selectedCartId ? false : true}
                        >
                            Checkout
                            <img
                                src={require("../../asset/img/right-arrow.svg")}
                                alt=""
                                className="ms-2"
                            />
                        </button>
                    </div>
                </div>
                {
                    alertModal && <Alert
                        id="Alert"
                        description={response_message}
                        proceed={Constants.proceed}
                        cancel={Constants.cancel}
                        onSuccess={() => this.initiateLateOrders()}
                        onFailure={() => this.cancelLateOrders()}
                    />
                }
                {
                    isLoading && <Loader />
                }
                {
                    isOpenOtpPopUp && <PinModal
                        id='PinModal'
                        onSuccess={() => this.handleSubmit()}
                        onFailure={() => this.cancelOtpModal()}
                        handleChange={(e) => this.handleChange(e)}
                        errorMessage={errorMessage}
                    />
                }
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getCardDetails: Actions.getSavedCardDetails(state),
        getCheckoutParams: state?.ADD_TO_CART || {}
    };
};

export default withRouter(connect(mapStateToProps, {
    initializeAuthPaymentAction: Actions.initializeAuthPaymentAction,
    getPimCartList: Actions.getPimCartList,
    initializePaymentAction: Actions.addPaymantInformation,
    getSavedCardList: Actions.getSavedCardList,
    userPaymentOtp: Actions.userPaymentOtp,
})(CheckoutPayment))
