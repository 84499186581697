import React from 'react'
import { withRouter } from 'react-router-dom'
import Constants from '../../Utility/Constants'

class OrderStatus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}

    }
    _renderOrderStatus(paymentStatus) {
        if (paymentStatus === 0) {
            return <span
                className="payment-green">
                <img src={require("../../asset/img/green-tick.svg")} alt="Yes" className="me-1" />
                {Constants.ORDER_SUMMARY.paymentType.completed}</span>
        } else if (paymentStatus === 1 || paymentStatus === 4) {
            return <span
                className="payment-red">{Constants.ORDER_SUMMARY.paymentType.pending}</span>
        } else if (paymentStatus === 2) {
            return <span
                className="payment-blue">{Constants.ORDER_SUMMARY.paymentType.processing}</span>
        } else if (paymentStatus === 3) {
            return <span
                className="payment-red">{Constants.ORDER_SUMMARY.paymentType.failed}</span>
        } else if (paymentStatus === 11) {
            return <span
                className="text-warning">
                <img src={require("../../asset/img/pending.svg")} alt="Yes" className="me-1" />
                {Constants.ORDER_SUMMARY.paymentType.refundInitiated}</span>
        } else if (paymentStatus === 12) {
            return <span
                className="payment-green">
                <img src={require("../../asset/img/green-tick.svg")} alt="Yes" className="me-1" />
                {Constants.ORDER_SUMMARY.paymentType.refundApproved}</span>
        } else if (paymentStatus === 13) {
            return <span
                className="payment-red">
                <img src={require("../../asset/img/alert-triangle.svg")} alt="Yes" className="me-1" />
                {Constants.ORDER_SUMMARY.paymentType.refundDenied}</span>
        }
    }
    render() {
        const { paymentStatus } = this.props
        return (
            <React.Fragment>
                <h3 className="font16 mb-4">{this._renderOrderStatus(paymentStatus)}</h3>
            </React.Fragment>
        )
    }
}

export default withRouter(OrderStatus)