import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Actions from "../../redux/action";
import CardList from './cardList';

class ManagePayment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        const callback = (res) => {
            if (res?.response_code === 2) {
                toast.error(`${res?.response_message}`)
            } 
        }
        this.props.getSavedCardList(callback)
    }
    render() {
        return (
            <div className="col ps-md-5">
                <CardList />
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getCardDetails: Actions.getSavedCardDetails(state),
    };
};

export default withRouter(connect(mapStateToProps, {
    getSavedCardList: Actions.getSavedCardList,
})(ManagePayment))
