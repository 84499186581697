
const NAME = "ADDRESS_TYPE";
export const ADDRESS_TYPE = `${NAME}/ADDRESS_TYPE`;
export const ADDRESS_TYPE_SUCCESS = `${NAME}/ADDRESS_TYPE_SUCCESS`;
export const ADDRESS_TYPE_FAILURE = `${NAME}/ADDRESS_TYPE_FAILURE`;


export const getAddressType = store => store[NAME]
export const addressType = (params) => {
    return {
        type: ADDRESS_TYPE,
        params
    }
}

export const addressTypeSuccess = (data) => {
    return {
        type: ADDRESS_TYPE_SUCCESS,
        data
    }
}

export const addressTypeFailure = (error) => {
    return {
        type: ADDRESS_TYPE_FAILURE,
        error
    }
}

