import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doContionalGet } from '../../service/index'
import { PRODUCT_LIST } from '../../config/api_endpoint';
export function* foodProductList({ params }) {
  try {
    const response = yield doContionalGet(PRODUCT_LIST, params);
    yield put(Actions.foodProductListSuccess(response))
  }
  catch (error) {
    yield put(Actions.foodProductListFailure({ error }))
  }
}


export default function* rootWatcher() {
  yield all([
    takeLatest(Actions.FOOD_PRODUCT_LIST, foodProductList),
  ]);
}
