import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import Constants from "../../Utility/Constants";
import Placeholder from '../../asset/img/no-preview.png'
import EmptyPage from "../../component/emptyPage";
import { getRandomNumber } from "../../Utility/Utils";

class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.ProductGroupInformation();
  }

  navProducts(type, id) {
    if (type === "products") {
      this.props.history.push(`/products?categoryId=${id}`);
    } else if (type === "bistro") {
      this.props.history.push(`/bistro/products?categoryId=${id}`);
    } else {
      this.props.history.push(`/products?categoryId=${id}`);
    }
  }

  _renderRootCategories() {
    const { isBistro } = this.props.match.params;
    if (isBistro) {
      return this._renderBistro();
    } else {
      return this._renderGroceryCategory();
    }
  }
  _renderGroceryCategory() {
    const { productGroupInformation } = this.props.getProductGroup;
    let categoryList = [];
    let revealGrocery = productGroupInformation?.findIndex(
      (itm) => itm?.groupName === Constants.PRODUCT_GROUP.GROCERY
    );
    if (revealGrocery !== -1) {
      if (productGroupInformation && productGroupInformation[revealGrocery].categoryList) {
        categoryList = [...productGroupInformation[revealGrocery].categoryList];
      }
    }

    return (
      <div className="wrap py-35">
        {
          categoryList && categoryList.length > 0 && <>
            <h2 className="section-heading mb-3">PRODUCTS</h2>
            <div className="row g-4 home-menu">
              {categoryList && categoryList?.map((obj, index) => {
                return (
                  <div
                    className="col-12 col-md-6 col-lg-4" id="products"
                    onClick={() => this.navProducts("products", obj.categoryId)}
                    key={index}
                  >
                    <span>
                      <div className="card card-body p-0 border-0 text-link title-case card_height">
                        <img
                          src={obj.picture ? obj.picture + '?$' + getRandomNumber() : Placeholder}
                          alt="1"
                          className="card_images"
                        />
                        <h3>{obj.categoryName?.toLowerCase()} </h3>
                      </div>
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        }
        {
          categoryList && categoryList.length === 0 && <EmptyPage
            content={Constants.NOT_FOUND.category}
          />
        }

      </div>
    );
  }

  _renderBistro() {
    const { productGroupInformation } = this.props.getProductGroup;
    let bistroCategoryList = [];
    let revealBistro = productGroupInformation?.findIndex(
      (itm) => itm?.groupName === Constants.PRODUCT_GROUP.BISTRO
    );

    if (revealBistro !== -1) {
      if (productGroupInformation && productGroupInformation[revealBistro].categoryList) {
        bistroCategoryList = [...productGroupInformation[revealBistro].categoryList];
      }
    }


    return (
      <div className="wrap py-35">
        {
          bistroCategoryList && bistroCategoryList.length > 0 && <>
            <h2 className="section-heading mb-3">BISTRO</h2>
            <div className="row g-4 home-menu">
              {bistroCategoryList &&
                bistroCategoryList?.map((obj, index) => {
                  return (
                    <div
                      className="col-12 col-md-6 col-lg-4" id="bistro"
                      onClick={() => this.navProducts("bistro", obj.categoryId)}
                      key={index}
                    >
                      <span>
                        <div className="card card-body p-0 border-0 text-link">
                          <img className=""
                            src={obj.picture ? obj.picture + '?$' + getRandomNumber() : Placeholder}
                            alt="1"
                          />
                          <h3>{obj.categoryName} </h3>
                        </div>
                      </span>
                    </div>
                  );
                })}
            </div>
          </>
        }
        {bistroCategoryList && bistroCategoryList.length === 0 && <EmptyPage
          content={Constants.NOT_FOUND.category}
        />}
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        {this._renderRootCategories()}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getProductGroup: Actions.getProductGroupInformation(state),
  };
};
export default withRouter(
  connect(mapStateToProps, {
    ProductGroupInformation: Actions.productGroupInformation,
  })(CategoryList)
);
