
const NAME = "GROCERY_LIST";
export const GROCERY_LIST = `${NAME}/GROCERY_LIST`;
export const GROCERY_LIST_SUCCESS = `${NAME}/GROCERY_LIST_SUCCESS`;
export const GROCERY_LIST_FAILURE = `${NAME}/GROCERY_LIST_FAILURE`;


export const getGroceryList = store => store[NAME]
export const groceryList = (params) => {
    return {
        type: GROCERY_LIST,
        params
    }
}

export const groceryListSuccess = (data) => {
    return {
        type: GROCERY_LIST_SUCCESS,
        data
    }
}

export const groceryListFailure = (error) => {
    return {
        type: GROCERY_LIST_FAILURE,
        error
    }
}

