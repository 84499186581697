import { takeLatest, all, put, cancelled } from 'redux-saga/effects';
import Actions from '../action';
import { doContionalGet } from '../../service/index'
import { DAXBOT_CHECK_AVAILABILITY, GET_DAXBOT_DELIVERY_INFO } from '../../config/api_endpoint';
export function* daxbotCheckAvailability({ params, callback }) {
    try {
        const response = yield doContionalGet(DAXBOT_CHECK_AVAILABILITY, params);
        yield put(Actions.daxbotCheckAvailabilitySuccess(response))
        if (callback) {
            callback(response)
        }
    }
    catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.daxbotCheckAvailabilityFailure({ error }))
    } finally {
        if (yield cancelled()) {
            yield put(Actions.daxbotCheckAvailabilityFailure({ error: {} }))
        }
    }
}


export function* daxbotInfoSaga({ params, callback }) {
    try {
        const response = yield doContionalGet(GET_DAXBOT_DELIVERY_INFO + params, {});
        yield put(Actions.daxbotDeliveryInfoActionSuccess(response))
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.daxbotDeliveryInfoActionFailure({ error }))

    }
}


export default function* daxbotWatcher() {
    yield all([
        takeLatest(Actions.DAXBOT_CHECK_AVAILABILITY, daxbotCheckAvailability),
        takeLatest(Actions.DAXBOT_DELIVERY_INFO_ACTION, daxbotInfoSaga)
    ]);
}
