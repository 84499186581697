import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import eventBus, { EVENT_SIGN_IN_OPEN, EVENT_LOGIN_VERIFY_OTP_CLOSE, EVENT_RESEND_EMAIL_OPEN, EVENT_VERIFY_OTP_CLOSE } from "../../Utility/event";
import Actions from "../../redux/action";
import InputField from "../InputField";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Constants from "../../Utility/Constants";
import ModalPopup, { ModalHeader } from "../../component/ModalPopup";
class VerifyOtp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        otp: ''
      },
      error: '',
      errorMessage: null
    };
  }


  openResendEmail() {
    const { isLogin, mobileNumber } = this.props
    eventBus.dispatch(EVENT_RESEND_EMAIL_OPEN, { mobileNumber });
    if (isLogin) {
      eventBus.dispatch(EVENT_VERIFY_OTP_CLOSE, {});
    } else {
      eventBus.dispatch(EVENT_LOGIN_VERIFY_OTP_CLOSE, {});
    }
  }


  resendOtp() {
    this.props.resendOtpAction((resp) => {
      if (resp.response_code === 0) {
        toast.success(resp?.response_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (resp.error) {
        toast.error(Constants.API.something, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${resp && resp.response_message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }


  handleChange(value, name) {
    let { fields } = this.state
    fields[name] = value
    this.setState({ fields });
  }

  verifyOtp() {
    const { signupVerifyOtp, isLogin, emailId } = this.props;
    const { otp } = this.state.fields;
    if (otp === null || otp.length <= 0) {
      this.setState({ error: Constants.VERIFY_OTP.emptyField });
      return;
    }
    // singnup / forgot password flow
    if (!isLogin) {
      signupVerifyOtp({ smsOtp: otp, mobileNumber: emailId }, (resp) => {
        if (resp.response_code !== 0) {
          this.setState({ error: resp.response_message });
        } else {
          toast.success(Constants.VERIFY_OTP.successMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.closeVerifyModal();
          // refresh the page to get user related data's
          // window.location.reload();
        }
      });
    }
    // login flow
    else {
      const { forgotEmail } = this.props
      const callback = (response) => {
        let { response_code, response_message } = response
        if (response_code === 2) {
          this.setState({ error: response_message })
        } else {
          this.closeVerifyModal(response)
        }
      }
      let params = {
        mobileNumber: forgotEmail,
        smsOtp: otp
      }
      this.props.forgetPasswordVerifyOtp(params, callback)
    }
  }

  closeVerifyModal = (isResetPassword) => {
    this.setState({ fields: {}, error: '' })
    this.props.onCloseModal(isResetPassword)
  }

  openSignup = () => {
    eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    eventBus.dispatch(EVENT_VERIFY_OTP_CLOSE, {});
  }

  render() {
    const { isModalOpen, mobileNumber } = this.props;
    const { error, errorMessage } = this.state;
    return (
      <React.Fragment>
        {isModalOpen && <ModalPopup onCloseModal={this.closeVerifyModal}>
          <ModalHeader title="Verify" onCloseModal={this.closeVerifyModal} isSkip={true} />
          {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
          <form>
            <div className="row mb-5">
              <div className="col"> To continue, please type the verification code sent to <strong>{mobileNumber}</strong> </div>
            </div>

            <div className="row mb-5">
              <div className="col-12">
                <InputField
                  name="otp"
                  type="number"
                  autoComplete="off"
                  className="form-control custom-field mb-3"
                  tabIndex="1"
                  maxLength="6"
                  onChange={(value, name) => this.handleChange(value, name)}
                />
              </div>
              <div className="col-12 text-end">
                <div className="text-dark text-link" id="resend"
                  onClick={() => this.resendOtp()}>{Constants.VERIFY_OTP.resend}</div> </div>
            </div>
            <div className="text-center mobile-otp-text">
              <span>{Constants.VERIFY_OTP.validTwoMinutes} 15 minutes only</span>
            </div>
            {error && <p className="errorMsg">{error}</p>}

            <div className="text-center pt-4 mb-5">
              <button type="button" data-bs-toggle="modal" data-bs-target="#forgot-modal" id="verify" className="btn btn-dark big-btn w-100 px-5 text-uppercase" onClick={() => this.verifyOtp()}>VERIFY</button>
            </div>

            <div className="text-start">
              <div className="text-dark text-link" id="login" onClick={() => this.openSignup()}><strong>Back to login</strong></div>
            </div>
          </form>

        </ModalPopup>}
      </React.Fragment>

    );
  }
}

export const mapStateToProps = (state) => {
  return {
  };
};

export default withRouter(connect(mapStateToProps, {
  forgetPasswordVerifyOtp: Actions.forgetPasswordVerifyOtp,
  signupVerifyOtp: Actions.signupVerifyOTP,
  resendOtpAction: Actions.resendOtp,
  clearLoggedInInfo: Actions.loginFailure
})(VerifyOtp))
