import moment from 'moment';
import React from 'react';

export const ChatMessages = ({ message }) => {
    return <li className={message.isCustomer === 1 ? "sender" : "receiver"}>
        <p>{message.message}</p>
        <span className="time">{moment(message?.lastModified).calendar()}</span>
    </li>
}

export default ChatMessages