import Actions from '../action'
const initialState = {
    isSignupLoading: false,
    isEmailUpdateLoading: false,
    isResendOtpLoading: false,
    isVerifyOtpLoading: false,
    token: undefined,
    customer: undefined,
    extras: undefined,
    error: false,
    resendOtpError: null
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.SIGN_UP: {
            return {
                ...state,
                isSignupLoading: true,
            };
        }
        case Actions.SIGN_UP_SUCCESS: {
            const tokenInfo = action.data;
            const customer = tokenInfo?.response?.customer;
            const extras = tokenInfo?.response;
            return {
                ...state,
                token: tokenInfo,
                customer,
                extras,
                isSignupLoading: false,
            };
        }
        case Actions.SIGN_UP_FAILURE: {
            return {
                ...state,
                isSignupLoading: false,
            };
        }
        ////
        case Actions.SIGN_UP_RESEND_OTP: {
            return {
                ...state,
                isResendOtpLoading: true,
                resendOtpError: null
            };
        }
        case Actions.SIGN_UP_RESEND_OTP_SUCCESS: {
            return {
                ...state,
                isResendOtpLoading: false,
                resendOtpError: null
            };
        }
        case Actions.SIGN_UP_RESEND_OTP_FAILURE: {
            return {
                ...state,
                isResendOtpLoading: false,
                resendOtpError: action.data.response_message
            };
        }
        ////
        case Actions.SIGN_UP_VERIFY_OTP: {
            return {
                ...state,
                isVerifyOtpLoading: true,
            };
        }
        case Actions.SIGN_UP_VERIFY_OTP_SUCCESS: {
            return {
                ...state,
                isVerifyOtpLoading: false,
                error: false
            };
        }
        case Actions.SIGN_UP_VERIFY_OTP_FAILURE: {
            return {
                ...state,
                isVerifyOtpLoading: false,
            };
        }
        case Actions.SIGN_UP_UPDATE_EMAIL: {
            return {
                ...state,
                isEmailUpdateLoading: true,
            };
        }
        case Actions.SIGN_UP_UPDATE_EMAIL_SUCCESS: {
            const tokenInfo = action.data;
            const customer = tokenInfo?.response?.customer;
            const extras = tokenInfo?.response;
            if (customer && extras)
                return {
                    ...state,
                    customer,
                    extras,
                    isEmailUpdateLoading: false,
                };
            else
                return {
                    ...state,
                    isEmailUpdateLoading: false,
                };
        }
        case Actions.SIGN_UP_UPDATE_EMAIL_FAILURE: {
            return {
                ...state,
                isEmailUpdateLoading: false,
            };
        }
        default:
            return { ...state };
    }
}