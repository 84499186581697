import * as addAddress  from './addAddress'
import * as deleteAddress  from './deleteAddress'
import * as getAddress from './getAddress'
import * as updateAddress from './updateAddress'

export default {
    ...getAddress,
    ...addAddress,
    ...updateAddress,
    ...deleteAddress
} 