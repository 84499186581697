import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doContionalGet } from '../../service/index'
import { PRODUCT_LIST } from '../../config/api_endpoint';
export function* groceryList({ params }) {
  try {
    const response = yield doContionalGet(PRODUCT_LIST, params);
    yield put(Actions.groceryListSuccess(response))
  }
  catch (error) {
    yield put(Actions.groceryListFailure({ error }))
  }
}


export default function* grocery_watcher() {
  yield all([
    takeLatest(Actions.GROCERY_LIST, groceryList),
  ]);
}
