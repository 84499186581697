import React from 'react'
import Loader from '../loader';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

export const AppFullPageLoader = ({ isLoading }) => {
    return isLoading ?
        <Loader />
        : null

}

export const mapStateToProp = (state) => {
    let { ORDERS_LIST = {}, ADD_TO_CART = {},
        CART_DETAILS = {}, CLIENT_CREDENTIALS = {},
        ADDRESS = {}, FOOD_PRODUCT_LIST = {}, FORGOT_PASSWORD = {},
        SUBSCRIPTION = {},
        GROCERY_LIST = {}, SIGN_UP = {}, USER = {}, PRODUCT_GROUP_INFORMATION = {},
        FEED_BACK = {}, DAXBOT_CHECK_AVAILABILITY = {}, PIM_CART = {}, PIM_PRODUCTS = {},
        REWARDS = {}, MANAGE_CARDS = {}, CUSTOMER_CARE = {} } = state;

    const { addAddress, deleteAddress, getAddress, updateAddress } = ADDRESS
    const { createOrders, orderDetails, orderList, refundOrders } = ORDERS_LIST
    const { subscriptionList, subscribedList } = SUBSCRIPTION
    const { isLoading: addtoCartLoading } = ADD_TO_CART
    const { isLoading: cartLoading } = CART_DETAILS
    const { isLoading: clientLoading } = CLIENT_CREDENTIALS
    const { isLoading: foodProductLoading } = FOOD_PRODUCT_LIST
    const { isLoading: productLoading } = GROCERY_LIST
    const { isLoading: forgetLoading } = FORGOT_PASSWORD
    const { isLoading: signUpLoading } = SIGN_UP
    const { isLoading: userLoading, isUserPinLoading } = USER
    const { isLoading: feedbackLoading } = FEED_BACK

    const { isLoading: createOrderLoading } = createOrders || {}
    const { isLoading: orderDetailsLoading } = orderDetails || {}
    const { isLoading: orderListLoading } = orderList || {}

    const { isLoading: createAddressLoading } = addAddress || {}
    const { isLoading: updateAddressLoading } = deleteAddress || {}
    const { isLoading: deleteAddressLoading } = getAddress || {}
    const { isLoading: getAddressLoading } = updateAddress || {}
    const { isLoading: refundOrdersLoading } = refundOrders || {}
    const { imageUploading } = refundOrders || {}
    const { isLoading: productGroupLoading } = PRODUCT_GROUP_INFORMATION
    const { isLoading: daxBotLoading } = DAXBOT_CHECK_AVAILABILITY
    const { isLoading: pimCartLoading, createLoading, updateLoading, voucherLoading } = PIM_CART
    const { isLoading: pimLoading, isDetailLoading, isBistroDetailLoading, isPimBistroLoading } = PIM_PRODUCTS
    const { isLoading: createPinOtpLoading, savePinLoading, deleteLoading,
        primaryCardLoading, resetPinLoading } = MANAGE_CARDS
    const { isLoading: subscriptionListLoading } = subscriptionList || {}
    const { isLoading: subscribedLoading } = subscribedList || {}
    const { isLoading: customerCareLoading } = CUSTOMER_CARE
    const { isLoading: rewardsLoading } = REWARDS

    return {
        isLoading: userLoading || forgetLoading || productLoading ||
            foodProductLoading || clientLoading || cartLoading ||
            addtoCartLoading || signUpLoading || createAddressLoading || imageUploading ||
            updateAddressLoading || deleteAddressLoading || isPimBistroLoading
            || createLoading || updateLoading || voucherLoading || isDetailLoading || isBistroDetailLoading
            || getAddressLoading || createOrderLoading || subscriptionListLoading || subscribedLoading ||
            orderDetailsLoading || orderListLoading || feedbackLoading || productGroupLoading
            || daxBotLoading || pimCartLoading || pimLoading || refundOrdersLoading ||
            rewardsLoading || createPinOtpLoading || savePinLoading || resetPinLoading
            || deleteLoading || primaryCardLoading || isUserPinLoading || customerCareLoading
    }
}

export default connect(mapStateToProp)(withRouter(AppFullPageLoader));
