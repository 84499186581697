import React from 'react'
import { withRouter } from 'react-router-dom'

class About extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div className="col ps-md-5">
                {/* TODO ABOUT PAGE */}
                <div className="wrap  mb-5">
                    <div className="text-center">
                        <div > <img
                        style={{ width: '100px' ,height:'100px' }}
                            className="img-fluid mb-5"
                            alt="#"
                            src={require("../../asset/img/proglint.svg")} /></div>
                        <h4 className="text-center">Our mission is to make eating healthy affordable and convenient for everyone</h4>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(About)