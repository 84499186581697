import React from "react";
import { withRouter } from "react-router-dom";
import Actions from "../../redux/action";
import { connect } from "react-redux";
import Login from "../login";
import Register from "../register";
import Alert from '../alert';
import StorePopUp from "../ModalPopup/StorePopUp";
import eventBus, {
  EVENT_RESEND_EMAIL_CLOSE,
  EVENT_RESEND_EMAIL_OPEN,
  EVENT_SIGN_IN_CLOSE,
  EVENT_SIGN_IN_OPEN,
  EVENT_SIGN_UP_CLOSE,
  EVENT_SIGN_UP_OPEN,
  EVENT_VERIFY_OTP_CLOSE,
  EVENT_VERIFY_OTP_OPEN,
  EVENT_RESET_PASSWORD_OPEN,
} from "../../Utility/event";
import ResendEmail from "../resendEmail";
import VerifyOtp from "../verifyOtp";
import routes from "../../config/routes";
import Constants from "../../Utility/Constants";
import HeaderSearch from "./HeaderSearch";
import { ProfileDetails } from "../../config/profileDetails";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginModal: false,
      isSignUpModal: false,
      isResendEmailModal: false,
      isVerifyOtpModal: false,
      mobileNumber: "",
      isAddToCart: false,
      hideChangeEmail: false,
      isHambergerOpen: false,
      isAccountMenuOpen: false,
      alertModal: false,
      showStorePopUp: false,
      closeButton: true,
    };
    this.headerRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  handleClickOutside = (event) => {
    if (this.headerRef && !this.headerRef.current.contains(event.target)) {
      this.setState({ isHambergerOpen: false })
    }
  }

  componentDidMount() {
    eventBus.on(EVENT_SIGN_IN_OPEN, ({ isAddToCart }) => {
      this.setState({ isLoginModal: true, isAddToCart });
    });
    eventBus.on(EVENT_SIGN_IN_CLOSE, () => {
      this.setState({ isLoginModal: false, isAddToCart: false });
    });
    eventBus.on(EVENT_SIGN_UP_OPEN, () => {
      this.setState({ isSignUpModal: true });
    });
    eventBus.on(EVENT_SIGN_UP_CLOSE, () => {
      this.setState({ isSignUpModal: false });
    });
    eventBus.on(EVENT_RESEND_EMAIL_OPEN, ({ mobileNumber }) => {
      this.setState({ isResendEmailModal: true, mobileNumber });
    });
    eventBus.on(EVENT_RESEND_EMAIL_CLOSE, ({ mobileNumber }) => {
      this.setState({ isResendEmailModal: false, mobileNumber });
    });
    eventBus.on(EVENT_VERIFY_OTP_OPEN, ({ mobileNumber, hideChangeEmail }) => {
      const stateParams = { isVerifyOtpModal: true };
      if (hideChangeEmail) {
        stateParams.hideChangeEmail = hideChangeEmail;
      }
      if (mobileNumber) {
        stateParams.mobileNumber = mobileNumber;
        this.setState({ isVerifyOtpModal: true, mobileNumber });
      }
      this.setState(stateParams);
    });
    eventBus.on(EVENT_VERIFY_OTP_CLOSE, () => {
      this.setState({ isVerifyOtpModal: false, hideChangeEmail: false });
    });

    // check user info
    let { token } = this.props.getUserDetail || {};
    let { customer } = this.props.getUserDetail || {};
    if (token && customer?.active && customer?.smsOtpVerified) {
      this.getCartDetails();
    } else if (token && !customer?.active && !customer?.smsOtpVerified) {
      eventBus.dispatch(EVENT_VERIFY_OTP_OPEN, {
        mobileNumber: customer?.mobileNumber,
      });
    }
    if (token?.isTemp) {
      this.props.clearUserCreds();
    }
    const { isPasswordReset } = this.props;
    if (
      isPasswordReset &&
      token &&
      customer?.active &&
      customer?.smsOtpVerified
    ) {
      eventBus.dispatch(EVENT_RESET_PASSWORD_OPEN, {
        mobileNumber: customer?.mobileNumber,
      });
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ isHambergerOpen: false })
    }
  }

  getCartDetails() {
    let { token } = this.props.getUserDetail || {};
    if (token) {
      this.props.getPimCartList();
    }
  }

  closeLoginModal() {
    this.getCartDetails();
    eventBus.dispatch(EVENT_SIGN_IN_CLOSE, {});
  }

  openLoginModal() {
    eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
  }
  openSignUpModal() {
    eventBus.dispatch(EVENT_SIGN_UP_OPEN, {});
  }
  closeSignUpModal() {
    eventBus.dispatch(EVENT_SIGN_UP_CLOSE, {});
  }

  closeResendEmail(mobileNumber) {
    eventBus.dispatch(EVENT_RESEND_EMAIL_CLOSE, { mobileNumber });
    eventBus.dispatch(EVENT_VERIFY_OTP_OPEN, {});
  }

  componentWillUnmount() {
    eventBus.remove(EVENT_SIGN_IN_OPEN);
    eventBus.remove(EVENT_SIGN_IN_CLOSE);
    eventBus.remove(EVENT_SIGN_UP_OPEN);
    eventBus.remove(EVENT_SIGN_UP_CLOSE);
    eventBus.remove(EVENT_RESEND_EMAIL_OPEN);
    eventBus.remove(EVENT_RESEND_EMAIL_CLOSE);
    eventBus.remove(EVENT_VERIFY_OTP_OPEN);
    eventBus.remove(EVENT_VERIFY_OTP_CLOSE);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  checkout() {
    let { token } = this.props.getUserDetail || {};
    if (token) {
      this.props.history.push(routes.CHECKOUT);
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, { isAddToCart: true });
    }
  }

  logout(msg) {
    this.setState({ alertModal: !this.state.alertModal })
    if (msg) {
      this.props.logout()
    }
  }

  historyPushOrReplace(path) {
    const { pathname } = this.props.location;
    let pushOrReplace = this.props.history.push;
    if (pathname === routes.PAYMENT_SCREEN || pathname === routes.SUBSCRIPTION_PAYMENT) {
      pushOrReplace = this.props.history.replace;
    }
    pushOrReplace(path);
  }


  historyPushOrReplaceAll(path) {
    const { pathname } = this.props.location;
    let pushOrReplace = this.props.history.push;
    if (pathname === path) {
      pushOrReplace = this.props.history.replace;
    }
    pushOrReplace(path);
  }

  // _renderHeaderLinks() {
  //   let { customer } = this.props.getUserDetail || {};
  //   return (
  //     <React.Fragment>
  //       {customer && customer?.smsOtpVerified ? (
  //         <React.Fragment>
  //           <li></li>
  //           <li>
  //             <span
  //               id="cartIcon"
  //               className="cursor-pointer"
  //               onClick={() => {
  //                 this.historyPushOrReplace(routes.MYACCOUNTS);
  //               }}
  //             >

  //               <i className="fa fa-user-o"></i>
  //               {customer && customer.firstName} {customer && customer.lastName}
  //             </span>
  //           </li>
  //           <li>
  //             <span id="headerLogout" className="cursor-pointer" onClick={() => this.logout()}>
  //               <i className="fa fa-sign-out"></i> {Constants.HEADER.logout}
  //             </span>
  //           </li>
  //         </React.Fragment>
  //       ) : (
  //         <li>
  //           <span
  //             id="loginIcon"
  //             className="cursor-pointer"
  //             onClick={() => this.openLoginModal()}
  //           >
  //             <i className="fa fa-user-o"></i> {Constants.HEADER.login}
  //           </span>
  //           <span
  //             id="signUpIcon"
  //             className="cursor-pointer"
  //             onClick={() => this.openSignUpModal()}
  //           >
  //             {Constants.HEADER.register}
  //           </span>
  //         </li>
  //       )}
  //     </React.Fragment>
  //   );
  // }

  canShowSearch() {
    const { pathname } = this.props.location;
    return pathname.includes("/products") || pathname === "/" || pathname.includes("/searchAll")
  }

  logoOnClick() {
    const { pathname } = this.props.location;
    if (pathname === "/") {
      return;
    }
    this.historyPushOrReplace("/");
  }
  subscription(path) {
    let { token } = this.props.getUserDetail || {};
    const { pathname } = this.props.location;
    let pushOrReplace = this.props.history.push;
    if (token) {
      if (pathname === path) {
        pushOrReplace = this.props.history.replace;
      }
      pushOrReplace(path);
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    }
  }

  navCartScreen(customer) {
    let { token } = this.props.getUserDetail || {};
    if (token && customer?.smsOtpVerified) {
      this.props.history.push(routes.CHECKOUT)
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    }
  }
  handledHamberger = (isHambergerOpen) => {
    this.setState({ isHambergerOpen });
  }
  handledAccountMenu = (customer) => {
    let { token } = this.props.getUserDetail || {};
    if (token && customer?.smsOtpVerified) {
      const { isAccountMenuOpen } = this.state
      this.setState({ isAccountMenuOpen: !isAccountMenuOpen });
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    }
  }
  selectedStoreListPopUp = () => {
    const { getMultipleStoreList } = this.props.getSelectedStoreList
    if (getMultipleStoreList?.length > 1) {
      this.setState({ showStorePopUp: true })
    } else if (getMultipleStoreList?.length <= 1) {
      this.setState({ showStorePopUp: false })
    }

  }
  render() {
    const { pimCart } = this.props.getPimCartDetails || {};
    const { pathname } = this.props.location || {}
    const { selectedStoreList } = this.props?.getSelectedStoreList || {}
    const {
      isLoginModal,
      isSignUpModal,
      isResendEmailModal,
      isVerifyOtpModal,
      mobileNumber,
      isAddToCart,
      hideChangeEmail,
      isHambergerOpen,
      isAccountMenuOpen,
      alertModal
    } = this.state;
    let { customer } = this.props.getUserDetail || {};
    return (
      <div id="header-shadow" className="border-bottom shadow-sm">
        <div className="wrap">
          <header id="header">
            <div className="row align-items-center">
              <div id="handledHamberger" className="col-auto d-block d-lg-none" onClick={() => this.handledHamberger(true)}>

                <img
                  src={require("../../asset/img/hamburger.svg")}
                  alt="menu"
                  className="menu-icon"
                />
              </div>
              <div className="col-auto">
                <span id="homePage" className="text-link" onClick={() => this.props.history.push("/")}>  
                  <img
                    src={require("../../asset/img/proglint.png")}    /*TODO WHITE LABELING HEADER IMAGE*/
                    alt="PROGLINT"
                    className="logo me-5 d-none d-lg-block"
                  />
                </span>
                <span id="homeLogo" className="text-link" onClick={() => this.props.history.push("/")}>
                  <img
                    src={require("../../asset/img/proglint.png")}   /*TODO WHITE LABELING HEADER IMAGE*/
                    alt="PROGLINT"
                    className="mob-logo me-2 d-block d-lg-none"
                  />
                </span>
              </div>
              <div className="col-auto d-none d-lg-block">
                <nav>
                  <ul>
                    <li
                      className={`${pathname === '/category' ? 'menu-active' : ''}` || `${pathname === '/products' ? 'menu-active' : ''}`}>
                      <span
                        id="category"
                        className="me-4 text-link"
                        onClick={() => this.historyPushOrReplaceAll(routes.CATEGORY_LIST)}
                      >
                        Products
                      </span>
                    </li>
                    {/* <li
                      className={`${pathname === '/category/bistro' ? 'menu-active' : ''}` || `${pathname === '/bistro/products' ? 'menu-active' : ''}`}>
                      <span
                        id="bistro"
                        className="me-4 text-link"
                        onClick={() => this.historyPushOrReplaceAll(routes.bistroCategory())}
                      >
                        Bistro
                      </span>
                    </li> */}
                    {/* <li className={`${pathname === '/myaccounts/subscription' ? 'menu-active' : ''}`}>
                      <span
                        id="subscription"
                        className="text-link"
                        onClick={() => this.subscription(routes.SUBSCRIPTION)}
                      >
                        Subscribe
                      </span>
                    </li> */}
                    {<li className={`${pathname === '/category/bistro' ? 'menu-active' : ''}` || `${pathname === '/bistro/products' ? 'menu-active' : ''}`}>
                      <span
                        id="selectedPopUp"
                        className="text-link fw-bold ms-0 other"
                        onClick={() => this.selectedStoreListPopUp()}
                      >
                        {selectedStoreList?.value && <i class="fas fa-map-marker-alt"></i>}
                      </span>
                    </li>}
                    {selectedStoreList?.value &&
                      <li className={`${pathname === '/category/bistro' ? 'menu-active' : ''}` || `${pathname === '/bistro/products' ? 'menu-active' : ''}`}>
                        <div className="d-flex">
                          <div className="w-75 desc-wrap">
                            <span
                              title={`${selectedStoreList?.label}`}
                              id="subscription"
                              className="text-link ms-2"
                              onClick={() => this.selectedStoreListPopUp()}
                            >
                              {selectedStoreList?.label}
                            </span>
                          </div>
                          <div className="text-link w-25"><img className="ms-2 select-chevron" onClick={() => this.selectedStoreListPopUp()} src={require("../../asset/images/chevron-down.svg")} alt="user" /></div>
                        </div>
                      </li>
                    }
                  </ul>
                </nav>
              </div>

              <div className="col d-block d-lg-none text-end">
                {
                  customer && customer?.smsOtpVerified ?
                    <span
                      id="account"
                      className="user me-3 text-link text-link"
                      onClick={() => this.historyPushOrReplace(routes.myAccountDetails(ProfileDetails.Orders))}
                    >
                      <img src={require("../../asset/img/user.svg")} alt="user" />
                    </span> :
                    <span
                      id="openLoginModal"
                      className="user me-3 text-link text-link"
                      onClick={() => this.openLoginModal()}
                    >
                      <img src={require("../../asset/img/user.svg")} alt="user" />
                    </span>}

                <span id="navCartScreen" className="cart text-link" onClick={() => this.navCartScreen(customer)}>
                  <img src={require("../../asset/img/cart.svg")} alt="cart" />
                  {
                    (customer) && (pimCart && pimCart.totalQuantityInCart) ? (
                      <span> {pimCart.totalQuantityInCart}</span>
                    ) : ''
                  }
                </span>
              </div>

              <div className="col-12 col-lg text-end">
                {<HeaderSearch />}
                {
                  customer && customer?.smsOtpVerified ?
                    <span
                      id="historyPushOrReplace"
                      className="user d-none d-lg-inline-block me-4 text-link"
                      data-bs-toggle="modal"
                      data-bs-target="#login-modal"
                      onClick={() => this.historyPushOrReplace(routes.myAccountDetails(ProfileDetails.Orders))}
                    >
                      <img src={require("../../asset/img/user.svg")} alt="user" />
                    </span> :
                    <span
                      id="openLogin"
                      className="user d-none d-lg-inline-block me-4 text-link"
                      data-bs-toggle="modal"
                      data-bs-target="#login-modal"
                      onClick={() => this.openLoginModal()}
                    >
                      <img src={require("../../asset/img/user.svg")} alt="user" />
                    </span>
                }
                <span
                  id="navCart"
                  className="cart d-none d-lg-inline-block me-4 text-link"
                  onClick={() => this.navCartScreen(customer)}
                >
                  <img src={require("../../asset/img/cart.svg")} alt="cart" />
                  {
                    (customer) && (pimCart && pimCart.totalQuantityInCart) ? (
                      <span> {pimCart.totalQuantityInCart}</span>
                    ) : ''
                  }
                </span>
              </div>
            </div>

            <div className={isHambergerOpen ? "offcanvas-menu shadow show" : "offcanvas-menu shadow"} ref={this.headerRef}>
              <div id="mobileHandledHamberger" className="close-offcanvas text-link" onClick={() => this.handledHamberger(false)}>
                close
              </div>

              <nav>
                <span
                  id="home"
                  className={`${pathname === '/' ? 'menu-active' : ''}`}
                  onClick={() => this.historyPushOrReplaceAll(routes.HOME)} >Home</span>
                <span
                  id="Products"
                  className={`${pathname === '/category' ? 'menu-active' : ''} text-link` || `${pathname === '/products' ? 'menu-active' : ''}text-link`}
                  onClick={() => this.historyPushOrReplaceAll(routes.CATEGORY_LIST)}>Products</span>
                <span
                  id="bistroCategory"
                  className={`${pathname === '/category/bistro' ? 'menu-active' : ''} text-link` || `${pathname === '/bistro/products' ? 'menu-active' : ''} text-link`}
                  onClick={() => this.historyPushOrReplaceAll(routes.bistroCategory())}>Bistro</span>
                <span
                  id="AccountMenu"
                  className={`${isAccountMenuOpen ? "submenu-link active" : "submenu-link"} text-link`} onClick={() => this.handledAccountMenu(customer)}>Account</span>
                <ul className={isAccountMenuOpen ? "submenu d-block" : "submenu"}>
                  <li className={`${pathname === '/myaccounts/orders' ? 'menu-active' : ''}`}>
                    <span
                      id="myAccountDetails"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.Orders))}>Orders</span>
                  </li>
                  <li className={`${pathname === '/myaccounts/edit' ? 'menu-active' : ''}`}>
                    <span
                      id="myAccount"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.Edit))}>Edit account</span>
                  </li>
                  <li className={`${pathname === '/myaccounts/password' ? 'menu-active' : ''}`}>
                    <span
                      id="password"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.ChangePassword))}>Change password</span>
                  </li>
                  <li className={`${pathname === '/myaccounts/address' ? 'menu-active' : ''}`}>
                    <span
                      id="ManageAddress"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.ManageAddress))}>Manage addresses</span>
                  </li>
                  <li className={`${pathname === '/myaccounts/subscription' ? 'menu-active' : ''}`}>
                    <span
                      id="ManageSubscription"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.ManageSubscription))}>Manage subscriptions</span>
                  </li>
                  <li className={`${pathname === '/myaccounts/payment' ? 'menu-active' : ''}`}>
                    <span
                      id="ManagePayment"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.ManagePayment))}>Manage payments</span>
                  </li>
                  <li className={`${pathname === '/myaccounts/rewards' ? 'menu-active' : ''}`}>
                    <span
                      id="ManageRewards"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.Rewards))}>Rewards</span>
                  </li>
                  <li className={`${pathname === '/myaccounts/help' ? 'menu-active' : ''}`}>
                    <span
                      id="help"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.Help))}>Help
                    </span>
                  </li>
                  <li className={`${pathname === '/myaccounts/contact' ? 'menu-active' : ''}`}>
                    <span
                      id="contact"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.Contact))}>Contact</span>
                  </li>
                  <li className={`${pathname === '/myaccounts/legalinfo' ? 'menu-active' : ''}`}>
                    <span
                      id="legalinfo"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.LegalInfo))}>Legal information</span>
                  </li>
                  <li className={`${pathname === '/myaccounts/about' ? 'menu-active' : ''}`}>
                    <span
                      id="about"
                      className="text-link"
                      onClick={() => this.historyPushOrReplaceAll(routes.myAccountDetails(ProfileDetails.About))}>About</span>
                  </li>
                </ul>
                {
                  customer && customer?.smsOtpVerified ?
                    <span id="logout" onClick={() => this.logout()}>{Constants.HEADER.logout}</span>
                    :
                    <span id="login" onClick={() => this.openLoginModal()}>{Constants.HEADER.login}</span>
                }
              </nav>
            </div>

            <Login
              id="loginModal"
              isModalOpen={isLoginModal}
              onCloseModal={() => this.closeLoginModal()}
              isAddToCart={isAddToCart}
            />
            {isSignUpModal && (
              <Register
                id="signUpModal"
                isModalOpen={isSignUpModal}
                onCloseModal={() => this.closeSignUpModal()}
              />
            )}
            {isResendEmailModal && (
              <ResendEmail
                id="resendEmailModal"
                isModalOpen={isResendEmailModal}
                mobileNumber={mobileNumber}
                onCloseModal={(number) => this.closeResendEmail(number)}
              />
            )}
            {isVerifyOtpModal && (
              <VerifyOtp
                id="verifyOtp"
                mobileNumber={mobileNumber}
                recendOtp
                isModalOpen={isVerifyOtpModal}
                onCloseModal={() =>
                  eventBus.dispatch(EVENT_VERIFY_OTP_CLOSE, {})
                }
                hideChangeEmail={hideChangeEmail}
              />
            )}
            {alertModal && <Alert
              id="Alert"
              description={Constants.LOG_OUT.desc}
              proceed={Constants.LOG_OUT.proceed}
              cancel={Constants.LOG_OUT.cancel}
              onSuccess={(success) => this.logout(success)}
              onFailure={() => this.logout()}
            />}
            {this.state.showStorePopUp && <StorePopUp
              id="StorePopUp"
              onCloseModal={() => this.setState({ showStorePopUp: false })}
              isModalOpen={this.state.showStorePopUp}
              closeButton={this.state.closeButton}
            />}
          </header>
        </div>
      </div>
    );
  }
}
export const mapStateToProps = (state) => {
  return {
    user: Actions.getUserDetail(state),
    // cartDetails: Actions.cartDetails(state),
    getClientCredentials: Actions.getClientCredentials(state),
    getUserDetail: Actions.getUserDetail(state),
    isPasswordReset: state?.FORGOT_PASSWORD?.verifyOtp?.isPasswordReset,
    getPimCartDetails: Actions.getPimCartDetails(state),
    getSelectedStoreList: Actions.getStoreList(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addToCart: Actions.addToCart,
    clientCredentials: Actions.clientCredentials,
    logout: Actions.logout,
    getPimCartList: Actions.getPimCartList,
    clearClientCreds: Actions.clientCredentialsFailure,
    clearUserCreds: Actions.loginFailure,
    selectedStoreList: Actions.selectedStoreList,
  })(Header)
);

