import React from 'react'
import { withRouter } from 'react-router-dom'

class InvoiceSummary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    _renderDeliveryFee = () => {
        return this.props.cartOffer?.deliveryFee ? <>
            <div className="row mb-3">
                <div className="col">
                    Delivery Fee
                </div>
                <div className="col text-end">
                     ${(
                        this.props.cartOffer?.deliveryFee -
                        this.props.cartOffer?.deliveryDiscount
                    )?.toFixed(2)}
                </div>
            </div>
        </> : null;
    }

    _renderRewardsDiscount = () => {
        return this.props.cartOffer?.rewardsDiscount ? <>
            <div className="row mb-3">
                <div className="col">
                    Reward Discount
                </div>
                <div className="col text-end">
                    <strong>
                        - $
                        {(
                            this.props.cartOffer?.rewardsDiscount
                        )?.toFixed(2)}
                    </strong>
                </div>
            </div>
        </> : null;
    }

    _renderCustomerCouponOffer = () => {
        return this.props.cartOffer?.couponCode ? <>
            <div className="row mb-3">
                <div className="col">
                   Discount ({this.props.cartOffer?.couponCode})
                </div>
                <div className="col text-end">
                    <strong>- ${this.props.cartOffer?.couponDiscount?.toFixed(2)}</strong>
                </div>
            </div>
        </> : null;
    }

    _renderDollarOffer = () => {
        if (!this.props.cartOffer?.dollarOff) {
            return null;
        }
        const dollarOffObj = this.props.cartOffer?.dollarOff;
        const dollarValue = dollarOffObj?.offerType === 'DOLLAR'
            ? 'Flat $' +
            dollarOffObj?.offerValue?.toFixed(2) +
            ' discount applied'
            : dollarOffObj?.offerValue?.toFixed(2) +
            '% discount applied (Max $' +
            dollarOffObj?.maxDiscount?.toFixed(2) +
            ')';
        return dollarOffObj ? <>
            <div className="row mb-3" key={JSON.stringify(dollarOffObj)}>
                <div className="col">
                    {dollarValue}
                </div>
                <div className="col text-end">
                    <strong>- ${dollarOffObj?.discount?.toFixed(2)}</strong>
                </div>
            </div>
        </> : null;
    }
    _renderOffers = () => {
        if (this.props.cartOffer && ((this.props.cartOffer?.dollarOff) || (this.props.cartOffer?.couponCode))) {
            return (
                <>
                    {this._renderDollarOffer()}
                    {this._renderCustomerCouponOffer()}
                </>
            );
        } 
    };

    render() {
        return (
            <React.Fragment>
                {this._renderOffers()}
                {this.props.natureOfOrder === 1 && this._renderDeliveryFee()}
                {this._renderRewardsDiscount()}
            </React.Fragment>
        )
    }
}

export default withRouter(InvoiceSummary)