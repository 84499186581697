import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../../redux/action";
import Placeholder from '../../asset/img/no-preview.png'
import Constants from "../../Utility/Constants";
import { toast } from "react-toastify";
import AddToCartButtonDetails from "../../component/AddToCartButtonDetails";
import CartQuantityAdjuster from "../../component/CartQuantityAdjuster";
import EmptyPage from "../../component/emptyPage";
import { getRandomNumber } from "../../Utility/Utils";
import NutritionInfo from "./nutritionInfo";
import RealLifeComponent from "../../component/realLifeComponent";

class PimProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      isOpenRealLife: false,
      realLifeSku: null
    };
  }
  loadScript = (url, status, sku) => {
    let head = document.getElementsByTagName("head")[0]
    let body = document.getElementsByTagName("body")[0]
    let script
    if (status) {
      script = document.createElement("script")
      script.src = url;
      head.appendChild(script);
    } else {
      var div = body.querySelector('container p-3');
      var scripts = head.querySelector('https://meet.h4helpnow.com/3dButtonScript.js', 'p');
      if (scripts.length > 0) {
        head.removeChild(scripts[0]);
        body.removeChild(div[0]);
      }
    }
  }
  componentWillUnmount() {
    let body = document.getElementsByTagName("body")[0]
    let script = document.getElementById("jsscript");
    if (script) {
      body.removeChild(script)
    }
    this.props.loaderOff()
  }
  componentDidMount() {
    let { sku } = this.props.match.params;

    const callback = (response) => {
      if (response.response_code === 2) {
        this.setState({ error: response.response_message });
      }
    };
    let params = {
      storeId: 1,
      skuList: [sku],
    };
    this.props.pimProductsDetails(params, callback);
    this.loadScript("https://meet.h4helpnow.com/3dButtonScript.js", true, sku)
  }
 

  addToCart(o) {
    const params = {
      productSku: o.sku,
      quantity: 1,
      storeId: 1,
      addOnIds: [],
      bistroProduct: false,
    };
    this.props.createPimCart(params, this.updatePimCartCallback);
  }

  quantityDecrement(obj, cartDetails) {
    let getCart = cartDetails && cartDetails.find((o) => o.sku === obj.sku);
    let { quantity } = getCart || {};
    let { sku } = obj || {};
    if (quantity) {
      const params = {
        productSku: sku,
        quantity: quantity - 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: obj?.bistroProduct,
        cartItemId: getCart?.cartItemId,
      };
      this.props.updatePimCart(params, this.updatePimCartCallback);
    } else {
      const params = {
        productSku: sku,
        quantity: quantity,
        storeId: 1,
        addOnIds: [],
        bistroProduct: obj?.bistroProduct,
      };
      this.props.updatePimCart(params, this.updatePimCartCallback);
    }
  }

  quantityIncreament(obj, cartDetails) {
    let getCart = cartDetails && cartDetails.find((o) => o.sku === obj.sku);
    let { quantity } = getCart || {};
    let { sku, bistroProduct } = obj || {};
    if (quantity) {
      const params = {
        productSku: sku,
        quantity: quantity + 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: bistroProduct,
        cartItemId: getCart?.cartItemId,
      };
      this.props.updatePimCart(params, this.updatePimCartCallback);
    }
  }
  quantitybtn(obj, cartDetails) {
    let getCart = cartDetails && cartDetails.find((o) => o.sku === obj.sku);
    if (getCart) {
      if (getCart?.quantity > 0) {
        return (<CartQuantityAdjuster
          onMinus={() => this.quantityDecrement(obj, cartDetails)}
          onPlus={() => this.quantityIncreament(obj, cartDetails)}
          count={getCart?.quantity}
          className="qty-adj-detail"
        />);
      } else {
        return <AddToCartButtonDetails onClick={() => this.addToCart(obj)} />;
      }
    } else {
      return <AddToCartButtonDetails id='AddToCartButtonDetails' onClick={() => this.addToCart(obj)} />;
    }
  }
  updatePimCartCallback = (response) => {
    const { response_code } = response || {};
    if (response_code === Constants.RESPONSE_CODE.SUCCESS) {
      const { voucher } = this.props.pimCartDetails || {};
      if (voucher) {
        this.applyVoucher();
      }
    } else {
      toast.error(`${response?.response_message}`)
    }
  };

  openRealLife(item) {
    this.setState({ isOpenRealLife: !this.state.isOpenRealLife, realLifeSku: item?.sku })
  }
  render() {
    const { pimProductsDetails, isDetailLoading } = this.props.getPimProductsDetails || {};
    const { products } = pimProductsDetails || {};
    const { error, isOpenRealLife, realLifeSku } = this.state;
    const {
      pimCart: { products: cartProducts },
    } = this.props.getPimCartDetails;
    return (
      <React.Fragment>
        <div className="wrap">
          <div className="py-4">
            <div className="breadcrumb">
              <div className="breadcrumb-item text-link">
                <span id='home' onClick={() => this.props.history.push("/")}>Home</span>
              </div>
              <div className="breadcrumb-item text-link">
                <span id='category' onClick={() => this.props.history.push("/category")}>
                  Category
                </span>
              </div>
              <div
                className="breadcrumb-item active text-link"
                aria-current="page"
              >
                Product Detail
              </div>
            </div>
          </div>
        </div>
        {products &&
          products.map((item) => {
            return (
              <div className="wrap py-50 mb-5">
                {error && <p className="errorMsg">{error}</p>}
                <div className="row">
                  <div className="col-12 col-lg-6 text-center p-relative p-detail">
                    <img
                      src={
                        item && item.imageUrl
                          ? item.imageUrl + '?$' + getRandomNumber()
                          : Placeholder
                      }
                      alt="product"
                      className="img-fluid product_detail_image"
                    />
                  </div>
                  <div className="col-12 col-lg-6  mt-lg-0 mt-3">
                    <p>{item && item.sku}</p>
                    <span className="font13 title-case">
                      {item && item.productName?.toLowerCase()}
                    </span>
                    <div className="row mb-3">
                      <div className="col-auto"> <p className="font18 fw-bold">${item && item.price.toFixed(2)}</p> </div>
                      {
                        item && item.ageRestricted && <div className="col text">
                          <div className="badge age-restrict-text">Age Restricted</div>
                        </div>}
                    </div>
                    <p className="font18 mb-3 fw-bold"></p>
                    <div className="d-flex">
                      {this.quantitybtn(item, cartProducts)}
                      {/* <div className="mb-5 ms-2">
                        <button
                          className="btn btn-outline-dark big-btn1 fw-bold text-uppercase"
                          onClick={() => this.openRealLife(item)}>
                          <img src={require("../../asset/images/real-3d.svg")} alt="real3d" /> View in 3D
                        </button>
                      </div> */}
                    </div>
                    <h3 className="font16 text-uppercase fw-bold mb-2">
                      Description
                    </h3>
                    <p className="mb-5">{item && item.productDescription}</p>
                    {item?.nutritionalInformation && <>{(item?.nutritionalInformation?.displayEcommerceCalories || item?.nutritionalInformation?.displayEcommerceCarbohydrates ||
                      item?.nutritionalInformation?.displayEcommerceFats || item?.nutritionalInformation?.displayEcommerceProtein || item?.nutritionalInformation?.displayEcommerceSugar
                    ) && <NutritionInfo item={item} />}</>}
                  </div>
                </div>
              </div>
            );
          })}
        {(products?.length === 0 && !isDetailLoading) && (
          <EmptyPage
            content={Constants.NOT_FOUND.filter}
          />
        )}

        {isOpenRealLife && <RealLifeComponent isModalOpen={isOpenRealLife} onCloseModal={() => this.openRealLife()} realLifeSku={realLifeSku} />}

      </React.Fragment>
    );
  }
}
export const mapStateToProps = (state) => {
  return {
    getPimProductsDetails: Actions.getPimProducts(state),
    getProductGroup:
      Actions.getProductGroupInformation(state)?.productGroupInformation,
    getPimCartDetails: Actions.getPimCartDetails(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    pimProductsDetails: Actions.pimProductsDetails,
    updatePimCart: Actions.updatePimCart,
    createPimCart: Actions.createPimCart,
    loaderOff: Actions.loaderOff
  })(PimProductDetails)
);
