const NAME = "ADD_TO_CART";
export const ADD_TO_CART = `${NAME}/ADD_TO_CART`;
export const ADD_TO_CART_SUCCESS = `${NAME}/ADD_TO_CART_SUCCESS`;
export const ADD_TO_CART_FAILURE = `${NAME}/ ADD_TO_CART_FAILURE`;


export const SAVE_CHECKOUT_PARAMS = `${NAME}/SAVE_CHECKOUT_PARAMS`;

export const addToCart = (params, callback) => {
    return {
        type: ADD_TO_CART,
        params,
        callback
    }
}
export const addToCartSuccess = (data) => {
    return {
        type: ADD_TO_CART_SUCCESS,
        data
    }
}

export const addToCartFailure = (error) => {
    return {
        type: ADD_TO_CART_FAILURE,
        error
    }
}

export const saveCheckoutParams = (data) => {
    return {
        type: SAVE_CHECKOUT_PARAMS,
        data
    }
}




