import {
    BASE_URL,
    BASE_URL_SOCKET_IO,
    MAP_API_KEY
} from './base'

//end Urls
const CLIENT_CREDENTIALS = 'oauth/token'
const LOGIN = 'api/signin'
const SIGN_OUT = 'api/signout'
const FORGET_PASSWORD = 'forgotPassword/sms/otp'
const FORGOT_PASSWORD_VERIFY_OTP = 'forgotPassword/verify'
const UPDATE_FORGOT_PASSWORD = 'user/forgot/password'
const ACCOUNT_DELETE = 'resource/user/delete'

// Socket 
const SOCKET_IO_PATH = '/ust'
// Daxbot
const DAXBOT_CHECK_AVAILABILITY = 'resource/daxbot/check/availability'
const GET_DAXBOT_DELIVERY_INFO = 'resource/daxbot/delivery/';

// Login Url

const PRODUCT_LIST = 'resource/product/all/active'

//PIM Integration
const PRODUCT_GROUP_INFORMATIONS = "resource/product/pim/product-group/information"
const PIM_PRODUCTS = 'resource/product/pim/product/stores'
//Products Url

const ADD_TO_CART = 'resource/cart/add'
const GET_CART_DETAILS = 'resource/cart'
const UPDATE_CART = 'resource/cart/update'
//Cart Url

const CHECK_EMAIL = 'api/user'
const SIGN_UP = 'api/signup'
const VERIFY_OTP = 'otp/verify'
const RESENT_OTP = 'otp/resend/sms'
const UPDATE_EMAIL = 'api/update/mobile'

//Sign Up Url
const USER_API_ENDPOINT = 'api/user';
const SIGN_UP_ENDPOINT = 'api/signup';
const VERIFY_OTP_ENDPOINT = 'otp/verify';
const RESENT_OTP_ENDPOINT = 'otp/resend/sms';
const UPDATE_EMAIL_ENDPOINT = 'api/update/mobile';
const CHECK_MOBILE_NUMBER_EXISTS = 'api/user/mobile';

//Address
const GET_ADDRESS_DETAILS = 'resource/user/address'
const ADD_ADDRESS = 'resource/user/address/add'
const DELETE_ADDRESS_DETAILS = 'resource/user/address/delete'
const UPDATE_ADDRESS = 'resource/user/address/update'
const ADDRESS_TYPE = 'resource/user/address/type'
const GET_STORE_LIST = 'resource/user/store/available/time?storeId=1'

//Orders

const GET_ORDER_LIST = 'resource/transaction/invoice/v2/list'
const ORDER_DETAILS = 'resource/transaction/invoice/v2/details'
const CREATE_ORDER = 'resource/transaction/invoice/create'
const ORDER_SEND_TO_EMAIL = 'resource/transaction/invoice/email'
const REFUND_ORDER = 'resource/transaction/invoice/refund/v2'

//Refund Details
const REFUND_DETAILS = 'resource/transaction/refund/detail'

const INIT_PAYMENT_METHOD = 'resource/transaction/payment/worldnet/makePayment';
const GET_CARD_DETAILS = 'resource/transaction/payment/worldnet/details';
const INIT_PYM_PAYMENT = 'resource/transaction/pim-invoice/create';
const INIT_PREAUTH_PAYMENT = 'resource/cart/transaction/create';

//MailManager

const FRANCHISE_MAIL = 'mailManager/enquiry'
const FEED_BACK = 'resource/user/feedback';

const CREATE_NEW_CHAT_API = 'resource/user/chat/create';
const GET_CHAT_LIST_API = 'resource/chat/chatDetailsUser';

//Update Account Details

const UPDATE_ACCOUNT_NAME = 'resource/user/details'
const UPDATE_ACCOUNT_PASSWORD = 'user/password'

//Subscription
const GET_SUBSCRIPTION = 'resource/subscription/List'
const GET_SUBSCRIBED = 'resource/subscription/subscribed/List'
const INITIALIZE_SUBSCRIPTION = 'resource/subscription/enroll';
const CANCEL_SUBSCRIPTION = 'resource/subscription/cancel';
//Pim product cart url

const PIM_ADD_TO_CART = 'resource/pim-cart/add-item'
const PIM_CART_DETAILS = 'resource/pim-cart/'
const UPDATE_PIM_CART = 'resource/pim-cart/update-item'

const CALCULATE_CUSTOMER_COUPON = 'resource/promotion/customer-coupon/calculate-discount'
const GET_REWARDS_API = 'resource/rewards/get/rewardList'
const BISTRO_PRODUCT_LIST = 'resource/product/pim/bistro/product/stores';
const PIM_PRODUCT_DETAILS = 'resource/product/pim/store/skuList'
const BISTRO_PRODUCT_DETAILS = 'resource/product/pim/bistro/store/skuList';

const GET_USER_DETAILS = 'resource/user/customer-details'

const UPLOAD_REFUND_IMAGE = 'resource/transaction/invoice/refund/images'

const CREATE_OTP_PIN = 'resource/transaction/send-otp/create-pin'

const SAVE_OTP_PIN = 'resource/transaction/create-pin'
const DELETE_CARD = 'resource/transaction/remove-card'
const DEFAULT_CARD = 'resource/transaction/default'
const RESET_PIN = 'resource/transaction/reset-pin'

const USER_PIN_STATUS = 'resource/user/payment-pin/status'

const VERIFY_PAYMENT_OTP = 'resource/transaction/verify/email-otp'

const STORE_LIST = 'resource/user/getStore/details'

const STORE_DEACTIVATE = '/resource/pim-cart/cart/deactive'

const REAL_LIFE_IMAGE_URL = 'https://dev-meet.proglint.com/products/'

const REAL_LIFE_AR_URL = 'https://dev-meet.proglint.com/arproducts/'

const GUEST_CUSTOMER_TRANSACTION = 'resource/customerCare/get/transaction'

const CUSTOMER_CART_SWAP = 'resource/customerCare/liveagent'
export {
    BASE_URL,
    BASE_URL_SOCKET_IO,
    CLIENT_CREDENTIALS,
    LOGIN,
    SIGN_OUT,
    FORGET_PASSWORD,
    FORGOT_PASSWORD_VERIFY_OTP,
    UPDATE_FORGOT_PASSWORD,
    PRODUCT_LIST,
    ADD_TO_CART,
    CHECK_EMAIL,
    SIGN_UP,
    VERIFY_OTP,
    RESENT_OTP,
    UPDATE_EMAIL,
    USER_API_ENDPOINT,
    SIGN_UP_ENDPOINT,
    VERIFY_OTP_ENDPOINT,
    RESENT_OTP_ENDPOINT,
    UPDATE_EMAIL_ENDPOINT,
    GET_CART_DETAILS,
    GET_ADDRESS_DETAILS,
    ADD_ADDRESS,
    DELETE_ADDRESS_DETAILS,
    UPDATE_ADDRESS,
    GET_ORDER_LIST,
    ORDER_DETAILS,
    CREATE_ORDER,
    UPDATE_CART,
    ORDER_SEND_TO_EMAIL,
    REFUND_ORDER,
    SOCKET_IO_PATH,
    INIT_PAYMENT_METHOD,
    GET_CARD_DETAILS,
    FRANCHISE_MAIL,
    FEED_BACK,
    CREATE_NEW_CHAT_API,
    GET_CHAT_LIST_API,
    UPDATE_ACCOUNT_NAME,
    UPDATE_ACCOUNT_PASSWORD,
    INIT_PREAUTH_PAYMENT,
    PRODUCT_GROUP_INFORMATIONS,
    PIM_PRODUCTS,
    PIM_ADD_TO_CART,
    PIM_CART_DETAILS,
    UPDATE_PIM_CART,
    INIT_PYM_PAYMENT,
    DAXBOT_CHECK_AVAILABILITY,
    ACCOUNT_DELETE,
    MAP_API_KEY,
    GET_DAXBOT_DELIVERY_INFO,
    GET_SUBSCRIPTION,
    GET_SUBSCRIBED,
    INITIALIZE_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION,
    CHECK_MOBILE_NUMBER_EXISTS,
    CALCULATE_CUSTOMER_COUPON,
    GET_REWARDS_API,
    BISTRO_PRODUCT_LIST,
    PIM_PRODUCT_DETAILS,
    BISTRO_PRODUCT_DETAILS,
    GET_USER_DETAILS,
    UPLOAD_REFUND_IMAGE,
    ADDRESS_TYPE,
    CREATE_OTP_PIN,
    SAVE_OTP_PIN,
    DELETE_CARD,
    DEFAULT_CARD,
    RESET_PIN,
    USER_PIN_STATUS,
    GET_STORE_LIST,
    VERIFY_PAYMENT_OTP,
    REFUND_DETAILS,
    STORE_LIST,
    STORE_DEACTIVATE,
    REAL_LIFE_IMAGE_URL,
    REAL_LIFE_AR_URL,
    GUEST_CUSTOMER_TRANSACTION,
    CUSTOMER_CART_SWAP
}

