import React from 'react';
import { connect } from 'react-redux';
import Actions from "../../redux/action";
import { toast } from 'react-toastify'
import Constants from '../../Utility/Constants';


class CartOffers extends React.Component {


    updatePimCartCallBack = (response) => {
        const { response_code } = response || {}
        if (response_code === Constants.RESPONSE_CODE.SUCCESS) {
            const { voucher } = this.props.pimCartDetails || {}
            if (voucher) {
                this.props.applyVoucher()
            }
        } else {
            toast.error(`${response?.response_message}`)
        }
    }


    onClickFreeGive(freeGiveObj, missingObj) {
        const { cartItems } = this.props;
        const indexOf = cartItems.findIndex(
            i => i.sku === missingObj.sku,
        );

        if (indexOf === -1) {
            const params = {
                productSku: missingObj?.sku,
                quantity: 1,
                storeId: 1,
                addOnIds: [],
                bistroProduct: missingObj?.bistroProduct,
            }
            this.props.createPimCart(params, this.updatePimCartCallBack);
        } else {
            const cartItem = cartItems[indexOf];
            const params = {
                productSku: cartItem.sku,
                quantity: cartItem?.quantity + 1,
                storeId: 1,
                addOnIds: [],
                cartItemId: cartItem?.cartItemId,
                bistroProduct: cartItem?.bistroProduct,
            };
            this.props.updatePimCart(params, this.updatePimCartCallBack);
        }
    }

    _renderHeaders = (title, subtitle) => {
        return <tr>
            <td style={{ fontWeight: '600' }}>
                {title}
            </td>
            <td />
            <td style={{ fontWeight: '600', textAlign: 'right' }}>
                {subtitle}
            </td>
        </tr>
    }

    _renderComboOffer = () => {
        return this.props.cartOffer?.comboOffer?.length > 0 ? <>
            {this.props.cartOffer.comboOffer.map(comboOfferObj => {
                const comboProducts = comboOfferObj.skus?.map(sku => this.props.cartItems.find(c => c.sku === sku))
                const itemStr = comboProducts.map(c => c.productName).join(', ')
                return <div className="row mb-3" key={JSON.stringify(comboOfferObj) + itemStr}>
                    <div className="col title-case">
                        <strong>Combo Offers</strong> <br />
                        {comboOfferObj.comboAppliedTimes} X {itemStr?.toLowerCase()}
                    </div>
                    <div className="col text-end">
                        <strong></strong> <br />
                        <strong className="text-success">- ${comboOfferObj?.discount?.toFixed(2)}</strong>
                    </div>
                </div>
            })}
        </> : null;
    };

    _renderFreeGive = () => {
        return this.props.cartOffer?.freeGive?.length > 0 ? <>
            {this.props.cartOffer.freeGive.map(freeGiveObj => {
                const selectedFreeItemStr = freeGiveObj?.freeItemIdsWithQuantities?.reduce((accumulator, currentObj) => {
                    const cartObject = this.props.cartItems?.find(c => c.sku === currentObj?.sku)
                    accumulator = accumulator ? accumulator + ', ' : '';
                    return accumulator + currentObj?.quantity + 'x ' + cartObject?.productName?.toLowerCase();
                }, '');

                const cartFreeItemObject = this.props.cartItems?.find(d => d.sku === freeGiveObj.sku)
                return <React.Fragment key={JSON.stringify(freeGiveObj)}>
                    <div className="row mb-3">
                    <strong>Free Give</strong> <br />
                        {freeGiveObj?.missingCartProducts.length > 0 && <>
                            <div className="col title-case">
                                <strong>Pay:</strong> <br />
                                {cartFreeItemObject.productName?.toLowerCase()}
                            </div>
                            </>
                        }
                    </div>
                    {freeGiveObj?.missingCartProducts.length > 0 ? this._renderAddFreeGive(
                        freeGiveObj,
                        freeGiveObj.missingCartProducts,
                    ) : null}
                    {
                        selectedFreeItemStr && (
                            <div className="row mb-3" key={JSON.stringify(freeGiveObj)}>
                                {
                                    selectedFreeItemStr?.trim().length > 0 && <div className="col title-case">
                                        <strong>Pay:</strong> <br />
                                        {cartFreeItemObject.productName?.toLowerCase()} <br />
                                        <strong>Free:</strong> <br />
                                        {selectedFreeItemStr}
                                    </div>
                                }

                                <div className="col text-end">
                                    <strong></strong> <br />
                                    <strong className="text-success">- ${freeGiveObj?.subTotalDiscount?.toFixed(2)}</strong>
                                </div>
                            </div>
                        )
                    }

                </React.Fragment>
            })}
        </> : null;
    };


    _renderAddFreeGive = (freeGiveObj, missingCartProducts) => {
        return missingCartProducts.map((missingObj, mi) => {
            return <div className="row mb-3" key={JSON.stringify(missingObj) + mi}>
                <div className="col title-case">
                    <strong>Get Free:</strong> <br />
                    {missingObj?.quantity} X {missingObj.productName?.toLowerCase()}
                </div>
                <div className="col text-end">
                    <button id='addBtn' className="btn btn-outline-dark" onClick={() => this.onClickFreeGive(freeGiveObj, missingObj)}>Add</button>
                </div>
            </div>
        });
    };



    _renderMultiBuy = () => {
        return this.props.cartOffer?.multiBuy?.length > 0 ? <>
            {this.props.cartOffer.multiBuy.map(multiBuyObj => {
                const cartObject = this.props.cartItems?.find(d => d.sku === multiBuyObj.sku)
                return <div className="row mb-3" key={JSON.stringify(multiBuyObj)}>
                    <div className="col title-case">
                        <strong>Multi Buy:</strong> <br />
                        {multiBuyObj.quantity}
                        {'x '}
                        {cartObject.productName?.toLowerCase()}
                    </div>
                    <div className="col text-end">
                        <strong></strong> <br />
                        <strong className="text-success">- ${multiBuyObj?.discount?.toFixed(2)}</strong>
                    </div>
                </div>
            })}
        </> : null;
    };

    _renderHappyHours = () => {
        return this.props.cartOffer?.happyHours?.length > 0 ? <>
            {this.props.cartOffer.happyHours.map(happyHoursObj => {
                const cartObject = this.props.cartItems?.find(d => d.sku === happyHoursObj.sku)
                return <div className="row mb-3" key={JSON.stringify(happyHoursObj)}>
                    <div className="col title-case">
                        <strong>Happy Hours:</strong> <br />
                        {cartObject.productName?.toLowerCase()}
                    </div>
                    <div className="col text-end">
                        <strong></strong> <br />
                        <strong className="text-success">- ${happyHoursObj?.discount?.toFixed(2)}</strong>
                    </div>
                </div>
            })}
        </> : null;
    };


    _renderSubscription = () => {
        return this.props.cartOffer?.subscriptions?.length > 0 ? <>
            {this.props.cartOffer.subscriptions.map(subscriptionObj => {
                const subscriptionProducts = subscriptionObj?.products?.map(o => this.props.cartItems.find(c => c.sku === o.sku))
                const itemStr = subscriptionProducts?.map(c => c.productName).join(', ')
                return <div className="row mb-3" key={JSON.stringify(subscriptionObj) + itemStr}>
                    <div className="col title-case">
                        <strong>Subscription</strong> <br />
                        {itemStr?.toLowerCase()}
                    </div>
                    <div className="col text-end">
                        <strong></strong> <br />
                        <strong className="text-success">- ${subscriptionObj?.discount?.toFixed(2)}</strong>
                    </div>
                </div>
            })}
        </> : null;

    }
    _renderOffers = () => {
        if (
            this.props.cartOffer &&
            ((this.props.cartOffer.comboOffer &&
                this.props.cartOffer.comboOffer.length > 0) ||
                (this.props.cartOffer.freeGive &&
                    this.props.cartOffer.freeGive.length > 0) ||
                (this.props.cartOffer.multiBuy &&
                    this.props.cartOffer.multiBuy.length > 0) ||
                (this.props.cartOffer.happyHours &&
                    this.props.cartOffer.happyHours.length > 0) ||
                (this.props.cartOffer.subscriptions &&
                    this.props.cartOffer.subscriptions.length > 0))
        ) {
            return (
                <div className="order-divider pt-3 mb-3 title-case">
                    <h3 className="font16 fw-bold mb-3">OFFERS</h3>
                    {this._renderSubscription()}
                    {this._renderComboOffer()}
                    {this._renderFreeGive()}
                    {this._renderMultiBuy()}
                    {this._renderHappyHours()}
                </div>
            );
        } 
    };




    render() {
        return (
            <>
                {this._renderOffers()}
            </>
        )
    }
}


export const mapStateToProps = (state) => ({
    cartOffer: state?.PIM_CART?.pimCart?.offers || {},
    cartItems: state?.PIM_CART?.pimCart?.products || [],
});

const dispatchToProps = {
    createPimCart: Actions.createPimCart,
    updatePimCart: Actions.updatePimCart,

}
export default connect(mapStateToProps, dispatchToProps)(CartOffers);