import Actions from '../action'
const initialState = {
    isLoading:false,
    error:false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.FEED_BACK: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FEED_BACK_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.FEED_BACK_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
