
import React, { useLayoutEffect, useRef } from "react";

export const PreAuthPaymentTemplate = ({ data }) => {
  const buttonRef = useRef();
  useLayoutEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.click()
    }
  }, []);
  return <form action={
    data.url
  } method="post" id="form1" name="form1">
    <input type="hidden" name="TERMINALID" value={
      data.terminalId
    } />
    <input type="hidden" name="ORDERID" value={data.orderId} />
    <input type="hidden" name="CURRENCY" value={
      data.currency
    } />
    <input type="hidden" name="HASH" value={data.hash} />
    <input type="hidden" name="AMOUNT" value={data.amount} />
    <input type="hidden" name="DATETIME" value={
      data.dateTime
    } />
    <input type="hidden" name="AUTOREADY" value={
      data.autoReady
    } />
    <button ref={buttonRef} type="submit" style={{ display: 'hidden' }}></button>

  </form>
};