import moment from 'moment';
import Actions from '../action'
const initialState = {
    isLoading: false,
    token: undefined,
    customer: undefined,
    error: false,
    isUserPinLoading: false,
    isUserPinError: false,
    loggedInTokenExpires: null
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.LOGIN: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.LOGIN_SUCCESS: {
            const tokenInfo = action.data;
            const customer = tokenInfo?.customer;
            const lastLogin = tokenInfo?.lastLogin
            return {
                ...state,
                isLoading: false,
                token: tokenInfo,
                customer: customer,
                lastLogin: lastLogin,
                loggedInTokenExpires: moment().add(tokenInfo?.expires_in - 10, 'seconds'),
                error: false
            };
        }
        case Actions.LOGIN_FAILURE: {
            return {
                ...state,
                isLoading: false,
                token: undefined,
                customer: undefined,
                error: action.error,
            };
        }
        case Actions.LOGOUT: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.LOGOUT_SUCCESS: {
            return {
                ...initialState
            };
        }
        case Actions.LOGOUT_FAILURE: {
            return {
                ...initialState,
                error: false
            };
        }
        case Actions.CHANGE_CUSTOMER_PAYMENT_STATUS: {
            return {
                ...state,
                customer: {
                    ...state.customer,
                    paymentPinSet: true
                }
            };
        }

        case Actions.USER_PAYMENT_OTP: {
            return {
                ...state,
                isUserPinLoading: true,
            }
        }
        case Actions.USER_PAYMENT_OTP_SUCCESS: {
            return {
                ...state,
                isUserPinLoading: false,
            };
        }
        case Actions.USER_PAYMENT_OTP_FAILURE: {
            return {
                ...state,
                isUserPinLoading: false,
                isUserPinError: action.error
            };
        }

        default:
            return state;
    }
};
