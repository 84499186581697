import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../../redux/action";
import routes from "../../config/routes";
import Constants from "../../Utility/Constants";
import AddToCartButtonDetails from "../../component/AddToCartButtonDetails";
import AddOnModal from "../../component/addon";
import CartQuantityAdjuster from "../../component/CartQuantityAdjuster";
import Placeholder from '../../asset/img/no-preview.png'
import RepeatCustomization from "../../component/repeatCustomization";
import { toast } from "react-toastify";
import AlertModal from "../../component/AlertModal";
import EmptyPage from "../../component/emptyPage";
import { getRandomNumber } from "../../Utility/Utils";
import NutritionInfo from "./nutritionInfo";

class BistroProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      openAddonModal: false,
      productInfo: {},
      isOpenRepeatCustomModal: false,
      errorMessage: null
    };
  }
  componentDidMount() {
    let { sku } = this.props.match.params;
    const callback = (response) => {
      if (response.response_code === 2) {
        this.setState({ error: response.response_message });
      }
    };
    let params = {
      storeId: 1,
      skuList: [sku],
    }
    this.props.bistroProductsDetails(params, callback);
  }
  componentWillUnmount() {
    this.props.loaderOff()
  }
  addToCart(o) {
    const { hasAddOns } = o;
    if (hasAddOns) {
      this.setState({ openAddonModal: true, productInfo: o });
    } else {
      const params = {
        productSku: o.sku,
        quantity: 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: true,
      }
      this.props.createPimCart(params, this.updatePimCartCallback);
    }
  }
  applyVoucher() {
    return;
  }
  updatePimCartCallback = (response) => {
    const { response_code } = response || {};
    if (response_code === Constants.RESPONSE_CODE.SUCCESS) {
      const { voucher } = this.props.pimCartDetails || {};
      if (voucher) {
        this.applyVoucher()
      }
    } else {
      toast.error(`${response?.response_message}`)
    }
  };

  quantityIncreament(obj, cartDetails) {
    if (obj?.hasAddOns) {
      this.setState({ isOpenRepeatCustomModal: true, productInfo: obj });
    } else {
      let getCart = cartDetails && cartDetails.find((o) => o.sku === obj.sku);
      let { quantity } = getCart || {};
      if (quantity) {
        const params = {
          productSku: getCart.sku,
          quantity: getCart.quantity + 1,
          storeId: 1,
          addOnIds: [],
          bistroProduct: true,
          cartItemId: getCart?.cartItemId
        }
        this.props.updatePimCart(params, this.updatePimCartCallback);
      } else {
        const params = {
          productSku: getCart.sku,
          quantity: getCart.quantity,
          storeId: 1,
          addOnIds: [],
          bistroProduct: true
        }
        this.props.updatePimCart(params, this.updatePimCartCallback);
      }
    }
  }

  quantityDecrement(obj, cartDetails) {
    const { hasAddOns } = obj || {};
    const count = cartDetails?.reduce((n, val) => { return n + (val.sku === obj.sku); }, 0);
    if (hasAddOns && count > 1) {
      this.setState({
        errorMessage: {
          productInfo: obj,
          message: Constants.PRODUCTS.cartMessage
        }
      })
      return;
    }
    this.decreseQuantity(obj, cartDetails)
  }
  goToCartScreen() {
    this.props.history.push(routes.CHECKOUT)
  }
  decreseQuantity(obj, cartDetails) {
    let getCart = cartDetails && cartDetails.find((o) => o.sku === obj.sku);
    let { quantity } = getCart || {}
    if (quantity) {
      const params = {
        productSku: getCart.sku,
        quantity: getCart.quantity - 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: true,
        cartItemId: getCart?.cartItemId
      };
      this.props.updatePimCart(params)
    } else {
      const params = {
        productSku: getCart.sku,
        storeId: 1,
        bistroProduct: true,
        cartItemId: getCart?.cartItemId
      };
      this.props.updatePimCart(params)
    }
  }

  quantitybtn(obj, cartDetails) {
    const itemFilter = cartDetails?.filter((f) => f?.sku === obj?.sku);
    const quantity = itemFilter?.reduce((acc, o) => { return o.quantity + acc; }, 0);
    if (quantity > 0) {
      return (<CartQuantityAdjuster
        onMinus={() => this.quantityDecrement(obj, cartDetails)}
        onPlus={() => this.quantityIncreament(obj, cartDetails)}
        count={quantity}
        className="qty-adj-detail"
      />);
    } else {
      return <AddToCartButtonDetails onClick={() => this.addToCart(obj)} />;
    }
  }

  closeCustomModal() {
    this.setState({ isOpenRepeatCustomModal: false })
  }

  repeatCustom() {
    const { productInfo } = this.state
    const { pimCart: { products } } = this.props.getPimCartDetails;
    const revealCart = products && products?.filter((o) => o.sku === productInfo?.sku)
    let findLastItem = revealCart?.sort((a, b) => { return new Date(b.lastModified) - new Date(a.lastModified); });

    if (findLastItem?.length > 0) {
      let cartElement = findLastItem[0];
      const params = {
        productSku: cartElement.sku,
        quantity: cartElement.quantity + 1,
        storeId: 1,
        addOnIds: [],
        bistroProduct: true,
        cartItemId: cartElement?.cartItemId,
      };
      this.closeCustomModal()
      this.props.updatePimCart(params);
    }
  }

  chooseNew() {
    this.closeCustomModal()
    this.setState({ openAddonModal: true, })
  }

  render() {
    const { bistroProductsDetails, isBistroDetailLoading } = this.props.getBistroProductsDetails || {};
    const { response } = bistroProductsDetails || {};
    const { error, openAddonModal, productInfo, isOpenRepeatCustomModal, errorMessage } = this.state;
    const { pimCart: { products } } = this.props.getPimCartDetails;
    return (
      <React.Fragment>
        <div className="wrap">
          <div className="py-4">
            <div className="breadcrumb">
              <div className="breadcrumb-item text-link">
                <span id="home" onClick={() => this.props.history.push("/")}>Home</span>
              </div>
              <div className="breadcrumb-item text-link">
                <span id="bistroCategory"
                  onClick={() => this.props.history.push(routes.bistroCategory())}
                >
                  Category
                </span>
              </div>
              <div
                className="breadcrumb-item active text-link"
                aria-current="page"
              >
                Product Detail
              </div>
            </div>
          </div>
        </div>
        {response &&
          response.map((item) => {
            let { hasAddOns } = item;
            return (
              <div className="wrap py-50 mb-5">
                {error && <p className="errorMsg">{error}</p>}
                <div className="row">
                  <div className="col-12 col-lg-6 text-center p-relative p-detail">
                    <img
                      src={
                        item && item.imageUrl
                          ? item.imageUrl + '?$' + getRandomNumber()
                          : Placeholder
                      }
                      alt="product"
                      className="img-fluid product_detail_image"
                    />
                  </div>
                  <div className="col-12 col-lg-6 mt-lg-0 mt-3">
                    <p>{item && item.sku}</p>
                    <span className="font13 text-uppercase title-case">
                      {item && item.productName?.toLowerCase()}
                    </span>
                    <div className="row mb-3">
                      <div className="col-auto">
                        <p className="font18 fw-bold">
                          ${item && item.price.toFixed(2)}
                        </p>
                        {
                          item && item.ageRestricted && <div className="col text">
                            <div className="badge age-restrict-text">Age Restricted</div>
                          </div>
                        }

                      </div>
                      {hasAddOns && (
                        <div className="col">
                          <span
                            data-bs-toggle="modal"
                            data-bs-target="#customize-modal"
                            className="badge product-tag"
                          >
                            {Constants.PRODUCTS.customize}
                          </span>
                        </div>
                      )}
                    </div>
                    {this.quantitybtn(item, products)}
                    <h3 className="font16 text-uppercase fw-bold mb-2">
                      Description
                    </h3>
                    <p className="mb-5">{item && item.productDescription}</p>
                    {/* <div className="col-12 col-lg-4"> */}
                    {item?.nutritionalInformation && <>{(item?.nutritionalInformation?.displayEcommerceCalories || item?.nutritionalInformation?.displayEcommerceCarbohydrates ||
                      item?.nutritionalInformation?.displayEcommerceFats || item?.nutritionalInformation?.displayEcommerceProtein || item?.nutritionalInformation?.displayEcommerceSugar
                    ) && <NutritionInfo item={item}/>}</>}
                  </div>
                </div>
              </div>
            );
          })}
        {
          isOpenRepeatCustomModal && (
            <RepeatCustomization
              id="RepeatCustomization"
              isModalOpen={isOpenRepeatCustomModal}
              closeCustomModal={() => this.closeCustomModal()}
              repeatCustom={() => this.repeatCustom()}
              chooseNew={() => this.chooseNew()}
            />
          )
        }
        {openAddonModal && (
          <AddOnModal
            id="AddOnModal"
            isAddOnModalOpen={openAddonModal}
            closeAddOnModal={() => this.setState({ openAddonModal: false })}
            productInfo={productInfo}
          />
        )}
        {errorMessage && (
          <AlertModal
            id="AlertModal"
            message={errorMessage.message}
            title={errorMessage?.productInfo?.productName}
            onClose={() => this.setState({ errorMessage: null })}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
              }}
            >
              <button
                id="goToCartScreen"
                type="button"
                className="btn btn-dark big-btn px-5 mb-4 me-2 text-uppercase"
                onClick={() => this.goToCartScreen()}>Go to Cart</button>
            </div>
          </AlertModal>
        )}
        {(response?.length === 0 && !isBistroDetailLoading) && (
          <EmptyPage
            content={Constants.NOT_FOUND.filter}
          />
        )}
      </React.Fragment>
    );
  }
}
export const mapStateToProps = (state) => {
  return {
    getBistroProductsDetails: Actions.getPimProducts(state),
    getProductGroup: Actions.getProductGroupInformation(state)?.productGroupInformation,
    getPimProducts: Actions.getPimProducts(state),
    getPimCartDetails: Actions.getPimCartDetails(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    bistroProductsDetails: Actions.bistroProductsDetails,
    updatePimCart: Actions.updatePimCart,
    createPimCart: Actions.createPimCart,
    loaderOff: Actions.loaderOff
  })(BistroProductDetails)
);
