import React from "react";
import { connect } from "react-redux";
import ChatMessages from "./ChatMessages";
import Actions from "../../redux/action";
import { withRouter } from "react-router-dom";
import {
  getChatObjectSelector,
  isChatListLoadingSelector,
} from "../../redux/reducer/chatsReducer";
import cloneDeep from "lodash.clonedeep";
import { getQueryParams } from "../../Utility/validation";
import loader from '../../asset/images/loader.gif';

export const NoContent = () => {
  return <div className="chat-start text-center font16">
    <img src={require('../../asset/img/chat-start.svg')} alt="chat here" className="mb-4" /> <b> <br />
      Please let us know how we can help you</b>
  </div>
}

class ChatScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      userMessageState: "",
      loadingMessage: "loading...",
      isMessageSending: false,
      isFirstLoad: true
    };
  }

  componentDidMount() {
    const { invoiceId } = this.props.match.params;
    this.props.getChatMessages({ invoiceId }, (resp) => {
      if (resp.response_code === 2) {
        this.setState({
          loadingMessage:
            "Please send a message, our store manager will respond back as soon as possibe.",
        });
      } else {
        this.setState({ loadingMessage: null });
      }
    });

    const queryParams = getQueryParams(this.props.location.search);
    let isOpenChat = queryParams.get('openChat');
    isOpenChat = isOpenChat === 'true';
    const { manageChat } = this.props
    if (isOpenChat) {
      manageChat(true);
    }
  }

  componentDidUpdate() {
    const { chatMessages: messageList } = this.props?.chatObject;
    const { isFirstLoad } = this.state;
    if (messageList) {
      this.messagesEnd.scrollIntoView({ behavior: isFirstLoad ? "auto" : 'smooth' });
      setTimeout(() => {
        if (isFirstLoad)
          this.setState({ isFirstLoad: false });
      }, 200);
    }
  }

  sendMessage() {
    const { userMessageState } = this.state;
    if (userMessageState.trim().length === 0) return;
    this.setState({ userMessageState: '', isMessageSending: true }, () => {
      const { invoiceId } = this.props.match.params;
      const params = {
        invoiceId,
        message: userMessageState,
      };
      this.props.createNewChat(params, (response) => {
        this.setState({ loadingMessage: null });
        if (response.response_code !== 0) {
          this.setState({
            userMessageState,
          });
        }
        this.setState({ isMessageSending: false })
      });
    })

  }

  _renderChatItems() {
    const { loadingMessage } = this.state;
    const { chatMessages: messageList } = this.props?.chatObject;
    const chatMessages = messageList || [];
    if (this.props.isChatsLoading || loadingMessage) {
      return <NoContent />
    } else {
      return <ul className="clearfix">
        {chatMessages.map((message) => (
          <ChatMessages message={message} key={message.id} />
        ))}
      </ul>
    }
  }

  render() {
    const { userMessageState, isMessageSending } = this.state;
    const { showHideChat, manageChat } = this.props
    return (
      <div
        id="chat-window"
        className={`shadow p-4 ${!showHideChat ? "compress" : ""}`}
      >
        <div className="row align-items-center cursor-pointer" id="manage" onClick={() => manageChat()}>
          <div className="col-auto fw-bold pe-0"> Support Chat</div>

          <div
            className="col text-end" id="manageChat"
            onClick={() => manageChat()}
          >
            <img
              src={require("../../asset/img/down-chevron.svg")}
              alt="collapse"
              id="chat-collapse"
            />
          </div>
        </div>
        <div className="chat-history">
          {this._renderChatItems()}

          <div id="messagesEnd" style={{ float: "left", clear: "both" }}
            ref={(el) => { this.messagesEnd = el; }}>
          </div>
        </div>
        <div className="input-group mb-3 mb-lg-0">
          <input
            type="text"
            ref={input => input && input?.focus()}
            id="message"
            className="form-control py-3"
            placeholder="Type your message"
            disabled={isMessageSending}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                this.sendMessage();
              }
            }}
            value={userMessageState}
            onChange={(e) => {
              this.setState({ userMessageState: e.target.value });
            }}
          />
          {isMessageSending ? <div className="btn bg-white" style={{ paddingTop: '11px' }}>
            <img src={loader} alt="loading" />
          </div> : <button
            className="btn bg-white"
            type="button"
            id="sendMessage"
            onClick={() => this.sendMessage()}
          >
            <img src={require("../../asset/img/send.svg")} alt="Go" />
          </button>}
          {/* <button className="btn btn-light ps-1" type="button">
            <img src={require("../../asset/img/attach.svg")} alt="attach" />
          </button> */}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  const messages = getChatObjectSelector(state);
  return {
    chatObject: cloneDeep(messages),
    isChatsLoading: isChatListLoadingSelector(state),
  };
};

const dispatchToProps = {
  getChatMessages: Actions.getChatMessagesList,
  createNewChat: Actions.createNewChat,
};

ChatScreen.defaultProps = {
  onClose: () => null,
};

export default withRouter(
  connect(mapStateToProps, dispatchToProps)(ChatScreen)
);