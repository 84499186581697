import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import Orders from '../../component/orders';
import EditAccount from '../../component/editAccount';
import ChangePassword from '../../component/changePassword';
import Subscription from '../../container/subscription/subscription';
import { ProfileDetails } from '../../config/profileDetails';
import routes from '../../config/routes';
import ManageAddress from '../../component/manageAddress';
import Help from '../../component/help';
import Rewards from '../../component/rewards';
import Contact from '../../component/contact';
import LegalInformation from '../../component/legalInformation';
import About from '../../container/about';
import OrderSummary from '../accountOrderSummary';
import DaxbotTracker from '../accountOrderSummary/daxbot/TrackDaxBot'
import Alert from '../../component/alert';
import Constants from '../../Utility/Constants';
import ManagePayment from '../managePayment'
class MyAccount extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            alertModal: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        let data = props.match.params.id ? props.match.params.id : ProfileDetails.Orders
        if (state.currentFocus === data) {
            return {}
        }
        return {
            currentFocus: props.match.params.id ? props.match.params.id : ProfileDetails.Orders
        };
    }

    currentFocus = (value) => {
        if (value !== ProfileDetails.ManagePayment) {
            this.props.entryPin(null)
            this.props.forgetPin(false)
        }
        this.onNextStep(value);

    }

    breadcrumFirstText = () => {
        const { currentFocus } = this.state
        switch (currentFocus) {
            case ProfileDetails.Edit:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Edit account</li>
                );
            case ProfileDetails.ChangePassword:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Change password</li>
                );
            case ProfileDetails.ManageAddress:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Manage addresses</li>
                );
            case ProfileDetails.ManageSubscription:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Manage subscriptions</li>
                );
            case ProfileDetails.ManagePayment:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Manage payments</li>
                );
            case ProfileDetails.Orders:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Orders</li>
                );
            case ProfileDetails.Setting:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Settings</li>
                );
            case ProfileDetails.Help:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Help</li>
                );
            case ProfileDetails.Contact:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Contact</li>
                );
            case ProfileDetails.LegalInfo:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Legal info</li>
                );
            case ProfileDetails.About:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">About</li>
                );
            case ProfileDetails.Rewards:
                return (
                    <li className="breadcrumb-item active text-link" aria-current="page">Rewards</li>
                );
            case ProfileDetails.RewardsHistory:
                return (
                    <>
                        <li className="breadcrumb-item text-link"><span id='accountRewards' onClick={() => this.props.history.push(routes.myAccountDetails(ProfileDetails.Rewards))}>Rewards</span></li>
                        <li className="breadcrumb-item active text-link" aria-current="page">History</li>
                    </>
                );
            case ProfileDetails.OrdersSummary:
                return (
                    <>
                        <li className="breadcrumb-item text-link"><span id='accountOrders' onClick={() => this.props.history.push(routes.myAccountDetails(ProfileDetails.Orders))}>Orders</span></li>
                        <li className="breadcrumb-item active text-link" aria-current="page">#{this.props.match.params.invoiceId}</li>
                    </>
                );
            default:
                return ""
        }
    }

    onNextStep = (currentFocus) => {
        switch (currentFocus) {
            case ProfileDetails.Edit:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.ChangePassword:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.ManageAddress:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.ManageSubscription:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.ManagePayment:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.Orders:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.Setting:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.Help:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.Contact:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.LegalInfo:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.About:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.Rewards:
                this.props.history.push(routes.myAccountDetails(currentFocus));
                break;
            case ProfileDetails.LogOut:
                this.logOut()
                break;
            default:
                this.props.history.push(routes.myAccountDetails(currentFocus));
        }
    }

    logOut = () => {
        this.setState({ alertModal: !this.state.alertModal })
    }

    PageNavigation = () => {
        const { currentFocus } = this.state
        switch (currentFocus) {
            case ProfileDetails.Edit:
                return (
                    <EditAccount />
                );
            case ProfileDetails.ChangePassword:
                return (
                    <ChangePassword />
                );
            case ProfileDetails.ManageAddress:
                return (
                    <ManageAddress />
                );
            case ProfileDetails.ManageSubscription:
                return (
                    <Subscription />
                );
            case ProfileDetails.ManagePayment:
                return (
                    <ManagePayment />
                );
            case ProfileDetails.Orders:
                return (
                    <Orders />
                );
            case ProfileDetails.Help:
                return (
                    <Help />
                );
            case ProfileDetails.Contact:
                return (
                    <Contact />
                );
            case ProfileDetails.LegalInfo:
                return (
                    <LegalInformation />
                );
            case ProfileDetails.About:
                return (
                    <About />
                );
            case ProfileDetails.Rewards:
                return (
                    <Rewards />
                );
            case ProfileDetails.OrdersSummary:
                return (
                    <OrderSummary />
                );
            case ProfileDetails.TrackMyOrder:
                return (
                    <DaxbotTracker />
                );
            default:
                return <Orders />
        }
    }


    render() {
        const { currentFocus, alertModal } = this.state;
        return (
            <div>
                <div className="wrap">
                    <div className="py-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item text-link"><span id='home' onClick={() => this.props.history.push("/")}>Home</span></li>
                                {this.breadcrumFirstText()}
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="wrap py-50 mb-5">
                    <div className="row">
                        <div id="account-nav" className="col-3 d-none d-lg-block">
                            <ul>
                                <li className="text-link" id="Orders" onClick={() => this.currentFocus(ProfileDetails.Orders)}> <span className={currentFocus === ProfileDetails.Orders || currentFocus === ProfileDetails.OrdersSummary ? 'active' : ""}> <img src={require("../../asset/img/account/orders.svg")} alt="subs" className="me-3" />Orders</span></li>
                                <li className="text-link" id="Edit" onClick={() => this.currentFocus(ProfileDetails.Edit)}> <span className={currentFocus === ProfileDetails.Edit ? 'active' : ""}> <img src={require("../../asset/img/account/edit.svg")} alt="edit" className="me-3" /> Edit account</span></li>
                                <li className="text-link" id="ChangePassword" onClick={() => this.currentFocus(ProfileDetails.ChangePassword)}> <span className={currentFocus === ProfileDetails.ChangePassword ? 'active' : ""}> <img src={require("../../asset/img/account/change.svg")} alt="change" className="me-3" /> Change password</span></li>
                                <li className="text-link" id="ManageAddress" onClick={() => this.currentFocus(ProfileDetails.ManageAddress)}> <span className={currentFocus === ProfileDetails.ManageAddress ? 'active' : ""}> <img src={require("../../asset/img/account/addr.svg")} alt="addr" className="me-3" /> Manage addresses</span></li>
                                {/* <li className="text-link" id="ManageSubscription" onClick={() => this.currentFocus(ProfileDetails.ManageSubscription)}> <span className={currentFocus === ProfileDetails.ManageSubscription ? 'active' : ""}> <img src={require("../../asset/img/account/subs.svg")} alt="subs" className="me-3" /> Manage subscriptions</span></li> */}
                                <li className="text-link" id="ManagePayment"
                                    onClick={() =>
                                        this.currentFocus(ProfileDetails.ManagePayment)}>
                                    <span
                                        className={currentFocus === ProfileDetails.ManagePayment ? 'active' : ""}>
                                        <img src={require("../../asset/img/credit-card.svg")}
                                            alt="subs"
                                            className="me-3"
                                            style={{ width: '24px', height: '19px' }}
                                        /> Manage payments
                                    </span>
                                </li>
                                <li className="text-link" id="Rewards" onClick={() => this.currentFocus(ProfileDetails.Rewards)}> <span className={currentFocus === ProfileDetails.Rewards || currentFocus === ProfileDetails.RewardsHistory ? 'active' : ""}> <img src={require("../../asset/img/account/rewards.svg")} alt="rewards" className="me-3" /> Rewards</span></li>
                                <li className="text-link" id="Help" onClick={() => this.currentFocus(ProfileDetails.Help)}> <span className={currentFocus === ProfileDetails.Help ? 'active' : ""}> <img src={require("../../asset/img/account/help.svg")} alt="help" className="me-3" />  Help</span></li>
                                <li className="text-link" id="Contact" onClick={() => this.currentFocus(ProfileDetails.Contact)}> <span className={currentFocus === ProfileDetails.Contact ? 'active' : ""}> <img src={require("../../asset/img/account/contact.svg")} alt="contact" className="me-3" /> Contact</span></li>
                                <li className="text-link" id="LegalInfo" onClick={() => this.currentFocus(ProfileDetails.LegalInfo)}> <span className={currentFocus === ProfileDetails.LegalInfo ? 'active' : ""}> <img src={require("../../asset/img/account/legal.svg")} alt="legal" className="me-3" /> Legal information</span></li>
                                <li className="text-link" id="About" onClick={() => this.currentFocus(ProfileDetails.About)}> <span className={currentFocus === ProfileDetails.About ? 'active' : ""}> <img src={require("../../asset/img/account/about.svg")} alt="about" className="me-3" /> About</span></li>
                                <li className="text-link" id="LogOut" onClick={() => this.currentFocus(ProfileDetails.LogOut)}> <span className={currentFocus === ProfileDetails.LogOut ? 'active' : ""}> <img src={require("../../asset/img/account/logout.svg")} alt="about" className="me-3" /> Logout</span></li>
                            </ul>
                        </div>
                        {this.PageNavigation()}
                    </div >
                </div>
                {alertModal && <Alert
                    id="Alert"
                    description={Constants.LOG_OUT.desc}
                    proceed={Constants.LOG_OUT.proceed}
                    cancel={Constants.LOG_OUT.cancel}
                    onSuccess={() => this.props.logout()}
                    onFailure={() => this.logOut()}
                />}
            </div >
        )
    }
}

export const mapStateToProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, {
    logout: Actions.logout,
    entryPin: Actions.entryPin,
    forgetPin: Actions.forgetPin
})(MyAccount))