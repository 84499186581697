import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthGet, doAuthPost, doAuthPut } from '../../service/index'
import {
    GET_CARD_DETAILS, CREATE_OTP_PIN, SAVE_OTP_PIN,
    DELETE_CARD, DEFAULT_CARD, RESET_PIN, VERIFY_PAYMENT_OTP
} from '../../config/api_endpoint';

export function* getSavedCardsDetails({ callback }) {
    try {
        const params = { isMultiCard: true }
        const response = yield doAuthGet(GET_CARD_DETAILS, params);
        callback && callback(response)
        yield put(Actions.getSavedCardListSuccess(response))
    }
    catch (error) {
        callback && callback({ error })
        yield put(Actions.getSavedCardListFailure({ error }))
    }
}

export function* sendOtpCreatePin({ callback }) {
    try {
        const response = yield doAuthPut(CREATE_OTP_PIN);
        callback && callback(response)
        yield put(Actions.sendOtpCreatePinSuccess(response))
    }
    catch (error) {
        callback && callback(error)
        yield put(Actions.sendOtpCreatePinFailure({ error }))
    }
}

export function* saveOtpPin({ params, callback }) {
    try {
        const response = yield doAuthPut(SAVE_OTP_PIN, params);
        callback && callback(response)
        if (response?.response_code === 0) {
            yield put(Actions.changeCustomerPaymentStatus(response))
            yield put(Actions.saveOtpPinSuccess(response))
        } else {
            yield put(Actions.saveOtpPinFailure())
        }

    }
    catch (error) {
        callback && callback({ error })
        yield put(Actions.saveOtpPinFailure({ error }))
    }
}

export function* deleteCard({ params, callback }) {
    try {
        const response = yield doAuthPut(DELETE_CARD, params);
        callback && callback(response)
        yield put(Actions.deleteCardSuccess(params))
    }
    catch (error) {
        callback && callback({ error })
        yield put(Actions.deleteCardFailure({ error }))
    }
}

export function* makeDefaultCard({ params, callback }) {
    try {
        const response = yield doAuthPost(DEFAULT_CARD, params);
        callback && callback(response)
        yield put(Actions.makePrimaryPaymentCardSuccess(response?.response))
    }
    catch (error) {
        callback && callback({ error })
        yield put(Actions.makePrimaryPaymentCardFailure({ error }))
    }
}


export function* resetPinCards({ params, callback }) {
    try {
        const response = yield doAuthPut(RESET_PIN, params);
        callback && callback(response)
        yield put(Actions.resetPinSuccess(response))
    }
    catch (error) {
        callback && callback({ error })
        yield put(Actions.resetPinFailure({ error }))
    }
}


export function* userPaymentOtp({ params, callback }) {
    try {
        const response = yield doAuthPost(VERIFY_PAYMENT_OTP, params);
        callback && callback(response)
        yield put(Actions.userPaymentOtpSuccess(response?.response))
    }
    catch (error) {
        callback && callback(error)
        yield put(Actions.userPaymentOtpFailure({ error }))
    }
}


export default function* CardSagaWatcher() {
    yield all([
        takeLatest(Actions.GET_SAVED_CARD_DETAILS, getSavedCardsDetails),
        takeLatest(Actions.SEND_OTP_CREAE_PIN, sendOtpCreatePin),
        takeLatest(Actions.SAVE_OTP_PIN, saveOtpPin),
        takeLatest(Actions.DELETE_CARD, deleteCard),
        takeLatest(Actions.MAKE_PRIMARY_CARD, makeDefaultCard),
        takeLatest(Actions.RESET_PIN, resetPinCards),
        takeLatest(Actions.USER_PAYMENT_OTP, userPaymentOtp)
    ]);
}
