import React, { Component } from 'react';
import { DEV_URL } from '../../config/base';

class Help extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    onChangeIsNNB() {
        window.open(`${DEV_URL}policy/what_is_proglint.html`, '_blank');
    }
    onChangeIsShop() {
        window.open(`${DEV_URL}policy/how_to.html`, '_blank');
    }
    onChangeIsPaymant() {
        window.open(`${DEV_URL}policy/payment.html`, '_blank');
    }
    onChangeIsRefund() {
        window.open(`${DEV_URL}policy/refund.html`, '_blank');
    }
    render() {
        const { isNNB, isShop, isPayment } = this.state
        return (
            <div className="col ps-md-5">

                <div className="row mb-5 align-items-center text-link">
                    <div className="col">
                        <h1 className="font18 fw-bold">Help</h1>
                    </div>
                </div>
                {/* TODO HELP PAGE */}
                <div className="accordion accordion-flush" id="faq">

                    <div className="accordion-item">
                        <h2 className="accordion-header text-link" id="whatis-heading">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#whatis-acc"
                                aria-expanded="false"
                                aria-controls="whatis-acc"
                                id="WhatisProglint"
                                onClick={() => this.onChangeIsNNB()}>
                                What is Proglint Market?
                            </button>
                        </h2>
                        <div
                            id="whatis-acc"
                            className={!isNNB ? "accordion-collapse collapse" : ""}
                            aria-labelledby="whatis-heading"
                            data-bs-parent="#faq">
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header text-link" id="shop-heading">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#shop-acc"
                                aria-expanded="false"
                                aria-controls="shop-acc"
                                id="HowtoShop"
                                onClick={() => this.onChangeIsShop()}>
                                How to Shop?
                            </button>
                        </h2>
                        <div id="shop-acc"
                            className={!isShop ? "accordion-collapse collapse" : ""}
                            aria-labelledby="shop-heading"
                            data-bs-parent="#faq">
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header text-link" id="returns-heading">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#returns-acc"
                                aria-expanded="false"
                                aria-controls="returns-acc"
                                id="PaymentBillings"
                                onClick={() => this.onChangeIsPaymant()}>
                                Payment & Billings
                            </button>
                        </h2>
                        <div id="returns-acc"
                            className={!isPayment ? "accordion-collapse collapse" : ""}
                            aria-labelledby="returns-heading"
                            data-bs-parent="#faq">
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header text-link" id="returns-heading">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#returns-acc"
                                aria-expanded="false"
                                aria-controls="returns-acc"
                                id="Refund"
                                onClick={() => this.onChangeIsRefund()}>
                                Returns & Refunds
                            </button>
                        </h2>
                        <div id="returns-acc"
                            className={!isPayment ? "accordion-collapse collapse" : ""}
                            aria-labelledby="returns-heading"
                            data-bs-parent="#faq">
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Help;