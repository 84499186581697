import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthGet, doAuthPost, doAuthPut } from '../../service/index'
import { GET_SUBSCRIPTION, GET_SUBSCRIBED, INITIALIZE_SUBSCRIPTION, CANCEL_SUBSCRIPTION } from '../../config/api_endpoint';

export function* subscriptionList({ params }) {
  try {
    const response = yield doAuthGet(GET_SUBSCRIPTION, params);
    yield put(Actions.getSubscriptionListSuccess(response))
  }
  catch (error) {
    yield put(Actions.getSubscriptionListFailure({ error }))
  }
}
export function* subscribedList({ params }) {
  try {
    const response = yield doAuthGet(GET_SUBSCRIBED, params);
    yield put(Actions.getSubscribedListSuccess(response))
  }
  catch (error) {
    yield put(Actions.getSubscribedListFailure({ error }))
  }
}

export function* initializeSubscriptionsaga({ params, callback }) {
  try {
    const response = yield doAuthPost(INITIALIZE_SUBSCRIPTION, params);
    callback && callback(response)
  }
  catch (error) {
    callback && callback(error)
  }
}

export function* cancelSubscriptionSaga({ params, callback }) {
  try {
    const response = yield doAuthPut(CANCEL_SUBSCRIPTION, params);
    callback && callback(response)
  }
  catch (error) {
    callback && callback(error)
  }
}


export default function* subscriptionWatcher() {
  yield all([
    takeLatest(Actions.GET_SUBSCRIPTION_LIST, subscriptionList),
    takeLatest(Actions.GET_SUBSCRIBED_LIST, subscribedList),
    takeLatest(Actions.INITIALIZE_SUBSCRIPTION_PAYMENT, initializeSubscriptionsaga),
    takeLatest(Actions.CANCEL_SUBSCRIPTION_ACTION, cancelSubscriptionSaga)
  ]);
}
