import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doContionalGet, doAuthPost, doDelete, doPut } from '../../service/index'
import { GET_ADDRESS_DETAILS, ADD_ADDRESS, DELETE_ADDRESS_DETAILS, UPDATE_ADDRESS } from '../../config/api_endpoint';

export function* getAddress({ callback }) {
    try {
        const response = yield doContionalGet(GET_ADDRESS_DETAILS);
        if (response?.response_code === 0) {
            callback && callback(response)
            yield put(Actions.getAddressSuccess(response));
        } else {
            yield put(Actions.getAddressFailure());
        }
    } catch (error) {
        yield put(Actions.getAddressFailure(error));
    }
}

export function* addAddress({ params, callback }) {
    try {
        const response = yield doAuthPost(ADD_ADDRESS, params);
        if (response?.response_code === 0) {
            yield put(Actions.getAddressSuccess(response));
        }
        yield put(Actions.addAddressSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.addAddressFailure(error));
    }
}

export function* updateAddress({ params, callback }) {
    try {
        const response = yield doPut(UPDATE_ADDRESS, params);
        if (response?.response_code === 0) {
            yield put(Actions.getAddressSuccess(response));
        }
        yield put(Actions.updateAddressSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.updateAddressFailure(error));
    }
}

export function* deleteAddress({ params, callback }) {
    try {
        const response = yield doDelete(DELETE_ADDRESS_DETAILS, params);
        if (response?.response_code === 0) {
            yield put(Actions.getAddressSuccess(response));
            callback && callback(response)
        }
        yield put(Actions.deleteAddressSuccess(response));
    } catch (error) {
        callback({error})
        yield put(Actions.deleteAddressFailure(error));
    }
}



export default function* addressWatcher() {
    yield all([
        takeLatest(Actions.GET_ADDRESS, getAddress),
        takeLatest(Actions.ADD_ADDRESS, addAddress),
        takeLatest(Actions.UPDATE_ADDRESS, updateAddress),
        takeLatest(Actions.DELETE_ADDRESS, deleteAddress),
    ]);
}
