import { takeLatest, all, put } from 'redux-saga/effects';
import {
    CHECK_EMAIL, SIGN_UP, VERIFY_OTP_ENDPOINT, CHECK_MOBILE_NUMBER_EXISTS,
    RESENT_OTP_ENDPOINT, UPDATE_EMAIL_ENDPOINT, CLIENT_CREDENTIALS
} from '../../config/api_endpoint';
import { clientCredentials, doClientGet, doClientPost, doSignupPost } from '../../service/index'
import Utils from '../../Utility/Utils';
import Actions from '../action';


export function* checkEmailExists({ params, callback }) {
    try {
        let response = yield doClientGet(CHECK_EMAIL, params);
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback({ error })
        }
    }
}
export function* checkMobileNumberExists({ mobileNumber, callback }) {
    const params = {
        mobileNumber
    }
    try {
        let response = yield doClientPost(CHECK_MOBILE_NUMBER_EXISTS, params);
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback({ error })
        }
    }
}


export function* signupSaga({ params, callback }) {

    try {
        const response = yield clientCredentials(CLIENT_CREDENTIALS);
        if (response && response.access_token) {
            response.expMilliseconds = Utils.convertExpirySecToMilliSec(response.expires_in);
        }
        yield put(Actions.clientCredentialsSuccess(response))
    }
    catch (error) {
        yield put(Actions.clientCredentialsFailure({ error }))
    }


    try {
        let response = yield doClientPost(SIGN_UP, params);
        yield put(Actions.signUpSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.signUpFailure(error));
        if (callback) {
            callback({ error })
        }
    }
}

export function* signupVerifyOTP({ params, callback }) {
    try {
        let response = yield doSignupPost(VERIFY_OTP_ENDPOINT, params);
        if (response && response.response && response.response.access_token) {
            response.response.expMilliseconds = Utils.convertExpirySecToMilliSec(response.response.expires_in);
            yield put(Actions.loginSuccess(response.response));
        }
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.verifyOtpFailure(error));
        if (callback) {
            callback({ error })
        }
    }
}

export function* resendOtp({ callback }) {
    try {
        let response = yield doSignupPost(RESENT_OTP_ENDPOINT, {});
        yield put(Actions.resendOTPSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.resendOTPFailure(error));
        if (callback) {
            callback({ error })
        }
    }
}

export function* updateEmail({ params, callback }) {
    try {
        let response = yield doSignupPost(UPDATE_EMAIL_ENDPOINT, params);
        yield put(Actions.updateEmailSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.updateEmailFailure(error));
        if (callback) {
            callback({ error })
        }
    }
}

export default function* signUpWatcher() {
    yield all([
        takeLatest(Actions.SIGN_UP_VERIFY_EMAIL, checkEmailExists),
        takeLatest(Actions.SIGN_UP, signupSaga),
        takeLatest(Actions.SIGN_UP_VERIFY_OTP, signupVerifyOTP),
        takeLatest(Actions.SIGN_UP_RESEND_OTP, resendOtp),
        takeLatest(Actions.SIGN_UP_UPDATE_EMAIL, updateEmail),
        takeLatest(Actions.SIGN_UP_VERIFY_MOBILE_NUMBER, checkMobileNumberExists)
    ]);
}
