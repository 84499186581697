import React, { useRef, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux'
import Actions from '../../redux/action';
import { COLORS } from '../../Utility/Constants';


export const customSelectStyles = {
    menu: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: COLORS.appThemeHex,
        boxShadow: null,
        display: 'flex',
        flex: 1,
        minWidth: '300px'
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? COLORS.appThemeHex : 'white',
        zIndex: 999
    }),
    valueContainer: (provided, state) => ({
        ...provided,
    }),
};

const StoreTypeAhead = ({
    productType,
    defaultOptions = null,
    placeholder = '',
    style = {},
    skuId = null,
    onChange = () => null,
    isMulti,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(defaultOptions || []);
    const dispatch = useDispatch();

    const optionsRef = useRef()

    const searchApi = useCallback((value) => {
        const defaultFilter = {
            page: 0,
            size: 100,
            storeName: value ? value : ''
        };
        setIsLoading(true);
        dispatch(Actions.getMultipleStoreList(defaultFilter, (resp) => {
            let data = [];
            if (resp) {
                data = resp?.map(d => ({
                    label: `${d?.branchName} (${d?.address} ${d?.zipCode})`, value: d?.id
                }));
            }
            setIsLoading(false)
            setOptions(data)
            optionsRef.current = data
        }))

    }, [setIsLoading, setOptions, dispatch]);

    const debounceRef = useRef(debounce((value) => {
        searchApi(value ? value : '');
    }, 500));

    useEffect(() => {
        searchApi();
    }, [dispatch, searchApi])


    return <Select
        id="productstypeahead"
        className="title-case"
        {...props}
        // styles={customSelectStyles}
        placeholder={placeholder}
        isLoading={isLoading}
        isClearable={true}
        isSearchable={true}
        onInputChange={(value) => debounceRef.current(value)}
        options={options}
        isMulti={isMulti}
        onChange={(value) => onChange(value || {})}
    />
}



export default StoreTypeAhead;