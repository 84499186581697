export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE'


export const createOrder = (data, callback) => {
    return {
        type: CREATE_ORDER,
        data,
        callback
    }
}

export const createOrderSuccess = (response, data) => {
    return {
        type: CREATE_ORDER_SUCCESS,
        response,
        data
    }
}

export const createOrderFailure = (error) => {
    return {
        type: CREATE_ORDER_FAILURE,
        error
    }
}