import React from 'react';

export default (props) => {
    const { onCloseModal, children, isRefund, closeButton ,className = ''} = props;
    return (
        <>
            <div className="modal d-block" id={`${isRefund ? "refund-modal" : "feedback-modal"}`} tabindex="-1" aria-labelledby="feedback" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered ${className}`}>
                    <div className="modal-content">
                        <div className="modal-body p-50">
                            {closeButton && <img
                                src={require("../../asset/img/modal-close.svg")}
                                alt="X"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                className="modal-close"
                                onClick={onCloseModal}
                            />}
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}


export const ModalFooter = (props) => {
    const { onSuccess, buttonName, buttonDisable } = props;
    return (
        <div className="text-center pt-4 mb-5">
            <button
                type="button"
                className="btn btn-dark big-btn w-100 px-5 text-uppercase"
                onClick={() => onSuccess()}
                disabled={buttonDisable}
            >
                {buttonName}
            </button>
        </div>
    )
}