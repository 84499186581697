import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import Constants from "../../Utility/Constants";
import routes from "../../config/routes";
import eventBus, { EVENT_FEEDBACK_OPEN, EVENT_FRANCHISE_ENQUIRY_OPEN, EVENT_SIGN_IN_OPEN } from "../../Utility/event";
import FeedBack from "../../container/feedback";
import FranchiseEnquiry from "../../container/franchise";
import moment from 'moment'

export class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFeedBack: false,
      isFranchiseEnquiry: false,
    };
  }
  navProducts(id) {
    this.props.history.push(routes.CATEGORY_LIST);
  }
  profile() {
    let { token } = this.props.getUserDetail || {};
    if (token) {
      this.props.history.push(routes.MYACCOUNTS);
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    }
  }
  checkout() {
    let { token } = this.props.getUserDetail || {};
    if (token) {
      this.props.history.push(routes.CHECKOUT);
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    }
  }
  // franchiseEnquiry() {
  //   this.props.history.push(routes.FRANCHISE_ENQUIRY);
  // }
  termsAndCondition() {
    this.props.history.push(routes.TERMSANDCONDITION);
  }

  moveAbout() {
    this.props.history.push(routes.ABOUT_US);
  }

  moveHelp() {
    this.props.history.push(routes.HELP);
  }

  componentDidMount() {
    let { token } = this.props.getUserDetail || {};
    const { ordersList } = this.props.orderList;

    if (token && ordersList && ordersList.length === 0) {
      this.props.getOrdersList();
    }
    eventBus.on(EVENT_FEEDBACK_OPEN, () => {
      this.setState({ isFeedBack: true });
    });

    eventBus.on(EVENT_FRANCHISE_ENQUIRY_OPEN, () => {
      this.setState({ isFranchiseEnquiry: true });
    });
  }
  trackOrder() {
    const { ordersList } = this.props.orderList;
    let { token } = this.props.getUserDetail || {};
    if (token) {
      const { invoiceId } = ordersList[0] || {};
      this.props.history.push(`/ordersummary/${invoiceId}`);
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    }
  }
  moveFeedBack() {
    let { token } = this.props.getUserDetail || {};
    if (token) {
      this.props.history.push(routes.FEED_BACK);
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    }
  }
  subscription(path) {
    let { token } = this.props.getUserDetail || {};
    const { pathname } = this.props.location;
    let pushOrReplace = this.props.history.push;
    if (token) {
      if (pathname === path) {
        pushOrReplace = this.props.history.replace;
      }
      pushOrReplace(path);
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    }
  }
  about(path) {
    let { token, customer } = this.props.getUserDetail || {};
    if (token && customer?.active && customer?.smsOtpVerified) {
      const { pathname } = this.props.location;
      let pushOrReplace = this.props.history.push;
      if (pathname === path) {
        pushOrReplace = this.props.history.replace;
      }
      pushOrReplace(path);
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    }


  }

  openFeedBack() {
    let { token, customer } = this.props.getUserDetail || {};
    if (token && customer?.active && customer?.smsOtpVerified) {
      this.setState({ isFeedBack: true })
    } else {
      eventBus.dispatch(EVENT_SIGN_IN_OPEN, {});
    }
  }
  onCloseModalFeedBack = () => this.setState({ isFeedBack: false });

  openFranchiseEnquiry = () => this.setState({ isFranchiseEnquiry: true });
  onCloseModalFranchiseEnquiry = () =>
    this.setState({ isFranchiseEnquiry: false });
  openProglintMeet() {
    window.open('https://meet.h4helpnow.com/custom/066d7b86e9473316c3e24c933ed0c8f8')
  }
  render() {
    const { isFeedBack, isFranchiseEnquiry } = this.state;
    return (
      <React.Fragment>
        <footer id="footer" className="bg-cream">
          <div className="wrap">

            <div className="text-center">
              <img                                                   /*TODO WHITE LABELING FOOTER IMAGE*/
                src={require("../../asset/img/proglint.svg")}
                alt="Proglint Logo"
                className="footer-logo"
              />
            </div>
            <div className="row">
              {/* <div className="col-sm-4 text-center">
                <span id="openPlayStore" onClick={() => this.openPlayStore()} className="cursor-pointer">
                  <img
                    src={require("../../asset/img/play.png")}
                    style={{ width: '35%' }}
                    alt="playStore"
                    className="mt-4" />
                </span>
                <span style={{ marginLeft: '10px' }}
                  id="openAppStore"
                  className="cursor-pointer"
                  onClick={() => this.openAppStore()}>
                  <img
                    src={require("../../asset/img/ios.png")}
                    style={{ width: '35%' }}
                    className="mt-4"
                    alt="appStore"
                    />
                </span>
              </div> */}
              <div className="col-sm-12 footer-center-content">
                <nav className="mt-4 mb-3">
                  <ul className="d-flex justify-content-center">
                    <li>
                      <span
                        className="mx-3 text-link" id='about'
                        onClick={() => this.about(routes.ABOUT_US)}
                      >About</span>
                    </li>
                    {/* <li>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#franchise-modal"
                        className="mx-3 text-link"
                        id='openFranchiseEnquiry'
                        onClick={() => this.openFranchiseEnquiry()}
                      >
                        Franchise Inquiry
                      </span>
                    </li> */}

                    {/* <li>
                      <span
                        className="mx-3 text-link"
                        id='subscription'
                        onClick={() => this.subscription(routes.SUBSCRIPTION)}
                      >
                        {Constants.FOOTER.subscription}
                      </span>
                    </li> */}
                    <li>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#feedback-modal"
                        className="mx-3 text-link"
                        id='openFeedBack'
                        onClick={() => this.openFeedBack()}
                      >
                        Feedback
                      </span>
                    </li>

                    <li>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#feedback-modal"
                        className="mx-3 text-link"
                        id='FeedBack'
                        onClick={() => this.openProglintMeet()}
                      >
                        Customer care
                      </span>

                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="text-center"> {/* TODO copyright changes */}
              <span className="font-weight-normal">
                Copyright © {moment().format('YYYY')}, Proglint
              </span>
            </div>
          </div>
        </footer>
        {isFeedBack && (
          <FeedBack
            isModalOpen={isFeedBack}
            onCloseModal={this.onCloseModalFeedBack}
          />
        )}
        {isFranchiseEnquiry && (
          <FranchiseEnquiry
            isModalOpen={isFranchiseEnquiry}
            onCloseModal={this.onCloseModalFranchiseEnquiry}
          />
        )}
      </React.Fragment>
    );
  }
}
export const mapStateToProps = (state) => {
  return {
    user: Actions.getUserDetail(state),
    getUserDetail: Actions.getUserDetail(state),
    orderList: Actions.orderList(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getOrdersList: Actions.getOrdersList,
  })(Footer)
);
