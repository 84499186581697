import { takeLatest, all, put } from 'redux-saga/effects';
import { CLIENT_CREDENTIALS, LOGIN, SIGN_OUT } from '../../config/api_endpoint';
import { doClientPost, doAuthPost, refreshToken, clientCredentials } from '../../service/index'
import Utils from '../../Utility/Utils';
import Actions from '../action';
import { store } from '../createStore';
export function* loginUser({ params, callback }) {

  try {
    const response = yield clientCredentials(CLIENT_CREDENTIALS);
    if (response && response.access_token) {
      response.expMilliseconds = Utils.convertExpirySecToMilliSec(response.expires_in);
    }
    yield put(Actions.clientCredentialsSuccess(response))
  }
  catch (error) {
    yield put(Actions.clientCredentialsFailure({ error }))
  }


  try {
    let response = yield doClientPost(LOGIN, params)
    if (response && response.access_token) {
      const resp = { ...response };
      response.expMilliseconds = Utils.convertExpirySecToMilliSec(response.expires_in);
      yield put(Actions.loginSuccess(response))
      if (callback) {
        callback(resp)
      }
    } else {
      callback && callback(response)
      yield put(Actions.loginFailure(response))
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.loginFailure({ error }))
  }
}


export function* logoutUser() {
  const storeData = store?.getState()?.GET_STORE_LIST
  try {
    let response = yield doAuthPost(SIGN_OUT)
    yield put(Actions.logoutSuccess(response))
    yield put(Actions.storeListSuccess(storeData))
    yield put(Actions.clearCart())
  } catch (error) {
    yield put(Actions.logoutFailure({ error }))
  }
}


export function* getRefreshToken({ data, callback }) {
  try {
    const response = yield refreshToken(data);
    if (response && response.access_token) {
      response.expMilliseconds = Utils.convertExpirySecToMilliSec(response.expires_in);
    }
    if (callback) {
      callback(response)
    }
    yield put(Actions.loginSuccess(response))
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.logout());
  }
}

export default function* loginWatcher() {
  yield all([
    takeLatest(Actions.LOGIN, loginUser),
    takeLatest(Actions.LOGOUT, logoutUser),
    takeLatest(Actions.REFRESH_TOKEN_ACTION, getRefreshToken)
  ]);
}
