import GoogleMapReact from 'google-map-react';
import React from 'react';
import { MAP_API_KEY } from '../../config/api_endpoint';
import botImg from '../../asset/images/daxBot_marker.png';
import marker from '../../asset/images/marker_map_icon.png'
export const DaxbotMarker = ({ text }) => <span title={text}
    style={{ top: -15, left: -15, position: 'absolute' }}>
    <img alt="text"
        src={botImg}
        height="30px" width="30px" />
</span>

export const UserMarker = ({ text }) => <span title={text}
    style={{ top: -30, left: -15, position: 'absolute' }}>
    <img alt="text"
        src={marker}
        height="30px" width="30px" />
</span>

const AppMapView = ({ zoom, center, botLatLng, onClick, style = { height: '100%', width: '100%' } }) => {
    return <div style={style}>
        <GoogleMapReact
            id="GoogleMapReact"
            options={
                { fullscreenControl: false }
            }
            bootstrapURLKeys={{ key: MAP_API_KEY }}
            center={center}
            defaultZoom={zoom}
            onClick={(e) => onClick({ lat: e.lat, lng: e.lng })}
        >
            <UserMarker
                {...center} />
            {botLatLng?.lat && <DaxbotMarker
                {...botLatLng}
                text="My Marker"
            />}
        </GoogleMapReact>
    </div >
};


AppMapView.defaultProps = {
    center: {
        lat: 59.95,
        lng: 30.33
    },
    botLatLng: {

    },
    onClick: () => null,
    zoom: 12
};


export default AppMapView;