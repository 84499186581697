import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doContionalGet } from '../../service/index'
import { PRODUCT_GROUP_INFORMATIONS } from '../../config/api_endpoint'
export function* productGroupInformation({ params }) {
  try {
    const response = yield doContionalGet(PRODUCT_GROUP_INFORMATIONS, params);
    yield put(Actions.productGroupInformationSuccess(response))
  }
  catch (error) {
    yield put(Actions.productGroupInformationFailure({ error }))
  }
}


export default function* productGroupInformationWatcher() {
  yield all([
    takeLatest(Actions.PRODUCT_GROUP_INFORMATION, productGroupInformation),
  ]);
}
