import { takeLatest, all, put } from 'redux-saga/effects';
import { LOGIN, CLIENT_CREDENTIALS, GUEST_CUSTOMER_TRANSACTION, CUSTOMER_CART_SWAP } from '../../config/api_endpoint';
import { doClientPost, clientCredentials, doContionalGet, doAuthPost, doAuthGet } from '../../service/index'
import Utils from '../../Utility/Utils';
import Actions from '../action';
export function* guestCustomer({ params, callback }) {
    try {
        const response = yield clientCredentials(CLIENT_CREDENTIALS);
        if (response && response.access_token) {
            response.expMilliseconds = Utils.convertExpirySecToMilliSec(response.expires_in);
        }
        yield put(Actions.clientCredentialsSuccess(response))
    }
    catch (error) {
        yield put(Actions.clientCredentialsFailure({ error }))
    }
    try {
        let response = yield doClientPost(LOGIN, params)
        if (response && response.access_token) {
            const resp = { ...response };
            response.expMilliseconds = Utils.convertExpirySecToMilliSec(response.expires_in);
            yield put(Actions.loginSuccess(response))
            callback && callback(resp)
            yield put(Actions.createGuestCustomerSuccess())

        } else {
            callback && callback(response)
            yield put(Actions.loginFailure(response))
        }
    } catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.loginFailure({ error }))
        yield put(Actions.createGuestCustomerFailure({ error }))
    }
}

export function* guestCustomerTransactions({ params, callback }) {
    try {
        const response = yield doContionalGet(GUEST_CUSTOMER_TRANSACTION, params);
        callback && callback(response)
        yield put(Actions.getGuestTransactionsSuccess(response))
    }
    catch (error) {
        callback && callback({ error })
        yield put(Actions.getGuestTransactionsFailure({ error }))
    }
}


export function* cardSwap({ params, callback }) {
    try {
        const response = yield doAuthGet(CUSTOMER_CART_SWAP, params);
        callback && callback(response)
    }
    catch (error) {
        callback && callback({ error })
    }
}

export default function* guestCustomerWatcher() {
    yield all([
        takeLatest(Actions.CREATE_GUEST_CUSTOMER, guestCustomer),
        takeLatest(Actions.GET_GUEST_TRANSACTION_DETAILS, guestCustomerTransactions),
        takeLatest(Actions.CARD_SWAP_DETAILS, cardSwap)
    ]);
}
