export const ProfileDetails = {
    Edit: "edit",
    ChangePassword: "password",
    ManageAddress: "address",
    ManageSubscription: "subscription",
    Orders: "orders",
    Help: "help",
    Setting: "setting",
    Rewards: "rewards",
    Contact: "contact",
    LegalInfo: "legalinfo",
    About: "about",
    DeleteAccount: "delete",
    LogOut: "logout",
    RewardsHistory: "history",
    OrdersSummary: "order",
    TrackMyOrder:'track',
    ManagePayment: "payment"
}
