import Actions from '../action'
const initialState = {
    isLoading: false,
    getStoreList: [],
    getMultipleStoreList: [],
    selectedStoreList: [],
    error: false,
    storeListError: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_STORE_LIST: {
            return {
                ...state,
                isLoading: true,
                error: false
            };
        }
        case Actions.GET_STORE_LIST_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                getStoreList: action.data.response || [],
                error: false
            };
        }
        case Actions.GET_STORE_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.STORE_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.STORE_LIST_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                getMultipleStoreList: action.data.response,
                storeListError: false
            };
        }
        case Actions.STORE_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                storeListError: action.error,
            };
        }
        case Actions.SELECTED_STORE_LIST: {
            return {
                ...state,
                isLoading: false,
                selectedStoreList: action.data,
                error: false
            };
        }
        default:
            return state;
    }
};
