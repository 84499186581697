import Actions from '../../action'
const initialState = {
    isLoading: false,
    error: false,
    isPasswordReset: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.FORGET_PASSWORD_VERIFY_OTP: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FORGET_PASSWORD_VERIFY_OTP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isPasswordReset: action.data, // true or false
                error: false
            };
        }
        case Actions.FORGET_PASSWORD_VERIFY_OTP_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
