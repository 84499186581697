import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import ModalPopup, { ModalHeader } from '../ModalPopup';
import InputField from "../InputField";
import AddressMapView from "../map/AddressMapView";
import Geocode from 'react-geocode';
import GooglePlacesAutocomplete, { getLatLng, geocodeByAddress } from 'react-google-places-autocomplete';
import BoldError from "../BoldError";

Geocode.setApiKey('AIzaSyCVkLst2JnpmKjU72Ks0jF3ggcPmkGeFj0'); // our api key need to replace with Proglint 
Geocode.enableDebug();

export const processData = (result) => {
    let fullAddress = result.formatted_address;
    return { formattedAddress: fullAddress }
}

class ProfileAddressPopUp extends Component {
    constructor(props) {
        super(props);
        const { button, fields } = props;
        let latlng = null
        if (button === 'Update' && fields.lat) {
            latlng = {
                lat: fields.lat,
                lng: fields.lng
            }
        }
        this.state = {
            latlng,
            fields: {
                active: false
            },
            blockAddressSearch: false
        };
        this.googleInput = React.createRef();
        if (button !== 'Update')
            this.getCurrentLocation()
    }


    getCurrentLocation() {
        const success = (location) => {
            const latlng = {
                lat: location.coords.latitude,
                lng: location.coords.longitude
            };
            const { button } = this.props;
            if (button !== 'Update')
                this.updateState(latlng)
        };

        const failure = () => {
            console.log('Please enable your location!')
        };

        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, failure);
        } else {
            alert("Please enable your location!");
        }
    }

    componentDidMount() {
        this.getCurrentLocation()
        this.props.addressType()
    }

    async locationOnChange({ place_id, description }) {
        let { fields } = this.state
        let latLang = await geocodeByAddress(description)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                return { lat, lng }
            });
        fields['formattedAddress'] = description
        this.props.handleChange(description, 'formattedAddress')
        this.props.handleChange(latLang?.lat, 'lat')
        this.props.handleChange(latLang?.lng, 'lng')
        this.setState({
            latlng: latLang,
            fields,
            blockAddressSearch: true
        });
        setTimeout(() => {
            this.setState({ blockAddressSearch: false })
        }, 2000)
    }

    getGeoCodingAddress(latlng) {
        const { handleChange } = this.props;
        if (latlng?.lat) {
            Geocode.fromLatLng(latlng.lat, latlng.lng).then(
                response => {
                    const data = processData(response.results[0]);
                    data.lat = latlng.lat;
                    data.lng = latlng.lng;
                    Object.keys(data).forEach(k => handleChange(data[k] || '', k))
                },
                error => {
                    console.error(error);
                }
            )
        }

    }

    updateState(latlng) {
        if (!this.state.blockAddressSearch) {
            if (this.googleInput && this.googleInput.state) {
                this.googleInput.state.value = ''
            }
            this.setState({ latlng });
            this.getGeoCodingAddress(latlng);
        }
    }

    _renderDefaultAddress() {
        const { handleDefaultAddress, fields, getAddressDetails } = this.props
        const getSelectedAddress = getAddressDetails?.addressDetails?.find((o) => o.id === fields.addressId)
        if (!getSelectedAddress?.active) {
            return (
                <div className="row mb-4">
                    <div className="col">
                        <div className="form-check" style={{ cursor: 'pointer' }}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="active"
                                id="default-addr"
                                value={fields?.active}
                                defaultChecked={fields?.active}
                                onChange={(e) => handleDefaultAddress(e)}
                            />
                            <label className="form-check-label" for="default-addr">Mark as Default</label>
                        </div>
                    </div>
                </div>
            )
        }

    }

    render() {
        const { isModalOpen, fields, errors, closeAddressModal, handleChange, label, button, errorMessage, getAddressType } = this.props;
        return (
            isModalOpen && <ModalPopup onCloseModal={() => closeAddressModal(false)}>
                <ModalHeader title={label} />
                <BoldError message={errorMessage} />
                <div className="row mb-4">
                    <div className="col">
                        <div className="form-group">
                            <div className="p-relative">
                                <GooglePlacesAutocomplete
                                    id="GooglePlacesAutocomplete"
                                    className="form-control custom-field"
                                    type="text"
                                    placeholder="Search location"
                                    name="formattedAddress"
                                    debounce={300}
                                    ref={c => (this.googleInput = c)}
                                    onSelect={(locationObj) => this.locationOnChange(locationObj, "street")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div style={{ height: "250px", width: "400px" }}>
                        <AddressMapView center={this.state.latlng}
                            onClick={(latlng) => this.updateState(latlng)} />
                    </div>
                </div>
                {
                    fields.formattedAddress && <div className="row mb-4">
                        <div className="col">
                            <h4>Selected Address</h4>
                            <p>{fields.formattedAddress}</p>
                        </div>
                        {errors && <p className="errorMsg">{errors['formattedAddress']}</p>}
                    </div>
                }

                <div className="row mb-4">
                    <div className="col">
                        <InputField
                            id="doorNo"
                            className="form-control custom-field"
                            type="text"
                            name="doorNo"
                            placeholder="Door No"
                            value={fields['doorNo']}
                            onChange={(value, name) => handleChange(value, name)}
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        <InputField
                            id="landmark"
                            className="form-control custom-field"
                            type="text"
                            name="landmark"
                            placeholder="Landmark (optional)"
                            value={fields['landmark']}
                            onChange={(value, name) => handleChange(value, name)}
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col mb-4">
                        <select
                            className="form-select custom-field rounded-0 border-0"
                            placeholder="Residence Type"
                            name="addressType" id="addressType" value={fields['addressType']}
                            onChange={({ target: { value, name } }) => handleChange(value, name)}>
                            <option value=''>Select residence type</option>
                            {getAddressType?.addressType?.map((o) => {
                                return (<option value={o?.id}>{o?.type}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col">
                        <InputField
                            id="phone"
                            className="form-control custom-field"
                            type="number"
                            name="mobileNumber"
                            placeholder="Mobile Number"
                            value={fields['mobileNumber']}
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }}
                            onChange={(value, name) => handleChange(value, name)}
                        />
                    </div>
                </div>
                {this._renderDefaultAddress()}
                <div className="text-center pt-4 mb-5">
                    <button id='closeAddressModal'
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#verify-modal"
                        className="btn btn-dark big-btn w-100 px-5 text-uppercase"
                        onClick={() => closeAddressModal(true)}>{button}</button>
                </div>
            </ModalPopup>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getAddressType: Actions.getAddressType(state),
        getUserDetail: Actions.getUserDetail(state),
    };
};


export default withRouter(connect(mapStateToProps, {
    getAddress: Actions.getAddress,
    deleteAddress: Actions.deleteAddress,
    addressType: Actions.addressType
})(ProfileAddressPopUp));