const HOME = '/';
const LOGIN = '/login'
const STORE_DETAILS = '/store/:id'
const CHECKOUT = '/checkout'
const PROFILE = '/profile'
const ORDER_SUMMARY = '/ordersummary/:invoiceId'
const ACCOUNT_ORDER_SUMMARY = '/myaccounts/:id/summaryDetails/:invoiceId'
const PRIVACY = '/privacy'
const TERMS_OF_USE = '/termsofuse'
const FRANCHISE_ENQUIRY = '/franchise/enquiry'
const ABOUT_US = '/myaccounts/about'
const FEED_BACK = '/feedback'
const HELP = '/help'
const GUIDES = '/giudes'
const MYACCOUNT = '/myaccounts'
const MYACCOUNTS = '/myaccounts/:id'
const TERMSANDCONDITION = '/termsAndCondition';
const PAYMENT_SCREEN = '/payment'
const CATEGORY_LIST = '/category'
const BISTRO_CATEGORY_LIST = '/category/:isBistro'
const PIM_PRODUCTS = '/products'
const PIM_PRODUCTS_DETAILS = '/pimProducts/details/:sku'
const BISTRO_PRODUCTS_DETAILS = '/bistroProducts/details/:sku'
const BISTRO_PRODUCTS = '/bistro/products'
const ORDER_SUMMARY_REDIRECT = '/order/summary/:invoiceId'
const ORDER_FAILURE_REDIRECT = '/order/failed';
const TRACK_DAXBOT_SCREEN = '/trackbot/:id';
const SUBSCRIPTION = '/myaccounts/subscription';
const SUBSCRIPTION_PAYMENT = '/subscriptionPayment'
const SAVE_CARD_PAYMENT = '/savePayment'
const REWARDS_SUMMARY = '/myaccounts/rewards/:id'
const CONTACT_US = '/contact'
const CHOOSE_ADDRESS = '/chooseDelivery'
const ACCOUNT_ORDER_TRACK = '/myaccounts/:id/trackbot/:botId'
const SEARCH_ALL = '/searchAll';
const CHECKOUT_PAYMENT = '/checkout/payment'
const ENTRY_PIN = '/entry/pin'
const GUEST_CUSTOMER_TRANSACTIONS = '/customercare/transactions/:id'
const GUEST_PAYMENT_SCREEN = '/customercare/payment/:id'
export default {
    HOME,
    LOGIN,
    STORE_DETAILS,
    CHECKOUT,
    PROFILE,
    ORDER_SUMMARY,
    PRIVACY,
    TERMS_OF_USE,
    FRANCHISE_ENQUIRY,
    ABOUT_US,
    FEED_BACK,
    HELP,
    GUIDES,
    TERMSANDCONDITION,
    PAYMENT_SCREEN,
    CATEGORY_LIST,
    BISTRO_CATEGORY_LIST,
    PIM_PRODUCTS,
    ORDER_SUMMARY_REDIRECT,
    ORDER_FAILURE_REDIRECT,
    TRACK_DAXBOT_SCREEN,
    SUBSCRIPTION,
    SUBSCRIPTION_PAYMENT,
    REWARDS_SUMMARY,
    BISTRO_PRODUCTS,
    CONTACT_US,
    MYACCOUNT,
    MYACCOUNTS,
    CHOOSE_ADDRESS,
    PIM_PRODUCTS_DETAILS,
    BISTRO_PRODUCTS_DETAILS,
    ACCOUNT_ORDER_SUMMARY,
    ACCOUNT_ORDER_TRACK,
    SEARCH_ALL,
    SAVE_CARD_PAYMENT,
    CHECKOUT_PAYMENT,
    ENTRY_PIN,
    GUEST_CUSTOMER_TRANSACTIONS,
    GUEST_PAYMENT_SCREEN,
    bistroCategory: (id = 'bistro') => BISTRO_CATEGORY_LIST.replace(':isBistro', id),
    myAccountDetails: (id) => MYACCOUNTS.replace(':id', id),
    rewardsSummary: (id) => REWARDS_SUMMARY.replace(':id', id),
    accountOrdersSummary: (invoiceId, id = 'order') => ACCOUNT_ORDER_SUMMARY.replace(':invoiceId', invoiceId).replace(':id', id),
    accountOrdersTrack: (botOrderId, id = 'track') => ACCOUNT_ORDER_TRACK.replace(':id', id).replace(':botId', botOrderId)
}