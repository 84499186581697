import React from "react";
import { withRouter } from "react-router-dom";
import Constants from "../../Utility/Constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import ModalPopup, { ModalFooter } from "../../component/ModalPopup/ModalPopUpStore";
import { ModalHeader } from "../../component/ModalPopup";

import StoreTypeAhead from "./StoreTypeAhead";
import Action from "../../redux/action";
import Alert from "../alert";

class StorePopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStore: {},
            errorMessage: "",
            defaultSelectedStore: "",
            isOpenConfirmationModal: false,
            buttonDisable: true
        };
    }
    componentDidMount() {
        const { selectedStoreList } = this.props.getSelectedStoreList || {};
        this.setState({ selectedStore: selectedStoreList })
    }
    onCloseModal() {
        this.setState({ isOpenConfirmationModal: false })
    }
    selectedStoreData = (value) => {
        const { selectedStoreList } = this.props.getSelectedStoreList || {};
        if (!value.hasOwnProperty("label")) {
            this.setState({ errorMessage: "Please select store" })
            this.setState({ buttonDisable: true })
        } else if (selectedStoreList?.value === value?.value) {
            this.setState({ errorMessage: "You are selecting the same store" })
            this.setState({ buttonDisable: true })
        } else {
            this.setState({ errorMessage: "" })
            this.setState({ buttonDisable: false })
        }
        this.setState({ selectedStore: value });
    }
    storeConfirmation = () => {
        let params
        const callback = (res) => {
            if (res?.response_code === 0) {
                this.props.selectedStoreList(this.state.selectedStore);
                this.props.onCloseModal();
                setTimeout(() => {
                    window.location.reload();
                }, 500);
                this.props.history.push('/')
            } else {
                toast.error(res?.response_message);
                this.props.onCloseModal();
                setTimeout(() => {
                    window.location.reload();
                }, 500);

            }
        }
        this.props.cartEmpty(params, callback)

    }
    sendSelectedStore = () => {
        const { pimCart } = this.props.getPimCartDetails || {};
        const { selectedStoreList } = this.props.getSelectedStoreList || {};
        const { selectedStore } = this.state;

        if (selectedStore.hasOwnProperty("label")) {
            if (pimCart && pimCart?.totalQuantityInCart && selectedStoreList?.value !== selectedStore?.value) {
                this.setState({ isOpenConfirmationModal: true })
            } else if (selectedStoreList?.value === selectedStore?.value) {
                this.props.onCloseModal();
            } else {
                this.props.selectedStoreList(selectedStore);
                this.props.onCloseModal();
                this.props.history.push('/')
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }
        }
        else if (!selectedStore) {
            this.setState({ errorMessage: "Please select store state" })
        } else if (!selectedStore.hasOwnProperty("label")) {
            this.setState({ errorMessage: "Please select store" })
        } else if (selectedStoreList) {
            this.props.onCloseModal();
        }
    };
    render() {
        const { isModalOpen, onCloseModal, closeButton } = this.props;
        const { selectedStoreList } = this.props.getSelectedStoreList || {};
        const { errorMessage, buttonDisable } = this.state;
        return (
            <React.Fragment>
                {isModalOpen && <ModalPopup onCloseModal={onCloseModal} closeButton={closeButton}>
                    <ModalHeader title="Select Store" />
                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}
                    <div className="row mb-4">
                        <div className="col">
                            <label for="main-product"
                                className="form-label fw-bold">{Constants.MULTI_STORE.TITLE}</label>
                            <StoreTypeAhead
                                // defaultOptions={optionsData}
                                defaultValue={selectedStoreList}
                                placeholder="Select Store"
                                onChange={this.selectedStoreData}
                            />
                        </div>
                    </div>

                    <ModalFooter
                        buttonDisable={buttonDisable}
                        onSuccess={() => this.sendSelectedStore()}
                        buttonName="PROCEED" />
                    {this.state.isOpenConfirmationModal && <Alert
                        id="AlertInfo"
                        title={Constants.MULTI_STORE.STORE_TITLE}
                        description={Constants.MULTI_STORE.SUB_TITLE_STORE}
                        proceed={Constants.MULTI_STORE.ITEM_ALREADY_BUTTON}
                        cancel={Constants.MULTI_STORE.CANCEL_BUTTON}
                        onSuccess={() => this.storeConfirmation()}
                        onFailure={() => this.onCloseModal()}
                    />}
                </ModalPopup>
                }
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getSelectedStoreList: Action.getStoreList(state),
        getPimCartDetails: Action.getPimCartDetails(state),

    };
};

export default withRouter(
    connect(mapStateToProps, {
        selectedStoreList: Action.selectedStoreList,
        cartEmpty: Action.cartEmpty
    })(StorePopUp)
);