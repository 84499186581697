import Actions from '../action'
const initialState = {
    cartDetails: [],
    isLoading:false,
    error:false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.UPDATE_CART: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_CART_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                cartDetails: action.data,
                error: false
            };
        }
        case Actions.UPDATE_CART_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
