import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthGet, doAuthPost } from '../../service/index'
import { CREATE_ORDER, GET_ORDER_LIST, ORDER_DETAILS, ORDER_SEND_TO_EMAIL, REFUND_ORDER, UPLOAD_REFUND_IMAGE, REFUND_DETAILS } from '../../config/api_endpoint';

export function* orderList({ params }) {
    try {
        const response = yield doAuthGet(GET_ORDER_LIST, params);
        yield put(Actions.getOrdersListSuccess(response))
    }
    catch (error) {
        yield put(Actions.getOrdersListFailure({ error }))
    }
}


export function* createOrders({ data, callback }) {
    try {
        const response = yield doAuthPost(CREATE_ORDER, data);
        yield put(Actions.createOrderSuccess(response))
        if (callback) {
            callback(response)
        }
    }
    catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.createOrderFailure({ error }))
    }
}


export function* orderDetails({ data }) {
    try {
        const response = yield doAuthGet(ORDER_DETAILS, data);
        yield put(Actions.orderDetailsSuccess(response))
    }
    catch (error) {
        yield put(Actions.orderDetailsFailure({ error }))
    }
}

export function* sendToEmail({ data, callback }) {
    try {
        const response = yield doAuthGet(ORDER_SEND_TO_EMAIL, data);
        yield put(Actions.sendToEmailSuccess(response))
        if (callback) {
            callback(response)
        }
    }
    catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.sendToEmailFailure({ error }))
    }
}

export function* refundOrders({ data, callback }) {
    try {
        const response = yield doAuthPost(REFUND_ORDER, data);
        yield put(Actions.refundOrderSuccess(response))
        if (callback) {
            callback(response)
        }
    }
    catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.refundOrderFailure({ error }))
    }
}

export function* uploadRefundImages({ data, callback }) {
    try {
        const response = yield doAuthPost(UPLOAD_REFUND_IMAGE, data);
        callback && callback(response)
        yield put(Actions.uploadRefundImageSuccess(response))
    }
    catch (error) {
        callback && callback({ error })
        yield put(Actions.uploadRefundImageFailure({ error }))
    }
}


export function* getRefundDetails({ data }) {
    try {
        const response = yield doAuthGet(REFUND_DETAILS, data);
        yield put(Actions.getRefundDetailsSuccess(response))
    }
    catch (error) {
        yield put(Actions.getRefundDetailsFailure({ error }))
    }
}

export default function* orderWatcher() {
    yield all([
        takeLatest(Actions.ORDERS_LIST, orderList),
        takeLatest(Actions.CREATE_ORDER, createOrders),
        takeLatest(Actions.ORDER_DETAILS, orderDetails),
        takeLatest(Actions.SEND_TO_MAIL, sendToEmail),
        takeLatest(Actions.REFUND_ORDER, refundOrders),
        takeLatest(Actions.UPLOAD_REFUND_IMAGE, uploadRefundImages),
        takeLatest(Actions.REFUND_DETAILS, getRefundDetails),
    ]);
}
