import moment from 'moment';
import qs from 'qs';

const isTokenExpired = (milliseconds) => {
    return milliseconds && moment(milliseconds - 10000, 'x').isAfter(moment());
}


const convertExpirySecToMilliSec = (expSec) => {
    return moment().add(expSec, 'seconds').valueOf();
}

export const queryStringParser = (str) => {
    return qs.parse(str, { ignoreQueryPrefix: true })
}


export const sequenceNumberValidation = (input) => {
    let sa = /^(?!(\d)\1+$|(?:0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)|9(?=0)){5}\d$|(?:0(?=9)|1(?=0)|2(?=1)|3(?=2)|4(?=3)|5(?=4)|6(?=5)|7(?=6)|8(?=7)|9(?=8)){5}\d$)\d{6}$/;
    return sa.test(String(input));
}

export const getRandomNumber = () => {
    return Math.random()
}

export const getInvoiceDate = (date) => {
    return moment(date).format('MM/DD/YYYY - hh:mm A')

}

export default {
    isTokenExpired,
    convertExpirySecToMilliSec
}