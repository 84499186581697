import Actions from '../action'
const initialState = {
    isLoading: false,
    paymentCardsList: [],
    error: false,
    createPinOtpLoading: false,
    createPinOtpError: false,
    savePinLoading: false,
    savePinError: false,
    entryPin: undefined,
    deleteLoading: false,
    deleteError: false,
    primaryCardLoading: false,
    primaryCardError: false,
    isForgetPin: false,
    resetPinError: false,
    resetPinLoading: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_SAVED_CARD_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_SAVED_CARD_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                paymentCardsList: action.data?.response || [],
                error: false
            };
        }
        case Actions.GET_SAVED_CARD_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case Actions.SEND_OTP_CREAE_PIN: {
            return {
                ...state,
                createPinOtpLoading: true,
            };
        }
        case Actions.SEND_OTP_CREAE_PIN_SUCCESS: {
            return {
                ...state,
                createPinOtpLoading: false,
                createPinOtpError: false
            };
        }
        case Actions.SEND_OTP_CREAE_PIN_FAILURE: {
            return {
                ...state,
                createPinOtpLoading: false,
                createPinOtpError: action.error,
            };
        }


        case Actions.SAVE_OTP_PIN: {
            return {
                ...state,
                savePinLoading: true,
            };
        }
        case Actions.SAVE_OTP_PIN_SUCCESS: {
            return {
                ...state,
                savePinLoading: false,
                savePinError: false
            };
        }
        case Actions.SAVE_OTP_PIN_FAILURE: {
            return {
                ...state,
                savePinLoading: false,
                savePinError: action.error,
            };
        }

        case Actions.ENTRY_OTP_PIN: {
            return {
                ...state,
                entryPin: action?.data
            };
        }
        case Actions.DELETE_CARD: {
            return {
                ...state,
                deleteLoading: true
            };
        }
        case Actions.DELETE_CARD_SUCCESS: {
            let { paymentCardsList } = state
            let { tokenDetailsId } = action.data
            let index = paymentCardsList.findIndex(
                i => i.customerTokenDetailsId === tokenDetailsId,
            );
            paymentCardsList.splice(index, 1);
            return {
                ...state,
                deleteLoading: false,
                paymentCardsList: paymentCardsList,
                deleteError: false
            };
        }
        case Actions.DELETE_CARD_FAILURE: {
            return {
                ...state,
                deleteLoading: false,
                deleteError: action.error,
            };
        }


        case Actions.MAKE_PRIMARY_CARD: {
            return {
                ...state,
                primaryCardLoading: true
            };
        }
        case Actions.MAKE_PRIMARY_CARD_SUCCESS: {
            return {
                ...state,
                primaryCardLoading: false,
                paymentCardsList: action?.data,
                primaryCardError: false
            };
        }
        case Actions.MAKE_PRIMARY_CARD_FAILURE: {
            return {
                ...state,
                primaryCardLoading: false,
                primaryCardError: action.error,
            };
        }

        case Actions.FORGET_PIN: {
            return {
                ...state,
                isForgetPin: action.data
            }
        }


        case Actions.RESET_PIN: {
            return {
                ...state,
                resetPinLoading: true,
            };
        }
        case Actions.RESET_PIN_SUCCESS: {
            return {
                ...state,
                resetPinLoading: false,
                resetPinError: false
            };
        }
        case Actions.RESET_PIN_FAILURE: {
            return {
                ...state,
                resetPinLoading: false,
                resetPinError: action.error,
            };
        }

        default:
            return state;
    }
};
