const NAME = "UPDATE_CART";
export const UPDATE_CART = `${NAME}/UPDATE_CART`;
export const UPDATE_CART_SUCCESS = `${NAME}/UPDATE_CART_SUCCESS`;
export const  UPDATE_CART_FAILURE = `${NAME}/ UPDATE_CART_FAILURE`;

export const updateCart = (params ,callback) => {
    return {
        type: UPDATE_CART,
        params,
        callback
    }
   }
    export const updateCartSuccess = (data) => {
        return {
            type: UPDATE_CART_SUCCESS,
            data
        }
    }
    
    export const updateCartFailure = (error) => {
        return {
            type: UPDATE_CART_FAILURE,
            error
        }
    }  





