import React, { Component } from 'react';
import Constants from '../../Utility/Constants';
import { toast } from 'react-toastify'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import BoldError from '../../component/BoldError';
import PasswordInput from "../../component/PasswordInput";

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            oldPassword: "",
            newPassword: "",
            reTypePassword: "",
            passwordValid: "",
            showOldPassword: false,
            showNewPassword: false,
            showReTypePassword: false,
            userPasswordErrorMessage: '',
        }
    }

    onUpdateUserPassword = () => {
        const { oldPassword, newPassword, reTypePassword, passwordValid } = this.state;

        let error = null;
        if (!oldPassword) {
            error = "Please Enter the old Password"
        } else if (!passwordValid) {
            error = Constants.REGISTER.error.passkey;
        } else if (reTypePassword !== newPassword) {
            error = Constants.REGISTER.error.passkeyMismatch;
        }

        this.setState({ userPasswordErrorMessage: error });
        if (error) {
            return;
        }

        const callback = (res) => {
            if (res.response_code === 0) {
                toast.success('Password got updated')
                this.setState({ oldPassword: '', newPassword: '', reTypePassword: '' })
            } else {
                this.setState({ userPasswordErrorMessage: res?.response_message });
            }
        }

        let params = {
            oldPassword,
            newPassword,
            reTypePassword
        }
        this.props.updateAccountPassword(params, callback);
    }


    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value });
    }

    render() {
        const { userPasswordErrorMessage, oldPassword, newPassword, reTypePassword } = this.state;
        return (
            <div className="col ps-md-5">

                <div className="row mb-5 align-items-center text-link">
                    <div className="col-12 col">
                        <h1 className="font18 fw-bold mb-5">Change password</h1>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col col-md-5">
                        <BoldError message={userPasswordErrorMessage} />
                        <PasswordInput
                            id="OldPassword"
                            showEyeIcon
                            defaultValue={oldPassword}
                            onChange={(value) => this.setState({ oldPassword: value })}
                            value={oldPassword}
                            placeholder="Old Password"
                            isPasswordValid={passwordValid => this.setState({ passwordValid })} />
                        <PasswordInput
                            id="NewPassword"
                            showEyeIcon
                            defaultValue={newPassword}
                            onChange={(value) => this.setState({ newPassword: value })}
                            value={newPassword}
                            placeholder="New Password"
                            isPasswordValid={passwordValid => this.setState({ passwordValid })} />
                        <PasswordInput
                            id="RetypePassword"
                            showEyeIcon
                            hidePopover
                            className="form-control custom-field"
                            type="password"
                            name="password"
                            placeholder="Re-Type Password"
                            defaultValue={reTypePassword}
                            value={reTypePassword}
                            error={reTypePassword !== '' && newPassword !== reTypePassword ? Constants.REGISTER.error.passkeyNotMatch : false}
                            onChange={(value) => this.setState({ reTypePassword: value })}
                        />
                    </div>
                </div>

                <div className="row my-4 mb-5">
                    <div className="col col-md-5">
                        <button className="btn btn-dark big-btn text-uppercase"id='update' onClick={() => this.onUpdateUserPassword()}>Update</button>
                    </div>
                </div>

            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
    };
};


export default withRouter(connect(mapStateToProps, {
    updateAccountPassword: Actions.updateAccountPassword,
})(ChangePassword))