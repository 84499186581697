import { takeLatest, all, put, select } from 'redux-saga/effects';
import Actions from '../action';
import { doAuthPost, doAuthGet } from '../../service/index'
import { CREATE_NEW_CHAT_API, GET_CHAT_LIST_API } from '../../config/api_endpoint';
import { updateChatObjectSelector } from '../reducer/chatsReducer';

export function* createNewChatSaga({ params, callback }) {
    try {
        const response = yield doAuthPost(CREATE_NEW_CHAT_API, params);
        if (response.response_code === 0) {
            const newMessageObject = response.response;
            const existingChatObj = yield select(updateChatObjectSelector);
            if (existingChatObj.chatMessages) {
                existingChatObj.chatMessages.unshift(newMessageObject.chatMessages[0]);
                yield put(Actions.getChatMessagesListSuccess(existingChatObj));
            } else {
                yield put(Actions.getChatMessagesListSuccess(newMessageObject));
            }
        }
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.createNewChatFailure(error));
    }
}


export function* getChatMessagesSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(GET_CHAT_LIST_API, params);
        if (response.response_code === 0) {
            yield put(Actions.getChatMessagesListSuccess(response.response));
        } else {
            yield put(Actions.getChatMessagesListFailure({}));
        }
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.getChatMessagesListFailure(error));
    }
}

export default function* chatsWatcher() {
    yield all([
        takeLatest(Actions.CREATE_NEW_CHAT, createNewChatSaga),
        takeLatest(Actions.GET_CHAT_MESSAGES_LIST, getChatMessagesSaga)
    ]);
}
