import Actions from '../action'
const initialState = {
    isLoading: false,
    daxbotAvailability: [],
    error: false,
    isDaxbotInfoLoading: false,
    daxbotInfo: null,
    daxbotInfoError: null
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.DAXBOT_CHECK_AVAILABILITY: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case Actions.DAXBOT_CHECK_AVAILABILITY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                daxbotAvailability: action.data.response,
                error: false
            };
        }
        case Actions.DAXBOT_CHECK_AVAILABILITY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.DAXBOT_DELIVERY_INFO_ACTION: {
            return {
                ...state,
                isDaxbotInfoLoading: true,
                daxbotInfo: null
            };
        }
        case Actions.DAXBOT_DELIVERY_INFO_ACTION_SUCCESS: {
            return {
                ...state,
                isDaxbotInfoLoading: false,
                daxbotInfo: action?.data?.response,
                daxbotInfoError: false
            };
        }
        case Actions.DAXBOT_DELIVERY_INFO_ACTION_FAILURE: {
            return {
                ...state,
                isDaxbotInfoLoading: false,
                daxbotInfoError: action.error,
            };
        }
        default:
            return state;
    }
};
