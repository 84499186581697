const CREATE_NEW_CHAT = 'CREATE_NEW_CHAT';
const CREATE_NEW_CHAT_SUCCESS = 'CREATE_NEW_CHAT_SUCCESS';
const CREATE_NEW_CHAT_FAILURE = 'CREATE_NEW_CHAT_FAILURE';


const GET_CHAT_MESSAGES_LIST = 'GET_CHAT_MESSAGES_LIST';
const GET_CHAT_MESSAGES_LIST_SUCCESS = 'GET_CHAT_MESSAGES_LIST_SUCCESS';
const GET_CHAT_MESSAGES_LIST_FAILURE = 'GET_CHAT_MESSAGES_LIST_FAILURE';

export {
    GET_CHAT_MESSAGES_LIST,
    GET_CHAT_MESSAGES_LIST_SUCCESS,
    GET_CHAT_MESSAGES_LIST_FAILURE,
    CREATE_NEW_CHAT,
    CREATE_NEW_CHAT_SUCCESS,
    CREATE_NEW_CHAT_FAILURE
}

// initiate chat
export const createNewChat = (params, callback) => {
    return {
        type: CREATE_NEW_CHAT,
        params,
        callback
    }
};

export const createNewChatSuccess = (data) => {
    return {
        type: CREATE_NEW_CHAT_SUCCESS,
        data
    }
}

export const createNewChatFailure = (data) => {
    return {
        type: CREATE_NEW_CHAT_FAILURE,
        data
    }
}

// get chat messages
export const getChatMessagesList = (params, callback) => {
    return {
        type: GET_CHAT_MESSAGES_LIST,
        params,
        callback
    }
};

export const getChatMessagesListSuccess = (data) => {
    return {
        type: GET_CHAT_MESSAGES_LIST_SUCCESS,
        data
    }
}

export const getChatMessagesListFailure = (data) => {
    return {
        type: GET_CHAT_MESSAGES_LIST_FAILURE,
        data
    }
}