import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { PreAuthPaymentTemplate } from "./PreAuthPaymentTemplate";
import { createPortal } from 'react-dom'
import routes from '../../config/routes';
import { toast } from 'react-toastify';
import Constants from '../../Utility/Constants';
import Actions from '../../redux/action';

export const IFrame = ({
    children,
    setIsNewOrderAction,
    ...props
}) => {
    const [contentRef, setContentRef] = useState(null);
    const history = useHistory();

    const mountNode =
        contentRef?.contentWindow?.document?.body

    const loadedScreen = () => {
        try {
            const currentPath = document.getElementById("payment_iframe_id").contentWindow.location.href;
            setIsNewOrderAction(true)
            if (currentPath.includes('/order/summary')) {
                const id = currentPath.split('/')[currentPath.split('/').length - 1]
                history.replace(routes.ORDER_SUMMARY.replace(':invoiceId', id));
                toast.success(`${Constants.PAYMENT.successMessage}`);
            } else if (currentPath.includes('/order/failed')) {
                history.replace(routes.CHECKOUT);
                toast.error(Constants.PAYMENT.failureMessage);
            } else {
                history.replace(routes.HOME);
                toast.error(Constants.PAYMENT.failureMessage);
            }
        } catch (e) { console.log(e); }
    }
    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <iframe
                title="payment_iframe_title"
                id="payment_iframe_id"
                ref={setContentRef}
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                onLoad={loadedScreen}
                {...props}>
                {mountNode && createPortal(children, mountNode)}
            </iframe>
        </div>
    )
}

export class PaymentScreen extends React.Component {

    render() {
        const { htmlTemplate } = this.props.location.state;
        return htmlTemplate ? <IFrame setIsNewOrderAction={this.props.setIsNewOrderAction}>
            <PreAuthPaymentTemplate data={htmlTemplate} />
        </IFrame> : null
    }
}

export default connect(null, {
    setIsNewOrderAction: Actions.setIsNewOrderAction
})(withRouter(PaymentScreen));