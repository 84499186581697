/*************************************************
 * @exports
 * @class SignupAction.js
 *************************************************/
import moment from 'moment';
import IO from 'socket.io-client';

import { SOCKET_IO_PATH, BASE_URL_SOCKET_IO } from "../config/api_endpoint"
import { store } from '../redux/createStore';
import { getSocketRefreshToken } from '../service';

let socket;


const destroySocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
}
/**
 * Start socket conection
 */
export const startSocketConnect = (callback, props) => {
  if (socket) {
    destroySocket()
  }
  const userToken = 'bearer ' + store?.getState()?.USER?.token?.access_token;


  // connect to socket
  socket = IO(BASE_URL_SOCKET_IO, {
    path: SOCKET_IO_PATH,
    autoConnect: true,
    forceNew: true,
    verify: true,
    //  timeout: 6000,
    //  connect_timeout: 3000,
    transports: ['websocket'],
    jsonp: false,
    query: {
      authorization: userToken
    },
    extraHeaders: {
      Accept: 'application/json; charset=utf-8',
    },
  });
  socket.on('connect', val => {
    console.log('************ APP Connected ************');
  });
  socket.on('disconnect', () => {
    console.log('************ _onDisConnect ************');
  });
  socket.on('socket_connected', data => {
    console.log('************ socket_connected ************');
  });
  socket.on('socket_disconnected', data => {
    console.log('************ socket_disconnected ************');
  });
  const getLastLogin = store?.getState()?.USER?.loggedInTokenExpires
  socket.on('connect_error', data => {
    if (getLastLogin && moment().isAfter(getLastLogin)) {
      console.log('************ error - Entered error condition ************', data);
      getSocketRefreshToken()
    }
    console.log('************ error ************', data);
  });
  socket.on('connect_timeout', data => {
    console.log('************ connect_timeout enter error state ************');
    getSocketRefreshToken()
  });
  const customerId = store?.getState()?.USER?.token?.customerId;
  if (customerId) {
    socket.on(
      `notification/${customerId}`,
      data => {
        console.log('------>>>> Socket notification', data);
        callback(data)
      })
  } else {
    destroySocket()
  }

}
/**
 * Stop socket conection
 */
export const token = () => {
  return {
  };
};
export const stopSocketConnect = () => {
  destroySocket()
};

